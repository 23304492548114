import styles from "./empty-state.module.scss"
import { Box, Button, Image, Stack, Text, rem } from "@mantine/core"
import EmptyStatementsConnections from "@shared/assets/empty-statements-connections.svg"
import { pathConstants } from "frontend/routes/path-constants"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"

export const EmptyState = ({
  pageTitle,
}: {
  pageTitle: string | ReactNode
}) => {
  const navigate = useNavigate()

  return (
    <Box className={styles.EmptyState} pos="relative">
      <Text size="xxl" fw={700} c={"gray"} component="h1">
        {pageTitle}
      </Text>
      <Stack gap={rem(24)} w="100%" justify="center" align="center">
        <Image
          src={EmptyStatementsConnections}
          style={{ mixBlendMode: "multiply" }}
          w={rem(274)}
        />
        <Text c="gray" ta="center">
          There are no established connections yet. Go to
          &apos;Connections&apos;
          <br />
          and add your accounts.
        </Text>
        <Button
          size="sm"
          w={rem(77)}
          onClick={() => {
            navigate(pathConstants.CONNECTIONS, { replace: true })
          }}
        >
          Go
        </Button>
      </Stack>
    </Box>
  )
}
