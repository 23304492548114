import { impersonateButtonImpersonateUserMutation } from "./__generated__/impersonateButtonImpersonateUserMutation.graphql"
import { ActionIcon, Tooltip } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { IconSpy } from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"
import { graphql, useMutation } from "react-relay"

type Props = {
  userId: string
}

const ImpersonateUserMutation = graphql`
  mutation impersonateButtonImpersonateUserMutation(
    $input: ImpersonateUserMutationInput!
  ) {
    impersonateUser(input: $input) {
      errors
      alpyneJwtToken
      superAdminToken
      superAdmin {
        id
        fullName
      }
      user {
        id
        fullName
      }
    }
  }
`

export const ImpersonateButton = ({ userId }: Props) => {
  const [impersonateUser] =
    useMutation<impersonateButtonImpersonateUserMutation>(
      ImpersonateUserMutation
    )
  const { setAlpyneToken, setImpersonatorUser } = useUserStore()

  const handleImpersonation = (impersonatedUserId: string) => {
    impersonateUser({
      variables: {
        input: {
          userId: impersonatedUserId,
        },
      },
      updater: (store, response) => {
        store.invalidateStore()

        const alpyneJwtToken = response.impersonateUser?.alpyneJwtToken
        const impersonator = response.impersonateUser?.superAdmin
        const superAdminToken =
          response.impersonateUser?.superAdminToken || null

        if (alpyneJwtToken && impersonator?.id) {
          setAlpyneToken(alpyneJwtToken)
          setImpersonatorUser(
            impersonator?.id,
            impersonator?.fullName,
            superAdminToken
          )

          window.location.reload()
          window.location.href = pathConstants.PORTFOLIO
        }
      },
      onError: () => {
        notifications.show({
          title: "There was an error impersonating user",
          message: "Please try again",
          variant: "error",
        })
      },
    })
  }

  return (
    <>
      <Tooltip
        label="Impersonation mode gives you access as the firm owner."
        color="gray.7"
        multiline
        w="13.4375rem"
        withArrow
        position="bottom-end"
      >
        <ActionIcon
          variant="transparent"
          aria-label="Impersonate"
          onClick={() => handleImpersonation(userId)}
        >
          <IconSpy size={16} stroke={2} color={themeVars.colors.gray[6]} />
        </ActionIcon>
      </Tooltip>
    </>
  )
}
