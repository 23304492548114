import { DashboardUrl } from "../dashboard-url"
import { dashboardsTableFragment$key } from "./__generated__/dashboardsTableFragment.graphql"
import { ActionIcon, Box, rem } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { DeleteLinkModal } from "@shared/ui/delete-link-modal"
import { Table } from "@shared/ui/table"
import { getDaysAgo } from "@shared/utils/helpers"
import { IconTrash } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

const DashboardsTableFragment = graphql`
  fragment dashboardsTableFragment on HomeInfo
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    dashboards(first: $count, after: $cursor)
      @connection(key: "DashboardsTableFragment_dashboards") {
      edges {
        node {
          id
          name
          url
          createdAt
        }
      }
    }
  }
`

type Dashboard = {
  id: string
  name: string
  url: string
  createdAt: Date
}

type Props = {
  dashboards: dashboardsTableFragment$key
}

export const DashboardsTable = ({ dashboards }: Props) => {
  const { currentClient } = useUserStore()
  const [selectedDashboardId, setSelectedDashboardId] = useState("")

  const [
    deleteModalOpened,
    { open: deleteModalOpen, close: deleteModalClose },
  ] = useDisclosure(false)

  const {
    dashboards: { edges },
  } = useFragment(DashboardsTableFragment, dashboards)

  const tableData: Dashboard[] = edges.map((dashboard) => ({
    id: dashboard.node.id,
    name: dashboard.node.name,
    url: dashboard.node.url,
    createdAt: new Date(dashboard.node.createdAt),
  }))

  const columns: ColumnDef<Dashboard>[] = [
    {
      header: "Dashboard Name",
      accessorKey: "name",
    },
    {
      header: "Link",
      accessorKey: "url",
      cell: (info) => {
        const dashboardUrl = info.getValue<string>()

        return <DashboardUrl url={dashboardUrl} />
      },
    },
    {
      header: "Generated Day",
      accessorKey: "createdAt",
      cell: (info) => getDaysAgo(info.getValue<Date>()),
    },
    {
      header: "",
      accessorKey: "id",
      enableSorting: false,
      cell: (info) => {
        const dashboardId = info.getValue<string>()

        return (
          <ActionIcon
            variant="white"
            c="gray"
            size={rem(16)}
            onClick={() => {
              setSelectedDashboardId(dashboardId)
              deleteModalOpen()
            }}
          >
            <IconTrash size={rem(16)} />
          </ActionIcon>
        )
      },
    },
  ]

  const defaultDashboardId = currentClient.dashboardId

  return (
    <Box>
      <Table columns={columns} data={tableData} />
      {defaultDashboardId && (
        <DeleteLinkModal
          opened={deleteModalOpened}
          sharedDashboardId={selectedDashboardId}
          dashboardId={currentClient.dashboardId ?? 0}
          onClose={deleteModalClose}
        />
      )}
    </Box>
  )
}
