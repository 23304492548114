import { deleteFirmModalDeleteFirmMutation as DeleteFirmModalDeleteFirmMutationType } from "./__generated__/deleteFirmModalDeleteFirmMutation.graphql"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { ConnectionHandler, graphql, useMutation } from "react-relay"

const DeleteFirmModalDeleteFirmMutation = graphql`
  mutation deleteFirmModalDeleteFirmMutation($input: DeleteFirmMutationInput!) {
    deleteFirm(input: $input) {
      firm {
        id
      }
    }
  }
`

type FirmType = {
  id: string
  name: string
}

type Props = {
  firmData: FirmType
  opened: boolean
  onClose: () => void
}

export const DeleteFirmModal = ({ firmData, opened, onClose }: Props) => {
  const [deleteFirmMutation, deleteFirmLoading] =
    useMutation<DeleteFirmModalDeleteFirmMutationType>(
      DeleteFirmModalDeleteFirmMutation
    )

  const hasName = firmData.name == "--" ? false : true

  const handleDeleteClick = () => {
    deleteFirmMutation({
      variables: {
        input: {
          firmId: firmData.id,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "Firm Successfully Deleted",
          message: "",
        })
        onClose()
      },
      updater: (store) => {
        const firms = ConnectionHandler.getConnection(
          store.getRoot(),
          "FirmsTableFragment_getFirms"
        )

        if (firms) {
          ConnectionHandler.deleteNode(firms, firmData.id)
        }
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
      },
    })
  }

  const firmName = `"${firmData.name}"`

  return (
    <ConfirmationModal
      title={`Are you absolutely certain about delete ${
        hasName ? firmName : "this firm"
      }?`}
      opened={opened}
      onClose={onClose}
      cancelButtonProps={{
        onClick: () => {
          onClose()
        },
      }}
      confirmButtonProps={{
        onClick: handleDeleteClick,
        children: "Delete Firm",
        color: "red",
        loading: deleteFirmLoading,
      }}
      isLoading={deleteFirmLoading}
      fieldNameType="firm"
      fieldNameValidation={hasName}
      fieldNameValue={firmData.name}
      fieldNameValidationLabel="Firm Name"
      fieldNameValidationPlaceholder="Enter the exact firm name to proceed"
    >
      Deleting the firm will permanently erase all data and existing member
      accounts will also be irreversibly removed.
    </ConfirmationModal>
  )
}
