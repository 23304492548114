import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  Button,
  Group,
  ButtonProps as MantineButtonProps,
  Modal,
  ModalProps,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { DOMAttributes, PropsWithChildren, isValidElement } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

type ButtonProps = DOMAttributes<HTMLButtonElement> & MantineButtonProps

type Props = ModalProps & {
  title: string
  opened: boolean
  onClose: () => void
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps
  fieldNameType?: string
  fieldNameValidation?: boolean
  fieldNameValue?: string
  fieldNameValidationLabel?: string
  fieldNameValidationPlaceholder?: string
  isLoading?: boolean
}

export const ConfirmationModal = ({
  title,
  opened,
  onClose,
  fieldNameType = "",
  fieldNameValidation = false,
  fieldNameValue = "",
  fieldNameValidationLabel = "",
  fieldNameValidationPlaceholder = "",
  isLoading = false,
  cancelButtonProps,
  confirmButtonProps,
  children,
  ...modalProps
}: PropsWithChildren<Props>) => {
  const formValidationSchema = z.object({
    confirmField: z.literal(fieldNameValue, {
      errorMap: () => ({
        message: `Invalid name, please make sure you enter the exact ${fieldNameType} name.`,
      }),
    }),
  })

  type formValues = z.infer<typeof formValidationSchema>

  const {
    register,
    reset,
    formState: { isValid, errors },
  } = useForm<formValues>({
    mode: "onTouched",
    defaultValues: { confirmField: "" },
    resolver: zodResolver(formValidationSchema),
  })

  const submitDisabled = !isValid && fieldNameValidation

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose()
        reset()
      }}
      title={
        <Text size="md" fw={700} c="gray.7">
          {title}
        </Text>
      }
      centered
      size={"23.75rem"}
      shadow="sm"
      overlayProps={{
        bg: "#3333334D",
      }}
      closeButtonProps={{
        iconSize: "1rem",
        c: themeVars.colors.gray["6"],
      }}
      withCloseButton
      closeOnClickOutside={false}
      {...modalProps}
    >
      <Stack gap="1.5rem">
        <Stack gap="1rem">
          {isValidElement(children) ? (
            children
          ) : (
            <Box fz="sm" c="gray.7" lh="sm">
              {children}
            </Box>
          )}
          {fieldNameValidation && (
            <>
              <TextInput
                {...register("confirmField")}
                placeholder={fieldNameValidationPlaceholder}
                label={fieldNameValidationLabel}
                error={errors.confirmField?.message}
                disabled={isLoading}
              />
            </>
          )}
        </Stack>
        <Group justify="flex-end" gap="1rem">
          <Button
            variant="default"
            {...cancelButtonProps}
            onClick={(e) => {
              cancelButtonProps?.onClick && cancelButtonProps?.onClick(e)
              reset()
            }}
          >
            {cancelButtonProps?.children ?? "Cancel"}
          </Button>
          <Button
            data-autofocus
            {...confirmButtonProps}
            onClick={(e) => {
              confirmButtonProps?.onClick && confirmButtonProps?.onClick(e)
            }}
            disabled={submitDisabled}
          >
            {confirmButtonProps?.children ?? "Confirm"}
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
