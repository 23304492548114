/**
 * @generated SignedSource<<3834996d56a61226546c8bcc7050d99f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type shareButtonGenerateLinkMutation$variables = {
  dashboardId: number;
};
export type shareButtonGenerateLinkMutation$data = {
  readonly createSharedDashboard: {
    readonly sharedDashboard: {
      readonly id: string;
      readonly metabaseDashboardId: number;
      readonly name: string;
      readonly url: string;
      readonly uuid: string;
    } | null;
  } | null;
};
export type shareButtonGenerateLinkMutation = {
  response: shareButtonGenerateLinkMutation$data;
  variables: shareButtonGenerateLinkMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dashboardId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "dashboardId",
            "variableName": "dashboardId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreateSharedDashboardMutationPayload",
    "kind": "LinkedField",
    "name": "createSharedDashboard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SharedDashboard",
        "kind": "LinkedField",
        "name": "sharedDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metabaseDashboardId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shareButtonGenerateLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shareButtonGenerateLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9694428d8a3edbadb547d4d55c57d989",
    "id": null,
    "metadata": {},
    "name": "shareButtonGenerateLinkMutation",
    "operationKind": "mutation",
    "text": "mutation shareButtonGenerateLinkMutation(\n  $dashboardId: Int!\n) {\n  createSharedDashboard(input: {dashboardId: $dashboardId}) {\n    sharedDashboard {\n      id\n      metabaseDashboardId\n      name\n      uuid\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "101711d17b3091d0c3059fcbb381b402";

export default node;
