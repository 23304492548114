import { addAccountAccountsFragment$key } from "./__generated__/addAccountAccountsFragment.graphql"
import { addAccountIncomeCashFlowSettingMutation } from "./__generated__/addAccountIncomeCashFlowSettingMutation.graphql"
import { addAccountSearchIncomeAccountsQuery } from "./__generated__/addAccountSearchIncomeAccountsQuery.graphql"
import { Flex, InputLabel } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { notifications } from "@shared/ui/notifications"
import { SelectCombobox } from "@shared/ui/select-combobox"
import { IconTextPlus } from "@tabler/icons-react"
import { useMemo } from "react"
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from "react-relay"

const searchAccountsQuery = graphql`
  query addAccountSearchIncomeAccountsQuery(
    $clientId: ID!
    $connectionIds: [ID!]!
  ) {
    ...addAccountAccountsFragment
      @arguments(clientId: $clientId, connectionIds: $connectionIds)
  }
`
const accountsFragment = graphql`
  fragment addAccountAccountsFragment on Query
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int" }
    search: { type: "String" }
    clientId: { type: "ID!" }
    connectionIds: { type: "[ID!]!" }
  ) {
    searchIncomeAccounts(
      first: $count
      after: $cursor
      search: $search
      clientId: $clientId
      connectionIds: $connectionIds
    ) @connection(key: "SearchIncomeAccounts__searchIncomeAccounts") {
      edges {
        node {
          id
          displayName
          includeInCashFlow
          name
        }
      }
    }
  }
`
const addAccountMutation = graphql`
  mutation addAccountIncomeCashFlowSettingMutation(
    $input: IncomeCashFlowSettingMutationInput!
    $connections: [ID!]!
  ) {
    incomeCashFlowSetting(input: $input) {
      accounts
        @appendNode(connections: $connections, edgeTypeName: "LedgerAccount") {
        accountSubtype
        accountType
        active
        cashFlowCategory
        createdAt
        description
        displayName
        id
        includeInCashFlow
        name
        number
        parent
        subaccount
        updatedAt
      }
    }
  }
`
type Props = {
  data: addAccountAccountsFragment$key
  currentClientId: string
  connectionIds: string[]
}
const AddAccountInput = ({ data, connectionIds, currentClientId }: Props) => {
  const {
    searchIncomeAccounts: { edges },
  } = useFragment(accountsFragment, data)
  const [addAccount] =
    useMutation<addAccountIncomeCashFlowSettingMutation>(addAccountMutation)

  const saveAddAccount = (val: string) => {
    const connectionId = ConnectionHandler.getConnectionID(
      "root",
      "IncomeAccountsTableFragment_getIncomeAccountsNonCash",
      {
        clientId: currentClientId,
        connectionIds,
      }
    )
    addAccount({
      variables: {
        input: {
          ids: [val],
        },
        connections: [connectionId],
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "",
          message: "Successfully Added to the Income Accounts List.",
        })
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
      },
    })
  }
  const selectOptions = useMemo(
    () =>
      edges
        .filter(({ node }) => !node.includeInCashFlow)
        .map(({ node }) => ({
          label: node.displayName,
          value: node.id,
        })),
    [edges]
  )

  return (
    <div>
      <Flex align={"center"} gap={"xs"}>
        <InputLabel c={themeVars.colors.gray[7]}>Add Account:</InputLabel>
        <SelectCombobox
          value={null}
          options={selectOptions}
          inputProps={{
            label: "",
            w: "18.5rem",
            rightSection: null,
            leftSection: <IconTextPlus size={"1rem"} />,
          }}
          placeholder="Account Name"
          autocomplete
          onChange={(val) => saveAddAccount(val)}
        />
      </Flex>
    </div>
  )
}
export const AddAccount = ({ connectionIds }: { connectionIds: string[] }) => {
  const { currentClient } = useUserStore()
  const data = useLazyLoadQuery<addAccountSearchIncomeAccountsQuery>(
    searchAccountsQuery,
    {
      clientId: currentClient.id ?? "",
      connectionIds: [connectionIds[0]],
    }
  )

  return (
    <AddAccountInput
      data={data}
      currentClientId={currentClient.id ?? ""}
      connectionIds={[connectionIds[0]]}
    />
  )
}
