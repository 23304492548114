import { spreadsheetsDrawerListSpreadsheetsQuery } from "../__generated__/spreadsheetsDrawerListSpreadsheetsQuery.graphql"
import {
  ListSpreadsheetsFromWorkbook,
  SpreadsheetsDrawerType,
} from "../spreadsheets-drawer"
import { spreadsheetsItemsFieldsFragment$key } from "./__generated__/spreadsheetsItemsFieldsFragment.graphql"
import { WorkbookSpreadsheet } from "@components/spreadsheets/workbook-spreadsheet/workbook-spreadsheet"
import { Button, Flex, Stack, Text, rem } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { useEffect, useMemo } from "react"
import { useFormContext } from "react-hook-form"
import {
  PreloadedQuery,
  graphql,
  useFragment,
  usePreloadedQuery,
} from "react-relay"

const ReportSelectorFragment = graphql`
  fragment spreadsheetsItemsFieldsFragment on Query
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int" }
    clientId: { type: "ID!" }
    reportType: { type: "[SavedReportsTypeEnum!]" }
    search: { type: "String" }
  ) {
    getSavedReports(
      clientId: $clientId
      reportType: $reportType
      search: $search
      first: $count
      after: $cursor
    ) @connection(key: "ReportSelector__getSavedReports", filters: []) {
      edges {
        node {
          ... on SavedStatement {
            id
            name
            filterType
            type
            updatedAt
          }
          ... on SavedTransaction {
            id
            name
            type
            updatedAt
          }
        }
      }
    }
  }
`

type Props = {
  onAppendSpreadsheet: () => void
  isNewWorkbook?: boolean
  disabledSpreadsheets?: boolean
  itemsCollapsed?: boolean
  onRemoveSpreadsheet: (index: number) => void
  queryRef: PreloadedQuery<spreadsheetsDrawerListSpreadsheetsQuery>
  reportsData: spreadsheetsItemsFieldsFragment$key
  disabled?: boolean
}
export const SpreadsheetsItemsFields = ({
  onAppendSpreadsheet,
  onRemoveSpreadsheet,
  queryRef,
  isNewWorkbook,
  disabledSpreadsheets,
  itemsCollapsed,
  reportsData,
  disabled = false,
}: Props) => {
  const { getValues, setValue } = useFormContext<SpreadsheetsDrawerType>()
  const {
    listSpreadsheetsFromIntegration: { edges },
    getMetabaseQuestions: { edges: questionsEdges },
  } = usePreloadedQuery(ListSpreadsheetsFromWorkbook, queryRef)
  const {
    getSavedReports: { edges: savedReportsEdges },
  } = useFragment(ReportSelectorFragment, reportsData)

  const availableSpreadsheets = useMemo(
    () =>
      edges.map(({ node }) => ({
        id: node.id,
        name: node.name ?? "",
      })),
    [edges]
  )
  const availableQuestions = useMemo(
    () =>
      questionsEdges.map(({ node }) => ({
        id: node.id,
        name: node.name ?? "",
        error: node.status == "BROKEN" ? "Review Question" : undefined,
      })),
    [questionsEdges]
  )
  const availableStatements = useMemo(
    () =>
      savedReportsEdges.map(({ node }) => ({
        id: node.id ?? "",
        name: node.name ?? "",
        type: node.type,
      })),
    [savedReportsEdges]
  )
  useEffect(() => {
    if (isNewWorkbook) {
      const firstSpreadsheet = availableSpreadsheets.at(0)
      if (!firstSpreadsheet) return
      setValue("spreadsheets", [
        {
          id: crypto.randomUUID(),
          spreadsheetable: firstSpreadsheet.id,
          sheet: firstSpreadsheet.id,
          name: firstSpreadsheet.name ?? "",
          spreadsheetableType: "MetabaseQuestion",
        },
      ])
    }
  }, [availableSpreadsheets, isNewWorkbook, setValue])

  return (
    <Stack gap={rem(16)}>
      <Text size="sm" fw={500}>
        Spreadsheets
      </Text>
      {getValues("spreadsheets").map((spreadsheet, index) => (
        <WorkbookSpreadsheet
          key={spreadsheet.id}
          index={index}
          collapsed={!!itemsCollapsed}
          erroneousSheet={spreadsheet.erroneousSheet}
          isNewWorkbook={!!isNewWorkbook}
          availableSpreadsheets={availableSpreadsheets}
          availableStatements={availableStatements}
          availableQuestions={availableQuestions}
          onDelete={() => {
            onRemoveSpreadsheet(index)
          }}
        />
      ))}
      {!disabled && (
        <Flex justify="space-between" align="center">
          <Text size="sm" c="gray">
            Do you want to link additional spreadsheets?
          </Text>
          <Button
            leftSection={<IconPlus size={12} />}
            variant="outline"
            size="compact-xs"
            disabled={!!disabledSpreadsheets}
            px={rem(10)}
            fz={rem(12)}
            onClick={onAppendSpreadsheet}
          >
            Add New
          </Button>
        </Flex>
      )}
    </Stack>
  )
}
