/**
 * @generated SignedSource<<639053b7c2c3fc7ab1d82605f5c42be4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type customerSelectorGetCustomersFragment$data = {
  readonly getCustomers: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
      };
    }>;
  };
  readonly " $fragmentType": "customerSelectorGetCustomersFragment";
};
export type customerSelectorGetCustomersFragment$key = {
  readonly " $data"?: customerSelectorGetCustomersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"customerSelectorGetCustomersFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "connectionIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "customerSelectorGetCustomersFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "connectionIds",
          "variableName": "connectionIds"
        }
      ],
      "concreteType": "CustomerConnection",
      "kind": "LinkedField",
      "name": "getCustomers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Customer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cd9d7be5a1d364022411e623bdcc1c75";

export default node;
