import actionIconClasses from "./action-icon/action-icon.module.scss"
import ButtonClasses from "./button/button.module.scss"
import { appColors } from "./colors"
import DividerClasses from "./divider/divider.module.scss"
import InputWrapperClasses from "./input-wrapper/input-wrapper.module.scss"
import MenuClasses from "./menu/menu.module.scss"
import themeIconClasses from "./theme-icon/theme-icon.module.scss"
import { appTypography } from "./typography"
import {
  ActionIcon,
  Button,
  Divider,
  InputWrapper,
  MantineProvider,
  Menu,
  ThemeIcon,
  createTheme,
} from "@mantine/core"
import { themeToVars } from "@mantine/vanilla-extract"
import React from "react"

const appTheme = createTheme({
  colors: appColors,
  components: {
    ActionIcon: ActionIcon.extend({
      classNames: actionIconClasses,
    }),
    ThemeIcon: ThemeIcon.extend({
      classNames: themeIconClasses,
    }),
    Divider: Divider.extend({
      classNames: DividerClasses,
    }),
    Button: Button.extend({
      classNames: {
        label: ButtonClasses.label,
      },
    }),
    InputWrapper: InputWrapper.extend({
      classNames: {
        label: InputWrapperClasses.Label,
        description: InputWrapperClasses.Description,
      },
    }),
    Menu: Menu.extend({
      classNames: {
        item: MenuClasses.MenuItem,
      },
    }),
  },
  ...appTypography,
})

export const themeVars = themeToVars(appTheme)

export const Theme = ({ children }: React.PropsWithChildren) => {
  return <MantineProvider theme={appTheme}>{children}</MantineProvider>
}
