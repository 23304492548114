import styles from "./not-found-error.module.scss"
import { Box, Text } from "@mantine/core"
import NotfoundError from "@shared/assets/errors/not-found-error.svg"

export const NotFoundError = () => {
  return (
    <Box className={styles.NotFoundError}>
      <img src={NotfoundError} />
      <Text c="gray">Page not found</Text>
    </Box>
  )
}
