/**
 * @generated SignedSource<<0a1abf3c4d437fb9d9fb488d21b4def5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type updatePasswordUserUpdatePasswordWithTokenMutation$variables = {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
};
export type updatePasswordUserUpdatePasswordWithTokenMutation$data = {
  readonly userUpdatePasswordWithToken: {
    readonly authenticatable: {
      readonly email: string;
    };
  } | null;
};
export type updatePasswordUserUpdatePasswordWithTokenMutation = {
  response: updatePasswordUserUpdatePasswordWithTokenMutation$data;
  variables: updatePasswordUserUpdatePasswordWithTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passwordConfirmation"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "resetPasswordToken"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "passwordConfirmation",
        "variableName": "passwordConfirmation"
      },
      {
        "kind": "Variable",
        "name": "resetPasswordToken",
        "variableName": "resetPasswordToken"
      }
    ],
    "concreteType": "UserUpdatePasswordWithTokenPayload",
    "kind": "LinkedField",
    "name": "userUpdatePasswordWithToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Authenticatable",
        "kind": "LinkedField",
        "name": "authenticatable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePasswordUserUpdatePasswordWithTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePasswordUserUpdatePasswordWithTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c33befb599b8e7b3cd5bb5fbf45b149f",
    "id": null,
    "metadata": {},
    "name": "updatePasswordUserUpdatePasswordWithTokenMutation",
    "operationKind": "mutation",
    "text": "mutation updatePasswordUserUpdatePasswordWithTokenMutation(\n  $password: String!\n  $passwordConfirmation: String!\n  $resetPasswordToken: String!\n) {\n  userUpdatePasswordWithToken(password: $password, passwordConfirmation: $passwordConfirmation, resetPasswordToken: $resetPasswordToken) {\n    authenticatable {\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0cf0ee31487aa6641e4220dd8212c929";

export default node;
