import { firmOverviewQuery as FirmOverviewQueryType } from "./__generated__/firmOverviewQuery.graphql"
import { FirmsTable } from "./firms_table"
import { InviteFirmDrawer } from "./invite-firm-drawer"
import { Box, Button, Group, Space, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { graphql, useLazyLoadQuery } from "react-relay"

const FirmOverviewQuery = graphql`
  query firmOverviewQuery {
    ...firmsTableFragment
  }
`

export const FirmOverview = () => {
  const firmsData = useLazyLoadQuery<FirmOverviewQueryType>(
    FirmOverviewQuery,
    {}
  )
  const [opened, { open, close }] = useDisclosure(false)

  return (
    <Box>
      <Group w="100%" justify="space-between">
        <Text size="xxl" c="gray" fw={700}>
          Firm Overview
        </Text>
        <Button variant="outline" onClick={open}>
          Invite Firm
        </Button>
      </Group>
      <Space h="2.5rem" />
      <FirmsTable firms={firmsData} />
      <InviteFirmDrawer onClose={close} opened={opened} />
    </Box>
  )
}
