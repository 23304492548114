/**
 * @generated SignedSource<<f143523c6cef030e607bebc77fe828a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AmountRateEnum = "BETWEEN" | "EQUAL_TO" | "GREATER_THAN" | "GREATER_THAN_OR_EQUAL_TO" | "LESS_THAN" | "LESS_THAN_OR_EQUAL_TO";
export type transactionsContentGetSavedTransactionQuery$variables = {
  id: string;
  skip: boolean;
};
export type transactionsContentGetSavedTransactionQuery$data = {
  readonly getSavedTransaction?: {
    readonly data: {
      readonly accountIds: ReadonlyArray<string> | null;
      readonly amountRate: AmountRateEnum | null;
      readonly classIds: ReadonlyArray<string> | null;
      readonly customerIds: ReadonlyArray<string> | null;
      readonly endDate: string | null;
      readonly entityIds: ReadonlyArray<string> | null;
      readonly limit: number | null;
      readonly maxRange: number | null;
      readonly minRange: number | null;
      readonly pageNumber: number | null;
      readonly search: string | null;
      readonly startDate: string | null;
      readonly vendorIds: ReadonlyArray<string> | null;
    } | null;
    readonly id: string;
    readonly name: string | null;
  };
};
export type transactionsContentGetSavedTransactionQuery = {
  response: transactionsContentGetSavedTransactionQuery$data;
  variables: transactionsContentGetSavedTransactionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": "SavedTransaction",
        "kind": "LinkedField",
        "name": "getSavedTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedTransactionData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amountRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "classIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entityIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "limit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "search",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vendorIds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "transactionsContentGetSavedTransactionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transactionsContentGetSavedTransactionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2de88c8d5c25bf50354000cb8a1cc4cd",
    "id": null,
    "metadata": {},
    "name": "transactionsContentGetSavedTransactionQuery",
    "operationKind": "query",
    "text": "query transactionsContentGetSavedTransactionQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  getSavedTransaction(id: $id) @skip(if: $skip) {\n    id\n    name\n    data {\n      accountIds\n      amountRate\n      classIds\n      customerIds\n      endDate\n      entityIds\n      limit\n      maxRange\n      minRange\n      pageNumber\n      search\n      startDate\n      vendorIds\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3296329512cb5758e6ca83baf3bd08ea";

export default node;
