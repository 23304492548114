import { syncButtonFragment$key } from "./__generated__/syncButtonFragment.graphql"
import { syncButtonMutation as SyncButtonMutationType } from "./__generated__/syncButtonMutation.graphql"
import { ChargebeeConnectionModal } from "@components/connections/connections-modals/chargebee-connection-modal"
import { StripeConnectionModal } from "@components/connections/connections-modals/stripe-connection-modal"
import {
  IntegrationTypeEnum,
  oauthRedirectorQuery as OauthRedirectorQueryType,
} from "@components/connections/new-connection-button/oauth-redirector/__generated__/oauthRedirectorQuery.graphql"
import {
  OauthRedirector,
  OauthRedirectorQuery,
} from "@components/connections/new-connection-button/oauth-redirector/oauth-redirector"
import { Box, Button, Group, Loader, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@shared/ui/notifications"
import { IconPlug, IconRefresh } from "@tabler/icons-react"
import { Suspense } from "react"
import { graphql, useFragment, useMutation, useQueryLoader } from "react-relay"

type ConnectionOptions = {
  "Quickbooks Online": IntegrationTypeEnum
  Salesforce: IntegrationTypeEnum
  [key: string]: IntegrationTypeEnum
}

const connectionOptions: ConnectionOptions = {
  "Quickbooks Online": "QUICKBOOKS",
  Salesforce: "SALESFORCE",
}

const SyncButtonMutation = graphql`
  mutation syncButtonMutation($input: SyncDataInput!) {
    integrationSyncData(input: $input) {
      errors
      success
      integration {
        id
        name
        slug
        status
        lastSync
      }
    }
  }
`

const SyncButtonFragment = graphql`
  fragment syncButtonFragment on SystemIntegration {
    id
    status
    system {
      name
      slug
    }
  }
`

type Props = {
  statusRef: syncButtonFragment$key
  domain?: string
}

export const SyncButton = ({ statusRef, domain }: Props) => {
  const [
    chargebeeOpened,
    { open: openChargebeeModal, close: closeChargebeeModal },
  ] = useDisclosure(false)
  const [stripeOpened, { open: openStripeModal, close: closeStripeModal }] =
    useDisclosure(false)
  const {
    id,
    status,
    system: { name, slug },
  } = useFragment(SyncButtonFragment, statusRef)
  const [oauthQueryRef, oauthQuery] =
    useQueryLoader<OauthRedirectorQueryType>(OauthRedirectorQuery)
  const connectionIsBroken = status === "BROKEN"
  const [syncIntegration, syncIntegrationLoading] =
    useMutation<SyncButtonMutationType>(SyncButtonMutation)

  const handleSync = () => {
    syncIntegration({
      variables: {
        input: {
          connectionId: id,
        },
      },
      onCompleted: ({ integrationSyncData }) => {
        integrationSyncData?.success &&
          notifications.show({
            title: "Sync Started!",
            message: "It will take a while to synchronize all the data.",
            variant: "success",
          })
      },
      onError: () => {
        notifications.show({
          title: "Sync Connection Failed",
          message:
            "An unexpected error occurred while attempting to sync. Please try again.",
          variant: "error",
        })
      },
    })
  }

  const handleReconnect = () => {
    if (slug === "chargebee") {
      openChargebeeModal()
      return
    }
    if (slug === "stripe") {
      openStripeModal()
      return
    }
    oauthQuery({
      input: connectionOptions[name],
    })
  }

  return (
    <>
      <Box mt="0.5rem">
        {status === "SYNCING" ? (
          <Group miw="max-content" gap="0.25rem" align="flex-start">
            <Loader size={14} color="blue" />
            <Text size="sm" c="gray.7">
              Syncing
            </Text>
          </Group>
        ) : (
          <Button
            size="compact-sm"
            fw="normal"
            pl="0.75rem"
            leftSection={
              connectionIsBroken ? (
                <IconPlug size={12} />
              ) : (
                <IconRefresh size={12} />
              )
            }
            loading={syncIntegrationLoading || !!oauthQueryRef}
            onClick={connectionIsBroken ? handleReconnect : handleSync}
          >
            <Text size="xs">
              {connectionIsBroken ? "Reconnect" : "Sync Data"}
            </Text>
          </Button>
        )}
        {oauthQueryRef && (
          <Suspense>
            <OauthRedirector queryRef={oauthQueryRef} />
          </Suspense>
        )}
      </Box>
      <ChargebeeConnectionModal
        defaultValues={{ domain: domain || "", apiKey: "" }}
        opened={chargebeeOpened}
        onClose={closeChargebeeModal}
        confirmButtonProps={{
          children: "Connect",
          color: "blue",
        }}
        cancelButtonProps={{
          onClick: closeChargebeeModal,
        }}
      />
      <StripeConnectionModal
        opened={stripeOpened}
        onClose={closeStripeModal}
        confirmButtonProps={{
          children: "Connect",
          color: "blue",
        }}
        cancelButtonProps={{
          onClick: closeStripeModal,
        }}
      />
    </>
  )
}
