import { AccountSelector } from "../account-selector"
import { ClassSelector } from "../class-selector"
import { CustomerSelector } from "../customer-selector"
import { ExportTransactionsButton } from "../export-transactions-button"
import { AmountRateEnum } from "../export-transactions-button/utils"
import { SaveTransactionButton } from "../save-transaction-button"
import { TransactionsTable } from "../transactions-table"
import { VendorSelector } from "../vendor-selector"
import { transactionsContentConnectionsQuery } from "./__generated__/transactionsContentConnectionsQuery.graphql"
import { transactionsContentGetSavedTransactionQuery } from "./__generated__/transactionsContentGetSavedTransactionQuery.graphql"
import {
  AmountRateEnum as AmountRateRelayEnum,
  transactionsContentQuery,
} from "./__generated__/transactionsContentQuery.graphql"
import { EntitiesComponent } from "@components/statements/entities-component/entities-component"
import { ReportPeriodSelector } from "@components/statements/report-period-selector/report-period-selector"
import {
  Box,
  Card,
  Divider,
  Flex,
  Group,
  InputLabel,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { RangeSelector } from "@shared/ui/range-selector"
import { convertDateToISOString } from "@shared/utils/helpers"
import { IconChevronLeft, IconSearch } from "@tabler/icons-react"
import {
  addMinutes,
  endOfDay,
  endOfMonth,
  startOfYear,
  subMonths,
} from "date-fns"
import { pathConstants } from "frontend/routes/path-constants"
import { debounce, isEqual } from "lodash"
import { useMemo, useState, useTransition } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

const TransactionsQuery = graphql`
  query transactionsContentQuery(
    $accountIds: [ID!]
    $connectionIds: [ID!]!
    $classIds: [ID!]
    $customerIds: [ID!]
    $vendorIds: [ID!]
    $amountRate: AmountRateEnum
    $startDate: ISO8601DateTime
    $endDate: ISO8601DateTime
    $limit: Int
    $pageNumber: Int
    $search: String
    $maxRange: Float
    $minRange: Float
  ) {
    ...accountSelectorFragment @arguments(connectionIds: $connectionIds)
    ...customerSelectorGetCustomersFragment
      @arguments(connectionIds: $connectionIds)
    ...vendorSelectorGetVendorsFragment
      @arguments(connectionIds: $connectionIds)
    ...classSelectorGetClassesFragment @arguments(connectionIds: $connectionIds)
    ...transactionsTableFragment
      @arguments(
        systemConnectionIds: $connectionIds
        accountIds: $accountIds
        classIds: $classIds
        customerIds: $customerIds
        vendorIds: $vendorIds
        amountRate: $amountRate
        startDate: $startDate
        endDate: $endDate
        limit: $limit
        pageNumber: $pageNumber
        search: $search
        maxRange: $maxRange
        minRange: $minRange
      )
    getTransactions(
      systemConnectionIds: $connectionIds
      accountIds: $accountIds
      classIds: $classIds
      customerIds: $customerIds
      vendorIds: $vendorIds
      amountRate: $amountRate
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      pageNumber: $pageNumber
      search: $search
      maxRange: $maxRange
      minRange: $minRange
    ) {
      classTracking
    }
    getClasses(connectionIds: $connectionIds) {
      count
    }
  }
`
export const ConnectionsQuery = graphql`
  query transactionsContentConnectionsQuery($systemType: String) {
    getIntegrations(systemType: $systemType) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const TransactionsGetSavedTransaction = graphql`
  query transactionsContentGetSavedTransactionQuery($id: ID!, $skip: Boolean!) {
    getSavedTransaction(id: $id) @skip(if: $skip) {
      id
      name
      data {
        accountIds
        amountRate
        classIds
        customerIds
        endDate
        entityIds
        limit
        maxRange
        minRange
        pageNumber
        search
        startDate
        vendorIds
      }
    }
  }
`
const DEFAULT_TABLE_LIMIT = "50"
const DEFAULT_PAGE = "1"
const DEFAULT_DATES = {
  startDate: startOfYear(new Date()),
  endDate: endOfMonth(subMonths(new Date(), 1)),
}

export const TransactionsContent = () => {
  const navigate = useNavigate()

  const { id } = useParams()

  const isSavedTransaction = !!id

  const { getSavedTransaction } =
    useLazyLoadQuery<transactionsContentGetSavedTransactionQuery>(
      TransactionsGetSavedTransaction,
      { id: id ?? "", skip: !isSavedTransaction }
    )

  const transactionData = getSavedTransaction?.data

  const transactionValues = transactionData && {
    connectionIds: transactionData.entityIds?.map((val) => val) ?? [],
    accountIds: transactionData.accountIds?.map((val) => val) ?? [],
    customerIds: transactionData.customerIds?.map((val) => val) ?? [],
    vendorIds: transactionData.vendorIds?.map((val) => val) ?? [],
    classIds: transactionData.classIds?.map((val) => val) ?? [],
    limit: transactionData.limit?.toString() ?? DEFAULT_TABLE_LIMIT,
    ...(transactionData.amountRate && {
      amountRangeType:
        transactionData.amountRate.toLowerCase() as AmountRateEnum,
    }),
    search: transactionData.search ?? "",
    amountRangeValue: [
      transactionData.minRange?.toString() ?? "",
      transactionData.maxRange?.toString() ?? "",
    ],
    page: transactionData.pageNumber?.toString() ?? DEFAULT_PAGE,
    startDate:
      transactionData.startDate ??
      convertDateToISOString(DEFAULT_DATES.startDate),
    endDate:
      transactionData.endDate ?? convertDateToISOString(DEFAULT_DATES.endDate),
  }

  const { currentClient, lastUpdate, transactionPath, setTransactionPath } =
    useUserStore()
  const [params, setSearchParams] = useSearchParams(
    transactionValues ?? undefined
  )

  const paramValues = {
    connectionIds: params.getAll("connectionIds"),
    accountIds: params.getAll("accountIds"),
    customerIds: params.getAll("customerIds"),
    vendorIds: params.getAll("vendorIds"),
    classIds: params.getAll("classIds"),
    limit: params.get("limit") ?? DEFAULT_TABLE_LIMIT,
    search: params.get("search") ?? null,
    amountRangeType: params.get("amountRangeType"),
    amountRangeValue: params.getAll("amountRangeValue"),
    page: params.get("page") ?? DEFAULT_PAGE,
    startDate:
      params.get("startDate") ||
      convertDateToISOString(DEFAULT_DATES.startDate),
    endDate:
      params.get("endDate") || convertDateToISOString(DEFAULT_DATES.endDate),
  }
  const minAmount = paramValues.amountRangeValue.at(0)
    ? Number(paramValues.amountRangeValue.at(0))
    : null
  const maxAmount = paramValues.amountRangeValue.at(1)
    ? Number(paramValues.amountRangeValue.at(1))
    : null

  const [accountName, setAccountName] = useState<string>()
  const [, startTransition] = useTransition()

  const connectionsData = useLazyLoadQuery<transactionsContentConnectionsQuery>(
    ConnectionsQuery,
    {
      systemType: "ledger",
    },
    {
      fetchKey: [currentClient.id, lastUpdate].join(""),
      fetchPolicy: "network-only",
    }
  )

  const data = useLazyLoadQuery<transactionsContentQuery>(
    TransactionsQuery,
    {
      accountIds: paramValues.accountIds,
      connectionIds: paramValues.connectionIds,
      search: paramValues.search,
      classIds: paramValues.classIds || [],
      vendorIds: paramValues.vendorIds || [],
      customerIds: paramValues.customerIds || [],
      limit: Number(paramValues.limit),
      pageNumber: Number(paramValues.page),
      startDate: paramValues.startDate,
      endDate: paramValues.endDate,
      amountRate:
        paramValues.amountRangeType?.toUpperCase() as AmountRateRelayEnum,
      minRange: minAmount,
      maxRange: maxAmount || minAmount,
    },
    {
      fetchKey: [currentClient.id, lastUpdate].join(""),
      fetchPolicy: "network-only",
    }
  )

  const hasClasses = !!data.getTransactions.classTracking

  const connections = useMemo(
    () =>
      connectionsData.getIntegrations.edges.map((integration) => {
        return {
          key: integration.node.id,
          label: integration.node.name,
          isDeleted: false,
        }
      }),
    [connectionsData.getIntegrations.edges]
  )

  const isDirty = () => {
    let hasChanges = false

    Object.keys(paramValues).forEach((key) => {
      const formKey = key as keyof typeof paramValues
      if (
        paramValues[formKey] !== null &&
        transactionValues &&
        !isEqual(paramValues[formKey], transactionValues[formKey])
      ) {
        hasChanges = true
      }
    })

    return hasChanges
  }
  const cleanFilters = (filters: typeof paramValues) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanedFilters: Record<string, any> = {}
    Object.keys(filters).forEach((key) => {
      const formKey = key as keyof typeof paramValues
      if (filters[formKey] !== null) {
        cleanedFilters[formKey] = filters[formKey]
      }
    })
    return cleanedFilters
  }
  const updateParamValue = (
    key: keyof typeof paramValues,
    value: string | string[]
  ) => {
    const updates = cleanFilters({ ...paramValues, [key]: value })
    const updatingPagination = ["page", "limit"].includes(key)

    if (updatingPagination) {
      updates[key] = value
    } else {
      updates["page"] = DEFAULT_PAGE
      updates["limit"] = DEFAULT_TABLE_LIMIT
    }
    !isEqual(updates, paramValues) &&
      startTransition(() => {
        setSearchParams(updates)
      })
  }

  const updateMultipleParams = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newParams: Partial<Record<keyof typeof paramValues, any>>
  ) => {
    const updates = cleanFilters({ ...paramValues, ...newParams })

    const updatingPagination =
      Object.keys(newParams).includes("page") ||
      Object.keys(newParams).includes("limit")
    if (!updatingPagination) {
      updates["page"] = DEFAULT_PAGE
      updates["limit"] = DEFAULT_TABLE_LIMIT
    }
    !isEqual(updates, paramValues) &&
      startTransition(() => {
        setSearchParams(updates)
      })
  }

  const onSearch = debounce((value: string) => {
    updateMultipleParams({ search: value })
  }, 300)

  const today = new Date()

  const formattedStartDate = paramValues.startDate
    ? addMinutes(new Date(paramValues.startDate), today.getTimezoneOffset())
    : null

  const formattedEndDate = paramValues.endDate
    ? endOfDay(
        addMinutes(new Date(paramValues.endDate), today.getTimezoneOffset())
      )
    : null

  return (
    <Box
      size="xl"
      p={rem(32)}
      mih={"100dvh"}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Group justify="space-between">
        <Group gap={8}>
          <IconChevronLeft
            color="gray"
            onClick={() => {
              const defaultPath = isSavedTransaction
                ? pathConstants.SAVED_REPORTS
                : transactionPath ?? pathConstants.STATEMENTS

              navigate(defaultPath)
              setTransactionPath(null)
            }}
            style={{
              cursor: "pointer",
            }}
          />
          <Space w={0} />
          <Text size="xxl" fw={700} c={"gray"} component="h1">
            Transactions
          </Text>
          <Divider mx={8} orientation="vertical" />
          <EntitiesComponent
            options={connections}
            values={paramValues.connectionIds}
            allCheckedLabel="All Entities"
            onChange={(value) => {
              if (value.length < paramValues.connectionIds.length) {
                setSearchParams({ connectionIds: value }, { replace: true })
              } else {
                updateParamValue("connectionIds", value)
              }
            }}
          />
        </Group>
        {id && (
          <SaveTransactionButton
            id={id}
            name={getSavedTransaction?.name ?? ""}
            accountIds={paramValues.accountIds}
            systemConnectionIds={paramValues.connectionIds}
            search={paramValues.search || ""}
            classIds={paramValues.classIds || []}
            vendorIds={paramValues.vendorIds || []}
            customerIds={paramValues.customerIds || []}
            limit={Number(paramValues.limit)}
            pageNumber={Number(paramValues.page)}
            startDate={paramValues.startDate}
            endDate={paramValues.endDate}
            amountRate={
              paramValues.amountRangeType?.toUpperCase() as AmountRateRelayEnum
            }
            {...(paramValues.amountRangeValue.at(0)
              ? { minRange: Number(paramValues.amountRangeValue.at(0)) }
              : {})}
            {...(paramValues.amountRangeValue.at(1)
              ? { maxRange: Number(paramValues.amountRangeValue.at(1)) }
              : {})}
            disabled={!isDirty()}
          />
        )}
      </Group>
      <Flex
        direction={"column"}
        style={{ flexGrow: 1 }}
        pt={"3.5rem"}
        gap={"2rem"}
        mih={"100% !important"}
      >
        <Text c={"gray.7"} fw={"bold"} size="md">
          Customize your view
        </Text>
        <AccountSelector
          key={paramValues.connectionIds.join("_")}
          data={data}
          preSelectedAccounts={paramValues.accountIds}
          onAccountsChange={(accounts) => {
            updateParamValue("accountIds", accounts)
          }}
          setAccountName={(account) => {
            setAccountName(account)
          }}
        />
        <Card shadow="xs">
          <Flex gap={"md"}>
            <Stack gap={0} w={"100%"}>
              <InputLabel c={"gray.7"}>Date Range</InputLabel>
              <ReportPeriodSelector
                defaultDates={{
                  startDate: formattedStartDate ?? today,
                  endDate: formattedEndDate ?? today,
                }}
                onChange={(dates) => {
                  updateMultipleParams({
                    startDate: dates.startDate,
                    endDate: dates.endDate,
                  })
                }}
              />
            </Stack>
            <Stack gap={0} w={"100%"}>
              <InputLabel c={"gray.7"}>Vendor</InputLabel>
              <VendorSelector
                key={paramValues.connectionIds.join("_")}
                data={data}
                defaultValues={paramValues.vendorIds}
                onChange={(value) => updateParamValue("vendorIds", value)}
              />
            </Stack>
            <Stack gap={0} w={"100%"}>
              <InputLabel c={"gray.7"}>Customer</InputLabel>
              <CustomerSelector
                key={paramValues.connectionIds.join("_")}
                data={data}
                defaultValues={paramValues.customerIds}
                onChange={(value) => updateParamValue("customerIds", value)}
              />
            </Stack>
            {hasClasses && data.getClasses.count > 0 && (
              <Stack gap={0} w={"100%"}>
                <InputLabel c={"gray.7"}>Class</InputLabel>
                <ClassSelector
                  key={paramValues.connectionIds.join("_")}
                  data={data}
                  defaultValues={paramValues.classIds}
                  onChange={(value) => updateParamValue("classIds", value)}
                />
              </Stack>
            )}
            <Stack gap={0} w={"100%"}>
              <InputLabel c={"gray.7"}>Amount Range</InputLabel>
              <RangeSelector
                values={{
                  value:
                    paramValues.amountRangeType === AmountRateEnum.BETWEEN
                      ? [
                          minAmount?.toString() ?? "",
                          maxAmount?.toString() ?? "",
                        ]
                      : [minAmount?.toString() ?? ""],
                  type: paramValues.amountRangeType as AmountRateEnum,
                }}
                onChange={(val) => {
                  updateMultipleParams({
                    amountRangeType: val.type,
                    amountRangeValue: val.value,
                  })
                }}
              />
            </Stack>
          </Flex>
        </Card>
        <Flex justify={"space-between"} align={"center"}>
          <Flex gap={"md"} align={"center"}>
            <Text fw={"bold"} c={"gray.7"} size="md">
              Details
            </Text>
            <Divider orientation="vertical" />
            <TextInput
              w={"18.5rem"}
              placeholder="Search"
              defaultValue={paramValues.search ?? ""}
              onChange={(e) => {
                onSearch(e.target.value)
              }}
              leftSection={
                <IconSearch
                  size={"1rem"}
                  style={{ color: "var(--mantine-color-gray-6)" }}
                />
              }
            />
          </Flex>
          <Flex gap={"xs"}>
            <ExportTransactionsButton
              clientId={currentClient.id}
              filename={`${currentClient.name ?? ""} - Transactions`}
              systemConnectionIds={paramValues.connectionIds}
              accountIds={paramValues.accountIds}
              search={paramValues.search || ""}
              classIds={paramValues.classIds || []}
              vendorIds={paramValues.vendorIds || []}
              customerIds={paramValues.customerIds || []}
              limit={Number(paramValues.limit)}
              pageNumber={Number(paramValues.page)}
              startDate={paramValues.startDate}
              endDate={paramValues.endDate}
              amountRate={
                paramValues.amountRangeType?.toUpperCase() as AmountRateEnum
              }
              {...(paramValues.amountRangeValue.at(0)
                ? {
                    minRange: Number(paramValues.amountRangeValue.at(0)),
                    maxRange: Number(paramValues.amountRangeValue.at(0)),
                  }
                : {})}
              {...(paramValues.amountRangeValue.at(1)
                ? { maxRange: Number(paramValues.amountRangeValue.at(1)) }
                : {})}
            />
            {!isSavedTransaction && (
              <SaveTransactionButton
                name={
                  accountName
                    ? `${accountName} - Transactions`
                    : "New Statement"
                }
                accountIds={paramValues.accountIds}
                systemConnectionIds={paramValues.connectionIds}
                search={paramValues.search || ""}
                classIds={paramValues.classIds || []}
                vendorIds={paramValues.vendorIds || []}
                customerIds={paramValues.customerIds || []}
                limit={Number(paramValues.limit)}
                pageNumber={Number(paramValues.page)}
                startDate={paramValues.startDate}
                endDate={paramValues.endDate}
                amountRate={
                  paramValues.amountRangeType?.toUpperCase() as AmountRateRelayEnum
                }
                {...(paramValues.amountRangeValue.at(0)
                  ? { minRange: Number(paramValues.amountRangeValue.at(0)) }
                  : {})}
                {...(paramValues.amountRangeValue.at(1)
                  ? { maxRange: Number(paramValues.amountRangeValue.at(1)) }
                  : {})}
              />
            )}
          </Flex>
        </Flex>

        <TransactionsTable
          data={data}
          onChange={(limit, page) => {
            limit &&
              limit != paramValues.limit &&
              updateMultipleParams({ limit: limit, page: DEFAULT_PAGE })

            page && page != paramValues.page && updateParamValue("page", page)
          }}
          paginationProps={{
            limit: paramValues.limit,
            page: paramValues.page,
            defaultLimit: DEFAULT_TABLE_LIMIT,
            defaultPage: DEFAULT_PAGE,
          }}
        />
      </Flex>
    </Box>
  )
}
