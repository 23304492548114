import { FormType, RepresentationEnum } from "../statements"
import {
  saveStatementsModalUpsertSavedStatementMutation as SaveStatementsModalUpsertSavedStatementMutationType,
  UpsertSavedStatementMutationInput,
} from "./__generated__/saveStatementsModalUpsertSavedStatementMutation.graphql"
import styles from "./save-statements-modal.module.scss"
import { Input, Select, Stack, Text } from "@mantine/core"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { IconChevronDown } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { graphql, useMutation } from "react-relay"

const SaveStatementsModalUpsertSavedStatementMutation = graphql`
  mutation saveStatementsModalUpsertSavedStatementMutation(
    $input: UpsertSavedStatementMutationInput!
  ) {
    upsertSavedStatement(input: $input) {
      savedStatement {
        id
        name
        data {
          reportType
          entities {
            connection {
              id
              name
            }
            isDeleted
          }
          startDate
          endDate
          displayBy
          representationBy
          numberFormat
          showZeroBalance
        }
      }
    }
  }
`

type Props = {
  saveStatementsInput: UpsertSavedStatementMutationInput
  title: string
  opened: boolean
  onClose: () => void
  resetFields?: (values: FormType) => void
}

export const SaveStatementsModal = ({
  saveStatementsInput,
  title,
  opened,
  onClose,
  resetFields,
}: Props) => {
  const [statementName, setStatementName] = useState<string>(
    saveStatementsInput.name
  )
  const [selectedId, setSelectedId] = useState<string | null>(
    saveStatementsInput.id || "new-id"
  )

  const [upsertSavedStatement, upsertSavedStatementLoading] =
    useMutation<SaveStatementsModalUpsertSavedStatementMutationType>(
      SaveStatementsModalUpsertSavedStatementMutation
    )

  useEffect(() => {
    setStatementName(saveStatementsInput.name)
  }, [saveStatementsInput.name])

  const handleSave = () => {
    upsertSavedStatement({
      variables: {
        input: {
          ...saveStatementsInput,
          id: selectedId === "new-id" ? null : selectedId,
          name: statementName,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "",
          message: "Statement saved successfully!",
        })
        handleOnClose()
        resetFields &&
          resetFields({
            statementName,
            dates: {
              startDate: saveStatementsInput.startDate,
              endDate: saveStatementsInput.endDate,
            },
            display: saveStatementsInput.displayBy,
            zeroBalance: saveStatementsInput.showZeroBalance,
            systemConnectionIds: saveStatementsInput.systemConnectionIds.map(
              (val) => val
            ),
            representationBy:
              RepresentationEnum[
                saveStatementsInput.representationBy ?? "AMOUNT"
              ],
            reportType: saveStatementsInput.reportType,
            numberFormat:
              saveStatementsInput.numberFormat?.map((val) => val) ?? [],
          })
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
        handleOnClose()
      },
    })
  }

  const handleOnClose = () => {
    setStatementName(saveStatementsInput.name)
    setSelectedId(saveStatementsInput.id || "new-id")
    onClose()
  }
  return (
    <ConfirmationModal
      title={title}
      opened={opened}
      onClose={handleOnClose}
      cancelButtonProps={{
        onClick: handleOnClose,
      }}
      confirmButtonProps={{
        onClick: handleSave,
        children: "Save",
        color: "blue",
        loading: upsertSavedStatementLoading,
        disabled: upsertSavedStatementLoading,
      }}
    >
      {" "}
      <Stack gap="1.3rem">
        {saveStatementsInput.id && (
          <Stack gap="0.3rem">
            <Text size="sm" fw="bold">
              Save as
            </Text>
            <Select
              withCheckIcon={false}
              allowDeselect={false}
              rightSection={<IconChevronDown size={16} />}
              classNames={{ option: styles.Option }}
              onChange={(value) => setSelectedId(value)}
              value={selectedId}
              data={[
                { label: "Current Statement", value: saveStatementsInput.id },
                { label: "New Statement", value: "new-id" },
              ]}
            />
          </Stack>
        )}
        <Stack gap="0.3rem">
          <Text size="sm" fw="bold">
            Statement Title
          </Text>
          <Input
            value={statementName}
            onChange={(event) => setStatementName(event.currentTarget.value)}
          />
        </Stack>
      </Stack>
    </ConfirmationModal>
  )
}
