import { RoleSelect } from "../role-select/role-select"
import { inviteUserDrawerFragment$key } from "./__generated__/inviteUserDrawerFragment.graphql"
import { inviteUserDrawerMutation } from "./__generated__/inviteUserDrawerMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Avatar,
  Button,
  Flex,
  Stack,
  Text,
  TextInput,
  rem,
  useCombobox,
} from "@mantine/core"
import { AppDrawer } from "@shared/ui/app-drawer"
import { MultiSelectCombobox } from "@shared/ui/multiselect-combobox"
import { notifications } from "@shared/ui/notifications"
import { getClientMantineColor } from "@shared/utils/helpers"
import { IconCheck } from "@tabler/icons-react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay"
import { z } from "zod"

const FirmSchema = z.object({
  email: z.string().email("Enter a valid email"),
  role: z.string().min(1),
  clientSlugs: z.array(z.string()).optional(),
})

type formValues = z.infer<typeof FirmSchema>
type Props = {
  firmId: string | null
  firmSettings: inviteUserDrawerFragment$key
  opened: boolean
  close: () => void
}
const InviteUserMutation = graphql`
  mutation inviteUserDrawerMutation(
    $input: InviteUserToFirmInput!
    $connections: [ID!]!
  ) {
    inviteUserToFirm(input: $input) {
      user @appendNode(connections: $connections, edgeTypeName: "User") {
        avatar
        createdAt
        email
        firstName
        id
        isFirmAdmin
        isSuperAdmin
        lastName
        role
        clients {
          nodes {
            name
            color
            logo
          }
        }
      }
    }
  }
`
const inviteUserDrawerFragment = graphql`
  fragment inviteUserDrawerFragment on Firm
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    clients(first: $count, after: $cursor)
      @connection(key: "inviteUserClientsFragment_clients") {
      edges {
        node {
          color
          logo
          name
          slug
        }
      }
    }
  }
`
export const InviteUserDrawer = ({
  firmId,
  firmSettings,
  close,
  opened,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors: formErrors, isValid, isDirty },
  } = useForm<formValues>({
    resolver: zodResolver(FirmSchema),
  })
  const {
    clients: { edges },
  } = useFragment(inviteUserDrawerFragment, firmSettings)

  const [inviteToFirm, inviteToFirmLoading] =
    useMutation<inviteUserDrawerMutation>(InviteUserMutation)
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const onSubmit: SubmitHandler<formValues> = ({
    email,
    role,
    clientSlugs,
  }) => {
    const firmConnection = ConnectionHandler.getConnectionID(
      firmId ?? "root",
      "firmUsersTableFragment_users"
    )

    inviteToFirm({
      variables: {
        input: {
          email,
          practiceAdmin: role == "admin",
          clientSlugs: clientSlugs ?? [],
        },
        connections: [firmConnection],
      },
      onError: (error) => {
        notifications.show({
          title: "An Error occured",
          message: error.message ?? "Something went wrong.",
          variant: "error",
        })
      },
      onCompleted: () => {
        notifications.show({
          title: "User successfully invited!",
          message: "The user has been notified via email.",
          variant: "success",
        })
        close()
        reset()
      },
    })
  }

  const menuOptions = edges.map((item) => {
    return {
      value: item.node.slug,
      text: (_value: string, checked: boolean) => (
        <Flex justify="space-between" align="center">
          <Flex gap={rem(8)} align="center">
            <Avatar
              variant="filled"
              size="xs"
              color={getClientMantineColor(item.node.color)}
              src={item.node.logo ?? null}
            >
              <Text size={rem(8)}> {item.node.name[0]}</Text>
            </Avatar>
            {item.node.name}
          </Flex>
          {checked ? <IconCheck size="0.75rem" /> : null}
        </Flex>
      ),
    }
  })

  return (
    <AppDrawer
      title="Invite User"
      opened={opened}
      onClose={() => {
        close()
        reset()
      }}
      closeOnClickOutside={!isDirty}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack justify="space-between" h="calc(100vh - 4.75rem)">
          <Stack gap={rem(24)}>
            <Text size="sm" c="gray.7">
              Invite users to your firm by entering their E-mail.
            </Text>
            <TextInput
              {...register("email")}
              label="E-mail"
              withAsterisk
              error={formErrors.email?.message}
            />
            <Controller
              control={control}
              name="role"
              render={({ field: { onChange, value } }) => (
                <RoleSelect
                  onChange={onChange}
                  value={value ?? ""}
                ></RoleSelect>
              )}
            />
            <Controller
              control={control}
              name="clientSlugs"
              render={({ field: { onChange, value } }) => {
                return (
                  <div>
                    <Flex
                      gap={rem(4)}
                      pb={rem(8)}
                      align="center"
                      style={{ cursor: "default" }}
                    >
                      <Text size="sm" fw={500}>
                        Add to Client
                      </Text>
                      <Text size="xs" c="gray.6">
                        {"(optional)"}
                      </Text>
                    </Flex>
                    <MultiSelectCombobox
                      options={menuOptions}
                      value={value ?? []}
                      onChange={onChange}
                    />
                  </div>
                )
              }}
            />
          </Stack>
          <Button
            type="submit"
            loading={inviteToFirmLoading}
            disabled={!isValid}
          >
            Invite
          </Button>
        </Stack>
      </form>
    </AppDrawer>
  )
}
