import { deleteUserConnectionModalMutation as DeleteUserConnectionModalMutationType } from "./__generated__/deleteUserConnectionModalMutation.graphql"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { ConnectionHandler, graphql, useMutation } from "react-relay"

const DeleteUserConnectionModalMutation = graphql`
  mutation deleteUserConnectionModalMutation(
    $input: DeleteUserIntegrationInput!
    $connections: [ID!]!
  ) {
    deleteUserIntegration(input: $input) {
      errors
      userIntegration {
        id @deleteEdge(connections: $connections)
      }
    }
  }
`

type Props = {
  connectionId: string | null
  opened: boolean
  onClose: () => void
}

export const DeleteUserConnectionModal = ({
  connectionId,
  opened,
  onClose,
}: Props) => {
  const [deleteUserIntegration, deleteUserIntegrationLoading] =
    useMutation<DeleteUserConnectionModalMutationType>(
      DeleteUserConnectionModalMutation
    )

  const handleDelete = () => {
    const generalUserconnection = ConnectionHandler.getConnectionID(
      "root",
      "UserConnections_getUserIntegrations"
    )
    const integrationsUserConnection = ConnectionHandler.getConnectionID(
      "root",
      "UserConnectionsTableFragment_getUserIntegrations"
    )

    const userConnections = [generalUserconnection, integrationsUserConnection]

    connectionId &&
      deleteUserIntegration({
        variables: {
          input: {
            userIntegrationId: connectionId,
          },
          connections: userConnections,
        },
        onCompleted: () => {
          notifications.show({
            title: "Connection Successfully Deleted",
            message: "It may take a while to delete all the data.",
            variant: "success",
          })
          onClose()
        },
        onError: (e) => {
          notifications.show({
            title: "An error ocurred",
            message: e.message,
            variant: "error",
          })
          onClose()
        },
      })
  }

  return (
    <ConfirmationModal
      title="Are you sure you want to delete this connection?"
      opened={opened && !!connectionId}
      onClose={onClose}
      cancelButtonProps={{
        onClick: onClose,
      }}
      confirmButtonProps={{
        onClick: handleDelete,
        children: "Delete Connection",
        color: "red",
        loading: deleteUserIntegrationLoading,
      }}
    >
      Deleting the connection is a permanent action; all associated workbooks
      and spreadsheets will be removed.
    </ConfirmationModal>
  )
}
