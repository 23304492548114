import styles from "./app-drawer.module.scss"
import { Drawer, DrawerProps, Text } from "@mantine/core"

interface AppDrawerProps extends DrawerProps {
  opened: boolean
  title: string
}

export const AppDrawer = ({
  opened,
  onClose,
  children,
  title,
  ...rest
}: AppDrawerProps) => {
  return (
    <>
      <Drawer
        opened={opened}
        position="right"
        size={400}
        onClose={onClose}
        title={
          <Text size="md" fw="bold" c="gray">
            {title}
          </Text>
        }
        closeOnClickOutside={false}
        overlayProps={{ backgroundOpacity: 0 }}
        classNames={{
          content: styles.Content,
          body: styles.Body,
        }}
        {...rest}
      >
        {children}
      </Drawer>
    </>
  )
}
