import { connectionsQuery as ConnectionsQueryType } from "./__generated__/connectionsQuery.graphql"
import { ConnectionDrawer } from "./connections-drawer"
import { connectionsDrawerQuery as ConnectionsDrawerQueryType } from "./connections-drawer/__generated__/connectionsDrawerQuery.graphql"
import { ConnectionsDrawerQuery } from "./connections-drawer/connections-drawer"
import { ConnectionsEmpty } from "./connections-empty"
import { ConnectionsTable } from "./connections-table"
import styles from "./connections.module.scss"
import { NewConnectionButton } from "./new-connection-button"
import {
  Box,
  Group,
  Input,
  LoadingOverlay,
  Space,
  Text,
  rem,
} from "@mantine/core"
import { useOauthCallback } from "@shared/hooks/use-oauth-callback"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { IconSearch } from "@tabler/icons-react"
import { Suspense, useEffect, useState } from "react"
import { graphql, useLazyLoadQuery, useQueryLoader } from "react-relay"
import { useSearchParams } from "react-router-dom"

const ConnectionsQuery = graphql`
  query connectionsQuery($cursor: String, $count: Int) {
    getIntegrations(first: $count, after: $cursor)
      @connection(key: "Connections_getIntegrations") {
      edges {
        node {
          id
          status
        }
      }
    }
    ...connectionsTableFragment @arguments(count: $count, cursor: $cursor)
  }
`

export const Connections = () => {
  const {
    currentClient: { id: currentClientId },
  } = useUserStore()
  const [fetchKey, setFetchKey] = useState(0)
  const data = useLazyLoadQuery<ConnectionsQueryType>(
    ConnectionsQuery,
    {},
    {
      fetchKey: `${currentClientId ?? ""}_${fetchKey}`,
      fetchPolicy: "network-only",
    }
  )
  const [search, setSearch] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()
  const activeConnectionId = searchParams.get("connectionId")
  const oauthMutation = useOauthCallback()
  const [drawerQueryRef, loadDrawerQuery] =
    useQueryLoader<ConnectionsDrawerQueryType>(ConnectionsDrawerQuery)
  const {
    getIntegrations: { edges },
  } = data
  const hasConnections = edges.length > 0 ?? false

  useEffect(() => {
    activeConnectionId && loadDrawerQuery({ input: activeConnectionId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConnectionId])

  useEffect(() => {
    const someSyncing = edges.some(({ node }) => node.status === "SYNCING")

    const intervalId = setInterval(() => {
      someSyncing && setFetchKey(fetchKey + 1)
    }, 30 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [edges, fetchKey])

  return (
    <Box className={styles.Connections} pos="relative">
      <LoadingOverlay visible={oauthMutation} zIndex={1000} />
      <Group justify="space-between">
        <Text size="xxl" fw="bold" c="gray">
          Connections
        </Text>
        {hasConnections && (
          <Group gap={rem(8)}>
            <Input
              placeholder="Search"
              leftSection={
                <IconSearch size={16} color={themeVars.colors.gray[6]} />
              }
              w={rem(240)}
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              classNames={{
                input: styles.Connections__search,
              }}
            />
            <NewConnectionButton
              menuProps={{
                position: "bottom-end",
              }}
            />
          </Group>
        )}
      </Group>
      {hasConnections ? (
        <Box>
          <Space h={rem(56)} />
          <ConnectionsTable
            connections={data}
            searchQuery={search}
            onClick={(id) => loadDrawerQuery({ input: id })}
          />

          <Suspense>
            {drawerQueryRef && (
              <ConnectionDrawer
                onClose={() => setSearchParams()}
                open={!!activeConnectionId}
                queryRef={drawerQueryRef}
              />
            )}
          </Suspense>
        </Box>
      ) : (
        <ConnectionsEmpty />
      )}
    </Box>
  )
}
