import { Button, Flex, Stack, Text } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"

export const AccountPlaceholder = () => {
  return (
    <Stack>
      <Flex
        mih={"1.25rem"}
        align={"center"}
        gap={".5rem"}
        maw={"100%"}
        style={{ flexWrap: "wrap" }}
      >
        <Text fz=".875rem" fw={500} c={"gray.7"}>
          Accounts:
        </Text>
      </Flex>
      <Button
        variant="light"
        fz={".75rem"}
        maw={"min-content"}
        size="compact-xs"
        color="gray"
        disabled
      >
        <IconPlus size={".75rem"} style={{ marginRight: ".25rem" }} />
        Add
      </Button>
    </Stack>
  )
}
