import publicEnvironment from "@shared/relay/environment/public-environment"
import { RelayEnvironmentProvider } from "react-relay"
import { Outlet } from "react-router-dom"

export const PublicRelayProvider = () => {
  return (
    <RelayEnvironmentProvider environment={publicEnvironment}>
      <Outlet />
    </RelayEnvironmentProvider>
  )
}
