/**
 * @generated SignedSource<<3d59497b700af26188c5e51013aab88e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteUserIntegrationInput = {
  clientMutationId?: string | null;
  userIntegrationId: string;
};
export type deleteUserConnectionModalMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteUserIntegrationInput;
};
export type deleteUserConnectionModalMutation$data = {
  readonly deleteUserIntegration: {
    readonly errors: ReadonlyArray<string>;
    readonly userIntegration: {
      readonly id: string;
    };
  } | null;
};
export type deleteUserConnectionModalMutation = {
  response: deleteUserConnectionModalMutation$data;
  variables: deleteUserConnectionModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteUserConnectionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteUserIntegrationPayload",
        "kind": "LinkedField",
        "name": "deleteUserIntegration",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIntegration",
            "kind": "LinkedField",
            "name": "userIntegration",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteUserConnectionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteUserIntegrationPayload",
        "kind": "LinkedField",
        "name": "deleteUserIntegration",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIntegration",
            "kind": "LinkedField",
            "name": "userIntegration",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a634aaecf9160397e6179b924c8acc39",
    "id": null,
    "metadata": {},
    "name": "deleteUserConnectionModalMutation",
    "operationKind": "mutation",
    "text": "mutation deleteUserConnectionModalMutation(\n  $input: DeleteUserIntegrationInput!\n) {\n  deleteUserIntegration(input: $input) {\n    errors\n    userIntegration {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d427b5237213aef282b75e1f24a59fbd";

export default node;
