/**
 * @generated SignedSource<<221fdd46571358216267b2d9e52d2cfb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type cashFlowConfigurationQuery$variables = {
  clientId: string;
  connectionIds: ReadonlyArray<string>;
  count?: number | null;
  cursor?: string | null;
};
export type cashFlowConfigurationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"balanceAccountsTableAccountTypesFragment" | "incomeAccountsTableFragment">;
};
export type cashFlowConfigurationQuery = {
  response: cashFlowConfigurationQuery$data;
  variables: cashFlowConfigurationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clientId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectionIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v4 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v5 = {
  "kind": "Variable",
  "name": "connectionIds",
  "variableName": "connectionIds"
},
v6 = {
  "kind": "Variable",
  "name": "count",
  "variableName": "count"
},
v7 = {
  "kind": "Variable",
  "name": "cursor",
  "variableName": "cursor"
},
v8 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "cursor"
},
v9 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "count"
},
v10 = [
  (v8/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "statement",
    "value": "BALANCE"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "cashFlowConfigurationQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "incomeAccountsTableFragment"
      },
      {
        "args": [
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "balanceAccountsTableAccountTypesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "cashFlowConfigurationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "LedgerAccountsConnection",
        "kind": "LinkedField",
        "name": "getIncomeAccountsNonCash",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LedgerAccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LedgerAccount",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountType",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "includeInCashFlow",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "filters": [
          "clientId",
          "connectionIds"
        ],
        "handle": "connection",
        "key": "IncomeAccountsTableFragment_getIncomeAccountsNonCash",
        "kind": "LinkedHandle",
        "name": "getIncomeAccountsNonCash"
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "LedgerAccountTypesConnection",
        "kind": "LinkedField",
        "name": "getLedgerAccountTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LedgerAccountTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LedgerAccountType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "filters": [
          "statement"
        ],
        "handle": "connection",
        "key": "BalanceAccountsTableAccountTypesFragment_getLedgerAccountTypes",
        "kind": "LinkedHandle",
        "name": "getLedgerAccountTypes"
      }
    ]
  },
  "params": {
    "cacheID": "f4f2dbc751483506e3018e8d6d319e0b",
    "id": null,
    "metadata": {},
    "name": "cashFlowConfigurationQuery",
    "operationKind": "query",
    "text": "query cashFlowConfigurationQuery(\n  $clientId: ID!\n  $connectionIds: [ID!]!\n  $cursor: String\n  $count: Int\n) {\n  ...incomeAccountsTableFragment_1Lrajc\n  ...balanceAccountsTableAccountTypesFragment_1G22uz\n}\n\nfragment balanceAccountsTableAccountTypesFragment_1G22uz on Query {\n  getLedgerAccountTypes(first: $count, after: $cursor, statement: BALANCE) {\n    edges {\n      node {\n        name\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment incomeAccountsTableFragment_1Lrajc on Query {\n  getIncomeAccountsNonCash(clientId: $clientId, connectionIds: $connectionIds, first: $count, after: $cursor) {\n    edges {\n      node {\n        accountType\n        id\n        includeInCashFlow\n        displayName\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "03843dfc2ee0b82110cd1d4a4a41e888";

export default node;
