import { useUserStore } from "@shared/store"
import { ReactElement, cloneElement } from "react"
import { useNavigate } from "react-router-dom"

type Props = {
  element: ReactElement
}

export const ClientRoute = ({ element }: Props) => {
  const {
    currentClient: { id: currentClientId },
  } = useUserStore()
  const navigate = useNavigate()

  if (currentClientId) {
    return cloneElement(element, { key: currentClientId })
  } else {
    navigate("/portfolio", { replace: true })
    return null
  }
}
