import { AvatarUpload } from "../avatar-upload/avatar-upload"
import {
  Button,
  PasswordInput,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { IconEye, IconEyeOff } from "@tabler/icons-react"
import { Controller, useFormContext } from "react-hook-form"
import { z } from "zod"

export const UserSchema = z
  .object({
    avatar: z.string().nullable(),
    email: z
      .string()
      .min(1, { message: "Please fill out this required field. " })
      .email("Invalid email address."),
    fullName: z
      .string()
      .min(1, { message: "Please fill out this required field. " }),
    password: z.string().min(8),
  })
  .superRefine(({ password }, checkPassComplexity) => {
    const containsLetter = (ch: string) => /[A-Za-z]/.test(ch)
    const containsSpecialChar = (ch: string) =>
      /[ !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/.test(ch)
    let countOfLetters = 0,
      countOfNumbers = 0,
      countOfSpecialChar = 0
    for (let i = 0; i < password.length; i++) {
      const ch = password.charAt(i)
      if (!Number.isNaN(+ch)) countOfNumbers++
      else if (containsLetter(ch)) countOfLetters++
      else if (containsSpecialChar(ch)) countOfSpecialChar++
    }
    if (countOfLetters < 1 || countOfSpecialChar < 1 || countOfNumbers < 1) {
      checkPassComplexity.addIssue({
        code: "custom",
        path: ["password"],
        message:
          "Password must contain at least 8 chars, with at least 1 letter, 1 number and 1 symbol.",
      })
    }
  })

export type UserFormType = z.infer<typeof UserSchema>

type Props = {
  onSubmit: (data: UserFormType) => void
  submitText?: string
  loading?: boolean
}
export const UserDataForm = ({
  onSubmit,
  submitText = "Sign Up",
  loading = false,
}: Props) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<UserFormType>()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack py={rem(16)}>
        <Controller
          control={control}
          name={"avatar"}
          render={({ field: { value, onChange } }) => {
            return (
              <AvatarUpload
                optional
                image={value ?? undefined}
                label="Profile Picture"
                onChange={onChange}
              />
            )
          }}
        />
        <TextInput
          {...register("email")}
          label="E-mail"
          withAsterisk
          type="email"
          placeholder="hello@getalpyne.com"
          inputMode="email"
          error={errors.email?.message}
          autoFocus
        />
        <TextInput
          {...register("fullName")}
          label="Name"
          withAsterisk
          description="First name and last name"
          error={errors.fullName?.message}
          inputWrapperOrder={["label", "input", "error", "description"]}
        />
        <PasswordInput
          {...register("password")}
          label="Set Up New Password"
          error={errors.password?.message}
          withAsterisk
          description="At least 8 chars, with at least 1 letter, 1 number and 1 symbol."
          inputWrapperOrder={["label", "input", "error", "description"]}
          visibilityToggleIcon={({ reveal }) =>
            reveal ? (
              <IconEye
                color={themeVars.colors.gray[6]}
                style={{ width: rem(18) }}
              />
            ) : (
              <IconEyeOff
                color={themeVars.colors.gray[6]}
                style={{ width: rem(18) }}
              />
            )
          }
        />
        <Space h={rem(40)} />
        <Button type="submit" disabled={!isValid} loading={loading}>
          {submitText}
        </Button>
      </Stack>
      <Text size="xs" c="gray" ta="center">
        {"By singing up you are agree with the "}
        <Text c="blue" component="span">
          Terms and Conditions.
        </Text>
      </Text>
    </form>
  )
}
