export const appFontWeights = {
  BOLD: "700",
  SEMIBOLD: "500",
  REGULAR: "400",
}

export const appTypography = {
  fontFamily: "Ubuntu, sans-serif",
  fontSizes: {
    xxl: "1.75rem",
    xl: "1.25rem",
    lg: "1.125rem",
    md: "1rem",
    sm: "0.875rem",
    xs: "0.75rem",
    xxs: "0.625rem",
  },
  lineHeights: {
    xxl: "1.75rem",
    xl: "1.5rem",
    lg: "1.25rem",
    md: "1.125rem",
    sm: "1rem",
    xs: "0.875rem",
    xxs: "0.75rem",
  },
  headings: {
    fontFamily: "Ubuntu, sans-serif",
    sizes: {
      h6: {
        fontSize: "0.75rem",
        lineHeight: "1rem",
        fontWeight: appFontWeights.BOLD,
      },
      h5: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: appFontWeights.BOLD,
      },
      h4: {
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        fontWeight: appFontWeights.BOLD,
      },
      h3: {
        fontSize: "1.5rem",
        lineHeight: "2rem",
        fontWeight: appFontWeights.BOLD,
      },
      h2: {
        fontSize: "2rem",
        lineHeight: "2.5rem",
        fontWeight: appFontWeights.BOLD,
      },
      h1: {
        fontSize: "2.5rem",
        lineHeight: "3rem",
        fontWeight: appFontWeights.BOLD,
      },
    },
  },
  other: {
    fontWeights: {
      bold: appFontWeights.BOLD,
      semibold: appFontWeights.SEMIBOLD,
      regular: appFontWeights.REGULAR,
    },
  },
}
