import { useEffect } from "react"

export function useSyncingRefetch(
  someSyncing: boolean,
  setRefetchKey: React.Dispatch<React.SetStateAction<number>>,
  delay = 30
): void {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (someSyncing) {
        setRefetchKey((prev) => prev + 1)
      }
    }, delay * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [someSyncing, setRefetchKey])
}
