import styles from "./select-combobox.module.scss"
import {
  Combobox,
  ComboboxProps,
  Flex,
  InputBase,
  InputBaseProps,
  Text,
  rem,
  useCombobox,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { IconCheck, IconChevronDown } from "@tabler/icons-react"
import { ReactNode, useState } from "react"
import { Noop } from "react-hook-form"

export type SelectComboboxOptionType = {
  label: string
  labelRenderer?: ReactNode
  rightSection?: ReactNode
  disabled?: boolean
  value: string
}

export interface SelectComboboxProps extends ComboboxProps {
  value: string | null
  options: Array<SelectComboboxOptionType>
  error?: ReactNode
  inputProps?: InputBaseProps
  onChange: (_val: string) => void
  onBlur?: Noop
  autocomplete?: boolean
  textInput?: boolean
  placeholder?: string
}
export const SelectCombobox = ({
  value,
  onChange,
  options,
  inputProps,
  onBlur,
  autocomplete,
  textInput,
  placeholder,
  ...rest
}: SelectComboboxProps) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const selectedOption = options.find((option) => option.value == value)
  const selectedOptionLabel = selectedOption?.label || ""
  const [search, setSearch] = useState("")

  const filteredOptions =
    selectedOptionLabel === search
      ? options
      : options.filter((item) =>
          item.label.toLowerCase().includes(search.toLowerCase().trim())
        )

  const selectOptions = filteredOptions.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      disabled={!!item.disabled}
      className={[
        styles.roleOptionWrapper,
        selectedOption?.value === item.value &&
          styles["roleOptionWrapper--selected"],
      ].join(" ")}
    >
      <Flex justify="space-between" align="center">
        {item.labelRenderer ?? (
          <Text size="xs" fw="bold" className={styles.roleOptionText}>
            {item.label}
          </Text>
        )}
        {item.rightSection ??
          (selectedOption?.value === item.value ? (
            <IconCheck size={"0.75rem"} />
          ) : null)}
      </Flex>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      shadow="xs"
      onOptionSubmit={(val) => {
        onChange(val)
        setSearch("")
        combobox.closeDropdown()
      }}
      {...rest}
    >
      <Combobox.Target>
        <InputBase
          pointer={!autocomplete}
          type={autocomplete || textInput ? "text" : "button"}
          label="Role"
          rightSection={
            <IconChevronDown size={"1rem"} color={themeVars.colors.gray[6]} />
          }
          value={combobox.dropdownOpened ? search : selectedOptionLabel}
          placeholder={
            placeholder ||
            (combobox.dropdownOpened ? "Pick an option or search..." : "")
          }
          onBlur={onBlur}
          rightSectionPointerEvents="none"
          onClick={() => {
            combobox.toggleDropdown()
            setSearch(selectedOption?.label ?? "")
          }}
          onChange={(event) => {
            setSearch(event.currentTarget.value ?? "")
          }}
          {...inputProps}
        ></InputBase>
      </Combobox.Target>
      <Combobox.Dropdown>
        <Combobox.Options mah={rem(200)} style={{ overflowY: "auto" }}>
          {selectOptions.length > 0 ? (
            selectOptions
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
