import classes from "./autocomplete-checkbox-multiselect.module.scss"
import {
  ActionIcon,
  Box,
  Checkbox,
  Combobox,
  ComboboxDropdownProps,
  ComboboxProps,
  Divider,
  Pill,
  PillsInput,
  PillsInputProps,
  TextInput,
  rem,
  useCombobox,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { IconChevronDown, IconSearch, IconX } from "@tabler/icons-react"
import { ReactNode, useState } from "react"

type Props = ComboboxProps & {
  value: string[]
  inputRenderer?: ReactNode | null
  options: {
    text: string
    value: string
  }[]
  disableSort?: boolean
  fixedOptions?: {
    text: string
    value: string
  }[]
  onChange: (val: string[]) => void
  onClose?: () => void
  pillsInputProps?: Partial<PillsInputProps>
  dropdownProps?: Partial<ComboboxDropdownProps>
  groupLabel: string
}
export const AutocompleteCheckboxMultiselect = ({
  value,
  onChange,
  options,
  inputRenderer,
  pillsInputProps,
  dropdownProps,
  groupLabel,
  fixedOptions,
  disableSort,
  onClose,
  ...rest
}: Props) => {
  const [search, setSearch] = useState("")

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      setSearch("")
      onClose && onClose()
    },
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  })

  const sortedOptions = disableSort
    ? options
    : options.sort((a) => (value.includes(a.value) ? -1 : 1))

  const filteredOptions = search
    ? sortedOptions.filter((item) =>
        item.text.toLowerCase().includes(search.toLowerCase().trim())
      )
    : sortedOptions

  const handleValueSelect = (val: string) =>
    onChange(
      value.includes(val) ? value.filter((v) => v !== val) : [...value, val]
    )

  const handleValueRemove = (val: string) =>
    onChange(value.filter((v) => v !== val))

  const values = value.map((item) => {
    const pillLabel = [...options, ...(fixedOptions ?? [])].find(
      (option) => option.value === item
    )?.text
    return (
      <Pill
        key={item}
        withRemoveButton
        onRemove={() => handleValueRemove(item)}
        style={{ borderRadius: rem(4) }}
      >
        {pillLabel}
      </Pill>
    )
  })

  const displayOptions = filteredOptions.map((item) => {
    const isIncluded = value.includes(item.value)
    return (
      <Checkbox
        h={"2rem"}
        key={item.value}
        checked={isIncluded}
        label={item.text}
        classNames={classes}
        onChange={() =>
          onChange(
            isIncluded
              ? value.filter((val) => val != item.value)
              : [...value, item.value]
          )
        }
        ml={"1rem"}
      />
    )
  })

  const displayFixedOptions = fixedOptions
    ? fixedOptions.map((item) => {
        const isIncluded = value.includes(item.value)
        return (
          <Checkbox
            h={"2rem"}
            key={item.value}
            checked={isIncluded}
            label={item.text}
            classNames={classes}
            onChange={() =>
              onChange(
                isIncluded
                  ? value.filter((val) => val != item.value)
                  : [...value, item.value]
              )
            }
            ml={"1rem"}
          />
        )
      })
    : []

  return (
    <Combobox
      store={combobox}
      shadow="xs"
      onOptionSubmit={handleValueSelect}
      classNames={{ group: classes.comboboxGroup }}
      {...rest}
    >
      <Combobox.DropdownTarget>
        {inputRenderer ? (
          <Box onClick={() => combobox.toggleDropdown()}>{inputRenderer}</Box>
        ) : (
          <PillsInput
            rightSectionPointerEvents="none"
            rightSection={
              <IconChevronDown size={16} color={themeVars.colors.gray[6]} />
            }
            pointer
            onClick={() => combobox.toggleDropdown()}
            wrapperProps={{
              style: {
                alignContent: "center",
                alignItems: "center",
              },
            }}
            {...pillsInputProps}
          >
            <Pill.Group style={{ flexWrap: "nowrap", overflow: "hidden" }}>
              {values.length > 2 ? (
                <>
                  {values.slice(0, 2)}
                  <Pill style={{ borderRadius: rem(4) }}>
                    {`${values.length - 2} More`}
                  </Pill>
                </>
              ) : (
                values
              )}
            </Pill.Group>
          </PillsInput>
        )}
      </Combobox.DropdownTarget>

      <Combobox.Dropdown p={0} {...dropdownProps}>
        <TextInput
          variant="unstyled"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          leftSection={
            <IconSearch
              size={"1rem"}
              style={{ color: "var(--mantine-color-gray-6)" }}
            />
          }
          rightSection={
            search && (
              <ActionIcon
                onClick={() => {
                  setSearch("")
                }}
                size={".875rem"}
                variant="transparent"
                color="gray.6"
              >
                <IconX size={".875rem"} />
              </ActionIcon>
            )
          }
        />
        <Divider />
        <Combobox.Options
          mah={rem(200)}
          style={{ overflowY: "auto", padding: ".25rem" }}
          className={classes.comboboxScrollable}
        >
          {!search && displayFixedOptions}
          <Combobox.Group label={groupLabel}>
            {displayOptions.length > 0 ? (
              displayOptions
            ) : (
              <Combobox.Empty fz={"xs"}>Nothing found</Combobox.Empty>
            )}
          </Combobox.Group>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
