import { metabaseDataGetMetabaseSubPathQuery as MetabaseDataGetMetabaseSubPathQueryType } from "./__generated__/metabaseDataGetMetabaseSubPathQuery.graphql"
import styles from "./metabase-data.module.scss"
import { ShareButton } from "./share-button"
import { useUserStore } from "@shared/store"
import {
  MetabaseMessageEventType,
  extractIdFromURL,
} from "@shared/utils/metabase"
import { Suspense, useEffect, useRef, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

const MetabaseDataGetMetabaseSubPathQuery = graphql`
  query metabaseDataGetMetabaseSubPathQuery {
    getMetabaseSubPath
  }
`
const BASE_URL = import.meta.env.VITE_METABASE_EMBEDDING_BASE_URL
const BASE_UI_ATTRIBUTES = encodeURIComponent(
  "logo=false&side_nav=true&new_button=true&top_nav=true"
)

export const MetabaseData = () => {
  const {
    metabaseToken,
    currentClient: { dashboardId },
  } = useUserStore()

  const { getMetabaseSubPath } =
    useLazyLoadQuery<MetabaseDataGetMetabaseSubPathQueryType>(
      MetabaseDataGetMetabaseSubPathQuery,
      {},
      {
        fetchPolicy: "network-only",
      }
    )

  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [currentDashboardId, setCurrentDashboardId] = useState<number | null>()
  const returnURL = `${BASE_URL}/${getMetabaseSubPath}?${BASE_UI_ATTRIBUTES}`

  useEffect(() => {
    window.addEventListener(
      "message",
      (e: MessageEvent<MetabaseMessageEventType>) => {
        const location = e.data.metabase?.location?.href

        if (location) {
          const id = extractIdFromURL(location)
          setCurrentDashboardId(id)
        }
      }
    )
  }, [])

  const dashboardIsActive = getMetabaseSubPath.includes("dashboard")
  const idsAreNotNull = currentDashboardId && dashboardId
  const showShareButton =
    (!dashboardIsActive &&
      idsAreNotNull &&
      currentDashboardId !== dashboardId) ||
    (idsAreNotNull && currentDashboardId === dashboardId) ||
    (dashboardIsActive && currentDashboardId)
  return (
    <div className={styles.Frame}>
      {metabaseToken && (
        <iframe
          src={`${BASE_URL}/auth/sso?jwt=${metabaseToken}&return_to=${returnURL}`}
          loading="eager"
          ref={iframeRef}
        />
      )}
      {showShareButton && (
        <Suspense>
          <ShareButton dashboardId={currentDashboardId} />
        </Suspense>
      )}
    </div>
  )
}
