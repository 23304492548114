import styles from "./styles.module.scss"
import BottomGraph from "@assets/bottom-graph.svg"
import AlpyneLogo from "@assets/logo.svg"
import { Outlet } from "react-router-dom"

export const PublicLayout = () => {
  return (
    <main className={styles.PublicLayout}>
      <div className={styles.PublicLayout__LogoContainer}>
        <img
          src={AlpyneLogo}
          alt="Alpyne Logo"
          className={styles.PublicLayout__LogoContainer__Logo}
        />
      </div>
      <section className={styles.PublicLayout__Content}>
        <Outlet />
      </section>
      <img className={styles.PublicLayout__Graph} src={BottomGraph} />
    </main>
  )
}
