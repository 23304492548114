import styles from "./combobox.module.scss"
import { Combobox, InputBase, Text, useCombobox } from "@mantine/core"
import { IconCheck } from "@tabler/icons-react"
import { ReactElement, ReactNode, useEffect, useState } from "react"

export type ComboxboxDataType = {
  label: string
  value: string
  icon?: ReactElement | ReactNode
  imageSrc?: string
}

type Props = {
  data: ComboxboxDataType[]
  onChange: (value: string) => void
  compact?: boolean
  defaultValue?: string | null
  disabled?: boolean
  placeholder?: string
  onCompactClick?: () => void
}

export function SearchableSelect({
  data,
  placeholder,
  defaultValue,
  onChange,
  disabled = false,
  compact = false,
  onCompactClick,
}: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const [search, setSearch] = useState("")
  const [selectedOption, setSelectedOption] = useState<
    ComboxboxDataType | undefined
  >(data.find((item) => item.value === defaultValue))

  const shouldFilterOptions = data.every((item) => item.label !== search)
  const filteredOptions = shouldFilterOptions
    ? data.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase().trim())
      )
    : data

  const labelOnly = !selectedOption?.icon && !selectedOption?.imageSrc

  useEffect(() => {
    setSelectedOption(data.find((item) => item.value === defaultValue))
  }, [data, defaultValue])

  const options = filteredOptions.map((item) => {
    const activeOption = item.value === selectedOption?.value
    const hasGraphic = !!item.icon || !!item.imageSrc

    return (
      <Combobox.Option
        value={item.value}
        key={item.value}
        className={[
          styles.Combobox__Option,
          !hasGraphic && styles["Combobox__Option--labelOnly"],
          activeOption && styles["Combobox__Option--active"],
        ].join(" ")}
        active={defaultValue === item.value}
      >
        {hasGraphic && item.imageSrc ? (
          <img src={item.imageSrc} className={styles.Combobox__Option__Image} />
        ) : (
          item.icon
        )}

        <Text
          size="sm"
          className={[
            styles.Combobox__Option__Label,
            activeOption && styles["Combobox__Option__Label--active"],
          ].join(" ")}
        >
          {item.label}
        </Text>

        {activeOption && (
          <IconCheck size={12} className={styles.Combobox__Option__Check} />
        )}
      </Combobox.Option>
    )
  })

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        const selectedItem = data.find((item) => item.value === val)
        setSelectedOption(selectedItem)
        setSearch(selectedItem?.label ?? "")
        combobox.closeDropdown()
        onChange(val)
      }}
      disabled={disabled}
    >
      <Combobox.Target>
        <InputBase
          leftSection={
            selectedOption &&
            (selectedOption.imageSrc ? (
              <img
                src={selectedOption.imageSrc}
                className={styles.Combobox__Target__Image}
              />
            ) : (
              selectedOption.icon ?? null
            ))
          }
          rightSection={compact ? null : <Combobox.Chevron />}
          value={compact ? "" : search}
          onChange={(event) => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
            setSearch(event.currentTarget.value)
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown()
            setSearch(selectedOption?.label || "")
          }}
          placeholder={compact ? "" : selectedOption?.label ?? placeholder}
          rightSectionPointerEvents="none"
          classNames={{
            section: [
              styles.Combobox__Target__Section,
              labelOnly && styles["Combobox__Target__Section--labelOnly"],
              compact && styles["Combobox__Target__Section--compact"],
            ].join(" "),
            input: [
              styles.Combobox__Target__Input,
              labelOnly && styles["Combobox__Target__Input--labelOnly"],
              selectedOption && styles["Combobox__Target__Input--selected"],
              compact && styles["Combobox__Target__Input--compact"],
            ].join(" "),
          }}
          disabled={disabled}
          wrapperProps={{
            onClick: () => {
              if (compact) {
                onCompactClick && onCompactClick()
              }
            },
          }}
        />
      </Combobox.Target>

      <Combobox.Dropdown className={styles.Combobox__Dropdown}>
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
