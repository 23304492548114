/**
 * @generated SignedSource<<d9ecb7ec29f9eb7bc5cfa57f12952187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
import { FragmentRefs } from "relay-runtime";
export type syncButtonFragment$data = {
  readonly id: string;
  readonly status: StatusIntegrationTypeEnum;
  readonly system: {
    readonly name: string;
    readonly slug: string;
  };
  readonly " $fragmentType": "syncButtonFragment";
};
export type syncButtonFragment$key = {
  readonly " $data"?: syncButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"syncButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "syncButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "System",
      "kind": "LinkedField",
      "name": "system",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SystemIntegration",
  "abstractKey": null
};

(node as any).hash = "3b854dd6db5f5f8cb1e79e49d3dea298";

export default node;
