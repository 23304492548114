/**
 * @generated SignedSource<<a6962cf4c0521d25df1510487dd40833>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
export type GoogleOauthCallbackInput = {
  clientMutationId?: string | null;
  code: string;
};
export type useUserConnectionOauthCallbackGoogleMutation$variables = {
  input: GoogleOauthCallbackInput;
  userConnections: ReadonlyArray<string>;
};
export type useUserConnectionOauthCallbackGoogleMutation$data = {
  readonly googleOauthCallback: {
    readonly errors: ReadonlyArray<string>;
    readonly userConnection: {
      readonly id: string;
      readonly name: string;
      readonly status: StatusIntegrationTypeEnum;
      readonly system: {
        readonly category: string;
        readonly name: string;
        readonly slug: string;
      };
    } | null;
  } | null;
};
export type useUserConnectionOauthCallbackGoogleMutation = {
  response: useUserConnectionOauthCallbackGoogleMutation$data;
  variables: useUserConnectionOauthCallbackGoogleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userConnections"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUserConnectionOauthCallbackGoogleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GoogleOauthCallbackPayload",
        "kind": "LinkedField",
        "name": "googleOauthCallback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIntegration",
            "kind": "LinkedField",
            "name": "userConnection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "System",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserConnectionOauthCallbackGoogleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GoogleOauthCallbackPayload",
        "kind": "LinkedField",
        "name": "googleOauthCallback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIntegration",
            "kind": "LinkedField",
            "name": "userConnection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "System",
                "kind": "LinkedField",
                "name": "system",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "userConnection",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "userConnections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "UserIntegration"
              }
            ]
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38815a55e0ba6e9dd269ed7aefa166e7",
    "id": null,
    "metadata": {},
    "name": "useUserConnectionOauthCallbackGoogleMutation",
    "operationKind": "mutation",
    "text": "mutation useUserConnectionOauthCallbackGoogleMutation(\n  $input: GoogleOauthCallbackInput!\n) {\n  googleOauthCallback(input: $input) {\n    userConnection {\n      name\n      id\n      system {\n        category\n        name\n        slug\n        id\n      }\n      status\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "38db0df85517acb7e7d7bc264be42b2b";

export default node;
