/**
 * @generated SignedSource<<1f3b603abb441cc73dc79c03058bb5f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClientStatusEnum = "ACTIVE" | "INACTIVE";
export type ClientTypeEnum = "ECOMMERCE" | "OTHER" | "RETAIL" | "SAAS" | "SERVICES";
export type UpdateCurrentClientMutationInput = {
  clientMutationId?: string | null;
  closeMonth?: string | null;
  contactEmail?: string | null;
  contactName?: string | null;
  contactPhone?: string | null;
  fiscal?: number | null;
  logo?: string | null;
  name?: string | null;
  type?: ClientTypeEnum | null;
};
export type clientDrawerUpdateMutation$variables = {
  input: UpdateCurrentClientMutationInput;
};
export type clientDrawerUpdateMutation$data = {
  readonly updateCurrentClient: {
    readonly client: {
      readonly closeMonth: string;
      readonly contactEmail: string | null;
      readonly contactName: string | null;
      readonly contactPhone: string | null;
      readonly createdAt: string;
      readonly creator: {
        readonly firstName: string | null;
        readonly lastName: string | null;
      };
      readonly fiscal: number;
      readonly id: string;
      readonly logo: string | null;
      readonly name: string;
      readonly status: ClientStatusEnum;
      readonly type: ClientTypeEnum;
    };
    readonly errors: ReadonlyArray<string> | null;
  };
};
export type clientDrawerUpdateMutation = {
  response: clientDrawerUpdateMutation$data;
  variables: clientDrawerUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiscal",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactEmail",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactPhone",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeMonth",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientDrawerUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetClient",
        "kind": "LinkedField",
        "name": "updateCurrentClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientDrawerUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetClient",
        "kind": "LinkedField",
        "name": "updateCurrentClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f6428399ad3bcf693e303bfbe4d10f4",
    "id": null,
    "metadata": {},
    "name": "clientDrawerUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation clientDrawerUpdateMutation(\n  $input: UpdateCurrentClientMutationInput!\n) {\n  updateCurrentClient(input: $input) {\n    client {\n      id\n      creator {\n        firstName\n        lastName\n        id\n      }\n      createdAt\n      logo\n      fiscal\n      name\n      type\n      contactEmail\n      contactName\n      contactPhone\n      closeMonth\n      status\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b6542964f74d57f2fe099ca46e30a27";

export default node;
