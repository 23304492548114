/**
 * @generated SignedSource<<1d9285a2a311cad3f1106743cf543713>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type transactionsTableFragment$data = {
  readonly getTransactions: {
    readonly classTracking: boolean | null;
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountName: string | null;
        readonly accountNumber: number | null;
        readonly amount: number | null;
        readonly className: string | null;
        readonly customerName: string | null;
        readonly date: string | null;
        readonly description: string | null;
        readonly externalLink: string | null;
        readonly id: string;
        readonly type: string | null;
        readonly vendorName: string | null;
      };
    }>;
    readonly totalCount: number | null;
  };
  readonly " $fragmentType": "transactionsTableFragment";
};
export type transactionsTableFragment$key = {
  readonly " $data"?: transactionsTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"transactionsTableFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "accountIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "amountRate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "classIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "customerIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "limit"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "maxRange"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "minRange"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageNumber"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "systemConnectionIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "vendorIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "transactionsTableFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "accountIds",
          "variableName": "accountIds"
        },
        {
          "kind": "Variable",
          "name": "amountRate",
          "variableName": "amountRate"
        },
        {
          "kind": "Variable",
          "name": "classIds",
          "variableName": "classIds"
        },
        {
          "kind": "Variable",
          "name": "customerIds",
          "variableName": "customerIds"
        },
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "endDate"
        },
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "limit"
        },
        {
          "kind": "Variable",
          "name": "maxRange",
          "variableName": "maxRange"
        },
        {
          "kind": "Variable",
          "name": "minRange",
          "variableName": "minRange"
        },
        {
          "kind": "Variable",
          "name": "pageNumber",
          "variableName": "pageNumber"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        },
        {
          "kind": "Variable",
          "name": "systemConnectionIds",
          "variableName": "systemConnectionIds"
        },
        {
          "kind": "Variable",
          "name": "vendorIds",
          "variableName": "vendorIds"
        }
      ],
      "concreteType": "LedgerTransactionsConnection",
      "kind": "LinkedField",
      "name": "getTransactions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "classTracking",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LedgerTransactionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LedgerTransaction",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accountNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "className",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customerName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "vendorName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "externalLink",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2ac0f1dab9f9be3169ad30f3032ec354";

export default node;
