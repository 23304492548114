import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { Link } from "react-router-dom"

export enum NumberFormatEnum {
  EXCEPT_ZERO = "EXCEPT_ZERO",
  WITHOUT_CENTS = "WITHOUT_CENTS",
  DIVIDE_1000 = "DIVIDE_1000",
  SHOW_RED = "SHOW_RED",
}
export const formatValue = (
  redirectUrl = "",
  onClick: () => void,
  value: number,
  filters: string[],
  asCurrency = false,
  withPercentage = false,
  forceRed = false
) => {
  const shouldHideCents = filters.includes(NumberFormatEnum.WITHOUT_CENTS)
  const shouldDivide1000 = filters.includes(NumberFormatEnum.DIVIDE_1000)
  const shouldHideZero = filters.includes(NumberFormatEnum.EXCEPT_ZERO)
  const hasRedNegatives = filters.includes(NumberFormatEnum.SHOW_RED)
  const hasRedirectUrl = redirectUrl != ""

  let formattedValue: number | string = value ?? 0

  if (
    !asCurrency &&
    shouldHideZero &&
    formattedValue === 0 &&
    !withPercentage
  ) {
    return ""
  }

  if (shouldDivide1000) {
    formattedValue /= 1000
  }

  if (shouldHideCents && formattedValue > 0) {
    formattedValue = Math.round(formattedValue)
  }

  const maxDecimals = shouldHideCents ? 0 : 2
  const currencyOptions: Intl.NumberFormatOptions = {
    style: asCurrency ? "currency" : "decimal",
    currency: "USD",
    maximumFractionDigits: maxDecimals,
    minimumFractionDigits: maxDecimals,
  }

  formattedValue =
    formattedValue < 0
      ? `(${(shouldHideCents
          ? Math.round(Math.abs(formattedValue))
          : Math.abs(formattedValue)
        ).toLocaleString("en-US", currencyOptions)})`
      : formattedValue.toLocaleString("en-US", currencyOptions)

  // Using feature flags for enabling transactions redirects
  const enableTransactionsRedirect = useUserStore
    .getState()
    .checkFeature("transactions_feature")

  return (
    <Link
      to={enableTransactionsRedirect ? redirectUrl : ""}
      style={{
        color: forceRed
          ? themeVars.colors.red[6]
          : value < 0 && hasRedNegatives
          ? themeVars.colors.red[6]
          : "inherit",
        cursor:
          enableTransactionsRedirect && hasRedirectUrl ? "pointer" : "default",
      }}
      onClick={() => enableTransactionsRedirect && onClick()}
    >
      {formattedValue.toString()}
      {withPercentage ? "%" : ""}
    </Link>
  )
}
