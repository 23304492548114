import { ActionIcon, Anchor, CopyButton, Flex, rem } from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { notifications } from "@shared/ui/notifications"
import { IconCopy } from "@tabler/icons-react"

type Props = {
  url: string
}

export const DashboardUrl = ({ url }: Props) => {
  const { hovered, ref } = useHover()

  const copyUrl = (copy: () => void) => {
    copy()
    notifications.show({
      title: "Dashboard Url Copied",
      message: `${url} copied to clipboard`,
      variant: "success",
    })
  }

  return (
    <Flex gap={rem(8)} maw={rem(400)} ref={ref}>
      <Anchor href={url} size="sm" truncate="end" target="_blank">
        {url}
      </Anchor>
      <CopyButton value={url}>
        {({ copy }) => (
          <ActionIcon
            variant="white"
            c="blue.6"
            size={rem(16)}
            unselectable={hovered ? "off" : "on"}
          >
            <IconCopy
              display={hovered ? "" : "none"}
              onClick={() => {
                copyUrl(copy)
              }}
            />
          </ActionIcon>
        )}
      </CopyButton>
    </Flex>
  )
}
