/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-call */
import { ExportStatementsButton } from "../export-statements-button/export-statements-button"
import { SaveStatementsButton } from "../save-statements-button"
import { RepresentationEnum } from "../statements"
import { NumberFormatEnum, formatValue } from "../utils/format"
import {
  DisplayEnum,
  reportTableGetFinancialStatementQuery as ReportTableGetFinancialStatementQueryType,
  ReportTypeEnum,
} from "./__generated__/reportTableGetFinancialStatementQuery.graphql"
import styles from "./report-table.module.scss"
import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Group,
  Menu,
  Stack,
  Switch,
  Text,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { ErrorBadge } from "@shared/ui/error-badge"
import { ExpandableTable } from "@shared/ui/expandable-table"
import { IconCaretDownFilled, IconCaretRightFilled } from "@tabler/icons-react"
import { CellContext, ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import { enUS } from "date-fns/locale/en-US"
import { pathConstants } from "frontend/routes/path-constants"
import { useState } from "react"
import { PreloadedQuery, graphql, usePreloadedQuery } from "react-relay"
import { Link, createSearchParams, useLocation } from "react-router-dom"

export const ReportTableGetFinancialStatementQuery = graphql`
  query reportTableGetFinancialStatementQuery(
    $clientId: ID
    $startDate: UtcDate!
    $endDate: UtcDate!
    $systemConnectionIds: [ID!]!
    $display: DisplayEnum
    $reportType: ReportTypeEnum!
  ) {
    getFinancialStatement(
      startDate: $startDate
      endDate: $endDate
      systemConnectionIds: $systemConnectionIds
      display: $display
      clientId: $clientId
      reportType: $reportType
    ) {
      data
      success
      reportName
    }
  }
`

const generateExpandedObject = (length: number): Record<string, boolean> => {
  const result: Record<string, boolean> = {}

  for (let count = 0; count < length; count++) {
    result[count] = true
  }

  return result
}

const rowStyle = (context: CellContext<any, unknown>) => {
  const rowType = context.row.original.type
  const isExpanded = context.row.getIsExpanded()
  if (rowType == "checklist") {
    return styles["ReportTable--Checklist"]
  }

  if ((rowType == "parent" || rowType == "category_parent") && !isExpanded) {
    return styles["ReportTable--Parent"]
  } else if (
    (rowType == "parent" || rowType == "category_parent") &&
    isExpanded
  ) {
    return styles["ReportTable--Parent__expanded"]
  }

  if (rowType == "totals") {
    return styles["ReportTable--Parent"]
  }

  if (rowType == "account_totals") {
    return styles["ReportTable--AccountTotals"]
  }

  if (rowType == "category_child") {
    return styles["ReportTable--CategoryChild"]
  }

  return styles["ReportTable--Child"]
}

type Props = {
  id: string | undefined
  systemConnectionIds: string[]
  startDate: string
  reportTypeLabel?: string
  endDate: string
  clientId: string | null
  clientName: string | null
  display: DisplayEnum
  reportType: ReportTypeEnum
  queryRef: PreloadedQuery<ReportTableGetFinancialStatementQueryType>
  formatFilters: string[]
  representationBy: RepresentationEnum
  zeroBalance: boolean
  setFormValueZeroBalance?: (value: boolean) => void
}

export const ReportTable = ({
  id,
  queryRef,
  clientName,
  formatFilters,
  reportTypeLabel,
  representationBy,
  zeroBalance,
  setFormValueZeroBalance,
  ...rest
}: Props) => {
  const location = useLocation()
  const { setCashFlowPath, setTransactionPath } = useUserStore()

  const redirectTransactionPath = () => {
    setTransactionPath(`${location.pathname}${location.search}`)
  }

  const {
    getFinancialStatement: { data: result, reportName },
  } = usePreloadedQuery<ReportTableGetFinancialStatementQueryType>(
    ReportTableGetFinancialStatementQuery,
    queryRef
  )

  const [showZeroBalance, setShowZeroBalance] = useState(zeroBalance)

  const representation =
    rest.reportType === "INCOME" ? representationBy : RepresentationEnum.AMOUNT
  const isPercentage = representation === RepresentationEnum.PERCENTAGE

  const generateTransactionUrl = (
    cell: any,
    startDate: string,
    endDate: string
  ) => {
    if (rest.reportType === "CASH") return null

    const { display } = rest
    const connectionIds = cell.ledger_connection_id
      ? [cell.ledger_connection_id]
      : cell.ledger_connection_ids
    const accountIds = cell.ledger_account_id
      ? [cell.ledger_account_id]
      : cell.ledger_account_ids

    if (!accountIds || !connectionIds) {
      return null
    }

    const params = {
      accountIds,
      connectionIds,
      startDate: display == "ENTITY" ? rest.startDate : startDate,
      endDate: display == "ENTITY" ? rest.endDate : endDate,
    }

    return `${pathConstants.TRANSACTIONS}?${createSearchParams(
      params
    ).toString()}`
  }

  const rowValue = ({ row, column }: CellContext<any, unknown>) => {
    const rowType: string = row.original.type
    const isExpanded = row.getIsExpanded()
    const dateHeaders = result.date_headers || {}

    let redirectUrl = generateTransactionUrl(
      row.original,
      dateHeaders[column.id]?.start_date,
      dateHeaders[column.id]?.end_date
    )

    if (rowType == "checklist") {
      const finalValue: number = row.original[column.id][representation] ?? 0

      return finalValue === 0 ? (
        formatValue(
          redirectUrl ?? "",
          () => redirectUrl && redirectTransactionPath(),
          finalValue,
          [],
          true
        )
      ) : (
        <Menu trigger="hover" position="top">
          <Menu.Target>
            <Group justify="flex-end" gap={8} w={"max-content"}>
              <ErrorBadge label="" />
              {formatValue(
                redirectUrl ?? "",
                () => redirectUrl && redirectTransactionPath(),
                finalValue,
                formatFilters,
                true,
                false,
                true
              )}
            </Group>
          </Menu.Target>
          <Menu.Dropdown>
            <Stack px={16} py={12} gap={8}>
              <Text size="sm">
                An error occurred. Review
                <br />
                the account configuration.
              </Text>
              <Button
                variant="outline"
                size="xs"
                w={64}
                miw={0}
                p={0}
                component={Link}
                to={pathConstants.CASH_FLOW_CONFIGURATION}
                onClick={() => {
                  setCashFlowPath(`${location.pathname}${location.search}`)
                }}
              >
                Review
              </Button>
            </Stack>
          </Menu.Dropdown>
        </Menu>
      )
    }

    if (rowType == "category_parent" && isExpanded) {
      return ""
    }

    if (column.id == "total") {
      const finalValue: number = isExpanded
        ? row.original[column.id][representation] === 0
          ? 0
          : row.original[column.id][representation]
        : rowType === "account_totals" || rowType === "child"
        ? row.original.total[representation]
        : row.original.accounts_total[representation]

      const shouldRedirect =
        ((rowType == "parent" || rowType == "category_parent") && isExpanded) ||
        rowType == "child"

      redirectUrl = shouldRedirect
        ? generateTransactionUrl(row.original, rest.startDate, rest.endDate)
        : null

      return formatValue(
        redirectUrl ?? "",
        () => redirectUrl && redirectTransactionPath(),
        finalValue,
        formatFilters,
        !isPercentage,
        isPercentage
      )
    }

    if ((rowType == "parent" || rowType == "category_parent") && !isExpanded) {
      const finalValue: number =
        row.original.accounts_totals[column.id][representation] ?? 0

      return formatValue(
        "",
        () => {
          return
        },
        finalValue,
        formatFilters,
        !isPercentage,
        isPercentage
      )
    }

    if (!row.original[column.id]) {
      return ""
    }

    const currentAmount: number = row.original[column.id][representation] ?? 0
    return formatValue(
      redirectUrl ?? "",
      () => redirectUrl && redirectTransactionPath(),
      currentAmount,
      formatFilters,
      ["account_totals", "category_child"].includes(rowType) && !isPercentage,
      !["parent", "child"].includes(rowType) && isPercentage
    )
  }

  const columns = result.headers
    ? result.headers.map((header: any, index: number) => {
        if (index === 0) {
          const col: ColumnDef<unknown> = {
            header: reportName ?? "",
            accessorKey: header,
            enableSorting: false,
            meta: {
              getCellContext: rowStyle,
            },
            cell: ({ row, getValue }) => (
              <Group pl={rem(row.depth * 24)} gap={rem(4)} miw={rem(340)}>
                <>
                  {row.getCanExpand() && (
                    <ActionIcon
                      variant="transparent"
                      c="gray"
                      size="sm"
                      onClick={() => row.toggleExpanded()}
                    >
                      {row.getIsExpanded() ? (
                        <IconCaretDownFilled />
                      ) : (
                        <IconCaretRightFilled />
                      )}
                    </ActionIcon>
                  )}
                  {getValue()}
                </>
              </Group>
            ),
          }

          return col
        }

        return {
          header: header,
          accessorKey: header,
          meta: {
            getCellContext: rowStyle,
            align: "right",
            className: header == "total" && styles["ReportTable--Total"],
          },
          cell: rowValue,
          enableSorting: false,
        }
      })
    : []

  const getAccounts = (account: any) => {
    const isParent = account.accounts.length > 0
    const hasAccountstotals = account.accounts_totals

    const isAllZero = account.amounts
      ? Object.values(account.amounts)
          ?.map((item: any) => item[representation])
          .every((item: any) => item == 0)
      : true

    if (!isParent && isAllZero && !showZeroBalance) {
      return undefined
    }

    const accountResult = {
      ...account,
      ...account.amounts,
      name: [account.account_number, account.account_name].join(" "),
      type: isParent ? "parent" : "child",
      accounts:
        isParent && hasAccountstotals
          ? [
              ...account.accounts
                .filter((acc: any) => acc !== undefined)
                .map((acc: any) => getAccounts(acc)),
              {
                ...account.accounts_totals,
                total: account.accounts_total,
                accounts_totals: account.accounts_totals,
                name: `Total ${[
                  account.account_number,
                  account.account_name,
                ].join(" ")}`,
                type: "account_totals",
              },
            ]
          : account.accounts
              .filter((acc: any) => acc !== undefined)
              .map((acc: any) => getAccounts(acc)),
    }

    if (Array.isArray(accountResult.accounts)) {
      accountResult.accounts = accountResult.accounts.filter(
        (acc: any) => acc !== undefined
      )
    }

    return accountResult
  }

  const data = result.data
    ? result.data.categories
        .map((category: any) => {
          const isParent = category.accounts.length > 0
          const subInfo = getAccounts(category)
          return {
            ...category,
            ...subInfo,
            ...(subInfo &&
              isParent && {
                accounts: [
                  ...subInfo.accounts,
                  {
                    ...category.totals,
                    total: category.total,
                    accounts_totals: category.totals,
                    name: `Total ${[category.name].join(" ")}`,
                    type: "account_totals",
                  },
                ],
              }),
            ...(!isParent && { ...category.totals }),
            name: category.key == "check" ? "Checklist" : category.name,
            type:
              category.key == "check"
                ? "checklist"
                : isParent
                ? "category_parent"
                : "category_child",
            total: category.total,
            accounts_total: category.total,
            accounts_totals: category.totals,
          }
        })
        .filter(
          (category: any) =>
            category.key == "check" ||
            !Object.values(category.totals)
              ?.map((item: any) => item[representation])
              .every((item: any) => item == 0 && !showZeroBalance)
        )
    : []

  return result.data ? (
    <Stack gap={"2rem"}>
      <Flex justify={"space-between"} align={"center"}>
        <Group gap={rem(12)}>
          <Text fw="bold" c="gray.7">
            {"Details"}
          </Text>
          <Divider
            orientation="vertical"
            style={{ borderColor: themeVars.colors.gray[3] }}
          />
          <Switch
            label={
              <Text size="sm" fw={500} c="dark.3" component="span">
                Include zero balance accounts
              </Text>
            }
            checked={showZeroBalance}
            onChange={(event) => {
              setShowZeroBalance(event.currentTarget.checked)
              if (setFormValueZeroBalance) {
                setFormValueZeroBalance(event.currentTarget.checked)
              }
            }}
            labelPosition="left"
          />
        </Group>
        <Group gap={rem(8)}>
          <ExportStatementsButton
            columns={columns}
            negativeInRed={formatFilters.includes(NumberFormatEnum.SHOW_RED)}
            exceptZeroAmmount={formatFilters.includes(
              NumberFormatEnum.EXCEPT_ZERO
            )}
            showDecimals={
              !formatFilters.includes(NumberFormatEnum.WITHOUT_CENTS)
            }
            divideOneThousand={formatFilters.includes(
              NumberFormatEnum.DIVIDE_1000
            )}
            data={data}
            title={`${clientName ?? ""} - ${reportTypeLabel ?? ""}`}
            filename={`${clientName ?? ""}-${reportTypeLabel ?? ""}`}
            footer={`Report generated on ${format(
              new Date(),
              "MMMM d, yyyy 'at' hh:mm aa OOO",
              {
                locale: enUS,
              }
            )}`}
            numberFormat={formatFilters}
            showZeroBalance={showZeroBalance}
            representation={representation}
            {...rest}
          />
          {!id && (
            <SaveStatementsButton
              {...rest}
              statementName={`${clientName ?? ""} - ${reportTypeLabel ?? ""}`}
              numberFormat={formatFilters}
              showZeroBalance={showZeroBalance}
              representation={representation}
            />
          )}
        </Group>
      </Flex>
      <ExpandableTable
        data={data}
        columns={columns}
        miw={120}
        {...(rest.reportType == "CASH" && {
          expandedIds: generateExpandedObject(data.length),
        })}
      />
    </Stack>
  ) : (
    <></>
  )
}
