import { deleteIncomeAccountModalIncomeCashFlowSettingMutation as DeleteIncomeAccountModalIncomeCashFlowSettingMutationType } from "./__generated__/deleteIncomeAccountModalIncomeCashFlowSettingMutation.graphql"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { ConnectionHandler, graphql, useMutation } from "react-relay"

const DeleteIncomeAccountModalIncomeCashFlowSettingMutation = graphql`
  mutation deleteIncomeAccountModalIncomeCashFlowSettingMutation(
    $input: IncomeCashFlowSettingMutationInput!
    $connections: [ID!]!
  ) {
    incomeCashFlowSetting(input: $input) {
      accounts {
        id @deleteEdge(connections: $connections)
        includeInCashFlow
      }
    }
  }
`

type Props = {
  account: {
    id: string
    name: string
  }
  cashFlowConfigurationInputs: {
    clientId: string
    connectionIds: string[]
  }
  opened: boolean
  onClose: () => void
}

export const DeleteIncomeAccountModal = ({
  account,
  opened,
  cashFlowConfigurationInputs,
  onClose,
}: Props) => {
  const [incomeCashFlowSettingsMutation, incomeCashFlowSettingsLoading] =
    useMutation<DeleteIncomeAccountModalIncomeCashFlowSettingMutationType>(
      DeleteIncomeAccountModalIncomeCashFlowSettingMutation
    )

  const handleDelete = () => {
    const connectionId = ConnectionHandler.getConnectionID(
      "root",
      "IncomeAccountsTableFragment_getIncomeAccountsNonCash",
      {
        ...cashFlowConfigurationInputs,
      }
    )

    incomeCashFlowSettingsMutation({
      variables: {
        input: {
          ids: [account.id],
        },
        connections: [connectionId],
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "",
          message: "Successfully Removed from Income Accounts List.",
        })
        onClose()
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
        onClose()
      },
    })
  }

  return (
    <ConfirmationModal
      title={`Are you sure you want to remove the "${account.name}" from the income accounts list?`}
      opened={opened && !!account.id}
      onClose={onClose}
      cancelButtonProps={{
        onClick: onClose,
      }}
      confirmButtonProps={{
        onClick: handleDelete,
        children: "Remove",
        color: "red",
        loading: incomeCashFlowSettingsLoading,
        disabled: incomeCashFlowSettingsLoading,
      }}
    >
      By removing this account, you will not lose any of its stored information.
    </ConfirmationModal>
  )
}
