/**
 * @generated SignedSource<<520d20effbc7f9d0c6c7290b1f7120b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AmountRateEnum = "BETWEEN" | "EQUAL_TO" | "GREATER_THAN" | "GREATER_THAN_OR_EQUAL_TO" | "LESS_THAN" | "LESS_THAN_OR_EQUAL_TO";
export type UpsertSavedTransactionMutationInput = {
  accountIds?: ReadonlyArray<string> | null;
  amountRate?: AmountRateEnum | null;
  classIds?: ReadonlyArray<string> | null;
  clientMutationId?: string | null;
  customerIds?: ReadonlyArray<string> | null;
  endDate: string;
  id?: string | null;
  limit?: number | null;
  maxRange?: number | null;
  minRange?: number | null;
  name: string;
  pageNumber?: number | null;
  search?: string | null;
  startDate: string;
  systemConnectionIds: ReadonlyArray<string>;
  vendorIds?: ReadonlyArray<string> | null;
};
export type saveTransactionModalUpsertSavedTransactionMutation$variables = {
  input: UpsertSavedTransactionMutationInput;
};
export type saveTransactionModalUpsertSavedTransactionMutation$data = {
  readonly upsertSavedTransaction: {
    readonly savedTransaction: {
      readonly data: {
        readonly accountIds: ReadonlyArray<string> | null;
        readonly amountRate: AmountRateEnum | null;
        readonly classIds: ReadonlyArray<string> | null;
        readonly customerIds: ReadonlyArray<string> | null;
        readonly endDate: string | null;
        readonly entityIds: ReadonlyArray<string> | null;
        readonly limit: number | null;
        readonly maxRange: number | null;
        readonly minRange: number | null;
        readonly pageNumber: number | null;
        readonly search: string | null;
        readonly startDate: string | null;
        readonly vendorIds: ReadonlyArray<string> | null;
      } | null;
      readonly id: string;
      readonly name: string | null;
    } | null;
  } | null;
};
export type saveTransactionModalUpsertSavedTransactionMutation = {
  response: saveTransactionModalUpsertSavedTransactionMutation$data;
  variables: saveTransactionModalUpsertSavedTransactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpsertSavedTransactionMutationPayload",
    "kind": "LinkedField",
    "name": "upsertSavedTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SavedTransaction",
        "kind": "LinkedField",
        "name": "savedTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedTransactionData",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amountRate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "classIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entityIds",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "limit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minRange",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pageNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "search",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vendorIds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "saveTransactionModalUpsertSavedTransactionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "saveTransactionModalUpsertSavedTransactionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "82a11ee0e51edd4c71168adc6ddae110",
    "id": null,
    "metadata": {},
    "name": "saveTransactionModalUpsertSavedTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation saveTransactionModalUpsertSavedTransactionMutation(\n  $input: UpsertSavedTransactionMutationInput!\n) {\n  upsertSavedTransaction(input: $input) {\n    savedTransaction {\n      id\n      name\n      data {\n        accountIds\n        amountRate\n        classIds\n        customerIds\n        endDate\n        entityIds\n        limit\n        maxRange\n        minRange\n        pageNumber\n        search\n        startDate\n        vendorIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8ba922d969df6d7c505ad6079b80232";

export default node;
