import { inviteFirmDrawerMutation as InviteFirmDrawerMutationType } from "./__generated__/inviteFirmDrawerMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Group, Stack, Text, TextInput } from "@mantine/core"
import { AppDrawer } from "@shared/ui/app-drawer"
import { notifications } from "@shared/ui/notifications"
import { SubmitHandler, useForm } from "react-hook-form"
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import { z } from "zod"

const InviteFirmDrawerMutation = graphql`
  mutation inviteFirmDrawerMutation(
    $input: InviteFirmMutationInput!
    $connections: [ID!]!
  ) {
    inviteFirm(input: $input) {
      firm @appendNode(connections: $connections, edgeTypeName: "Firm") {
        name
        createdAt
        creator {
          fullName
          email
        }
        id
        users {
          count
        }
      }
    }
  }
`

const formValidationSchema = z.object({
  email: z.string().trim().email("Enter a valid email"),
  ownerName: z.string().nullable(),
  firmName: z.string().nullable(),
})

type formValues = z.infer<typeof formValidationSchema>

const defaultValues: formValues = {
  email: "",
  firmName: null,
  ownerName: null,
}

type Props = {
  opened: boolean
  onClose: () => void
}

export const InviteFirmDrawer = ({ opened, onClose }: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<formValues>({
    defaultValues: defaultValues,
    resolver: zodResolver(formValidationSchema),
  })
  const [inviteFirm, inviteFirmLoading] =
    useMutation<InviteFirmDrawerMutationType>(InviteFirmDrawerMutation)

  const onSubmit: SubmitHandler<formValues> = ({
    firmName,
    email,
    ownerName,
  }) => {
    const firmsConnection = ConnectionHandler.getConnectionID(
      "root",
      "FirmsTableFragment_getFirms"
    )

    inviteFirm({
      variables: {
        input: {
          email,
          firmName,
          ownerName,
        },
        connections: [firmsConnection],
      },
      onCompleted: () => {
        notifications.show({
          title: "Firm Successfully Created",
          message: "The user has been notified via email.",
          variant: "success",
        })
        onClose()
        reset()
      },
      onError: (error) => {
        notifications.show({
          title: "An error ocurred",
          message: error.message,
          variant: "error",
        })
      },
    })
  }

  return (
    <AppDrawer
      opened={opened}
      title="Invite Firm"
      onClose={() => {
        onClose()
        reset()
      }}
      closeOnClickOutside={!isDirty}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Stack justify="space-between" h="100%">
          <Stack gap="1.5rem">
            <TextInput
              {...register("email")}
              label="E-mail"
              error={errors.email?.message}
              type="email"
              inputMode="email"
              withAsterisk
            />

            <TextInput
              {...register("ownerName")}
              label={
                <Group align="center" gap="0.25rem">
                  <Text size="sm" c="dark" fw={500}>
                    Owner Name
                  </Text>{" "}
                  <Text size="xs" c="gray">
                    {"(Optional)"}
                  </Text>
                </Group>
              }
              description="First and last name"
              inputWrapperOrder={["label", "input", "description", "error"]}
              error={errors.ownerName?.message}
            />
            <TextInput
              {...register("firmName")}
              label={
                <Group align="center" gap="0.25rem">
                  <Text size="sm" c="dark" fw={500}>
                    Firm Name
                  </Text>{" "}
                  <Text size="xs" c="gray">
                    {"(Optional)"}
                  </Text>
                </Group>
              }
              error={errors.firmName?.message}
            />
          </Stack>
          <Button
            type="submit"
            disabled={!isValid}
            loading={inviteFirmLoading}
            fullWidth
          >
            Send Invite
          </Button>
        </Stack>
      </form>
    </AppDrawer>
  )
}
