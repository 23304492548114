import classes from "./entities-component.module.scss"
import {
  Checkbox,
  Input,
  MantineProvider,
  Popover,
  Stack,
  Text,
  createTheme,
} from "@mantine/core"
import { useElementSize } from "@mantine/hooks"
import { IconChevronDown } from "@tabler/icons-react"

const theme = createTheme({
  cursorType: "pointer",
})
type Props = {
  values: string[]
  options: {
    label: string
    key: string
    isDeleted?: boolean
  }[]
  allCheckedLabel?: React.ReactNode
  onChange: (values: string[]) => void
}

export const EntitiesComponent = ({
  allCheckedLabel,
  options,
  values,
  onChange,
}: Props) => {
  const allChecked = values.length === options.length
  const indeterminate = values.length > 0 && !allChecked
  const firstLabel = options.find((opt) => opt.key === values[0])?.label
  const { ref: popoverRef, width: popoverWidth } =
    useElementSize<HTMLDivElement>()
  const items = options.map((value) => (
    <Checkbox
      key={value.key}
      checked={values.includes(value.key)}
      label={value.label}
      classNames={classes}
      onChange={() =>
        onChange(
          values.includes(value.key)
            ? values.filter((val) => val != value.key)
            : [...values, value.key]
        )
      }
      ml={"2rem"}
      disabled={value.isDeleted}
    />
  ))

  return (
    <Popover width="max-content" position="bottom-start" shadow="md">
      <Popover.Target>
        <Input
          miw="13.75rem"
          type="button"
          wrapperProps={{ ref: popoverRef }}
          component="button"
          pointer
          rightSection={<IconChevronDown size={16} />}
        >
          <Text truncate="end" size="sm">
            {allChecked
              ? allCheckedLabel
              : values.length > 1
              ? `${values.length} Items selected`
              : firstLabel}
          </Text>
        </Input>
      </Popover.Target>
      <Popover.Dropdown p={".75rem"} py={"10px"} miw={popoverWidth}>
        <MantineProvider theme={theme}>
          <Stack gap={0}>
            <Checkbox
              checked={allChecked}
              label={allCheckedLabel}
              indeterminate={indeterminate}
              classNames={classes}
              onChange={() =>
                onChange(allChecked ? [] : options.map(({ key }) => key))
              }
            />
            {items}
          </Stack>
        </MantineProvider>
      </Popover.Dropdown>
    </Popover>
  )
}
