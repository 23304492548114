import styles from "./members-combobox.module.scss"
import {
  Avatar,
  Combobox,
  Group,
  Pill,
  PillGroup,
  PillsInput,
  Text,
  rem,
  useCombobox,
} from "@mantine/core"
import { DashedCircle } from "@shared/ui/dashed-circle"
import { getAvatarText } from "@shared/utils/helpers"
import { IconCheck } from "@tabler/icons-react"
import { useState } from "react"

type User = {
  avatar: string | null
  email: string
  firstName: string | null
  id: string
  lastName: string | null
}

type Props = {
  users: User[]
  setUserIdsCallback: (values: string[]) => void
}

export const MembersCombobox = ({ users, setUserIdsCallback }: Props) => {
  const [search, setSearch] = useState("")
  const [value, setValue] = useState<string[]>([])
  const [userIds, setUserIds] = useState<string[]>([])

  const combobox = useCombobox()
  const filteredOptions = users.filter(({ email, firstName, lastName }) => {
    const formattedValue = search.toLowerCase().trim()

    return !!(
      email.toLowerCase().includes(formattedValue) ||
      `${firstName ?? ""} ${lastName ?? ""}`
        .toLowerCase()
        .includes(formattedValue)
    )
  })

  const handleValueSelect = (optionValue: string) => {
    const currentUser = users.find(({ id }) => id === optionValue)
    const inputValue =
      currentUser?.firstName && currentUser?.lastName
        ? `${currentUser?.firstName} ${currentUser.lastName}`
        : currentUser?.email ?? ""

    setValue((currentValues) =>
      currentValues.includes(inputValue)
        ? currentValues.filter((val) => val !== inputValue)
        : [...currentValues, inputValue]
    )

    const newValues = userIds.includes(optionValue)
      ? userIds.filter((val) => val !== optionValue)
      : [...userIds, optionValue]

    setUserIds(newValues)
    setUserIdsCallback(newValues)
  }

  const handleValueRemove = (optionValue: string) => {
    const currentUser = users.find(({ id }) => id === optionValue)
    const inputValue =
      currentUser?.firstName && currentUser?.lastName
        ? `${currentUser?.firstName} ${currentUser.lastName}`
        : currentUser?.email ?? ""

    setValue((currentValues) =>
      currentValues.filter((val) => val !== inputValue)
    )

    const newValues = userIds.filter((val) => val !== optionValue)

    setUserIds(newValues)
    setUserIdsCallback(newValues)
  }

  const values = value.map((item, index) => (
    <Pill
      key={item}
      withRemoveButton
      onRemove={() => handleValueRemove(userIds[index])}
    >
      {item}
    </Pill>
  ))

  const options = filteredOptions.map((user) => {
    const activeOption = userIds.includes(user.id)

    if (user.firstName && user.lastName) {
      return (
        <Combobox.Option
          value={user.id}
          key={user.id}
          className={[
            styles.Combobox__Option,
            activeOption && styles["Combobox__Option--active"],
          ].join(" ")}
        >
          <Group justify="space-between">
            <Group gap={rem(8)}>
              <Avatar src={user.avatar} radius="xl" size="xs">
                {getAvatarText(`${user.firstName} ${user.lastName}`)}
              </Avatar>
              <Text fw="bold" size="sm" c={activeOption ? "blue.7" : "gray.7"}>
                {`${user.firstName} ${user.lastName}`}
              </Text>
            </Group>
            {activeOption && <IconCheck className={styles.CheckIcon} />}
          </Group>
        </Combobox.Option>
      )
    }

    return (
      <Combobox.Option
        value={user.id}
        key={user.id}
        className={[
          styles.Combobox__Option,
          activeOption && styles["Combobox__Option--active"],
        ].join(" ")}
      >
        <Group justify="space-between">
          <Group gap={rem(8)}>
            <DashedCircle />
            <Text fw="bold" size="sm" c={activeOption ? "blue.7" : "gray.6"}>
              {user.email}
            </Text>
          </Group>
          {activeOption && <IconCheck className={styles.CheckIcon} />}
        </Group>
      </Combobox.Option>
    )
  })

  return (
    <Combobox
      onOptionSubmit={handleValueSelect}
      store={combobox}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput onClick={() => combobox.openDropdown()} label="Member">
          <PillGroup>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder="Find member by E-mail or name"
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault()
                    handleValueRemove(userIds.at(-1) ?? "")
                  }
                }}
              />
            </Combobox.EventsTarget>
          </PillGroup>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={400} style={{ overflowY: "auto" }}>
          {options.length === 0 ? (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
