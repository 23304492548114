import {
  saveTransactionModalUpsertSavedTransactionMutation as SaveTransactionModalUpsertSavedTransactionMutationType,
  UpsertSavedTransactionMutationInput,
} from "./__generated__/saveTransactionModalUpsertSavedTransactionMutation.graphql"
import styles from "./save-transaction-modal.module.scss"
import { Input, Select, Stack, Text } from "@mantine/core"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { IconChevronDown } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { graphql, useMutation } from "react-relay"

const SaveTransactionModalUpsertSavedTransactionMutation = graphql`
  mutation saveTransactionModalUpsertSavedTransactionMutation(
    $input: UpsertSavedTransactionMutationInput!
  ) {
    upsertSavedTransaction(input: $input) {
      savedTransaction {
        id
        name
        data {
          accountIds
          amountRate
          classIds
          customerIds
          endDate
          entityIds
          limit
          maxRange
          minRange
          pageNumber
          search
          startDate
          vendorIds
        }
      }
    }
  }
`

type Props = {
  saveTransactionInput: UpsertSavedTransactionMutationInput
  title: string
  opened: boolean
  onClose: () => void
}

export const SaveTransactionModal = ({
  saveTransactionInput,
  title,
  opened,
  onClose,
}: Props) => {
  const [transactionName, setTransactionName] = useState<string>(
    saveTransactionInput.name
  )
  const [selectedId, setSelectedId] = useState<string | null>(
    saveTransactionInput.id || "new-id"
  )

  const [upsertSavedTransaction, upsertSavedTransactionLoading] =
    useMutation<SaveTransactionModalUpsertSavedTransactionMutationType>(
      SaveTransactionModalUpsertSavedTransactionMutation
    )

  useEffect(() => {
    setTransactionName(saveTransactionInput.name)
  }, [saveTransactionInput.name])

  const handleSave = () => {
    upsertSavedTransaction({
      variables: {
        input: {
          ...saveTransactionInput,
          id: selectedId === "new-id" ? null : selectedId,
          name: transactionName,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "",
          message: "Transaction saved successfully!",
        })
        handleOnClose()
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
        handleOnClose()
      },
    })
  }

  const handleOnClose = () => {
    setTransactionName(saveTransactionInput.name)
    setSelectedId(saveTransactionInput.id || "new-id")
    onClose()
  }
  return (
    <ConfirmationModal
      title={title}
      opened={opened}
      onClose={handleOnClose}
      cancelButtonProps={{
        onClick: handleOnClose,
      }}
      confirmButtonProps={{
        onClick: handleSave,
        children: "Save",
        color: "blue",
        loading: upsertSavedTransactionLoading,
        disabled: upsertSavedTransactionLoading,
      }}
    >
      {" "}
      <Stack gap="1.3rem">
        {saveTransactionInput.id && (
          <Stack gap="0.3rem">
            <Text size="sm" fw="bold">
              Save as
            </Text>
            <Select
              withCheckIcon={false}
              allowDeselect={false}
              rightSection={<IconChevronDown size={16} />}
              classNames={{ option: styles.Option }}
              onChange={(value) => setSelectedId(value)}
              value={selectedId}
              data={[
                {
                  label: "Current Transaction",
                  value: saveTransactionInput.id,
                },
                { label: "New Transaction", value: "new-id" },
              ]}
            />
          </Stack>
        )}
        <Stack gap="0.3rem">
          <Text size="sm" fw="bold">
            {"Report Name"}
          </Text>
          <Input
            value={transactionName}
            onChange={(event) => setTransactionName(event.currentTarget.value)}
          />
        </Stack>
      </Stack>
    </ConfirmationModal>
  )
}
