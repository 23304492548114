import { connectionsDrawerQuery as ConnectionsDrawerQueryType } from "./__generated__/connectionsDrawerQuery.graphql"
import { connectionsDrawerUpdateMutation as ConnectionsDrawerUpdateMutationType } from "./__generated__/connectionsDrawerUpdateMutation.graphql"
import { ConnectionRow } from "./connection-row"
import { DeleteButton } from "./delete-button/delete-button"
import { SyncButton } from "./sync-button"
import {
  Avatar,
  Button,
  Divider,
  Flex,
  Group,
  Image,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { AppDrawer } from "@shared/ui/app-drawer"
import { notifications } from "@shared/ui/notifications"
import {
  getAvatarText,
  getDaysAgo,
  getIntegrationSlugIcon,
} from "@shared/utils/helpers"
import { IconAlertTriangle } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import {
  PreloadedQuery,
  graphql,
  useMutation,
  usePreloadedQuery,
} from "react-relay"

interface Props {
  open: boolean
  onClose: () => void
  queryRef: PreloadedQuery<ConnectionsDrawerQueryType>
}

export const ConnectionsDrawerQuery = graphql`
  query connectionsDrawerQuery($input: ID!) {
    getIntegrationDetails(integrationId: $input) {
      id
      name
      status
      lastSync
      system {
        slug
        name
        category
      }
      domain
      creator {
        fullName
      }
      ...syncButtonFragment
      ...deleteButtonFragment
    }
  }
`

const ConnectionsDrawerUpdateMutation = graphql`
  mutation connectionsDrawerUpdateMutation($input: UpdateIntegrationInput!) {
    updateIntegration(input: $input) {
      clientMutationId
      integration {
        id
        name
        lastSync
      }
    }
  }
`

export const ConnectionDrawer = ({ open, queryRef, onClose }: Props) => {
  const { getIntegrationDetails } =
    usePreloadedQuery<ConnectionsDrawerQueryType>(
      ConnectionsDrawerQuery,
      queryRef
    )
  const [updateIntegration, updateIntegratinoLoading] =
    useMutation<ConnectionsDrawerUpdateMutationType>(
      ConnectionsDrawerUpdateMutation
    )
  const [connectionName, setConnectionName] = useState(
    getIntegrationDetails?.name ?? ""
  )

  useEffect(() => {
    setConnectionName(getIntegrationDetails?.name ?? "")
  }, [getIntegrationDetails?.name])

  const saveButtonDisabled =
    getIntegrationDetails?.name === connectionName || !connectionName
  const formattedLastSync = getIntegrationDetails?.lastSync
    ? getDaysAgo(new Date(getIntegrationDetails?.lastSync))
    : "--"

  const handleUpdate = () => {
    getIntegrationDetails?.id &&
      updateIntegration({
        variables: {
          input: {
            integrationId: getIntegrationDetails?.id,
            name: connectionName,
          },
        },
        onCompleted: () => {
          notifications.show({
            title: "Changes Saved Successfully",
            message:
              "Your changes have been saved successfully. You're all set to continue!",
            variant: "success",
          })
        },
        onError: () => {
          notifications.show({
            title: "Update failed",
            message:
              "An unexpected error occurred while updating the connection. Please try again.",
            variant: "error",
          })
        },
      })
  }

  return (
    <AppDrawer
      opened={open}
      onClose={onClose}
      title="Connection Details"
      closeOnClickOutside={saveButtonDisabled}
    >
      {getIntegrationDetails && (
        <Stack justify="space-between" h="100%">
          <Stack gap="0.5rem">
            <Flex gap="0.25rem" align="flex-end">
              <TextInput
                label="Name"
                onChange={(e) => setConnectionName(e.target.value)}
                w="100%"
                value={connectionName}
              />
              <Button
                onClick={handleUpdate}
                disabled={saveButtonDisabled}
                loading={updateIntegratinoLoading}
              >
                Save
              </Button>
            </Flex>
            <ConnectionRow title="Data Source">
              <Group gap={rem(4)}>
                <Image
                  src={getIntegrationSlugIcon(
                    getIntegrationDetails.system?.slug ?? ""
                  )}
                  miw={rem(16)}
                />
                <Text size="sm" c="gray.7">
                  {getIntegrationDetails?.system?.name}
                </Text>
              </Group>
            </ConnectionRow>
            <Divider />
            <ConnectionRow title="Data Type">
              <Text size="sm" c="gray.7">
                {getIntegrationDetails?.system?.category}
              </Text>
            </ConnectionRow>
            <Divider />
            <ConnectionRow title="Created by">
              <Group gap={rem(4)}>
                <Avatar size="xs" color="lightBlue.9" variant="filled">
                  {getAvatarText(getIntegrationDetails.creator.fullName ?? "")}
                </Avatar>
                <Text size="sm" c="gray.7">
                  {getIntegrationDetails.creator.fullName}
                </Text>
              </Group>
            </ConnectionRow>
            <Divider />
            <Flex justify="space-between" direction="row">
              <ConnectionRow title="Last update">
                <Text size="sm" c="gray.7">
                  {getIntegrationDetails.status === "SYNCING"
                    ? "--"
                    : formattedLastSync}
                </Text>
              </ConnectionRow>
              <SyncButton
                statusRef={getIntegrationDetails}
                domain={getIntegrationDetails.domain ?? ""}
              />
            </Flex>
            {(getIntegrationDetails.status == "BROKEN" ||
              getIntegrationDetails.status == "SYNC_FAILED") && (
              <Group p="0.5rem" gap="0.5rem" c="red">
                <IconAlertTriangle size={16} />
                <Text size="sm">
                  {getIntegrationDetails.status == "BROKEN"
                    ? "This connection is broken, please reconnect!"
                    : "Sync failed, please try again!"}
                </Text>
              </Group>
            )}
          </Stack>
          <DeleteButton
            integrationRef={getIntegrationDetails}
            onComplete={onClose}
          />
        </Stack>
      )}
    </AppDrawer>
  )
}
