import { MantineProvider } from "@mantine/core"
import "@mantine/core/styles.css"
import "@mantine/core/styles.css"
import "@mantine/dates/styles.css"
import { Notifications } from "@mantine/notifications"
import "@mantine/notifications/styles.css"
import { ServerError } from "@shared/ui/errors/server-error"
import { Router } from "frontend/routes/router"
import "frontend/shared/assets/index.scss"
import { Theme } from "frontend/shared/theme"
import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"
import { ErrorBoundary } from "react-error-boundary"
import { BrowserRouter } from "react-router-dom"

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.querySelector("#root")!).render(
  <React.StrictMode>
    <MantineProvider>
      <Notifications position="bottom-center" />
      <Theme>
        <BrowserRouter>
          <ErrorBoundary
            fallbackRender={(error) => <ServerError error={error} />}
          >
            <Suspense>
              <Router />
            </Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </Theme>
    </MantineProvider>
  </React.StrictMode>
)
