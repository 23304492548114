/**
 * @generated SignedSource<<3f5e0c5e76db55b9dc1b3ce537971270>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type IncomeCashFlowSettingMutationInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type deleteIncomeAccountModalIncomeCashFlowSettingMutation$variables = {
  connections: ReadonlyArray<string>;
  input: IncomeCashFlowSettingMutationInput;
};
export type deleteIncomeAccountModalIncomeCashFlowSettingMutation$data = {
  readonly incomeCashFlowSetting: {
    readonly accounts: ReadonlyArray<{
      readonly id: string;
      readonly includeInCashFlow: boolean | null;
    }> | null;
  } | null;
};
export type deleteIncomeAccountModalIncomeCashFlowSettingMutation = {
  response: deleteIncomeAccountModalIncomeCashFlowSettingMutation$data;
  variables: deleteIncomeAccountModalIncomeCashFlowSettingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "includeInCashFlow",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteIncomeAccountModalIncomeCashFlowSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "IncomeCashFlowSettingMutationPayload",
        "kind": "LinkedField",
        "name": "incomeCashFlowSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LedgerAccount",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteIncomeAccountModalIncomeCashFlowSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "IncomeCashFlowSettingMutationPayload",
        "kind": "LinkedField",
        "name": "incomeCashFlowSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LedgerAccount",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1529e0bca774359847ac84e5bf2e8b59",
    "id": null,
    "metadata": {},
    "name": "deleteIncomeAccountModalIncomeCashFlowSettingMutation",
    "operationKind": "mutation",
    "text": "mutation deleteIncomeAccountModalIncomeCashFlowSettingMutation(\n  $input: IncomeCashFlowSettingMutationInput!\n) {\n  incomeCashFlowSetting(input: $input) {\n    accounts {\n      id\n      includeInCashFlow\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74cea5543e43bc0b9c06c518eb040cd5";

export default node;
