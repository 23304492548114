/**
 * @generated SignedSource<<24c1e9a4762565f58cf6f2f0814744c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type homeGetHomeInfoQuery$variables = {
  clientSlug: string;
};
export type homeGetHomeInfoQuery$data = {
  readonly getHomeInfo: {
    readonly data: {
      readonly brokenConnections: number;
      readonly connectionsCount: number;
      readonly dashboards: {
        readonly count: number;
      };
      readonly dataSourcesCount: number;
      readonly " $fragmentSpreads": FragmentRefs<"dashboardsTableFragment">;
    };
    readonly errors: ReadonlyArray<string>;
  };
};
export type homeGetHomeInfoQuery = {
  response: homeGetHomeInfoQuery$data;
  variables: homeGetHomeInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "clientSlug",
    "variableName": "clientSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "connectionsCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brokenConnections",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataSourcesCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "homeGetHomeInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetHomeInfo",
        "kind": "LinkedField",
        "name": "getHomeInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "HomeInfo",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SharedDashboardConnection",
                "kind": "LinkedField",
                "name": "dashboards",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "dashboardsTableFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "homeGetHomeInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetHomeInfo",
        "kind": "LinkedField",
        "name": "getHomeInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "HomeInfo",
            "kind": "LinkedField",
            "name": "data",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SharedDashboardConnection",
                "kind": "LinkedField",
                "name": "dashboards",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SharedDashboardEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SharedDashboard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "DashboardsTableFragment_dashboards",
                "kind": "LinkedHandle",
                "name": "dashboards"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "701eee15cc32bd828e88c2f0fb3353f7",
    "id": null,
    "metadata": {},
    "name": "homeGetHomeInfoQuery",
    "operationKind": "query",
    "text": "query homeGetHomeInfoQuery(\n  $clientSlug: String!\n) {\n  getHomeInfo(clientSlug: $clientSlug) {\n    errors\n    data {\n      connectionsCount\n      brokenConnections\n      dataSourcesCount\n      dashboards {\n        count\n      }\n      ...dashboardsTableFragment\n    }\n  }\n}\n\nfragment dashboardsTableFragment on HomeInfo {\n  dashboards {\n    edges {\n      node {\n        id\n        name\n        url\n        createdAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd7707dfd35a7277af2ab4460e6cfd50";

export default node;
