import {
  clientSettingsGetClientQuery as ClientSettingsGetClientQueryType,
  ClientTypeEnum,
} from "./__generated__/clientSettingsGetClientQuery.graphql"
import { clientSettingsUpdateLogoMutation as ClientSettingsUpdateLogoMutationType } from "./__generated__/clientSettingsUpdateLogoMutation.graphql"
import { DeleteClientModal } from "./delete-client-modal"
import { MembersDrawer } from "./members-drawer"
import { MembersTable } from "./members-table"
import {
  Box,
  Button,
  Card,
  Group,
  Space,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { ClientDrawer } from "@shared/ui/client-drawer"
import { ImageBox } from "@shared/ui/image-box"
import { notifications } from "@shared/ui/notifications"
import { getAvatarText, getFormattedDate } from "@shared/utils/helpers"
import {
  IconBuilding,
  IconCalendar,
  IconCalendarEvent,
  IconMail,
  IconPhone,
  IconUser,
} from "@tabler/icons-react"
import { format } from "date-fns"
import { useState } from "react"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import { graphql, useLazyLoadQuery, useMutation } from "react-relay"
import { Link } from "react-router-dom"

const ClientSettingsGetClientQuery = graphql`
  query clientSettingsGetClientQuery($clientId: ID!) {
    getClient(clientId: $clientId) {
      errors
      client {
        id
        creator {
          firstName
          lastName
        }
        createdAt
        type
        status
        logo
        color
        fiscal
        name
        contactEmail
        contactName
        contactPhone
        closeMonth
        firm {
          ...membersDrawerFirmInfoFragment
          ...membersTableFirmInfoFragment
        }
        ...membersTableFragment
        ...membersDrawerFragment
      }
    }
  }
`

const ClientSettingsUpdateLogoMutation = graphql`
  mutation clientSettingsUpdateLogoMutation($logo: String) {
    updateCurrentClient(input: { logo: $logo }) {
      client {
        logo
      }
      errors
    }
  }
`

const months = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const CLIENT_TYPE_MAPPER: { [key in ClientTypeEnum]: string } = {
  SAAS: "SaaS",
  ECOMMERCE: "E-Commerce",
  SERVICES: "Services",
  RETAIL: "Retail",
  OTHER: "Others",
}

export const ClientSettings = () => {
  const { currentClient, isAdmin } = useUserStore()
  const [clientToRemove, setClientToRemove] = useState({ id: "", name: "" })

  const [
    deleteClientModalOpened,
    { open: deleteClientModalOpen, close: deleteClientModalClose },
  ] = useDisclosure(false)

  const handleRemoveClientAction = (clientId: string, clientName: string) => {
    setClientToRemove({ id: clientId, name: clientName })
    deleteClientModalOpen()
  }

  const [
    updateDrawerOpened,
    { open: updateDrawerOpen, close: updateDrawerClose },
  ] = useDisclosure(false)

  const [
    addMemberDrawerOpened,
    { open: addMemberDrawerOpen, close: addMemberDrawerClose },
  ] = useDisclosure(false)

  const { getClient } = useLazyLoadQuery<ClientSettingsGetClientQueryType>(
    ClientSettingsGetClientQuery,
    { clientId: currentClient.id ?? "" },
    { fetchKey: currentClient.id ?? "" }
  )
  const [updateClientLogo, updateClientLogoLoading] =
    useMutation<ClientSettingsUpdateLogoMutationType>(
      ClientSettingsUpdateLogoMutation
    )

  const updateLogoCallback = (file: string) => {
    updateClientLogo({
      variables: {
        logo: file,
      },
      onCompleted: () => {
        notifications.show({
          title: "Logo succesfully updated!",
          message: "The logo has been updated in the client.",
          variant: "success",
        })
      },
      onError: (error) => {
        notifications.show({
          title: error.message,
          message: "Please try again",
          variant: "error",
        })
      },
    })
  }

  const client = getClient.client

  return (
    <Box p="xl">
      <Text size="xxl" fw={700} c={"gray"} component="h1">
        Client Settings
      </Text>
      <Space h={rem(56)} />
      <Stack gap={rem(32)}>
        <Group justify="space-between">
          <Stack gap="0.5rem">
            <Text fw="bold" c="gray.7">
              Client Information
            </Text>
            <Text size="sm" c="gray">
              Created by{" "}
              <strong>
                {client.creator.firstName} {client.creator.lastName}
              </strong>{" "}
              on <strong>{getFormattedDate(new Date(client.createdAt))}</strong>
            </Text>
          </Stack>
          {isAdmin && (
            <Button
              variant="outline"
              px={rem(16)}
              fw="normal"
              w={rem(120)}
              onClick={updateDrawerOpen}
            >
              Edit
            </Button>
          )}
        </Group>
        <Group grow align="normal">
          <Card shadow="xs" radius="md" p="1.5rem">
            <Stack gap="1rem">
              <Text c="dark.3" fw={700}>
                {client.name}
              </Text>
              <Group gap="0.75rem" py="0.5rem">
                <IconBuilding size={16} color={themeVars.colors.gray[6]} />
                <Text size="sm" fw="bold" c="gray.6" style={{ flexGrow: 1 }}>
                  Company Type
                </Text>
                <Text size="sm" c="gray.7">
                  {CLIENT_TYPE_MAPPER[client.type]}
                </Text>
              </Group>
              <Group gap="0.75rem" py="0.5rem">
                <IconCalendarEvent size={16} color={themeVars.colors.gray[6]} />
                <Text size="sm" fw="bold" c="gray.6" style={{ flexGrow: 1 }}>
                  Close Month
                </Text>
                <Text size="sm" c="gray.7">
                  {format(client.closeMonth, "MMM, yyyy")}
                </Text>
              </Group>
              <Group gap="0.75rem" py="0.5rem">
                <IconCalendar size={16} color={themeVars.colors.gray[6]} />
                <Text size="sm" fw="bold" c="gray.6" style={{ flexGrow: 1 }}>
                  Fiscal Start Month
                </Text>
                <Text size="sm" c="gray.7">
                  {months[client.fiscal]}
                </Text>
              </Group>
            </Stack>
          </Card>
          <Card shadow="xs" radius="md" p="1.5rem">
            <Stack gap="1rem">
              <Text c="dark.3" fw={700}>
                Contact
              </Text>
              <Group gap="0.75rem" py="0.5rem">
                <IconUser size={16} color={themeVars.colors.gray[6]} />
                <Text size="sm" fw="bold" c="gray.6" style={{ flexGrow: 1 }}>
                  Name
                </Text>
                <Text size="sm" c="gray.7">
                  {client.contactName ?? "-"}
                </Text>
              </Group>
              <Group gap="0.75rem" py="0.5rem">
                <IconMail size={16} color={themeVars.colors.gray[6]} />
                <Text size="sm" fw="bold" c="gray.6" style={{ flexGrow: 1 }}>
                  E-mail
                </Text>
                <Text
                  size="sm"
                  c={client.contactEmail ? "blue" : "gray.7"}
                  component={Link}
                  to={
                    client.contactEmail ? `mailto:${client.contactEmail}` : "#"
                  }
                >
                  {client.contactEmail ?? "-"}
                </Text>
              </Group>
              <Group gap="0.75rem" py="0.5rem">
                <IconPhone size={16} color={themeVars.colors.gray[6]} />
                <Text size="sm" fw="bold" c="gray.6" style={{ flexGrow: 1 }}>
                  Phone
                </Text>
                <Text size="sm" c="gray.7">
                  {client.contactPhone
                    ? formatPhoneNumberIntl(client.contactPhone)
                    : "-"}
                </Text>
              </Group>
            </Stack>
          </Card>
        </Group>
        <ImageBox
          title="Client Logo"
          image={client.logo ?? ""}
          placeholder={getAvatarText(client.name ?? "")}
          placeholderColor={client.color ?? "lightBlue"}
          loading={updateClientLogoLoading}
          imageUpdaterCallback={updateLogoCallback}
          shouldUpdate={isAdmin ?? false}
        />
        <Group justify="space-between">
          <Text fw="bold" c="gray.7">
            Client Members
          </Text>
          {isAdmin && (
            <Button
              variant="outline"
              px={rem(16)}
              fw="normal"
              w={rem(120)}
              onClick={addMemberDrawerOpen}
            >
              Add Member
            </Button>
          )}
        </Group>
        <MembersTable members={client} firm={client.firm} />
        {isAdmin && (
          <Box>
            <Text fw="bold" c="gray.7">
              Delete Client
            </Text>
            <Space h={rem(32)} />
            <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
              <Group justify="space-between">
                <Text size="sm" c="gray.7" py={rem(8)}>
                  If you wish to permanently delete this client, along with all
                  related data, which includes users and boards.
                </Text>
                <Button
                  color="red"
                  w={rem(120)}
                  onClick={() =>
                    handleRemoveClientAction(client.id, client.name)
                  }
                >
                  Delete
                </Button>
              </Group>
            </Card>
          </Box>
        )}
      </Stack>
      <ClientDrawer
        opened={updateDrawerOpened}
        onClose={updateDrawerClose}
        propsFormValues={{
          type: client.type,
          fiscal: `${client.fiscal}`,
          name: client.name,
          contactEmail: client.contactEmail ?? "",
          contactName: client.contactName ?? "",
          contactPhone: client.contactPhone ?? "",
          closeMonth: new Date(client.closeMonth),
        }}
      />
      <MembersDrawer
        members={client}
        firm={client.firm}
        opened={addMemberDrawerOpened}
        onClose={addMemberDrawerClose}
      />
      <DeleteClientModal
        clientData={clientToRemove}
        opened={deleteClientModalOpened}
        onClose={deleteClientModalClose}
      />
    </Box>
  )
}
