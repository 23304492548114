/**
 * @generated SignedSource<<4d9e63efb7c5bedfcf66ae11dcdee3ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type adminLayoutCurrentUserDataQuery$variables = {};
export type adminLayoutCurrentUserDataQuery$data = {
  readonly getCurrentUserData: {
    readonly user: {
      readonly isSuperAdmin: boolean;
    };
  };
};
export type adminLayoutCurrentUserDataQuery = {
  response: adminLayoutCurrentUserDataQuery$data;
  variables: adminLayoutCurrentUserDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSuperAdmin",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "adminLayoutCurrentUserDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "getCurrentUserData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "adminLayoutCurrentUserDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CurrentUser",
        "kind": "LinkedField",
        "name": "getCurrentUserData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "410d022ad981223625c04cfd2f249bf9",
    "id": null,
    "metadata": {},
    "name": "adminLayoutCurrentUserDataQuery",
    "operationKind": "query",
    "text": "query adminLayoutCurrentUserDataQuery {\n  getCurrentUserData {\n    user {\n      isSuperAdmin\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4440ebab9428f56ae6695f4e3a53c10b";

export default node;
