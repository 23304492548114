/**
 * @generated SignedSource<<ebc62eab99a931c8b778882bdec5289c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type signOutButtonMutation$variables = {};
export type signOutButtonMutation$data = {
  readonly userLogout: {
    readonly authenticatable: {
      readonly email: string;
    };
  } | null;
};
export type signOutButtonMutation = {
  response: signOutButtonMutation$data;
  variables: signOutButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserLogoutPayload",
    "kind": "LinkedField",
    "name": "userLogout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Authenticatable",
        "kind": "LinkedField",
        "name": "authenticatable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "signOutButtonMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "signOutButtonMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7a4cf3c2cfea02e9157cd3a12cfa96d7",
    "id": null,
    "metadata": {},
    "name": "signOutButtonMutation",
    "operationKind": "mutation",
    "text": "mutation signOutButtonMutation {\n  userLogout {\n    authenticatable {\n      email\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbac3f268cf4eccdce90c64e6eaa4d43";

export default node;
