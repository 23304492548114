import styles from "./select.module.scss"
import { Select as MantineSelect, SelectProps } from "@mantine/core"
import { themeVars } from "@shared/theme"
import { IconChevronDown } from "@tabler/icons-react"
import { Ref, forwardRef } from "react"

export const Select = forwardRef(function Select(
  props: SelectProps,
  ref: Ref<HTMLInputElement>
) {
  return (
    <MantineSelect
      ref={ref}
      checkIconPosition="right"
      rightSection={
        <IconChevronDown size={"1rem"} color={themeVars.colors.gray["6"]} />
      }
      classNames={{
        option: styles.Select__Option,
      }}
      comboboxProps={{ shadow: "xs" }}
      {...props}
    />
  )
})
