import ImagePlaceholder from "@assets/image-placeholder-variant.svg"
import {
  Box,
  Button,
  FileButton,
  Flex,
  Image,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { getBase64 } from "@shared/utils/helpers"

type Props = {
  label: string
  image?: string | undefined
  optional?: boolean
  onChange: (_val: string) => void
}
export const AvatarUpload = ({ label, optional, onChange, image }: Props) => {
  const imageConverter = async (temporalFile: File | null) => {
    if (!temporalFile) return
    const formattedImage = await getBase64(temporalFile)
    onChange(formattedImage)
  }

  return (
    <Stack gap={rem(8)} align="start">
      <Flex align="center" gap={rem(4)}>
        <Text fw={500} size="sm">
          {label}
        </Text>
        {optional && (
          <Text size="xs" c="gray.6">
            {"(Optional)"}
          </Text>
        )}
      </Flex>
      <Box>
        <Image
          src={image}
          style={{ borderRadius: rem(4) }}
          h={rem(84)}
          w={rem(84)}
          fallbackSrc={ImagePlaceholder}
        />
      </Box>
      <FileButton onChange={imageConverter} accept="image/png,image/jpeg">
        {(props) => (
          <Button {...props} variant="outline">
            Upload
          </Button>
        )}
      </FileButton>
    </Stack>
  )
}
