/**
 * @generated SignedSource<<53eb78514b7f5be9594c2905ee5d0ada>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MetabaseQuestionStatusTypeEnum = "BROKEN" | "ONLINE";
export type spreadsheetsDrawerListSpreadsheetsQuery$variables = {
  userConnectionId: string;
  workbookIntegrationId: string;
};
export type spreadsheetsDrawerListSpreadsheetsQuery$data = {
  readonly getMetabaseQuestions: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly collectionPath: ReadonlyArray<string> | null;
        readonly createdAt: string;
        readonly id: string;
        readonly name: string | null;
        readonly status: MetabaseQuestionStatusTypeEnum | null;
      };
    }>;
  };
  readonly listSpreadsheetsFromIntegration: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type spreadsheetsDrawerListSpreadsheetsQuery = {
  response: spreadsheetsDrawerListSpreadsheetsQuery$data;
  variables: spreadsheetsDrawerListSpreadsheetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userConnectionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workbookIntegrationId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userConnectionId",
        "variableName": "userConnectionId"
      },
      {
        "kind": "Variable",
        "name": "workbookIntegrationId",
        "variableName": "workbookIntegrationId"
      }
    ],
    "concreteType": "SpreadsheetIntegrationConnection",
    "kind": "LinkedField",
    "name": "listSpreadsheetsFromIntegration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SpreadsheetIntegrationEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SpreadsheetIntegration",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MetabaseQuestionConnection",
    "kind": "LinkedField",
    "name": "getMetabaseQuestions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MetabaseQuestionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MetabaseQuestion",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collectionPath",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "spreadsheetsDrawerListSpreadsheetsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "spreadsheetsDrawerListSpreadsheetsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7e885b6603f37d47bdb9c87c81963fb3",
    "id": null,
    "metadata": {},
    "name": "spreadsheetsDrawerListSpreadsheetsQuery",
    "operationKind": "query",
    "text": "query spreadsheetsDrawerListSpreadsheetsQuery(\n  $userConnectionId: ID!\n  $workbookIntegrationId: ID!\n) {\n  listSpreadsheetsFromIntegration(userConnectionId: $userConnectionId, workbookIntegrationId: $workbookIntegrationId) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  getMetabaseQuestions {\n    count\n    edges {\n      node {\n        id\n        collectionPath\n        createdAt\n        name\n        status\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3287c64210aa9757dd471cf8aa098bf";

export default node;
