import {
  NumberInput as MantineNumberInput,
  NumberInputProps,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { Ref, forwardRef } from "react"

export const NumberInput = forwardRef(function NumberInput(
  props: Omit<NumberInputProps, "defaultValue"> & {
    defaultValue: number | string | null | undefined
  },
  ref: Ref<HTMLDivElement>
) {
  return (
    <MantineNumberInput
      ref={ref}
      thousandSeparator=","
      decimalScale={2}
      hideControls
      leftSection={
        <IconCurrencyDollar size={16} color={themeVars.colors.gray[6]} />
      }
      styles={{
        input: {
          textAlign: "right",
        },
      }}
      w="100%"
      {...props}
      defaultValue={props.defaultValue ?? ""}
    />
  )
})
