/**
 * @generated SignedSource<<fa0039d1f71402141c222b8413ef02ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MetabaseQuestionStatusTypeEnum = "BROKEN" | "ONLINE";
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
export type WorkbookSpreadsheetStatusTypeEnum = "BROKEN" | "GOOGLE_ERROR" | "ONLINE" | "SPREADSHEETABLE_ERROR" | "SYNCING";
export type WorkbookSyncPeriodTypeEnum = "DAILY" | "MANUALLY";
export type spreadsheetsGetSpreadsheetSettingsQuery$variables = {
  workbookId: string;
};
export type spreadsheetsGetSpreadsheetSettingsQuery$data = {
  readonly getSpreadsheetSettings: {
    readonly bookId: string | null;
    readonly createdAt: string;
    readonly id: string;
    readonly lastSync: string | null;
    readonly name: string | null;
    readonly readonly: boolean;
    readonly syncPeriod: WorkbookSyncPeriodTypeEnum | null;
    readonly updatedAt: string;
    readonly userConnection: {
      readonly id: string;
      readonly name: string;
      readonly status: StatusIntegrationTypeEnum;
      readonly user: {
        readonly fullName: string | null;
      };
    };
    readonly workbookSpreadsheets: {
      readonly count: number;
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly createdAt: string;
          readonly id: string;
          readonly lastSync: string | null;
          readonly name: string | null;
          readonly sheetId: string | null;
          readonly spreadsheetable: {
            readonly id?: string;
            readonly metabaseQuestionId?: number | null;
            readonly name?: string | null;
            readonly status?: MetabaseQuestionStatusTypeEnum | null;
            readonly updatedAt?: string;
          };
          readonly spreadsheetableType: string | null;
          readonly status: WorkbookSpreadsheetStatusTypeEnum;
          readonly syncPeriod: number | null;
        };
      }>;
    };
  } | null;
};
export type spreadsheetsGetSpreadsheetSettingsQuery = {
  response: spreadsheetsGetSpreadsheetSettingsQuery$data;
  variables: spreadsheetsGetSpreadsheetSettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workbookId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "workbookId",
    "variableName": "workbookId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSync",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readonly",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncPeriod",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sheetId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "spreadsheetableType",
  "storageKey": null
},
v16 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metabaseQuestionId",
      "storageKey": null
    },
    (v6/*: any*/),
    (v11/*: any*/)
  ],
  "type": "MetabaseQuestion",
  "abstractKey": null
},
v17 = [
  (v4/*: any*/),
  (v6/*: any*/),
  (v9/*: any*/)
],
v18 = {
  "kind": "InlineFragment",
  "selections": (v17/*: any*/),
  "type": "SavedStatement",
  "abstractKey": null
},
v19 = {
  "kind": "InlineFragment",
  "selections": (v17/*: any*/),
  "type": "SavedTransaction",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "spreadsheetsGetSpreadsheetSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "getSpreadsheetSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIntegration",
            "kind": "LinkedField",
            "name": "userConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v6/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkbookSpreadsheetConnection",
            "kind": "LinkedField",
            "name": "workbookSpreadsheets",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkbookSpreadsheetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkbookSpreadsheet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v14/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v11/*: any*/),
                      (v8/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "spreadsheetable",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "spreadsheetsGetSpreadsheetSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "getSpreadsheetSettings",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserIntegration",
            "kind": "LinkedField",
            "name": "userConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v6/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkbookSpreadsheetConnection",
            "kind": "LinkedField",
            "name": "workbookSpreadsheets",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkbookSpreadsheetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkbookSpreadsheet",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v14/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v11/*: any*/),
                      (v8/*: any*/),
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "spreadsheetable",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v4/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04abdebd1d01236bbfb59c961a81ae94",
    "id": null,
    "metadata": {},
    "name": "spreadsheetsGetSpreadsheetSettingsQuery",
    "operationKind": "query",
    "text": "query spreadsheetsGetSpreadsheetSettingsQuery(\n  $workbookId: ID!\n) {\n  getSpreadsheetSettings(workbookId: $workbookId) {\n    bookId\n    createdAt\n    id\n    lastSync\n    name\n    readonly\n    syncPeriod\n    updatedAt\n    userConnection {\n      user {\n        fullName\n        id\n      }\n      status\n      name\n      id\n    }\n    workbookSpreadsheets {\n      count\n      edges {\n        cursor\n        node {\n          createdAt\n          id\n          sheetId\n          lastSync\n          name\n          status\n          syncPeriod\n          spreadsheetableType\n          spreadsheetable {\n            __typename\n            ... on MetabaseQuestion {\n              id\n              metabaseQuestionId\n              name\n              status\n            }\n            ... on SavedStatement {\n              id\n              name\n              updatedAt\n            }\n            ... on SavedTransaction {\n              id\n              name\n              updatedAt\n            }\n            ... on Node {\n              __isNode: __typename\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ffa8434e7130fd14f3cbe78b284ffdd3";

export default node;
