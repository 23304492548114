/**
 * @generated SignedSource<<e1b2b5add68ce799487082e890924eb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportTypeEnum = "BALANCE" | "CASH" | "INCOME";
export type DeleteSavedReportMutationInput = {
  clientMutationId?: string | null;
  id: string;
};
export type deleteSavedReportModalMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteSavedReportMutationInput;
};
export type deleteSavedReportModalMutation$data = {
  readonly deleteSavedReport: {
    readonly savedReport: {
      readonly filterType?: ReportTypeEnum;
      readonly id?: string;
      readonly name?: string | null;
      readonly updatedAt?: string;
    } | null;
  } | null;
};
export type deleteSavedReportModalMutation = {
  response: deleteSavedReportModalMutation$data;
  variables: deleteSavedReportModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "filterType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "filters": null,
  "handle": "deleteEdge",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id",
  "handleArgs": [
    {
      "kind": "Variable",
      "name": "connections",
      "variableName": "connections"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSavedReportModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteSavedReportMutationPayload",
        "kind": "LinkedField",
        "name": "deleteSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "SavedStatement",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "SavedTransaction",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteSavedReportModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteSavedReportMutationPayload",
        "kind": "LinkedField",
        "name": "deleteSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "SavedStatement",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "type": "SavedTransaction",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "938960bee906f0f1cf2938daffa2ca3e",
    "id": null,
    "metadata": {},
    "name": "deleteSavedReportModalMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSavedReportModalMutation(\n  $input: DeleteSavedReportMutationInput!\n) {\n  deleteSavedReport(input: $input) {\n    savedReport {\n      __typename\n      ... on SavedStatement {\n        id\n        name\n        filterType\n        updatedAt\n      }\n      ... on SavedTransaction {\n        id\n        name\n        updatedAt\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b66e2da58f7a0295ebae781039c18a9";

export default node;
