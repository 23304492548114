export type MetabaseMessageEventType = {
  metabase: {
    location: {
      href: string
    } | null
  } | null
}

export const extractIdFromURL = (url: string) => {
  const pattern = /\/dashboard\/(\d+)(-(\w+))?/
  const match = url.match(pattern)

  if (match) {
    const id = match[1]
    return Number.parseInt(id)
  } else {
    return null
  }
}
