import classes from "./date-picker.module.scss"
import { DatePickerType, DatePicker as MantineDatePicker } from "@mantine/dates"
import { DatePickerProps } from "@mantine/dates"

export const DatePicker = ({ ...props }: DatePickerProps<DatePickerType>) => {
  return (
    <MantineDatePicker
      classNames={classes}
      type="range"
      firstDayOfWeek={0}
      allowSingleDateInRange
      {...props}
    />
  )
}
