import { signOutButtonMutation as SignOutButtonMutationType } from "./__generated__/signOutButtonMutation.graphql"
import { Button } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { graphql, useMutation } from "react-relay"

const SignOutButtonMutation = graphql`
  mutation signOutButtonMutation {
    userLogout {
      authenticatable {
        email
      }
    }
  }
`

export const SignOutButton = () => {
  const { reset } = useUserStore()
  const [signOut, signOutLoading] = useMutation<SignOutButtonMutationType>(
    SignOutButtonMutation
  )

  const handleSignOut = () => {
    signOut({
      variables: {},
      onCompleted: () => reset(),
      updater: (store) => store.invalidateStore(),
    })
  }

  return (
    <Button color="red" loading={signOutLoading} onClick={handleSignOut}>
      Sign Out
    </Button>
  )
}
