/**
 * @generated SignedSource<<1db4747f6ae7e5467cb1805c48224979>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type savedStatementsTableGetUsersQuery$variables = {};
export type savedStatementsTableGetUsersQuery$data = {
  readonly getFirmUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fullName: string | null;
        readonly id: string;
      };
    }>;
  };
};
export type savedStatementsTableGetUsersQuery = {
  response: savedStatementsTableGetUsersQuery$data;
  variables: savedStatementsTableGetUsersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserConnection",
    "kind": "LinkedField",
    "name": "getFirmUsers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "savedStatementsTableGetUsersQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "savedStatementsTableGetUsersQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a480aecff9c9dd387ca8e851af56a60a",
    "id": null,
    "metadata": {},
    "name": "savedStatementsTableGetUsersQuery",
    "operationKind": "query",
    "text": "query savedStatementsTableGetUsersQuery {\n  getFirmUsers {\n    edges {\n      node {\n        id\n        fullName\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9e6f6499e77d9ad9a8f0e3a0f65da2c6";

export default node;
