import { deleteSavedReportModalMutation as DeleteSavedReportModalMutationType } from "./__generated__/deleteSavedReportModalMutation.graphql"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { ConnectionHandler, graphql, useMutation } from "react-relay"

const DeleteSavedReportModalMutation = graphql`
  mutation deleteSavedReportModalMutation(
    $input: DeleteSavedReportMutationInput!
    $connections: [ID!]!
  ) {
    deleteSavedReport(input: $input) {
      savedReport {
        ... on SavedStatement {
          id @deleteEdge(connections: $connections)
          name
          filterType
          updatedAt
        }
        ... on SavedTransaction {
          id @deleteEdge(connections: $connections)
          name
          updatedAt
        }
      }
    }
  }
`

type Props = {
  savedReport: {
    id: string
    name: string
  }
  opened: boolean
  onClose: () => void
}

export const DeleteSavedReportModal = ({
  savedReport,
  opened,
  onClose,
}: Props) => {
  const [deleteSavedReport, deleteSavedReportLoading] =
    useMutation<DeleteSavedReportModalMutationType>(
      DeleteSavedReportModalMutation
    )

  const handleDelete = () => {
    const connectionId = ConnectionHandler.getConnectionID(
      "root",
      "SavedStatementsTableFragment_getSavedReports"
    )

    deleteSavedReport({
      variables: {
        input: {
          id: savedReport.id,
        },
        connections: [connectionId],
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "",
          message: "Statement successfully deleted!",
        })
        onClose()
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
        onClose()
      },
    })
  }

  return (
    <ConfirmationModal
      title={`Are you sure you want to delete the "${savedReport.name}" saved statement?`}
      opened={opened && !!savedReport.id}
      onClose={onClose}
      cancelButtonProps={{
        onClick: onClose,
      }}
      confirmButtonProps={{
        onClick: handleDelete,
        children: "Delete",
        color: "red",
        loading: deleteSavedReportLoading,
        disabled: deleteSavedReportLoading,
      }}
    >
      Removing this saved statement will not delete any of the associated data.
    </ConfirmationModal>
  )
}
