/**
 * @generated SignedSource<<8bf87725216e1e605eb906a45f52b709>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
import { FragmentRefs } from "relay-runtime";
export type deleteButtonFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly status: StatusIntegrationTypeEnum;
  readonly " $fragmentType": "deleteButtonFragment";
};
export type deleteButtonFragment$key = {
  readonly " $data"?: deleteButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"deleteButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "deleteButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "SystemIntegration",
  "abstractKey": null
};

(node as any).hash = "bb172103ebbadd7962c349d25cf7e15c";

export default node;
