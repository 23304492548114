import classes from "./accounts-popover.module.scss"
import {
  ActionIcon,
  Box,
  Checkbox,
  Combobox,
  ComboboxDropdownProps,
  ComboboxProps,
  Divider,
  Flex,
  PillsInputProps,
  Text,
  TextInput,
  rem,
  useCombobox,
} from "@mantine/core"
import { IconSearch, IconX } from "@tabler/icons-react"
import { filter } from "lodash"
import {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react"

export type AccountOptionType = {
  label: string
  checked: boolean
  key: string
  rightLabel?: string
  options: AccountOptionType[]
}
type Props = ComboboxProps & {
  inputRenderer?: ReactElement | null
  target?: ReactNode
  options: AccountOptionType[]
  onChange: (_val: AccountOptionType[]) => void
  onClose?: () => void
  pillsInputProps?: Partial<PillsInputProps>
  dropdownProps?: Partial<ComboboxDropdownProps>
  groupLabel: string
}
export const AccountsPopover = ({
  onChange,
  options,
  target,
  dropdownProps,
  groupLabel,
  onClose,
  ...rest
}: Props) => {
  const [search, setSearch] = useState("")

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      setSearch("")
      onClose && onClose()
    },
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  })

  const flatten = useCallback((items: AccountOptionType[]) => {
    const flat: AccountOptionType[] = []
    items.forEach((item) => {
      if (item.options.length > 0) {
        flat.push(item, ...flatten(item.options))
      } else {
        flat.push(item)
      }
    })

    return flat
  }, [])

  const filteredOptions = useMemo(
    () =>
      search
        ? filter(flatten(options), (item) =>
            item.label
              .trim()
              .toLowerCase()
              .includes(search.trim().toLowerCase())
          )
        : options,
    [flatten, options, search]
  )

  const checkAllChildren = (items: AccountOptionType[], checked: boolean) => {
    items.forEach((option) => {
      option.checked = checked
      if (option.options.length > 0) {
        checkAllChildren(option.options, checked)
      }
    })
    return items
  }
  const updateItem = (
    items: AccountOptionType[],
    key: string,
    checked: boolean
  ) => {
    if (items.length === 0) {
      return
    }
    items.forEach((option) => {
      if (option.key === key) {
        option.checked = checked
      }
      updateItem(option.options, key, checked)
    })
    return items
  }

  const deepAllChecked = (checkOptions: AccountOptionType[]): boolean => {
    return checkOptions.every((item) => {
      if (item.options.length > 0) {
        return deepAllChecked(item.options)
      }
      if (item.checked) {
        deepAllChecked(item.options)
        return true
      } else {
        return false
      }
    })
  }
  const deepCheckIndeterminate = (
    checkOptions: AccountOptionType[]
  ): boolean => {
    return checkOptions.some((item) => {
      if (item.options.length > 0) {
        return deepCheckIndeterminate(item.options)
      }
      if (item.checked) {
        deepCheckIndeterminate(item.options)
        return true
      } else {
        return false
      }
    })
  }
  const displayOptions = (
    renderOptions: AccountOptionType[],
    level: number
  ): ReactNode[] =>
    renderOptions.map((item) => {
      const optionsCopy = Object.assign([], [...options])
      const itemOptionsCopy = Object.assign([], [...item.options])
      const allChecked = deepAllChecked(item.options) && item.checked
      const indeterminate = deepCheckIndeterminate(item.options)
      const isParent = item.options.length > 0
      const hideChildren =
        search &&
        item.label.trim().toLowerCase().includes(search.trim().toLowerCase())
      const isCategory = item.key.includes("CATEGORY_KEY")
      return (
        <Fragment key={item.key}>
          <Checkbox
            h={"2rem"}
            indeterminate={!allChecked && indeterminate}
            checked={isParent ? allChecked || item.checked : item.checked}
            label={
              <Flex justify={"space-between"} align={"center"}>
                <Text
                  fz={"xs"}
                  tt={isCategory ? "uppercase" : "none"}
                  c={isParent ? "gray.7" : "dark.3"}
                  fw={isParent ? "bold" : "normal"}
                >
                  {item.label}
                </Text>
                {search && item.rightLabel && (
                  <Text c={"gray.6"} size="xs">
                    {item.rightLabel}
                  </Text>
                )}
              </Flex>
            }
            size="xs"
            classNames={classes}
            onChange={(event) => {
              if (isParent) {
                checkAllChildren(itemOptionsCopy, event.currentTarget.checked)
                updateItem(optionsCopy, item.key, event.currentTarget.checked)
              } else {
                updateItem(optionsCopy, item.key, event.currentTarget.checked)
              }
              onChange(optionsCopy)
            }}
            pl={search ? rem(12) : rem(level * 12)}
          />
          {!hideChildren && displayOptions(item.options, level + 1)}
        </Fragment>
      )
    })

  return (
    <Combobox
      store={combobox}
      shadow="xs"
      classNames={{ group: classes.comboboxGroup }}
      {...rest}
    >
      <Combobox.DropdownTarget>
        <Box display={"flex"} onClick={() => combobox.toggleDropdown()}>
          {target}
        </Box>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown p={0} {...dropdownProps}>
        <TextInput
          variant="unstyled"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          leftSection={
            <IconSearch
              size={"1rem"}
              style={{ color: "var(--mantine-color-gray-6)" }}
            />
          }
          rightSection={
            search && (
              <ActionIcon
                onClick={() => {
                  setSearch("")
                }}
                size={".875rem"}
                variant="transparent"
                color="gray.6"
              >
                <IconX size={".875rem"} />
              </ActionIcon>
            )
          }
        />
        <Divider />
        <Combobox.Options
          mah={rem(500)}
          pr={".625rem"}
          style={{ overflowY: "auto", padding: ".25rem" }}
          className={classes.comboboxScrollable}
        >
          <Combobox.Group label={groupLabel}>
            {filteredOptions.length > 0 ? (
              displayOptions(filteredOptions, 1)
            ) : (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
          </Combobox.Group>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
