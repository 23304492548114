import { MantineColorShade } from "@mantine/core"
import { themeVars } from "@shared/theme"
import { formatISO } from "date-fns"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import ChargebeeIcon from "frontend/shared/assets/chargebee.svg"
import GoogleIcon from "frontend/shared/assets/google.svg"
import HubspotIcon from "frontend/shared/assets/hubspot.svg"
import NetsuitIcon from "frontend/shared/assets/netsuit.svg"
import PipeDriveIcon from "frontend/shared/assets/pipedrive.svg"
import QuickbooksIcon from "frontend/shared/assets/quickbooks.svg"
import SalesForceIcon from "frontend/shared/assets/salesforce.svg"
import ShopifyIcon from "frontend/shared/assets/shopify.svg"
import StripeIcon from "frontend/shared/assets/stripe.svg"
import jsPDF from "jspdf"
import autoTable, { ColumnInput, RowInput } from "jspdf-autotable"

type downloadPDFProps = {
  rows: RowInput[]
  columns: ColumnInput[]
  title: string
  filename: string
  footer?: string | null | undefined
  childCategories?: string[] | undefined
  negativeInRed?: boolean
}
const isNegative = (value: string): boolean => {
  return value.includes("(") || value.includes("-")
}
export const downloadPDF = ({
  rows,
  columns,
  title,
  filename,
  footer,
  childCategories,
  negativeInRed,
}: downloadPDFProps) => {
  const doc = new jsPDF()
  doc.text(title, 15, 15)
  const addFooters = () => {
    const pageCount = doc.internal.pages.length
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      doc.setFontSize(8).text(String(footer), 15, 288)
    }
  }
  autoTable(doc, {
    startY: 20,
    styles: {
      minCellWidth: 0,
      fontSize: 8,
      fillColor: "#ffffff",
    },
    headStyles: {
      textColor: "#343A40",
      cellPadding: { vertical: 2, horizontal: 2 },
      lineColor: "#212529",
      lineWidth: { bottom: 1 },
    },
    bodyStyles: {
      textColor: "#343A40",
      cellPadding: { horizontal: 2, vertical: 1 },
    },
    alternateRowStyles: { fillColor: "#ffffff" },
    body: rows,
    columns: columns,
    horizontalPageBreak: true,
    horizontalPageBreakBehaviour: "immediately",
    horizontalPageBreakRepeat: 0,
    didParseCell(tableData) {
      const rowCellsName = tableData.row.cells.name.raw?.toString()
      const cellText = tableData.cell.raw?.toString()

      if (tableData.column.index !== 0) {
        tableData.cell.styles.halign = "right"
        if (
          negativeInRed &&
          !!cellText && // cell has text ?
          tableData.row.index != 0 && //exclude "-" in headers
          isNegative(cellText) // if cell has "-" or "("
        ) {
          tableData.cell.styles.textColor = "#de0029"
        }
      }
      if (rowCellsName?.includes("Total ")) {
        tableData.cell.styles.fontStyle = "bold"
        tableData.cell.styles.lineWidth = { top: 0.2 }
        tableData.cell.styles.lineColor = "#212529"
      }
      if (rowCellsName && childCategories?.includes(rowCellsName.trim())) {
        if (tableData.column.index == 0) {
          tableData.cell.text = [rowCellsName.toUpperCase()]
        }
        tableData.cell.styles.fontStyle = "bold"
      }
    },
  })
  footer && addFooters()
  doc.save(filename)
}

dayjs.extend(relativeTime)

export const getIntegrationIcon = (iconName: string) => {
  switch (iconName) {
    case "Salesforce": {
      return SalesForceIcon
    }
    case "Netsuit": {
      return NetsuitIcon
    }
    case "Pipedrive": {
      return PipeDriveIcon
    }
    case "Quickbooks": {
      return QuickbooksIcon
    }
    case "Chargebee": {
      return ChargebeeIcon
    }
    case "Shopify": {
      return ShopifyIcon
    }
    case "Stripe": {
      return StripeIcon
    }
    case "Hubspot": {
      return HubspotIcon
    }
    default: {
      return
    }
  }
}
export const getIntegrationSlugIcon = (slug: string) => {
  switch (slug) {
    case "quickbooks_online": {
      return QuickbooksIcon
    }
    case "salesforce": {
      return SalesForceIcon
    }
    case "google": {
      return GoogleIcon
    }
    case "chargebee": {
      return ChargebeeIcon
    }
    case "shopify": {
      return ShopifyIcon
    }
    case "stripe": {
      return StripeIcon
    }
    case "hubspot": {
      return HubspotIcon
    }
    default: {
      return
    }
  }
}

//simple function to get initials of first name and last name
export const getAvatarText = (name: string) => {
  if (!name || name.trim() === "") {
    return ""
  }

  const words = name.trim().split(" ")
  const initials = words
    .map((word) => word[0])
    .slice(0, 2)
    .join("")

  return initials
}
type formatMoneyProps = {
  value: number
  showDecimals: boolean
  showPercentage?: boolean
  negativeParenthesis: boolean
  divideOneThousand: boolean
  hideCurrency?: boolean
}
export const formatMoney = ({
  value,
  showDecimals = false,
  negativeParenthesis = true,
  divideOneThousand = false,
  hideCurrency = false,
  showPercentage = false,
}: formatMoneyProps) => {
  let formatValue = negativeParenthesis && value < 0 ? Math.abs(value) : value
  const USDollar = new Intl.NumberFormat("en-US", {
    style: hideCurrency ? "decimal" : "currency",
    currency: "USD",
    ...(showDecimals
      ? { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      : { minimumFractionDigits: 0, maximumFractionDigits: 0 }),
  })
  if (divideOneThousand) {
    formatValue /= 1000
  }
  formatValue = showDecimals
    ? Number(formatValue.toFixed(2))
    : Math.round(Number(formatValue))

  let result = USDollar.format(formatValue)

  if (negativeParenthesis && value < 0) {
    result = hideCurrency ? `(${result})` : `$ (${formatValue})`
  }
  if (showPercentage) {
    result = [result, "%"].join("")
  }
  return result
}
//simple function to get format date
export const getFormattedDate = (date: Date) =>
  date.toLocaleDateString(["en"], {
    timeZone: "UTC",
    month: "long",
    day: "numeric",
    year: "numeric",
  })

//Function to capitalize a string first letter
export const capitalizeFirstLetter = (input: string) => {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
}

export const getDaysAgo = (date: Date) =>
  capitalizeFirstLetter(dayjs(date).fromNow())

export async function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener("load", () => {
      const formattedFile = reader.result

      typeof formattedFile === "string" ? resolve(formattedFile) : resolve("")
    })
    reader.addEventListener("error", reject)
  })
}

export const getClientMantineColor = (color: string | null) => {
  const clientColor = (color ?? "lightBlue.6").split(".")
  const mantineClientColor =
    themeVars.colors[clientColor[0]][
      clientColor[1] as unknown as MantineColorShade
    ]
  return mantineClientColor
}

export const convertDateToISOString = (date: Date) => {
  return formatISO(date, {
    representation: "date",
  })
}
export const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isDefined = <T>(arg: any): arg is T =>
  arg !== undefined && arg !== null

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (arg: any) =>
  // eslint-disable-next-line @typescript-eslint/ban-types
  isDefined<{}>(arg) && typeof arg === "object" && Object.keys(arg).length > 0
