import { notifications as MantineNotifications } from "@mantine/notifications"
import type {
  NotificationData,
  NotificationsStore,
} from "@mantine/notifications"
import { IconCheck, IconX } from "@tabler/icons-react"

type extendedNotification = NotificationData & {
  variant?: "success" | "error"
}

export const notifications = {
  ...MantineNotifications,

  show: (
    notification: extendedNotification,
    store?: NotificationsStore
  ): string => {
    const icon = notification.variant ? (
      notification.variant === "error" ? (
        <IconX size={16} />
      ) : (
        <IconCheck size={16} />
      )
    ) : null

    const color: typeof notification.color =
      notification.variant === "error" ? "red" : "teal"

    return MantineNotifications.show(
      {
        icon,
        color,
        withBorder: true,
        ...notification,
      },
      store
    )
  },
}
