import { DeleteIncomeAccountModal } from "../delete-income-account-modal"
import { incomeAccountsTableFragment$key } from "./__generated__/incomeAccountsTableFragment.graphql"
import { ActionIcon, Card, Text, rem } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { themeVars } from "@shared/theme"
import { Table } from "@shared/ui/table"
import { IconTrash } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

const IncomeAccountsTableFragment = graphql`
  fragment incomeAccountsTableFragment on Query
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int" }
    clientId: { type: "ID!" }
    connectionIds: { type: "[ID!]!" }
  ) {
    getIncomeAccountsNonCash(
      clientId: $clientId
      connectionIds: $connectionIds
      first: $count
      after: $cursor
    ) @connection(key: "IncomeAccountsTableFragment_getIncomeAccountsNonCash") {
      edges {
        node {
          accountType
          id
          includeInCashFlow
          displayName
        }
      }
    }
  }
`

type IncomeAccount = {
  id: string
  name: string
  accountType: string
  includeInCashFlow: boolean
}

type Props = {
  incomeAccounts: incomeAccountsTableFragment$key
  cashFlowConfigurationInputs: {
    clientId: string
    connectionIds: string[]
  }
}

export const IncomeAccountsTable = ({
  incomeAccounts,
  cashFlowConfigurationInputs,
}: Props) => {
  const [selectedAccount, setSelectedAccount] = useState<{
    id: string
    name: string
  } | null>(null)
  const {
    getIncomeAccountsNonCash: { edges },
  } = useFragment(IncomeAccountsTableFragment, incomeAccounts)

  const [
    confirmationOpened,
    { open: confirmationOpen, close: confirmationClose },
  ] = useDisclosure(false)

  const columns: ColumnDef<IncomeAccount>[] = [
    {
      header: "Account Name",
      accessorKey: "name",
      cell: (info) => (
        <Text fw="normal" c="gray.7" size="sm">
          {info.getValue<string>()}
        </Text>
      ),
    },
    {
      header: "Account Type",
      accessorKey: "accountType",
      cell: (info) => (
        <Text c="gray.7" size="sm">
          {info.getValue<string>()}
        </Text>
      ),
    },
    {
      header: "",
      accessorKey: "trash",
      cell: (info) => (
        <ActionIcon
          variant="light"
          color="gray.0"
          onClick={() =>
            handleTrashButton(info.row.original.id, info.row.original.name)
          }
        >
          <IconTrash size={16} color={themeVars.colors.gray[5]} />
        </ActionIcon>
      ),
      enableSorting: false,
      meta: {
        align: "right",
      },
    },
  ]

  const tableData: IncomeAccount[] = edges.map(({ node }) => ({
    id: node.id,
    name: node.displayName,
    accountType: node.accountType || "",
    includeInCashFlow: node.includeInCashFlow || false,
  }))

  const handleTrashButton = (id: string, name: string) => {
    setSelectedAccount({ id, name })
    confirmationOpen()
  }

  if (tableData.length === 0) {
    return (
      <Card py={rem(24)} shadow="xs" fz="sm" radius={rem(8)}>
        Currently no income accounts have been added. To add income accounts,
        please utilize the search feature on the right-hand side.
      </Card>
    )
  }

  return (
    <>
      <Table columns={columns} data={tableData} compact />
      {selectedAccount && (
        <DeleteIncomeAccountModal
          account={selectedAccount}
          cashFlowConfigurationInputs={cashFlowConfigurationInputs}
          onClose={confirmationClose}
          opened={confirmationOpened}
        />
      )}
    </>
  )
}
