import styles from "./hover-menu.module.scss"
import { ActionIcon, Menu } from "@mantine/core"
import { IconDots } from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"

type Props = {
  className?: string
  onClick: (to: string) => void
  loading: boolean
}

export const HoverMenu = ({ className, loading, onClick }: Props) => {
  return (
    <Menu
      shadow="xs"
      width="9rem"
      position="bottom-end"
      classNames={{
        itemLabel: styles.Item,
      }}
      trigger="hover"
      withinPortal={false}
      disabled={loading}
    >
      <Menu.Target>
        <ActionIcon variant="light" className={className ?? ""}>
          <IconDots size={16} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown fw={700} fz="xs" c="red">
        <Menu.Item onClick={() => onClick(pathConstants.CONNECTIONS)}>
          Connections
        </Menu.Item>
        <Menu.Item onClick={() => onClick(pathConstants.DATA)}>Data</Menu.Item>
        <Menu.Item onClick={() => onClick(pathConstants.SPREADSHEETS)}>
          Spreadsheets
        </Menu.Item>
        <Menu.Item onClick={() => onClick(pathConstants.CLIENT_SETTINGS)}>
          Client Settings
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
