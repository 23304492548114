/**
 * @generated SignedSource<<325a7befcd418b6d965e0be2d7f77294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ReportTypeEnum = "BALANCE" | "CASH" | "INCOME";
export type SavedReportTypeEnum = "STATEMENT" | "TRANSACTION";
export type PinSavedReportMutationInput = {
  clientMutationId?: string | null;
  id: string;
  pinned: boolean;
};
export type savedStatementsTablePinStatementMutation$variables = {
  input: PinSavedReportMutationInput;
};
export type savedStatementsTablePinStatementMutation$data = {
  readonly pinSavedReport: {
    readonly savedReport: {
      readonly filterType?: ReportTypeEnum;
      readonly id?: string;
      readonly name?: string | null;
      readonly pinned?: boolean;
      readonly source?: string | null;
      readonly type?: SavedReportTypeEnum;
      readonly updatedAt?: string;
      readonly user?: {
        readonly avatar: string | null;
        readonly fullName: string | null;
        readonly id: string;
      };
    } | null;
  } | null;
};
export type savedStatementsTablePinStatementMutation = {
  response: savedStatementsTablePinStatementMutation$data;
  variables: savedStatementsTablePinStatementMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filterType",
      "storageKey": null
    },
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "type": "SavedStatement",
  "abstractKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ],
  "type": "SavedTransaction",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "savedStatementsTablePinStatementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PinSavedReportMutationPayload",
        "kind": "LinkedField",
        "name": "pinSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "savedStatementsTablePinStatementMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PinSavedReportMutationPayload",
        "kind": "LinkedField",
        "name": "pinSavedReport",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "savedReport",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6448f5893d091ec17a6aa7549906ed0f",
    "id": null,
    "metadata": {},
    "name": "savedStatementsTablePinStatementMutation",
    "operationKind": "mutation",
    "text": "mutation savedStatementsTablePinStatementMutation(\n  $input: PinSavedReportMutationInput!\n) {\n  pinSavedReport(input: $input) {\n    savedReport {\n      __typename\n      ... on SavedStatement {\n        id\n        name\n        filterType\n        pinned\n        type\n        source\n        updatedAt\n        user {\n          id\n          fullName\n          avatar\n        }\n      }\n      ... on SavedTransaction {\n        id\n        name\n        pinned\n        type\n        source\n        updatedAt\n        user {\n          id\n          fullName\n          avatar\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "158410a3e260ad8708d116e3a1fdc8d0";

export default node;
