/**
 * @generated SignedSource<<d34859bc044a9e7f30e61fcfa0b9649f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MetabaseQuestionStatusTypeEnum = "BROKEN" | "ONLINE";
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
export type WorkbookSpreadsheetStatusTypeEnum = "BROKEN" | "GOOGLE_ERROR" | "ONLINE" | "SPREADSHEETABLE_ERROR" | "SYNCING";
export type WorkbookSyncPeriodTypeEnum = "DAILY" | "MANUALLY";
export type SyncWorkbookMutationInput = {
  clientMutationId?: string | null;
  workbookId: string;
};
export type workbookSyncButtonMutation$variables = {
  input: SyncWorkbookMutationInput;
};
export type workbookSyncButtonMutation$data = {
  readonly syncWorkbook: {
    readonly errors: ReadonlyArray<string>;
    readonly workbook: {
      readonly id: string;
      readonly lastSync: string | null;
      readonly name: string | null;
      readonly syncPeriod: WorkbookSyncPeriodTypeEnum | null;
      readonly userConnection: {
        readonly id: string;
        readonly status: StatusIntegrationTypeEnum;
        readonly user: {
          readonly fullName: string | null;
        };
      };
      readonly workbookSpreadsheets: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly createdAt: string;
            readonly id: string;
            readonly lastSync: string | null;
            readonly name: string | null;
            readonly spreadsheetable: {
              readonly id?: string;
              readonly metabaseQuestionId?: number | null;
              readonly name?: string | null;
              readonly status?: MetabaseQuestionStatusTypeEnum | null;
              readonly updatedAt?: string;
            };
            readonly status: WorkbookSpreadsheetStatusTypeEnum;
            readonly syncPeriod: number | null;
          };
        }>;
      };
    };
  } | null;
};
export type workbookSyncButtonMutation = {
  response: workbookSyncButtonMutation$data;
  variables: workbookSyncButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "syncPeriod",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSync",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metabaseQuestionId",
      "storageKey": null
    },
    (v4/*: any*/),
    (v8/*: any*/)
  ],
  "type": "MetabaseQuestion",
  "abstractKey": null
},
v10 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "updatedAt",
    "storageKey": null
  }
],
v11 = {
  "kind": "InlineFragment",
  "selections": (v10/*: any*/),
  "type": "SavedStatement",
  "abstractKey": null
},
v12 = {
  "kind": "InlineFragment",
  "selections": (v10/*: any*/),
  "type": "SavedTransaction",
  "abstractKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "workbookSyncButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncWorkbookMutationPayload",
        "kind": "LinkedField",
        "name": "syncWorkbook",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Workbook",
            "kind": "LinkedField",
            "name": "workbook",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkbookSpreadsheetConnection",
                "kind": "LinkedField",
                "name": "workbookSpreadsheets",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkbookSpreadsheetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkbookSpreadsheet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/),
                          (v8/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "spreadsheetable",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserIntegration",
                "kind": "LinkedField",
                "name": "userConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "workbookSyncButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SyncWorkbookMutationPayload",
        "kind": "LinkedField",
        "name": "syncWorkbook",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Workbook",
            "kind": "LinkedField",
            "name": "workbook",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkbookSpreadsheetConnection",
                "kind": "LinkedField",
                "name": "workbookSpreadsheets",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkbookSpreadsheetEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorkbookSpreadsheet",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/),
                          (v8/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "spreadsheetable",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/)
                                ],
                                "type": "Node",
                                "abstractKey": "__isNode"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserIntegration",
                "kind": "LinkedField",
                "name": "userConnection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54cc693c7ebf1cd7ad71bbae965858de",
    "id": null,
    "metadata": {},
    "name": "workbookSyncButtonMutation",
    "operationKind": "mutation",
    "text": "mutation workbookSyncButtonMutation(\n  $input: SyncWorkbookMutationInput!\n) {\n  syncWorkbook(input: $input) {\n    errors\n    workbook {\n      id\n      name\n      syncPeriod\n      lastSync\n      workbookSpreadsheets {\n        edges {\n          node {\n            createdAt\n            id\n            lastSync\n            name\n            status\n            syncPeriod\n            spreadsheetable {\n              __typename\n              ... on MetabaseQuestion {\n                id\n                metabaseQuestionId\n                name\n                status\n              }\n              ... on SavedStatement {\n                id\n                name\n                updatedAt\n              }\n              ... on SavedTransaction {\n                id\n                name\n                updatedAt\n              }\n              ... on Node {\n                __isNode: __typename\n                id\n              }\n            }\n          }\n        }\n      }\n      userConnection {\n        user {\n          fullName\n          id\n        }\n        status\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ad8e4dec66a0471c7b1255854cbfc31";

export default node;
