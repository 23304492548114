import { TransactionsContent } from "./transactions-content"
import { TransactionsEmpty } from "./transactions-empty"
import { Suspense, useCallback, useState } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useLocation } from "react-router-dom"

export const Transactions = () => {
  const [errorKey, setErrorKey] = useState<number>(0)
  const location = useLocation()

  const onReset = useCallback(() => {
    setErrorKey((prev) => prev + 1)
  }, [])

  return (
    <ErrorBoundary
      fallback={<TransactionsEmpty reset={onReset} />}
      resetKeys={[errorKey, location.key]}
    >
      <Suspense fallback={<TransactionsContent key={location.key} />}>
        <TransactionsContent key={location.key} />
      </Suspense>
    </ErrorBoundary>
  )
}
