import { shareButtonGenerateLinkMutation as ShareButtonGenerateLinkMutationType } from "./__generated__/shareButtonGenerateLinkMutation.graphql"
import { shareButtonGetSharedDashboardQuery as ShareButtonGetSharedDashboardQueryType } from "./__generated__/shareButtonGetSharedDashboardQuery.graphql"
import styles from "./share-button.module.scss"
import {
  Affix,
  Button,
  CopyButton,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { themeVars } from "@shared/theme"
import { DeleteLinkModal } from "@shared/ui/delete-link-modal"
import { IconLock, IconShare3 } from "@tabler/icons-react"
import { graphql, useLazyLoadQuery, useMutation } from "react-relay"

const ShareButtonGenerateLinkMutation = graphql`
  mutation shareButtonGenerateLinkMutation($dashboardId: Int!) {
    createSharedDashboard(input: { dashboardId: $dashboardId }) {
      sharedDashboard {
        id
        metabaseDashboardId
        name
        uuid
        url
      }
    }
  }
`

const ShareButtonGetSharedDashboardQuery = graphql`
  query shareButtonGetSharedDashboardQuery($metabaseDashboardId: Int!) {
    getSharedDashboard(metabaseDashboardId: $metabaseDashboardId) {
      sharedDashboard {
        id
        metabaseDashboardId
        name
        uuid
        url
      }
    }
  }
`

type Props = {
  dashboardId: number
}

export const ShareButton = ({ dashboardId }: Props) => {
  const [modalOpened, { open: modalOpen, close: modalClose }] =
    useDisclosure(false)
  const [
    confirmationOpened,
    { open: confirmationOpen, close: confirmationClose },
  ] = useDisclosure(false)

  const {
    getSharedDashboard: { sharedDashboard },
  } = useLazyLoadQuery<ShareButtonGetSharedDashboardQueryType>(
    ShareButtonGetSharedDashboardQuery,
    { metabaseDashboardId: dashboardId },
    { fetchKey: dashboardId, fetchPolicy: "store-and-network" }
  )
  const [generateLinkMutation, generateLinkLoading] =
    useMutation<ShareButtonGenerateLinkMutationType>(
      ShareButtonGenerateLinkMutation
    )

  const handleClick = () => {
    generateLinkMutation({
      variables: {
        dashboardId,
      },
      updater: (store) => {
        const payload = store.getRootField("createSharedDashboard")
        const sharedDashboardTemp = payload.getLinkedRecord("sharedDashboard")

        store
          .getRoot()
          .getLinkedRecord(
            `getSharedDashboard(metabaseDashboardId:${dashboardId})`
          )
          ?.setLinkedRecord(sharedDashboardTemp, "sharedDashboard")
      },
    })
  }

  return (
    <>
      <Affix position={{ bottom: "2rem", right: "2rem" }}>
        <Tooltip label="Share Board" color="gray.7" withArrow>
          <button className={styles.Share} onClick={modalOpen}>
            <IconShare3 size={32} />
          </button>
        </Tooltip>
      </Affix>

      <Modal
        opened={modalOpened}
        onClose={modalClose}
        title={
          <Text size="md" fw={700} c="gray.7">
            Share Board Link
          </Text>
        }
        centered
        size={"30rem"}
        shadow="sm"
        overlayProps={{
          bg: "#3333334D",
        }}
        closeOnClickOutside={!generateLinkLoading}
        withCloseButton
      >
        <Stack gap="1.5rem">
          {sharedDashboard?.url ? (
            <>
              <Text size="sm" c="gray.7">
                Be careful with the link as it may contain sensitive data. If
                compromised, ensure security by deleting and generating a new
                one.
              </Text>
              <TextInput
                variant="filled"
                label="Public Link"
                value={sharedDashboard.url}
                rightSection={
                  <IconLock size={16} color={themeVars.colors.gray["6"]} />
                }
                labelProps={{
                  fw: "500",
                  mb: "0.25rem",
                }}
                readOnly
              />
              <Group justify="flex-end" gap="1rem">
                <Button
                  variant="default"
                  onClick={() => {
                    modalClose()
                    confirmationOpen()
                  }}
                >
                  Delete Link
                </Button>
                <CopyButton value={sharedDashboard.url} timeout={1500}>
                  {({ copy, copied }) => (
                    <Button onClick={copy} data-autofocus>
                      {copied ? "Copied!" : "Copy Link"}
                    </Button>
                  )}
                </CopyButton>
              </Group>
            </>
          ) : (
            <>
              <Text size="sm" c="gray.7">
                When generating a link for a board, it will create a public link
                that you can share with external individuals. They will be able
                to view the data in real-time.
              </Text>
              <Group justify="flex-end">
                <Button
                  data-autofocus
                  onClick={handleClick}
                  loading={generateLinkLoading}
                >
                  Generate Link
                </Button>
              </Group>
            </>
          )}
        </Stack>
      </Modal>

      {sharedDashboard?.id && (
        <DeleteLinkModal
          opened={confirmationOpened}
          sharedDashboardId={sharedDashboard.id}
          dashboardId={dashboardId}
          onClose={confirmationClose}
        />
      )}
    </>
  )
}
