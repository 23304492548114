import { GenerateTableReportProps, generateXlsxReport } from "./utils"
import { Button } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { useState } from "react"

export const ExportTransactionsButton = (
  generateReportOptions: GenerateTableReportProps
) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { alpyneToken } = useUserStore()

  return (
    <Button
      variant="outline"
      color="blue"
      loading={loading}
      onClick={async () => {
        setLoading(true)
        await generateXlsxReport(generateReportOptions, alpyneToken || "")
        setLoading(false)
      }}
    >
      Export as .xlsx
    </Button>
  )
}
