/**
 * @generated SignedSource<<5970cf2e6c2a6ad77363379d433091d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type accountSelectorFragment$data = {
  readonly getAccounts: {
    readonly data: any | null;
  };
  readonly " $fragmentType": "accountSelectorFragment";
};
export type accountSelectorFragment$key = {
  readonly " $data"?: accountSelectorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"accountSelectorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "connectionIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "accountSelectorFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "systemConnectionIds",
          "variableName": "connectionIds"
        }
      ],
      "concreteType": "AccountResults",
      "kind": "LinkedField",
      "name": "getAccounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "data",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "f8d7b122295f3ba4c8256b3babeb1d09";

export default node;
