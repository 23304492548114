import { MantineColorsTuple } from "@mantine/core"

export const appColors = {
  blue: [
    "#E1F9FF",
    "#CDEEFF",
    "#9edafa",
    "#6cc5f6",
    "#43B4F1",
    "#28A9EF",
    "#0EA3EF",
    "#008ED7",
    "#007FC1",
    "#006EAC",
  ] as MantineColorsTuple,
  lightBlue: [
    "#E2F2FF",
    "#C7E6FF",
    "#A6D7FF",
    "#78C2FF",
    "#6EB8F4",
    "#50A4E9",
    "#2A80C8",
    "#1367AC",
    "#095695",
    "#003560",
  ] as MantineColorsTuple,
  indigo: [
    "#B0CAE9",
    "#729DCE",
    "#3F70A9",
    "#345780",
    "#1E3A5A",
    "#0A2340",
    "#061D38",
    "#02152B",
    "#051A33",
    "#001834",
  ] as MantineColorsTuple,
  green: [
    "#EEFFD8",
    "#E6FBCB",
    "#E0FABF",
    "#D2F5A5",
    "#C3E597",
    "#B7DA8A",
    "#90BA5B",
    "#7CB633",
    "#71AF22",
    "#457B00",
  ] as MantineColorsTuple,
}
