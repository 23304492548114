import styles from "./custom-select.module.scss"
import { Combobox, InputBase, Text, useCombobox } from "@mantine/core"
import { themeVars } from "@shared/theme"
import { IconChevronDown } from "@tabler/icons-react"
import { ReactElement, ReactNode, useEffect, useState } from "react"

export type CustomSelectDatatype = {
  label: string
  value: string
  icon?: ReactElement | ReactNode
  imageSrc?: string
}

type Props = {
  data: CustomSelectDatatype[]
  onChange: (value: string) => void
  defaultValue?: string | null
  disabled?: boolean
  placeholder?: string
  menuLabel?: string
  width?: string
  height?: string
}

export const CustomSelect = ({
  data,
  placeholder,
  defaultValue,
  onChange,
  disabled = false,
  menuLabel,
  width,
  height,
}: Props) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const [selectedOption, setSelectedOption] = useState<
    CustomSelectDatatype | undefined
  >(data.find((item) => item.value === defaultValue))

  useEffect(() => {
    setSelectedOption(data.find((item) => item.value === defaultValue))
  }, [data, defaultValue])

  const options = data.map((item) => {
    const activeOption = item.value === selectedOption?.value
    const hasGraphic = !!item.icon || !!item.imageSrc

    return (
      <Combobox.Option
        value={item.value}
        key={item.value}
        className={[
          styles.Combobox__Option,
          !hasGraphic && styles["Combobox__Option--labelOnly"],
          activeOption && styles["Combobox__Option--active"],
        ].join(" ")}
        active={defaultValue === item.value}
      >
        {hasGraphic && item.imageSrc ? (
          <img src={item.imageSrc} className={styles.Combobox__Option__Image} />
        ) : (
          item.icon
        )}

        <Text
          size="sm"
          className={[
            styles.Combobox__Option__Label,
            activeOption && styles["Combobox__Option__Label--active"],
          ].join(" ")}
        >
          {item.label}
        </Text>
      </Combobox.Option>
    )
  })

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      onOptionSubmit={(val) => {
        const selectedItem = data.find((item) => item.value === val)
        setSelectedOption(selectedItem)
        combobox.closeDropdown()
        onChange(val)
      }}
      disabled={disabled}
    >
      <Combobox.Target>
        <InputBase
          type="button"
          pointer
          w={width}
          h={height}
          leftSection={
            selectedOption &&
            (selectedOption.imageSrc ? (
              <img
                src={selectedOption.imageSrc}
                className={styles.Combobox__Target__Image}
                onClick={() => combobox.openDropdown()}
              />
            ) : (
              selectedOption.icon ?? null
            ))
          }
          rightSection={
            <IconChevronDown
              onClick={() => combobox.openDropdown()}
              cursor="pointer"
              size={"1rem"}
              color={themeVars.colors.gray["6"]}
            />
          }
          className={styles.Combobox__Target__Input}
          value={selectedOption?.label}
          onChange={() => {
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown()
          }}
          placeholder={selectedOption?.label ?? placeholder}
          disabled={disabled}
        />
      </Combobox.Target>

      <Combobox.Dropdown className={styles.Combobox__Dropdown}>
        {menuLabel && (
          <Text className={styles["Combobox__Option__Group"]}>{menuLabel}</Text>
        )}
        <Combobox.Options>
          {options.length > 0 ? (
            options
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
