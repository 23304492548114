/**
 * @generated SignedSource<<3cfd329a616a4a39a22a4c1a8b7fab70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type sharedDashboardQuery$variables = {
  uuid: string;
};
export type sharedDashboardQuery$data = {
  readonly getPublicSharedDashboard: {
    readonly errors: ReadonlyArray<string> | null;
    readonly metabaseJwtToken: string | null;
    readonly sharedDashboard: {
      readonly client: {
        readonly name: string;
      } | null;
      readonly metabaseDashboardId: number;
      readonly name: string;
    } | null;
  };
};
export type sharedDashboardQuery = {
  response: sharedDashboardQuery$data;
  variables: sharedDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uuid"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "uuid"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metabaseDashboardId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metabaseJwtToken",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sharedDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetPublicSharedDashboardData",
        "kind": "LinkedField",
        "name": "getPublicSharedDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SharedDashboard",
            "kind": "LinkedField",
            "name": "sharedDashboard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sharedDashboardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetPublicSharedDashboardData",
        "kind": "LinkedField",
        "name": "getPublicSharedDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SharedDashboard",
            "kind": "LinkedField",
            "name": "sharedDashboard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "client",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3acbc0950d480fbca550f8b9e60cabb3",
    "id": null,
    "metadata": {},
    "name": "sharedDashboardQuery",
    "operationKind": "query",
    "text": "query sharedDashboardQuery(\n  $uuid: String!\n) {\n  getPublicSharedDashboard(uuid: $uuid) {\n    sharedDashboard {\n      name\n      metabaseDashboardId\n      client {\n        name\n        id\n      }\n      id\n    }\n    metabaseJwtToken\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "5789895df7d18eff10ad24b47204398a";

export default node;
