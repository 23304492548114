import { signUpMutation as SignUpMutationType } from "../__generated__/signUpMutation.graphql"
import { FirmDataForm } from "../firm-data-form"
import { FirmSchema, OwnerSignupType } from "../firm-data-form/firm-data-form"
import { UserDataForm } from "../user-data-form"
import { UserSchema } from "../user-data-form/user-data-form"
import { userSignUpFragment$key } from "../user-sign-up/__generated__/userSignUpFragment.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, Paper, Stack, Stepper, Text, rem } from "@mantine/core"
import { notifications } from "@shared/ui/notifications"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
  GraphQLTaggedNode,
  graphql,
  useFragment,
  useMutation,
} from "react-relay"

const OwnerSignUpFragment = graphql`
  fragment ownerSignUpFragment on GetOnboardingInfo {
    user {
      isFirmOwner
      firm {
        logo
        name
      }
      avatar
      email
      fullName
    }
  }
`

type Props = {
  invitationToken: string
  onboardingUser: userSignUpFragment$key
  mutation: GraphQLTaggedNode
}

export const OwnerSignUp = ({
  onboardingUser,
  mutation,
  invitationToken,
}: Props) => {
  const { user } = useFragment(OwnerSignUpFragment, onboardingUser)
  const [signUpMutation, signUpMutationLoading] =
    useMutation<SignUpMutationType>(mutation)
  const [step, setStep] = useState(0)

  const initialValues: OwnerSignupType = {
    email: user?.email ?? "",
    fullName: user?.fullName ?? "",
    firmName: user?.firm.name ?? "",
    password: "",
    avatar: "",
    logo: "",
  }

  const form = useForm<OwnerSignupType>({
    defaultValues: initialValues,
    resolver: zodResolver(step === 0 ? UserSchema : UserSchema.and(FirmSchema)),
  })

  const handleSignUp = (data: OwnerSignupType) => {
    signUpMutation({
      variables: {
        input: {
          user: {
            email: data.email,
            fullName: data.fullName,
            password: data.password,
            avatar: data.avatar,
          },
          firm: {
            name: data.firmName,
            logo: data.logo,
          },
          invitationToken: invitationToken,
        },
      },
      onError: (error) => {
        notifications.show({
          message: error.message,
          title: "An error ocurred",
          variant: "error",
        })
      },
    })
  }

  return (
    <Paper shadow="xs" radius="md" p="xl" w={rem(504)} mih={rem(768)}>
      <Stack gap={0}>
        <Stack gap={rem(4)} align="center" py={rem(16)}>
          <Text ta="center" size={rem(24)} fw="bold">
            Welcome to
            <Text c="blue" component="span" fw="bold">
              {" Alpyne"}
            </Text>
          </Text>
        </Stack>
        <Divider c="gray.3" my={rem(16)} />
        <FormProvider {...form}>
          <Stepper
            active={step}
            onStepClick={(current) => {
              current == 0 && setStep(0)
            }}
          >
            <Stepper.Step
              label={
                <Text size="sm" fw={500}>
                  Set Up Your Account
                </Text>
              }
            >
              <UserDataForm onSubmit={() => setStep(1)} submitText="Next" />
            </Stepper.Step>
            <Stepper.Step
              style={{ cursor: "default" }}
              label={
                <Text size="sm" fw={500}>
                  Set Up Your Firm
                </Text>
              }
            >
              <FirmDataForm
                onSubmit={handleSignUp}
                loading={signUpMutationLoading}
              />
            </Stepper.Step>
          </Stepper>
        </FormProvider>
      </Stack>
    </Paper>
  )
}
