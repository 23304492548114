import { NumberInput } from "../number-input"
import { Select } from "../select"
import { AmountRateEnum } from "@components/transactions/export-transactions-button/utils"
import { Button, Group, InputBase, Popover, Stack } from "@mantine/core"
import { formatMoney } from "@shared/utils/helpers"
import { IconChevronDown } from "@tabler/icons-react"
import { useCallback, useState } from "react"

export type AmountRangesValueType = {
  type: AmountRateEnum
  value?: string[] | null
}
type Props = {
  values?: AmountRangesValueType | null
  onChange: (val: AmountRangesValueType) => void
}
const rangeSelectorOptions = [
  { value: AmountRateEnum.BETWEEN, label: "Between" },
  { value: AmountRateEnum.EQUAL_TO, label: "Equal" },
  { value: AmountRateEnum.GREATER_THAN, label: "More than" },
  { value: AmountRateEnum.LESS_THAN, label: "Less than" },
  {
    value: AmountRateEnum.GREATER_THAN_OR_EQUAL_TO,
    label: "More or equal than",
  },
  { value: AmountRateEnum.LESS_THAN_OR_EQUAL_TO, label: "Less or equal than" },
]
export const RangeSelector = ({ values, onChange }: Props) => {
  const [currentValue, setCurrentValue] = useState<
    AmountRangesValueType | null | undefined
  >(values)
  const min = currentValue?.value ? currentValue?.value[0] : null
  const max = currentValue?.value ? currentValue?.value[1] : null

  const currentTypeLabel = rangeSelectorOptions.find(
    (opt) => opt.value === currentValue?.type
  )?.label

  const isRange = currentValue?.type === AmountRateEnum.BETWEEN

  const onPopoverClose = useCallback(() => {
    if (
      currentValue?.type &&
      currentValue.value &&
      currentValue.value?.length > 0
    ) {
      if (isRange && currentValue.value.length < 2) {
        setCurrentValue(values)
        return
      }
      onChange({
        type: currentValue?.type,
        value: isRange
          ? currentValue.value.sort((a, b) => Number(a) - Number(b))
          : [currentValue.value[0]],
      })
    }
  }, [currentValue, isRange, onChange, values])

  return (
    <Popover
      shadow="xs"
      styles={{ dropdown: { minWidth: "20rem" } }}
      width="target"
      trapFocus
      position="bottom-end"
      onClose={onPopoverClose}
    >
      <Popover.Target>
        <InputBase
          component="button"
          type="button"
          style={{ overflow: "hidden" }}
          pointer
          rightSection={<IconChevronDown size={16} />}
          rightSectionPointerEvents="none"
        >
          {`${min && currentTypeLabel ? currentTypeLabel : ""} ${
            isRange && min && max
              ? `${
                  formatMoney({
                    value: Number(min),
                    divideOneThousand: false,
                    negativeParenthesis: false,
                    showDecimals: true,
                  }) ?? ""
                } - ${
                  formatMoney({
                    value: Number(max),
                    divideOneThousand: false,
                    negativeParenthesis: false,
                    showDecimals: true,
                  }) ?? ""
                }`
              : min
              ? formatMoney({
                  value: Number(min),
                  divideOneThousand: false,
                  negativeParenthesis: false,
                  showDecimals: true,
                })
              : ""
          }`}
        </InputBase>
      </Popover.Target>
      <Popover.Dropdown p="1rem">
        <Stack gap="0.62rem">
          <Select
            label="Comparison"
            data={rangeSelectorOptions}
            defaultValue={currentValue?.type ?? AmountRateEnum.EQUAL_TO}
            comboboxProps={{ withinPortal: false }}
            withCheckIcon
            checkIconPosition="right"
            allowDeselect={false}
            onChange={(value) => {
              setCurrentValue((prev) => ({
                ...prev,
                type: value as AmountRateEnum,
              }))
            }}
          />
          {isRange ? (
            <Group wrap="nowrap">
              <NumberInput
                label="Min"
                value={min ?? ""}
                defaultValue={min}
                onChange={(value) => {
                  setCurrentValue((prev) => ({
                    type: prev?.type ?? AmountRateEnum.BETWEEN,
                    value: [
                      value.toString() ?? "",
                      prev?.value ? prev?.value[1] : "",
                    ],
                  }))
                }}
              />
              <NumberInput
                label="Max"
                value={max ?? ""}
                defaultValue={max}
                onChange={(value) => {
                  setCurrentValue((prev) => ({
                    type: prev?.type ?? AmountRateEnum.BETWEEN,
                    value: [
                      prev?.value ? prev?.value[0] : "",
                      value.toString() ?? "",
                    ],
                  }))
                }}
              />
            </Group>
          ) : (
            <NumberInput
              label="Value"
              value={(min as string) ?? ""}
              defaultValue={(min as string) ?? ""}
              onChange={(val) => {
                setCurrentValue((prev) => ({
                  type: prev?.type ?? AmountRateEnum.EQUAL_TO,
                  value: [val.toString()],
                }))
              }}
            />
          )}
          {currentValue?.value && currentValue.value[0] && (
            <Button
              onClick={() => {
                setCurrentValue({ type: AmountRateEnum.EQUAL_TO, value: [""] })
              }}
              size="compact-sm"
              mt={"xs"}
              variant="light"
            >
              Clear
            </Button>
          )}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
