/**
 * @generated SignedSource<<eedad0dea6f94e036dec12bba0358b55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type navbarUserFragment$data = {
  readonly avatar: string | null;
  readonly clients: {
    readonly count: number;
  };
  readonly firm: {
    readonly name: string | null;
  };
  readonly fullName: string | null;
  readonly isFirmAdmin: boolean;
  readonly isSuperAdmin: boolean;
  readonly role: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"clientSelectorClientsFragment">;
  readonly " $fragmentType": "navbarUserFragment";
};
export type navbarUserFragment$key = {
  readonly " $data"?: navbarUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"navbarUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "navbarUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isFirmAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Firm",
      "kind": "LinkedField",
      "name": "firm",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientConnection",
      "kind": "LinkedField",
      "name": "clients",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "clientSelectorClientsFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "170c5c4d7e0a12d779bfb5537cccef61";

export default node;
