/**
 * @generated SignedSource<<91e8a1a190cfd0b75e34ce3b7c6c3991>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type metabaseDataGetMetabaseSubPathQuery$variables = {};
export type metabaseDataGetMetabaseSubPathQuery$data = {
  readonly getMetabaseSubPath: string;
};
export type metabaseDataGetMetabaseSubPathQuery = {
  response: metabaseDataGetMetabaseSubPathQuery$data;
  variables: metabaseDataGetMetabaseSubPathQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "getMetabaseSubPath",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "metabaseDataGetMetabaseSubPathQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "metabaseDataGetMetabaseSubPathQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "2b600c1c9a085cc4e02bd0a9d8c0b2a8",
    "id": null,
    "metadata": {},
    "name": "metabaseDataGetMetabaseSubPathQuery",
    "operationKind": "query",
    "text": "query metabaseDataGetMetabaseSubPathQuery {\n  getMetabaseSubPath\n}\n"
  }
};
})();

(node as any).hash = "9d71f9c2a268985423894cb8c8831528";

export default node;
