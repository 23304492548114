import { membersDrawerAssignClientMutation as MembersDrawerAssignClientMutationType } from "./__generated__/membersDrawerAssignClientMutation.graphql"
import { membersDrawerFirmInfoFragment$key } from "./__generated__/membersDrawerFirmInfoFragment.graphql"
import { membersDrawerFragment$key } from "./__generated__/membersDrawerFragment.graphql"
import { MembersCombobox } from "./members-combobox"
import { Button, Stack, Text } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { AppDrawer } from "@shared/ui/app-drawer"
import { notifications } from "@shared/ui/notifications"
import { useState } from "react"
import { graphql, useFragment, useMutation } from "react-relay"

const MembersDrawerFragment = graphql`
  fragment membersDrawerFragment on Client
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    members(first: $count, after: $cursor)
      @connection(key: "MembersDrawerFragment_members") {
      edges {
        node {
          id
          firstName
          lastName
          email
          avatar
        }
      }
    }
  }
`

const MembersDrawerFirmInfoFragment = graphql`
  fragment membersDrawerFirmInfoFragment on Firm
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    users(first: $count, after: $cursor)
      @connection(key: "MembersDrawerFirmInfoFragment_users") {
      edges {
        node {
          id
          firstName
          lastName
          email
          avatar
          isFirmAdmin
        }
      }
    }
  }
`

const MembersDrawerAssignClientMutation = graphql`
  mutation membersDrawerAssignClientMutation(
    $input: AssignClientMutationInput!
  ) {
    assignClient(input: $input) {
      client {
        firm {
          ...membersDrawerFirmInfoFragment
        }
        ...membersTableFragment
        ...membersDrawerFragment
      }
      errors
    }
  }
`

type Props = {
  members: membersDrawerFragment$key
  firm: membersDrawerFirmInfoFragment$key
  opened: boolean
  onClose: () => void
}

export const MembersDrawer = ({ members, firm, opened, onClose }: Props) => {
  const { currentClient } = useUserStore()

  const [userIds, setUserIds] = useState<string[]>([])

  const {
    members: { edges: currentMembers },
  } = useFragment(MembersDrawerFragment, members)

  const {
    users: { edges: firmUsers },
  } = useFragment(MembersDrawerFirmInfoFragment, firm)

  const [assignClient, assignClientLoading] =
    useMutation<MembersDrawerAssignClientMutationType>(
      MembersDrawerAssignClientMutation
    )

  const assignUserToClient = () => {
    assignClient({
      variables: {
        input: {
          userIds: userIds,
          clientSlug: currentClient.slug ?? "",
        },
      },
      onCompleted: () => {
        onClose()
        setUserIds([])

        notifications.show({
          title: "Members successfully added!",
          message:
            "The members have been notified via email to join the client.",
          variant: "success",
        })
      },
      onError: () => {
        notifications.show({
          title: "There was an error adding members",
          message: "Please try again",
          variant: "error",
        })
      },
    })
  }

  const formattedFirmUsers = firmUsers.map(({ node }) => node)
  const formattedCurrentMembers = currentMembers.map(({ node }) => node)

  const availableUsers = formattedFirmUsers.filter(
    (user) =>
      !formattedCurrentMembers.some(
        (memberUser) => memberUser.id === user.id
      ) && !user.isFirmAdmin
  )

  return (
    <AppDrawer
      opened={opened}
      title={"Add Member"}
      onClose={() => {
        onClose()
        setUserIds([])
      }}
      closeOnClickOutside
    >
      <Stack justify="space-between" h="100%">
        <Stack>
          <Text c="gray.7" size="sm">
            Give member users permission to access this client. Admins can
            access all clients.
          </Text>
          <MembersCombobox
            users={availableUsers}
            setUserIdsCallback={setUserIds}
          />
        </Stack>
        <Button
          onClick={assignUserToClient}
          disabled={userIds.length > 0 ? false : true}
          loading={assignClientLoading}
          fullWidth
        >
          Add
        </Button>
      </Stack>
    </AppDrawer>
  )
}
