/**
 * @generated SignedSource<<6a698ea67d82311d3548364389bdc13a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userSignUpFragment$data = {
  readonly user: {
    readonly avatar: string | null;
    readonly email: string;
    readonly firm: {
      readonly name: string | null;
    };
    readonly fullName: string | null;
    readonly isFirmOwner: boolean;
  } | null;
  readonly " $fragmentType": "userSignUpFragment";
};
export type userSignUpFragment$key = {
  readonly " $data"?: userSignUpFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userSignUpFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userSignUpFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFirmOwner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Firm",
          "kind": "LinkedField",
          "name": "firm",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GetOnboardingInfo",
  "abstractKey": null
};

(node as any).hash = "c488f55b5ad59eecd24db55c7d9821da";

export default node;
