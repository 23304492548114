export const pathConstants = {
  ROOT: "/",
  PUBLIC: {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    RESET_PASSWORD: "/reset-password",
    DASHBOARD: "public/dashboard/:uuid",
  },
  PORTFOLIO: "/portfolio",
  SPREADSHEETS: "/spreadsheets",
  HOME: "/home",
  DATA: "/data",
  STATEMENTS: "/financial/statements",
  CASH_FLOW_CONFIGURATION: "/financial/statements/cash-flow-configuration",
  SAVED_STATEMENTS: "/financial/saved-statements",
  SAVED_REPORTS: "/financial/saved-reports",
  SAVED_STATEMENT_DETAILS: "/financial/saved-statements/:id",
  SAVED_TRANSACTION_DETAILS: "/financial/saved-transactions/:id",
  TRANSACTIONS: "/financial/transactions",
  SAVED_TRANSACTIONS: "/financial/saved-transactions",
  METABASE: "/metabase",
  CONNECTIONS: "/connections",
  CLIENT_SETTINGS: "/client-settings",
  PROFILE_SETTINGS: "/profile-settings",
  FIRM_SETTINGS: "/firm-settings",
  ADMIN_SETTINGS: "/admin-settings",
}
