import styles from "./navbar-link.module.scss"
import { Badge, Group, Space, Text, Tooltip } from "@mantine/core"
import { IconAlertTriangle } from "@tabler/icons-react"
import { ReactElement, Ref, cloneElement, forwardRef } from "react"
import { NavLink, NavLinkProps } from "react-router-dom"

type Props = {
  icon?: ReactElement
  label: string | ReactElement
  to: string
  collapsed: boolean
  hasIssues?: boolean
  hoverable?: boolean
  forceActive?: boolean
  isParent?: boolean
  right?: ReactElement
  isMenu?: boolean
  tooltipDisabled?: boolean
} & NavLinkProps

export const NavbarLink = forwardRef(function NavBarLink(
  {
    to,
    icon,
    label,
    hasIssues,
    collapsed = false,
    hoverable = true,
    forceActive = false,
    isParent = false,
    isMenu = false,
    right,
    tooltipDisabled = false,
    ...navLinkProps
  }: Props,
  ref: Ref<HTMLAnchorElement>
) {
  return (
    <Tooltip
      label={label}
      disabled={tooltipDisabled || !collapsed}
      position="right"
      color="gray.7"
      withArrow
    >
      <NavLink
        {...navLinkProps}
        to={to}
        className={({ isActive }) =>
          [
            styles.NavbarLink,
            ((isActive && to !== "#") || forceActive) &&
              (isParent
                ? styles["NavbarLink--parent"]
                : styles["NavbarLink--active"]),
            hoverable && styles["NavbarLink--hoverable"],
          ].join(" ")
        }
        ref={ref}
      >
        <Group
          justify={collapsed ? "center" : "space-between"}
          w="100%"
          wrap="nowrap"
        >
          <Group gap="0.75rem" wrap="nowrap" justify="center">
            {icon ? (
              cloneElement(icon, {
                size: 16,
                className: styles.NavbarLink__Icon,
              })
            ) : isMenu ? (
              <></>
            ) : (
              <Space w={16} />
            )}

            {!collapsed && (
              <Text
                component="div"
                size="sm"
                w="max-content"
                className={styles.NavbarLink__Label}
              >
                {label}
              </Text>
            )}
          </Group>
          {hasIssues && !collapsed && (
            <Badge
              variant="light"
              color="red"
              size="xs"
              classNames={{
                label: styles.NavbarLink__BadgeLabel,
                root: styles.NavbarLink__Badge,
              }}
            >
              <IconAlertTriangle size={12} />
            </Badge>
          )}
          {right && !collapsed ? cloneElement(right) : <></>}
        </Group>
      </NavLink>
    </Tooltip>
  )
})
