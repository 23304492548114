import {
  Button,
  Center,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
} from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"

export const TableLoader = () => {
  return (
    <Stack gap={"2.5rem"}>
      <Group justify="space-between">
        <Text fw="bold" c="gray.7">
          Details
        </Text>
        <Button
          variant="outline"
          color="blue"
          leftSection={<IconDownload size={"1rem"} />}
          disabled
        >
          Export
        </Button>
      </Group>
      <Paper shadow="md" radius="md" h={"40rem"}>
        <Center h={"100%"}>
          <Stack align="center">
            <Loader />
            <Text c={"gray"} size="md">
              Loading Data...
            </Text>
          </Stack>
        </Center>
      </Paper>
    </Stack>
  )
}
