import { RecordSourceSelectorProxy } from "relay-runtime"

export const updateCurrentMetabaseTokenCache = <T>(
  store: RecordSourceSelectorProxy<T>,
  _data: T,
  newMetabaseToken: string | null | undefined
) => {
  if (newMetabaseToken) {
    store
      .getRoot()
      .getLinkedRecord("getCurrentUserData")
      ?.setValue(newMetabaseToken, "metabaseJwtToken")
  }
}
