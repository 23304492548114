/**
 * @generated SignedSource<<25d1e67fe0be83d58f36540c4f24a697>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClientStatusEnum = "ACTIVE" | "INACTIVE";
export type ClientTypeEnum = "ECOMMERCE" | "OTHER" | "RETAIL" | "SAAS" | "SERVICES";
export type CreateClientMutationInput = {
  clientMutationId?: string | null;
  closeMonth: string;
  contactEmail?: string | null;
  contactName?: string | null;
  contactPhone?: string | null;
  fiscal: number;
  name: string;
  type?: ClientTypeEnum | null;
};
export type clientDrawerCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateClientMutationInput;
};
export type clientDrawerCreateMutation$data = {
  readonly createClient: {
    readonly client: {
      readonly createdAt: string;
      readonly id: string;
      readonly metabaseCollectionId: number;
      readonly metabaseDefaultDashboardId: number;
      readonly metabaseGroupId: number;
      readonly metabasePublicGroupId: number;
      readonly metabasePublicUserId: number;
      readonly name: string;
      readonly slug: string;
      readonly status: ClientStatusEnum;
      readonly type: ClientTypeEnum;
      readonly updatedAt: string;
    } | null;
    readonly errors: ReadonlyArray<string>;
    readonly metabaseJwtToken: string | null;
  } | null;
};
export type clientDrawerCreateMutation = {
  response: clientDrawerCreateMutation$data;
  variables: clientDrawerCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metabaseCollectionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metabaseDefaultDashboardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metabaseGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metabasePublicGroupId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metabasePublicUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "metabaseJwtToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clientDrawerCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateClientMutationPayload",
        "kind": "LinkedField",
        "name": "createClient",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "clientDrawerCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateClientMutationPayload",
        "kind": "LinkedField",
        "name": "createClient",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "client",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "Client"
              }
            ]
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0373329c1ba365a75f1d5270edd24c88",
    "id": null,
    "metadata": {},
    "name": "clientDrawerCreateMutation",
    "operationKind": "mutation",
    "text": "mutation clientDrawerCreateMutation(\n  $input: CreateClientMutationInput!\n) {\n  createClient(input: $input) {\n    client {\n      createdAt\n      id\n      metabaseCollectionId\n      metabaseDefaultDashboardId\n      metabaseGroupId\n      metabasePublicGroupId\n      metabasePublicUserId\n      name\n      slug\n      status\n      type\n      updatedAt\n    }\n    errors\n    metabaseJwtToken\n  }\n}\n"
  }
};
})();

(node as any).hash = "f9ec7592f28b724457129ca57c8079c9";

export default node;
