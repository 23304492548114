import { signInMutation as SignInMutationType } from "./__generated__/signInMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Alert,
  Anchor,
  Button,
  Divider,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { IconEye, IconEyeOff } from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { graphql, useMutation } from "react-relay"
import { Link, Navigate } from "react-router-dom"
import { z } from "zod"

const SignInSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
})

const SignInMutation = graphql`
  mutation signInMutation($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      credentials {
        accessToken
        uid
      }
    }
  }
`

type FormInputs = {
  email: string
  password: string
}

export const SignIn = () => {
  const [signIn, signInLoading] =
    useMutation<SignInMutationType>(SignInMutation)
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
  } = useForm<FormInputs>({
    resolver: zodResolver(SignInSchema),
  })
  const [errors, setErrors] = useState(false)
  const { alpyneToken } = useUserStore()

  const onSubmit: SubmitHandler<FormInputs> = ({ email, password }) => {
    signIn({
      variables: {
        email,
        password,
      },
      onError: () => setErrors(true),
    })
  }

  if (alpyneToken) {
    return <Navigate to="/portfolio" replace />
  }

  return (
    <Paper shadow="xs" radius="md" p="xl">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack w="31.5rem" gap="3rem">
          <Stack gap={0} align="stretch">
            <Title order={3} ta="center">
              Nice to see you again!
            </Title>
            <Text size="sm" c="gray" ta="center">
              Enter your credentials to access your account.
            </Text>
            <Divider mt="2rem" />
          </Stack>

          {errors && (
            <Alert
              color="red"
              title="Error"
              withCloseButton
              onClose={() => setErrors(false)}
            >
              Invalid login credentials.
            </Alert>
          )}

          <Stack gap="1.5rem">
            <TextInput
              {...register("email")}
              label="Email"
              type="email"
              placeholder="hello@getalpyne.com"
              inputMode="email"
              error={formErrors.email?.message}
              autoFocus
            />

            <Stack gap={"0.25rem"}>
              <Group justify="space-between">
                <Text size="sm" fw={500}>
                  Password
                </Text>
                <Text size="sm" ta="center">
                  <Anchor
                    fw={500}
                    to={pathConstants.PUBLIC.RESET_PASSWORD}
                    component={Link}
                    tabIndex={-1}
                  >
                    Forgot your password?
                  </Anchor>
                </Text>
              </Group>
              <PasswordInput
                {...register("password")}
                placeholder="Your password"
                error={formErrors.password?.message}
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? (
                    <IconEye
                      color={themeVars.colors.gray[6]}
                      style={{ width: rem(18) }}
                    />
                  ) : (
                    <IconEyeOff
                      color={themeVars.colors.gray[6]}
                      style={{ width: rem(18) }}
                    />
                  )
                }
              />
            </Stack>
          </Stack>

          <Button type="submit" loading={signInLoading} disabled={!isValid}>
            Sign In
          </Button>
        </Stack>
      </form>
    </Paper>
  )
}
