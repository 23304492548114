/**
 * @generated SignedSource<<ecbf4b20c669eb01bd7e2dc04cecb2e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type signInMutation$variables = {
  email: string;
  password: string;
};
export type signInMutation$data = {
  readonly userLogin: {
    readonly credentials: {
      readonly accessToken: string;
      readonly uid: string;
    };
  } | null;
};
export type signInMutation = {
  response: signInMutation$data;
  variables: signInMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      }
    ],
    "concreteType": "UserLoginPayload",
    "kind": "LinkedField",
    "name": "userLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Credential",
        "kind": "LinkedField",
        "name": "credentials",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uid",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signInMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signInMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "703c0be6e5669ad0539e8c70493a51b4",
    "id": null,
    "metadata": {},
    "name": "signInMutation",
    "operationKind": "mutation",
    "text": "mutation signInMutation(\n  $email: String!\n  $password: String!\n) {\n  userLogin(email: $email, password: $password) {\n    credentials {\n      accessToken\n      uid\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4134297cb44683c732fd6213450cb43";

export default node;
