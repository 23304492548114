import {
  DisplayEnum,
  ReportTypeEnum,
} from "../report-table/__generated__/reportTableGetFinancialStatementQuery.graphql"
import { SaveStatementsModal } from "../save-statements-modal"
import {
  StatementNumberFormatEnum,
  StatementRepresentationByEnum,
} from "../save-statements-modal/__generated__/saveStatementsModalUpsertSavedStatementMutation.graphql"
import { FormType, RepresentationEnum } from "../statements"
import { Button } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

export type SaveStatementsButtonProps = {
  id?: string
  statementName: string
  hideCurrency?: boolean
  showDecimals?: boolean
  systemConnectionIds: string[]
  startDate: string
  endDate: string
  display: DisplayEnum
  reportType: ReportTypeEnum
  numberFormat: string[]
  showZeroBalance: boolean
  representation: RepresentationEnum
  disabled?: boolean
  resetFields?: (params: FormType) => void
}

export const SaveStatementsButton = (params: SaveStatementsButtonProps) => {
  const [
    confirmationOpened,
    { open: confirmationOpen, close: confirmationClose },
  ] = useDisclosure(false)

  return (
    <>
      <Button
        variant={params.id ? "filled" : "outline"}
        color="blue"
        disabled={params.disabled || false}
        onClick={() => confirmationOpen()}
      >
        {params.id ? "Save Updates" : "Save Statement"}
      </Button>
      <SaveStatementsModal
        saveStatementsInput={{
          id: params.id || null,
          name: params.statementName,
          systemConnectionIds: params.systemConnectionIds || [],
          startDate: params.startDate,
          endDate: params.endDate,
          displayBy: params.display,
          representationBy:
            params.representation.toUpperCase() as StatementRepresentationByEnum,
          numberFormat: params.numberFormat as StatementNumberFormatEnum[],
          reportType: params.reportType,
          showZeroBalance: params.showZeroBalance,
        }}
        title={params.id ? "Save Statement Updates" : "Add to Saved Statements"}
        onClose={confirmationClose}
        opened={confirmationOpened}
        resetFields={(values: FormType) => {
          params.resetFields && params.resetFields(values)
        }}
      />
    </>
  )
}
