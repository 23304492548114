import {
  GenerateTableReportProps,
  generateTablePDF,
  generateXlsxReport,
} from "./utils"
import { Button, Menu } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { useState } from "react"

export const ExportStatementsButton = (
  generateReportOptions: GenerateTableReportProps
) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { alpyneToken } = useUserStore()

  return (
    <Menu shadow="md" position="bottom" width={100}>
      <Menu.Target>
        <Button variant="outline" color="blue" loading={loading}>
          Export
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          fw={"bold"}
          fz={"xs"}
          onClick={async () => {
            setLoading(true)
            await generateXlsxReport(generateReportOptions, alpyneToken || "")
            setLoading(false)
          }}
        >
          .xlsx
        </Menu.Item>
        <Menu.Item
          fw={"bold"}
          fz={"xs"}
          onClick={() => generateTablePDF(generateReportOptions)}
        >
          .pdf
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
