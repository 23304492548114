import {
  ActionIcon,
  Box,
  Button,
  ButtonProps,
  Flex,
  Grid,
  Stack,
} from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"
import {
  endOfDecade,
  isAfter,
  isBefore,
  isWithinInterval,
  startOfDecade,
} from "date-fns"
import { useCallback, useEffect, useState } from "react"

const MONTHS_SHORT = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]
const CALENDAR_YEARS_OFFSET = 10

const MonthButton = ({
  isHovering,
  ...rest
}: {
  isHovering: (_val: boolean) => void
  onClick: () => void
} & ButtonProps) => {
  const { hovered, ref } = useHover<HTMLButtonElement>()

  useEffect(() => {
    isHovering(!!hovered)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered])

  return (
    <Button ref={ref} {...rest} onClick={rest.onClick}>
      {rest.children}
    </Button>
  )
}
type Props = {
  firstDate: Date | null
  secondDate: Date | null
  setFirstDate: (val: Date | null) => void
  setSecondDate: (val: Date | null) => void
}
export const DateRangePicker = ({
  firstDate,
  setFirstDate,
  secondDate,
  setSecondDate,
}: Props) => {
  const [referenceDate, setReferenceDate] = useState(firstDate ?? new Date())

  const [openedYearPicker, setOpenedYearPicker] = useState(false)
  const [hoveringMonth, setHoveringMonth] = useState<Date>()
  const startDecade = startOfDecade(new Date(referenceDate.getFullYear(), 1, 1))
  const endDecade = endOfDecade(new Date(referenceDate.getFullYear(), 1, 1))

  const monthCells = useCallback(
    (yearToCompare: number) =>
      MONTHS_SHORT.map((month, index) => {
        const iteratorMonthIndex = MONTHS_SHORT.indexOf(month)

        const isSelected =
          (iteratorMonthIndex === firstDate?.getMonth() &&
            yearToCompare === firstDate.getFullYear()) ||
          (iteratorMonthIndex === secondDate?.getMonth() &&
            yearToCompare === secondDate.getFullYear())

        const rangePicked = firstDate && secondDate
        const secondAfterFirst = rangePicked && isAfter(secondDate, firstDate)
        const isInRange =
          rangePicked &&
          isWithinInterval(new Date(yearToCompare, index, 1), {
            start: secondAfterFirst ? firstDate : secondDate,
            end: secondAfterFirst ? secondDate : firstDate,
          })
        const iteratorAfterFirstDate = firstDate
          ? isAfter(new Date(yearToCompare, index, 1), new Date(firstDate))
          : null
        const iteratorBeforeFirstDate = firstDate
          ? isBefore(new Date(yearToCompare, index, 1), new Date(firstDate))
          : null
        const iteratorBeforeHoveringMonth = hoveringMonth
          ? isBefore(new Date(yearToCompare, index, 1), hoveringMonth)
          : null
        const iteratorAfterHoveringMonth = hoveringMonth
          ? isAfter(new Date(yearToCompare, index, 1), hoveringMonth)
          : null

        return (
          <Grid.Col key={`${month}_${index}`} span={4}>
            <MonthButton
              px={0}
              color={isSelected ? "blue" : "black"}
              bg={
                (iteratorBeforeHoveringMonth &&
                  iteratorAfterFirstDate &&
                  !rangePicked) ||
                (iteratorBeforeFirstDate &&
                  iteratorAfterHoveringMonth &&
                  !rangePicked) ||
                (isInRange && !isSelected)
                  ? "blue.1"
                  : ""
              }
              variant={isSelected ? "filled" : "transparent"}
              h={"32px"}
              style={{
                ...(isSelected || isInRange
                  ? {}
                  : {
                      "--button-hover": "var(--mantine-color-blue-1)",
                    }),
                border: "0.0625rem solid white",
              }}
              ta={"center"}
              w={"100%"}
              onClick={() => {
                const monthSelectedDate = new Date(
                  yearToCompare,
                  iteratorMonthIndex,
                  1
                )
                if (rangePicked || !firstDate) {
                  setFirstDate(monthSelectedDate)
                  rangePicked && setSecondDate(null)
                  return
                }
                setSecondDate(monthSelectedDate)
              }}
              size="xs"
              isHovering={(val) => {
                val &&
                  setHoveringMonth(
                    new Date(yearToCompare, iteratorMonthIndex, 1)
                  )
              }}
            >
              {month}
            </MonthButton>
          </Grid.Col>
        )
      }),
    [firstDate, hoveringMonth, secondDate, setFirstDate, setSecondDate]
  )
  const yearPickerOptions = useCallback((yearToCompare: number) => {
    const yearsRange = Array.from(
      { length: CALENDAR_YEARS_OFFSET },
      (_, i) => startOfDecade(new Date(yearToCompare, 1, 1)).getFullYear() + i
    )

    return (
      <Box
        style={{
          position: "absolute",
          top: "3.375rem",
          zIndex: 400,
          height: "8.75rem",
          backgroundColor: "white",
          width: "14rem",
        }}
      >
        <Grid gutter={0}>
          {yearsRange.map((year, index) => (
            <Grid.Col key={`${year}_${index}`} span={4}>
              <Button
                px={0}
                color={"black"}
                variant={"transparent"}
                h={"32px"}
                style={{
                  "--button-hover": "var(--mantine-color-blue-1)",
                  border: "0.0625rem solid white",
                }}
                ta={"center"}
                w={"100%"}
                onClick={() => {
                  setReferenceDate(new Date(year, 1, 1))
                  setOpenedYearPicker(false)
                }}
                size="xs"
              >
                {year}
              </Button>
            </Grid.Col>
          ))}
        </Grid>
      </Box>
    )
  }, [])
  return (
    <Flex gap={"0.5rem"}>
      <Stack
        w={"16rem"}
        p={"md"}
        gap={"0.25rem"}
        style={{ position: "relative" }}
      >
        {openedYearPicker && yearPickerOptions(referenceDate.getFullYear())}
        <Flex justify={"space-between"} align={"center"}>
          <ActionIcon
            variant="transparent"
            color="gray"
            size={"sm"}
            onClick={() => {
              setReferenceDate(
                new Date(
                  referenceDate.getFullYear() -
                    (openedYearPicker ? CALENDAR_YEARS_OFFSET : 1),
                  1,
                  1
                )
              )
            }}
          >
            <IconChevronLeft />
          </ActionIcon>
          <Button
            variant="transparent"
            w={"100%"}
            pr={"2.5rem"}
            color="black"
            fz={"0.875rem"}
            onClick={() => setOpenedYearPicker(true)}
          >
            {openedYearPicker
              ? `${startDecade.getFullYear()} - ${endDecade.getFullYear()}`
              : referenceDate.getFullYear()}
          </Button>
        </Flex>
        <Grid gutter={0}>{monthCells(referenceDate.getFullYear())}</Grid>
      </Stack>
      <Stack
        w={"16rem"}
        p={"md"}
        gap={"0.25rem"}
        style={{ position: "relative" }}
      >
        {openedYearPicker &&
          yearPickerOptions(
            referenceDate.getFullYear() + CALENDAR_YEARS_OFFSET
          )}
        <Flex justify={"space-between"} align={"center"}>
          <Button
            variant="transparent"
            w={"100%"}
            pl={"2.5rem"}
            color="black"
            fz={"0.875rem"}
            onClick={() => setOpenedYearPicker(true)}
          >
            {openedYearPicker
              ? `${startDecade.getFullYear() + CALENDAR_YEARS_OFFSET} - ${
                  endDecade.getFullYear() + CALENDAR_YEARS_OFFSET
                }`
              : referenceDate.getFullYear() + 1}
          </Button>
          <ActionIcon
            variant="transparent"
            color="gray"
            size={"sm"}
            onClick={() => {
              setReferenceDate(
                new Date(
                  referenceDate.getFullYear() +
                    (openedYearPicker ? CALENDAR_YEARS_OFFSET : 1),
                  1,
                  1
                )
              )
            }}
          >
            <IconChevronRight />
          </ActionIcon>
        </Flex>
        <Grid gutter={0}>{monthCells(referenceDate.getFullYear() + 1)}</Grid>
      </Stack>
    </Flex>
  )
}
