import authEnvironment from "@shared/relay/environment/auth-environment"
import { useUserStore } from "@shared/store"
import { RelayEnvironmentProvider } from "react-relay"
import { Navigate, Outlet } from "react-router-dom"

export const AuthRelayProvider = () => {
  const { alpyneToken } = useUserStore()

  if (!alpyneToken) {
    return <Navigate to="sign-in" replace />
  }

  return (
    <RelayEnvironmentProvider environment={authEnvironment}>
      <Outlet />
    </RelayEnvironmentProvider>
  )
}
