import { ChargebeeConnectionModal } from "../connections-modals/chargebee-connection-modal"
import { StripeConnectionModal } from "../connections-modals/stripe-connection-modal"
import { OauthRedirector } from "./oauth-redirector"
import {
  IntegrationTypeEnum,
  oauthRedirectorQuery as OauthRedirectorQueryType,
} from "./oauth-redirector/__generated__/oauthRedirectorQuery.graphql"
import { OauthRedirectorQuery } from "./oauth-redirector/oauth-redirector"
import { Button, Group, Image, Menu, MenuProps, Text, rem } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { getIntegrationIcon } from "@shared/utils/helpers"
import { IconPlus } from "@tabler/icons-react"
import { Suspense } from "react"
import { useQueryLoader } from "react-relay"

type IntegrationTypeEnumWithoutPersonalConnections = Exclude<
  IntegrationTypeEnum,
  "GOOGLE" | "SHOPIFY"
>

const integrationTypeMapping: Record<
  IntegrationTypeEnumWithoutPersonalConnections,
  string
> = {
  CHARGEBEE: "Chargebee",
  HUBSPOT: "Hubspot",
  QUICKBOOKS: "Quickbooks",
  SALESFORCE: "Salesforce",
  STRIPE: "Stripe",
}

const connectionOptions = Object.entries(integrationTypeMapping).map(
  ([key, text]) => ({
    text,
    value: key as IntegrationTypeEnum,
  })
)

type NewConnectionButtonProps = {
  menuProps?: MenuProps
}

export const NewConnectionButton = ({
  menuProps,
}: NewConnectionButtonProps) => {
  const [
    chargebeeOpened,
    { open: openChargebeeModal, close: closeChargebeeModal },
  ] = useDisclosure(false)
  const [stripeOpened, { open: openStripeModal, close: closeStripeModal }] =
    useDisclosure(false)
  const [oauthQueryRef, oauthQuery] =
    useQueryLoader<OauthRedirectorQueryType>(OauthRedirectorQuery)

  return (
    <>
      <Menu width={rem(192)} {...menuProps} shadow="sm">
        <Menu.Target>
          <Button
            variant="filled"
            leftSection={<IconPlus size={16} />}
            loading={!!oauthQueryRef}
          >
            New Connection
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>
            <Text c="gray" size="xs" fw="500">
              DATA SOURCE
            </Text>
          </Menu.Label>
          {connectionOptions.map((connection) => (
            <Menu.Item
              key={connection.value}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (connection.value === "CHARGEBEE") {
                  openChargebeeModal()
                  return
                }
                if (connection.value === "STRIPE") {
                  openStripeModal()
                  return
                }
                oauthQuery(
                  { input: connection.value },
                  {
                    fetchPolicy: "network-only",
                  }
                )
              }}
            >
              <Group gap="0.5rem">
                <Image
                  src={getIntegrationIcon(connection.text)}
                  w={12}
                  h={12}
                  radius="0.125rem"
                />
                <Text size="xs" fw={700}>
                  {connection.text}
                </Text>
              </Group>
            </Menu.Item>
          ))}
        </Menu.Dropdown>
        {oauthQueryRef && (
          <Suspense>
            <OauthRedirector queryRef={oauthQueryRef} />
          </Suspense>
        )}
      </Menu>
      <ChargebeeConnectionModal
        opened={chargebeeOpened}
        onClose={closeChargebeeModal}
        confirmButtonProps={{
          children: "Connect",
          color: "blue",
        }}
        cancelButtonProps={{
          onClick: closeChargebeeModal,
        }}
      />
      <StripeConnectionModal
        opened={stripeOpened}
        onClose={closeStripeModal}
        confirmButtonProps={{
          children: "Connect",
          color: "blue",
        }}
        cancelButtonProps={{
          onClick: closeStripeModal,
        }}
      />
    </>
  )
}
