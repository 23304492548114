import { stripeConnectionModalCreateStripeIntegrationMutation as StripeConnectionModalCreateStripeIntegrationMutationType } from "./__generated__/stripeConnectionModalCreateStripeIntegrationMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  Group,
  ButtonProps as MantineButtonProps,
  Modal,
  ModalProps,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { notifications } from "@shared/ui/notifications"
import StripeIcon from "frontend/shared/assets/stripe.svg"
import { DOMAttributes, PropsWithChildren } from "react"
import { useForm } from "react-hook-form"
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"

const StripeConnectionModalCreateStripeIntegration = graphql`
  mutation stripeConnectionModalCreateStripeIntegrationMutation(
    $input: CreateStripeIntegrationInput!
    $connections: [ID!]!
  ) {
    createStripeIntegration(input: $input) {
      connection
        @appendNode(
          connections: $connections
          edgeTypeName: "SystemIntegration"
        ) {
        name
        id
        lastSync
        system {
          category
          name
          slug
        }
        creator {
          fullName
        }
        status
      }
      errors {
        key
        value
      }
    }
  }
`

type ButtonProps = DOMAttributes<HTMLButtonElement> & MantineButtonProps

type Props = ModalProps & {
  opened: boolean
  onClose: () => void
  cancelButtonProps?: ButtonProps
  confirmButtonProps?: ButtonProps
  isLoading?: boolean
  defaultValues?: { domain: string; apiKey: string }
}

export const StripeConnectionModal = ({
  defaultValues = { apiKey: "", domain: "" },
  opened,
  onClose,
  isLoading = false,
  cancelButtonProps,
  confirmButtonProps,
  ...modalProps
}: PropsWithChildren<Props>) => {
  const [, setSearchParams] = useSearchParams()
  const [createStripeIntegration, createStripeIntegrationLoading] =
    useMutation<StripeConnectionModalCreateStripeIntegrationMutationType>(
      StripeConnectionModalCreateStripeIntegration
    )

  const formValidationSchema = z.object({
    apiKey: z.string().trim().min(1, { message: "Required" }),
  })

  type formValues = z.infer<typeof formValidationSchema>

  const successHandler = (newConnectionId: string | undefined) => {
    notifications.show({
      title: "Successful Connection",
      message: "The connection has been successfully added from the system.",
      variant: "success",
    })

    newConnectionId &&
      setSearchParams({
        connectionId: newConnectionId,
      })
  }

  const {
    register,
    reset,
    getValues,
    setError,
    formState: { isValid, errors },
  } = useForm<formValues>({
    mode: "onTouched",
    defaultValues: defaultValues,
    resolver: zodResolver(formValidationSchema),
  })

  const submitDisabled = !isValid

  const verifyApiKey = ({ apiKey }: formValues) => {
    const generalconnection = ConnectionHandler.getConnectionID(
      "root",
      "Connections_getIntegrations"
    )
    const integrationsConnection = ConnectionHandler.getConnectionID(
      "root",
      "ConnectionsTableFragment_getIntegrations"
    )

    const connections = [generalconnection, integrationsConnection]

    createStripeIntegration({
      variables: {
        input: {
          apiKey: apiKey,
          connectionType: "STRIPE",
        },
        connections: connections,
      },
      onCompleted: (response) => {
        const newConnectionId = response.createStripeIntegration?.connection?.id
        const validationError = response.createStripeIntegration?.errors

        if (validationError && validationError[0]) {
          const key = validationError[0].key

          if (key == "standard") {
            notifications.show({
              title: "Connection Error",
              message: validationError[0].value,
              variant: "error",
            })
            return
          }

          setError(key as keyof formValues, {
            type: "custom",
            message: validationError[0].value,
          })
        } else {
          onClose()
          successHandler(newConnectionId)
        }
      },
      onError: () => {
        notifications.show({
          title: "Ops, connection failed!",
          message: "Try again later. ",
          variant: "error",
        })
      },
    })
  }

  return (
    <Modal
      opened={opened}
      onClose={() => {
        onClose()
        reset()
      }}
      centered
      size={"25rem"}
      shadow="sm"
      overlayProps={{
        bg: "#3333334D",
      }}
      closeButtonProps={{
        iconSize: "1rem",
        c: themeVars.colors.gray["6"],
      }}
      withCloseButton
      closeOnClickOutside={false}
      {...modalProps}
    >
      <Stack gap="1.5rem">
        <Stack gap="1rem">
          <Stack gap="1.3rem" align="center" justify="center">
            <img src={StripeIcon} width={56} height={56} />
            <Text>Connect Alpyne to your Stripe account</Text>
            <Text size="sm" c="gray.7" style={{ alignSelf: "start" }}>
              <div>In your Stripe dashboard:</div>
              <div>
                Navigate to{" "}
                <strong>{"Developer > API keys tab > Secret Key"}</strong>
              </div>
              <div>{"Reveal key if necessary and click over it to copy."}</div>
            </Text>
          </Stack>
          <TextInput
            {...register("apiKey")}
            placeholder={"Copy the API key here"}
            label={"API Key"}
            error={errors.apiKey?.message}
            disabled={isLoading}
            required
          />
        </Stack>
        <Group justify="flex-end" gap="1rem">
          <Button
            variant="default"
            {...cancelButtonProps}
            onClick={(e) => {
              cancelButtonProps?.onClick && cancelButtonProps?.onClick(e)
              reset()
            }}
          >
            {cancelButtonProps?.children ?? "Cancel"}
          </Button>
          <Button
            data-autofocus
            {...confirmButtonProps}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              verifyApiKey({
                apiKey: getValues("apiKey"),
              })
            }}
            disabled={submitDisabled}
            loading={createStripeIntegrationLoading}
          >
            {confirmButtonProps?.children ?? "Confirm"}
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
