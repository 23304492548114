import {
  Box,
  Combobox,
  ComboboxProps,
  Pill,
  PillsInput,
  rem,
  rgba,
  useCombobox,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { appColors } from "@shared/theme/colors"
import { IconChevronDown } from "@tabler/icons-react"
import React, { ReactElement } from "react"

type Props = ComboboxProps & {
  value: string[]
  inputRenderer?: ReactElement | null
  options: {
    text: (value: string, checked: boolean) => React.ReactElement
    value: string
    onClick?: (value: string) => void
  }[]
  onChange: (_val: string[]) => void
}
export function MultiSelectCombobox({
  value,
  onChange,
  options,
  inputRenderer,
  ...rest
}: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  })

  const handleValueSelect = (val: string) =>
    onChange(
      value.includes(val) ? value.filter((v) => v !== val) : [...value, val]
    )

  const handleValueRemove = (val: string) =>
    onChange(value.filter((v) => v !== val))

  const values = value.map((item) => (
    <Pill
      key={item}
      withRemoveButton
      onRemove={() => handleValueRemove(item)}
      style={{ borderRadius: rem(4) }}
    >
      {item}
    </Pill>
  ))

  const displayOptions = options.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      style={{
        backgroundColor: value.includes(item.value)
          ? rgba(appColors.blue[6], 0.25)
          : undefined,
        color: value.includes(item.value) ? appColors.blue[6] : undefined,
        fontWeight: 500,
        borderBottom: "0.0625rem solid white",
      }}
      onClick={() => {
        item.onClick && item.onClick(item.value)
      }}
    >
      {item.text(item.value, value.includes(item.value))}
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      shadow="xs"
      onOptionSubmit={handleValueSelect}
      {...rest}
    >
      <Combobox.DropdownTarget>
        {inputRenderer ? (
          <Box onClick={() => combobox.toggleDropdown()}>{inputRenderer}</Box>
        ) : (
          <PillsInput
            rightSectionPointerEvents="none"
            rightSection={
              <IconChevronDown size={16} color={themeVars.colors.gray[6]} />
            }
            pointer
            onClick={() => combobox.toggleDropdown()}
            wrapperProps={{
              style: {
                alignContent: "center",
                alignItems: "center",
              },
            }}
          >
            <Pill.Group style={{ flexWrap: "nowrap", overflow: "hidden" }}>
              {values.length > 0 && values}
            </Pill.Group>
          </PillsInput>
        )}
      </Combobox.DropdownTarget>

      <Combobox.Dropdown mah={rem(200)} style={{ overflowY: "auto" }}>
        <Combobox.Options>
          {displayOptions.length > 0 ? (
            displayOptions
          ) : (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
