import { removeMemberModalUnassignUserMutation as RemoveMemberModalUnassignUserMutationType } from "./__generated__/removeMemberModalUnassignUserMutation.graphql"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { graphql, useMutation } from "react-relay"

const RemoveMemberModalUnassignUserMutation = graphql`
  mutation removeMemberModalUnassignUserMutation(
    $input: UnassignUserMutationInput!
  ) {
    unassignUser(input: $input) {
      client {
        firm {
          ...membersDrawerFirmInfoFragment
        }
        ...membersTableFragment
        ...membersDrawerFragment
      }
      errors
    }
  }
`

type Props = {
  userId: string
  clientSlug: string
  opened: boolean
  onClose: () => void
}

export const RemoveMemberModal = ({
  userId,
  clientSlug,
  opened,
  onClose,
}: Props) => {
  const [unassignUserMutation, unassignUserLoading] =
    useMutation<RemoveMemberModalUnassignUserMutationType>(
      RemoveMemberModalUnassignUserMutation
    )

  const handleDeleteClick = () => {
    unassignUserMutation({
      variables: {
        input: {
          userId,
          clientSlug,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "User removed successfully!",
          message: "Access permissions successfully revoked.",
        })
        onClose()
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
      },
    })
  }

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to remove this member?"
        opened={opened}
        onClose={onClose}
        cancelButtonProps={{
          onClick: onClose,
        }}
        confirmButtonProps={{
          onClick: handleDeleteClick,
          children: "Remove Member",
          color: "red",
          loading: unassignUserLoading,
        }}
      >
        Removing this member will not result in the loss of generated data or
        any connections; only access permissions will be revoked.
      </ConfirmationModal>
    </>
  )
}
