import { oauthRedirectorQuery as OauthRedirectorQueryType } from "../connections/new-connection-button/oauth-redirector/__generated__/oauthRedirectorQuery.graphql"
import {
  OauthRedirector,
  OauthRedirectorQuery,
} from "../connections/new-connection-button/oauth-redirector/oauth-redirector"
import { profileSettingsGetCurrentUserDataQuery as ProfileSettingsGetCurrentUserDataQueryType } from "./__generated__/profileSettingsGetCurrentUserDataQuery.graphql"
import { profileSettingsUpdateCurrentUserAvatarMutation as ProfileSettingsUpdateCurrentUserAvatarMutationType } from "./__generated__/profileSettingsUpdateCurrentUserAvatarMutation.graphql"
import { profileSettingsUpdateCurrentUserMutation as ProfileSettingsUpdateCurrentUserMutationType } from "./__generated__/profileSettingsUpdateCurrentUserMutation.graphql"
import { profileSettingsUserconnectionsQuery as ProfileSettingsUserconnectionsQuery } from "./__generated__/profileSettingsUserconnectionsQuery.graphql"
import { SignOutButton } from "./sign-out-button"
import { UserConnectionsTable } from "@components/profile-settings/user-connections-table"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Alert,
  Box,
  Button,
  Card,
  Flex,
  Group,
  LoadingOverlay,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserConnectionOauthCallback } from "@shared/hooks/use-user-connection-oauth-callback"
import { AppDrawer } from "@shared/ui/app-drawer"
import { ImageBox } from "@shared/ui/image-box"
import { notifications } from "@shared/ui/notifications"
import { Suspense, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  useQueryLoader,
} from "react-relay"
import { z } from "zod"

const ProfileSettingsGetCurrentUserDataQuery = graphql`
  query profileSettingsGetCurrentUserDataQuery {
    getCurrentUserData {
      errors
      user {
        id
        firstName
        lastName
        isFirmAdmin
        role
        email
        createdAt
        avatar
      }
    }
  }
`
const ProfileSettingsUserConnectionsQuery = graphql`
  query profileSettingsUserconnectionsQuery($cursor: String, $count: Int) {
    getUserIntegrations(first: $count, after: $cursor)
      @connection(key: "UserConnections_getUserIntegrations") {
      edges {
        node {
          id
        }
      }
    }
    ...userConnectionsTableFragment @arguments(count: $count, cursor: $cursor)
  }
`

const UpdateUserSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  role: z.string().optional(),
})

const ProfileSettingsUpdateUserMutation = graphql`
  mutation profileSettingsUpdateCurrentUserMutation(
    $avatar: String
    $firstName: String
    $lastName: String
    $role: String
  ) {
    updateUser(
      input: {
        avatar: $avatar
        firstName: $firstName
        lastName: $lastName
        role: $role
      }
    ) {
      errors
      user {
        avatar
        ...navbarUserFragment
      }
    }
  }
`

const UpdateUserAvatarMutation = graphql`
  mutation profileSettingsUpdateCurrentUserAvatarMutation($avatar: String!) {
    updateUser(input: { avatar: $avatar }) {
      errors
      user {
        avatar
        ...navbarUserFragment
      }
    }
  }
`

type FormInputs = {
  firstName: string
  lastName: string
  role: string
}

export const ProfileSettings = () => {
  const { getCurrentUserData } =
    useLazyLoadQuery<ProfileSettingsGetCurrentUserDataQueryType>(
      ProfileSettingsGetCurrentUserDataQuery,
      {}
    )

  const data = useLazyLoadQuery<ProfileSettingsUserconnectionsQuery>(
    ProfileSettingsUserConnectionsQuery,
    {},
    {
      fetchPolicy: "store-and-network",
    }
  )

  const [oauthQueryRef, oauthQuery] =
    useQueryLoader<OauthRedirectorQueryType>(OauthRedirectorQuery)

  const [updateUser, updateUserLoading] =
    useMutation<ProfileSettingsUpdateCurrentUserMutationType>(
      ProfileSettingsUpdateUserMutation
    )
  const [updateUserAvatar, updateUserAvatarLoading] =
    useMutation<ProfileSettingsUpdateCurrentUserAvatarMutationType>(
      UpdateUserAvatarMutation
    )

  const {
    getUserIntegrations: { edges },
  } = data
  const hasConnections = edges.length > 0 ?? false

  const user = getCurrentUserData?.user

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: formErrors, isValid },
  } = useForm<FormInputs>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues: {
      firstName: user.firstName ?? "",
      lastName: user.lastName ?? "",
      role: user.role ?? "",
    },
  })

  const [errors, setErrors] = useState(false)
  const [opened, { open, close }] = useDisclosure(false)

  const oauthMutation = useUserConnectionOauthCallback()

  const formatDate = (date: string) => {
    const options = { month: "long", day: "numeric", year: "numeric" } as const
    const formattedDate = new Date(date)

    return formattedDate.toLocaleDateString(undefined, options)
  }

  const onSubmit: SubmitHandler<FormInputs> = ({
    firstName,
    lastName,
    role,
  }) => {
    if (user) {
      updateUser({
        variables: {
          firstName,
          lastName,
          role,
        },
        onError: () => setErrors(true),
        onCompleted: () => {
          close()
          reset()
        },
      })
    }
  }

  const updateAvatarCallback = (file: string) => {
    updateUserAvatar({
      variables: {
        avatar: file,
      },
      onError: (error) => {
        notifications.show({
          title: error.message,
          message: "Please try again",
          variant: "error",
        })
      },
    })
  }

  return (
    <Box p={rem(32)}>
      <AppDrawer
        title="Edit Personal Information"
        opened={opened}
        onClose={() => {
          close()
          reset()
        }}
        closeOnClickOutside
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack justify="space-between" h="calc(100vh - 78px)">
            <Stack gap={rem(24)}>
              {errors && (
                <Alert
                  color="red"
                  title="Error"
                  withCloseButton
                  onClose={() => setErrors(false)}
                >
                  Something went wrong.
                </Alert>
              )}
              <TextInput
                {...register("firstName")}
                label="First Name"
                error={formErrors.firstName?.message}
              />
              <TextInput
                {...register("lastName")}
                label="Last Name"
                error={formErrors.lastName?.message}
              />
              <TextInput
                label="E-mail"
                type="email"
                inputMode="email"
                value={user.email}
                disabled={true}
              />
              <TextInput
                {...register("role")}
                label="Job Title"
                error={formErrors.role?.message}
              />
            </Stack>
            <Button
              type="submit"
              loading={updateUserLoading}
              disabled={!isValid}
            >
              Save
            </Button>
          </Stack>
        </form>
      </AppDrawer>
      <Text size="xxl" fw="bold" c="gray">
        Profile Settings
      </Text>
      <Space h={rem(56)} />
      <Group justify="space-between">
        <Stack gap="0.5rem">
          <Text fw="bold" c="gray.7">
            Profile Information
          </Text>
          <Text size="sm" c="gray">
            Created on <strong>{formatDate(user.createdAt)}</strong>
          </Text>
        </Stack>
        <Button
          variant="outline"
          px={rem(16)}
          onClick={open}
          fw="normal"
          w={rem(120)}
        >
          Edit
        </Button>
      </Group>
      <Space h={rem(32)} />
      <Group grow align="normal">
        <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
          <Stack gap={rem(12)} py={rem(8)}>
            <Text size="sm" fw="bold" c="dark.3">
              Name
            </Text>
            <Text size="sm" c="gray.7">
              {user.firstName ?? "-"} {user.lastName}
            </Text>
          </Stack>
          <Stack gap={rem(12)} py={rem(8)}>
            <Text size="sm" fw="bold" c="dark.3">
              E-mail
            </Text>
            <Text size="sm" c="gray.7">
              {user.email}
            </Text>
          </Stack>
        </Card>
        <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
          <Stack gap={rem(12)} py={rem(8)}>
            <Text size="sm" fw="bold" c="dark.3">
              Role
            </Text>
            <Text size="sm" c="gray.7">
              {user.isFirmAdmin ? "Admin" : "Member"}
            </Text>
          </Stack>
          <Stack gap={rem(12)} py={rem(8)}>
            <Text size="sm" fw="bold" c="dark.3">
              Job Title
            </Text>
            <Text size="sm" c="gray.7">
              {user.role ?? ""}
            </Text>
          </Stack>
        </Card>
      </Group>
      <Space h={rem(32)} />
      <ImageBox
        title="Profile Picture"
        image={user.avatar ?? ""}
        loading={updateUserAvatarLoading}
        imageUpdaterCallback={updateAvatarCallback}
        shouldUpdate
      />
      <Space h={rem(32)} />
      <LoadingOverlay visible={oauthMutation} zIndex={1000} />
      <Flex align="center" justify="space-between">
        <Text fw="bold" c="gray.7">
          Personal Connections
        </Text>
        <Button
          variant="outline"
          px={rem(16)}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            oauthQuery(
              { input: "GOOGLE" },
              {
                fetchPolicy: "network-only",
              }
            )
          }}
          fw="normal"
          loading={!!oauthQueryRef}
          w={rem(120)}
        >
          Add
        </Button>
      </Flex>
      <Space h={rem(32)} />

      {hasConnections ? (
        <UserConnectionsTable userConnections={data} />
      ) : (
        <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
          <Stack gap={rem(8)}>
            <Text size="sm" c="gray.7" py={rem(8)}>
              Personal connections require linking your Google account to access
              your Google Drive and Spreadsheets. All this information is only
              visible to you.
            </Text>
          </Stack>
        </Card>
      )}

      {oauthQueryRef && (
        <Suspense>
          <OauthRedirector queryRef={oauthQueryRef} />
        </Suspense>
      )}

      <Space h={rem(32)} />
      <Stack gap="2rem">
        <Flex h={"2.25rem"} align="center">
          <Text fw="bold" c="gray.7">
            Sign Out
          </Text>
        </Flex>
        <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
          <Group justify="space-between">
            <Text size="sm" c="gray.7" py={rem(8)}>
              Close your current session.
            </Text>
            <SignOutButton />
          </Group>
        </Card>
      </Stack>
    </Box>
  )
}
