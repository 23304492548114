/**
 * @generated SignedSource<<ee47a4a3a7bc4852a6b37b4a95d2ac63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type portfolioGetClientsQuery$variables = {};
export type portfolioGetClientsQuery$data = {
  readonly getClients: {
    readonly count: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"clientsTableFragment">;
};
export type portfolioGetClientsQuery = {
  response: portfolioGetClientsQuery$data;
  variables: portfolioGetClientsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "portfolioGetClientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "getClients",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "clientsTableFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "portfolioGetClientsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ClientConnection",
        "kind": "LinkedField",
        "name": "getClients",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ClientEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Client",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "issues",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "filters": null,
        "handle": "connection",
        "key": "ClientsTableFragment_getClients",
        "kind": "LinkedHandle",
        "name": "getClients"
      }
    ]
  },
  "params": {
    "cacheID": "d555971d6bca9cf1a773ba2c512592db",
    "id": null,
    "metadata": {},
    "name": "portfolioGetClientsQuery",
    "operationKind": "query",
    "text": "query portfolioGetClientsQuery {\n  getClients {\n    count\n  }\n  ...clientsTableFragment\n}\n\nfragment clientsTableFragment on Query {\n  getClients {\n    edges {\n      node {\n        id\n        name\n        issues\n        status\n        logo\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "994f18cf13e2b1f8b02f96274a966866";

export default node;
