import { spreadsheetsGetSpreadsheetSettingsQuery } from "./__generated__/spreadsheetsGetSpreadsheetSettingsQuery.graphql"
import { spreadsheetsQuery } from "./__generated__/spreadsheetsQuery.graphql"
import { ConnectSpreadsheetsDrawer } from "./connect-spreadsheets-drawer"
import { SpreadsheetsDrawer } from "./spreadsheets-drawer"
import { SpreadsheetsTable } from "./spreadsheets-table"
import { Box, Button, Flex, Title, rem } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { Suspense, useEffect, useState } from "react"
import { graphql, useLazyLoadQuery, useQueryLoader } from "react-relay"
import { useSearchParams } from "react-router-dom"

const SpreadsheetsQuery = graphql`
  query spreadsheetsQuery(
    $refetchQuestions: Boolean
    $clientId: ID!
    $reportType: [SavedReportsTypeEnum!]
  ) {
    getUserWorkbooks(refetchQuestions: $refetchQuestions) {
      count
      edges {
        cursor
        node {
          workbookSpreadsheets {
            edges {
              node {
                status
              }
            }
          }
        }
      }
    }
    ...spreadsheetsTableFragment @arguments(refetchQuestions: $refetchQuestions)
    getUserIntegrations {
      count
    }
    ...connectionSelectorFragment
    ...spreadsheetsItemsFieldsFragment
      @arguments(clientId: $clientId, reportType: $reportType)
  }
`
export const GetSpreadsheetSettings = graphql`
  query spreadsheetsGetSpreadsheetSettingsQuery($workbookId: ID!) {
    getSpreadsheetSettings(workbookId: $workbookId) {
      bookId
      createdAt
      id
      lastSync
      name
      readonly
      syncPeriod
      updatedAt
      userConnection {
        user {
          fullName
        }
        status
        name
        id
      }
      workbookSpreadsheets {
        count
        edges {
          cursor
          node {
            createdAt
            id
            sheetId
            lastSync
            name
            status
            syncPeriod
            spreadsheetableType
            spreadsheetable {
              ... on MetabaseQuestion {
                id
                metabaseQuestionId
                name
                status
              }
              ... on SavedStatement {
                id
                name
                updatedAt
              }
              ... on SavedTransaction {
                id
                name
                updatedAt
              }
            }
          }
        }
      }
    }
  }
`

export const Spreadsheets = () => {
  const { currentClient } = useUserStore()
  const [searchParams, setSearchParams] = useSearchParams()
  const userWorkbookId = searchParams.get("workbookId")
  const [fetchKey, setFetchKey] = useState(0)
  const spreadsheetsData = useLazyLoadQuery<spreadsheetsQuery>(
    SpreadsheetsQuery,
    { refetchQuestions: true, clientId: currentClient.id ?? "" },
    {
      fetchPolicy: "network-only",
      fetchKey: `${currentClient.id ?? ""}_${fetchKey}` ?? "",
    }
  )

  const [
    connectedWorkbookRef,
    getConnectedWorkbook,
    disposeConnectedWorkbooks,
  ] = useQueryLoader<spreadsheetsGetSpreadsheetSettingsQuery>(
    GetSpreadsheetSettings
  )
  const [
    workbookDrawer,
    { open: openWorkBookDrawer, close: closeWorkbookDrawer },
  ] = useDisclosure(false)

  useEffect(() => {
    if (userWorkbookId && !connectedWorkbookRef) {
      getConnectedWorkbook(
        { workbookId: userWorkbookId },
        { fetchPolicy: "network-only" }
      )
    }
  }, [connectedWorkbookRef, getConnectedWorkbook, userWorkbookId])

  return (
    <Box p={rem(32)} h="100dvh">
      <Flex align="center" justify="space-between">
        <Title order={3} c="gray">
          Spreadsheets
        </Title>
        {spreadsheetsData.getUserIntegrations.count > 0 && (
          <Button onClick={openWorkBookDrawer}>New Link</Button>
        )}
      </Flex>
      <SpreadsheetsTable
        data={spreadsheetsData}
        onSpreadsheetRowClick={({ workbookId }) => {
          setSearchParams({ workbookId })
        }}
        emptyConnections={spreadsheetsData.getUserIntegrations.count === 0}
        setRefetchKey={setFetchKey}
      />
      <SpreadsheetsDrawer
        onClose={closeWorkbookDrawer}
        opened={workbookDrawer}
        connectionsData={spreadsheetsData}
        reportsData={spreadsheetsData}
      />
      <Suspense>
        {connectedWorkbookRef && (
          <ConnectSpreadsheetsDrawer
            reportsData={spreadsheetsData}
            onClose={() => {
              setSearchParams({})
              disposeConnectedWorkbooks()
            }}
            opened={!!userWorkbookId}
            connectedWorkbookRef={connectedWorkbookRef}
          />
        )}
      </Suspense>
    </Box>
  )
}
