import { NewConnectionButton } from "../new-connection-button"
import { Image, Stack, Text, rem } from "@mantine/core"
import EmptyPlaceholder from "@shared/assets/empty-connections.svg"

export const ConnectionsEmpty = () => {
  return (
    <Stack gap={rem(24)} w="100%" justify="center" align="center">
      <Image
        src={EmptyPlaceholder}
        style={{ mixBlendMode: "multiply" }}
        w={rem(274)}
      />
      <Text c="gray" ta="center">
        It appears you haven&apos;t added any connections yet.
        <br />
        Let&apos;s begin by adding your first connection.
      </Text>
      <NewConnectionButton />
    </Stack>
  )
}
