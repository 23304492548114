import { vendorSelectorGetVendorsFragment$key } from "./__generated__/vendorSelectorGetVendorsFragment.graphql"
import { Input } from "@mantine/core"
import { themeVars } from "@shared/theme"
import { AutocompleteCheckboxMultiselect } from "@shared/ui/autocomplete-checkbox-multiselect"
import { IconChevronDown } from "@tabler/icons-react"
import { useMemo, useState } from "react"
import { graphql, useFragment } from "react-relay"

const GetVendorsFragment = graphql`
  fragment vendorSelectorGetVendorsFragment on Query
  @argumentDefinitions(connectionIds: { type: "[ID!]!" }) {
    getVendors(connectionIds: $connectionIds) {
      count
      edges {
        node {
          name
          id
        }
      }
    }
  }
`
type Props = {
  data: vendorSelectorGetVendorsFragment$key
  defaultValues: string[]
  onChange: (val: string[]) => void
}
export const VendorSelector = ({ defaultValues, data, onChange }: Props) => {
  const {
    getVendors: { edges },
  } = useFragment(GetVendorsFragment, data)
  const [values, setValues] = useState(defaultValues)
  const options = useMemo(
    () =>
      edges
        .map(({ node }) => ({ text: node.name ?? "", value: node.id }))
        .sort((a) => (values.includes(a.value) ? -1 : 1)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [edges]
  )

  const onSelectorChange = (val: string[]) => {
    setValues(val)
  }

  return (
    <AutocompleteCheckboxMultiselect
      value={values}
      options={options}
      disableSort
      onClose={() => {
        onChange(values)
      }}
      onChange={onSelectorChange}
      dropdownProps={{ miw: "20rem" }}
      inputRenderer={
        values && values.length > 0 ? (
          <Input
            pointer
            component="button"
            rightSection={
              <IconChevronDown size={16} color={themeVars.colors.gray[6]} />
            }
          >{`${values.length} selected`}</Input>
        ) : undefined
      }
      position="bottom-start"
      fixedOptions={[{ text: "Without assigned values", value: "EMPTY" }]}
      groupLabel={"VENDOR LIST"}
    />
  )
}
