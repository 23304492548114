/**
 * @generated SignedSource<<1f78211af6f626a2c8602a7e9c7c771b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
import { FragmentRefs } from "relay-runtime";
export type connectionsTableFragment$data = {
  readonly getIntegrations: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly creator: {
          readonly avatar: string | null;
          readonly fullName: string | null;
        };
        readonly id: string;
        readonly lastSync: string | null;
        readonly name: string;
        readonly status: StatusIntegrationTypeEnum;
        readonly system: {
          readonly category: string;
          readonly name: string;
          readonly slug: string;
        };
      };
    }>;
  };
  readonly " $fragmentType": "connectionsTableFragment";
};
export type connectionsTableFragment$key = {
  readonly " $data"?: connectionsTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"connectionsTableFragment">;
};

import ConnectionsTableFragment_RefetchQuery_graphql from './ConnectionsTableFragment_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "getIntegrations"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": ConnectionsTableFragment_RefetchQuery_graphql
    }
  },
  "name": "connectionsTableFragment",
  "selections": [
    {
      "alias": "getIntegrations",
      "args": null,
      "concreteType": "SystemIntegrationConnection",
      "kind": "LinkedField",
      "name": "__ConnectionsTableFragment_getIntegrations_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SystemIntegrationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SystemIntegration",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastSync",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "System",
                  "kind": "LinkedField",
                  "name": "system",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "creator",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f57e305f06af044e1c62041618b89b89";

export default node;
