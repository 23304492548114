import { stopImpersonateButtonStopImpersonateUserMutation } from "./__generated__/stopImpersonateButtonStopImpersonateUserMutation.graphql"
import { Button } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useUserStore } from "@shared/store"
import { graphql, useMutation } from "react-relay"

const StopImpersonateUserMutation = graphql`
  mutation stopImpersonateButtonStopImpersonateUserMutation(
    $input: StopImpersonateUserMutationInput!
  ) {
    stopImpersonateUser(input: $input) {
      errors
      alpyneJwtToken
      user {
        id
        fullName
      }
    }
  }
`

export const StopImpersonateButton = () => {
  const [stopImpersonateUser] =
    useMutation<stopImpersonateButtonStopImpersonateUserMutation>(
      StopImpersonateUserMutation
    )
  const { impersonatorUser, setAlpyneToken, setImpersonatorUser } =
    useUserStore()

  const handleStopImpersonation = (userToken: string) => {
    stopImpersonateUser({
      variables: {
        input: {
          superAdminToken: userToken,
        },
      },
      updater: (store, response) => {
        store.invalidateStore()

        const alpyneJwtToken = response.stopImpersonateUser?.alpyneJwtToken

        if (alpyneJwtToken) {
          setAlpyneToken(alpyneJwtToken)
          setImpersonatorUser(null, null, null)

          window.location.reload()
        }
      },
      onError: () => {
        notifications.show({
          title: "There was an error stopping the impersonating user",
          message: "Please try again",
          variant: "error",
        })
      },
    })
  }

  return (
    <Button
      variant="outline"
      h="2rem"
      color="white"
      onClick={() => handleStopImpersonation(impersonatorUser?.jwtToken || "")}
    >
      Back to Super Admin Account
    </Button>
  )
}
