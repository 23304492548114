import { SpreadsheetsDrawerType } from "../../spreadsheets-drawer"
import { connectionSelectorFragment$key } from "./__generated__/connectionSelectorFragment.graphql"
import { Flex, Text, Tooltip } from "@mantine/core"
import { SelectCombobox } from "@shared/ui/select-combobox"
import { IconHelp } from "@tabler/icons-react"
import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { graphql, useFragment } from "react-relay"

const ConnectionSelectorFragment = graphql`
  fragment connectionSelectorFragment on Query
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    getUserIntegrations(first: $count, after: $cursor)
      @connection(key: "connectionSelectorFragment_getUserIntegrations") {
      edges {
        node {
          createdAt
          id
          name
          status
          updatedAt
        }
      }
    }
  }
`

type Props = {
  data: connectionSelectorFragment$key
  onChange: (val: string) => void
}

export const ConnectionSelector = ({
  data,
  onChange: externalOnchage,
}: Props) => {
  const {
    getUserIntegrations: { edges },
  } = useFragment(ConnectionSelectorFragment, data)

  const { setValue, watch, resetField, formState, control } =
    useFormContext<SpreadsheetsDrawerType>()

  const connectionsOptions = useMemo(
    () =>
      edges
        .map(({ node }) => {
          const disabled = node.status !== "ONLINE"

          return {
            label: node.name ?? "",
            value: node.id,
            disabled: disabled,
            rightSection: disabled && (
              <Flex gap="0.25rem" align="center">
                <Text size="xs">Disabled</Text>
                <Tooltip
                  label={
                    <Text size="xs">
                      Go to personal connections and reconnect
                    </Text>
                  }
                  color="gray"
                  withArrow
                  position="top-end"
                  w="11rem"
                  multiline
                >
                  <IconHelp size={"0.75rem"} />
                </Tooltip>
              </Flex>
            ),
          }
        })
        .sort((_a, b) => (b.disabled ? -1 : 1)),
    [edges]
  )

  const availableInput = connectionsOptions.some((item) => !item.disabled)

  return (
    <Controller
      control={control}
      name="connection"
      render={({ field: { onChange, onBlur, value } }) => (
        <SelectCombobox
          inputProps={{
            label: "Connection",
            error: availableInput
              ? formState.errors.connection?.message
              : "No available connections, check your personal connections' statuses",
            disabled: !availableInput,
          }}
          onChange={(val) => {
            onChange(val)
            setValue("workbook", "")
            resetField("spreadsheets")
            watch("spreadsheets")
            val && externalOnchage(val)
          }}
          autocomplete
          value={value ?? ""}
          onBlur={onBlur}
          options={connectionsOptions}
        />
      )}
    />
  )
}
