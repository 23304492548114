import { ClientRoute } from "./client-route"
import { pathConstants } from "./path-constants"
import { ClientSettings } from "@components/client-settings"
import { Connections } from "@components/connections"
import { FirmOverview } from "@components/firm-overview"
import { FirmSettings } from "@components/firm-settings/firm-settings"
import { Home } from "@components/home"
import { MetabaseData } from "@components/metabase-data"
import { Portfolio } from "@components/portfolio"
import { ProfileSettings } from "@components/profile-settings"
import { SavedStatements } from "@components/saved-statements"
import { Spreadsheets } from "@components/spreadsheets"
import { Statements } from "@components/statements"
import { CashFlowConfiguration } from "@components/statements/cash-flow-configuration"
import { Transactions } from "@components/transactions"
import { AdminLayout } from "@shared/layouts/admin-layout"
import { AppLayout } from "@shared/layouts/app-layout"
import { Navigate, type RouteObject } from "react-router-dom"

export const AuthRoutes: RouteObject[] = [
  {
    path: pathConstants.ROOT,
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={pathConstants.PORTFOLIO} />,
      },
      {
        path: pathConstants.PORTFOLIO,
        element: <Portfolio />,
      },
      {
        path: pathConstants.PROFILE_SETTINGS,
        element: <ProfileSettings />,
      },
      {
        path: pathConstants.HOME,
        element: <ClientRoute element={<Home />} />,
      },
      {
        path: pathConstants.DATA,
        element: <ClientRoute element={<MetabaseData />} />,
      },
      {
        path: pathConstants.STATEMENTS,
        element: <ClientRoute element={<Statements />} />,
      },
      {
        path: pathConstants.CASH_FLOW_CONFIGURATION,
        element: <ClientRoute element={<CashFlowConfiguration />} />,
      },
      {
        path: pathConstants.SAVED_STATEMENTS,
        element: <ClientRoute element={<SavedStatements />} />,
      },
      {
        path: pathConstants.SAVED_REPORTS,
        element: <ClientRoute element={<SavedStatements />} />,
      },
      {
        path: pathConstants.SAVED_STATEMENT_DETAILS,
        element: <ClientRoute element={<Statements />} />,
      },
      {
        path: pathConstants.TRANSACTIONS,
        element: <ClientRoute element={<Transactions />} />,
      },
      {
        path: pathConstants.SAVED_TRANSACTION_DETAILS,
        element: <ClientRoute element={<Transactions />} />,
      },
      {
        path: pathConstants.SPREADSHEETS,
        element: <ClientRoute element={<Spreadsheets />} />,
      },
      {
        path: pathConstants.CONNECTIONS,
        element: (
          <ClientRoute
            key={pathConstants.CONNECTIONS}
            element={<Connections />}
          />
        ),
      },
      {
        path: pathConstants.CLIENT_SETTINGS,
        element: (
          <ClientRoute
            key={pathConstants.CLIENT_SETTINGS}
            element={<ClientSettings />}
          />
        ),
      },
      {
        path: pathConstants.FIRM_SETTINGS,
        element: <FirmSettings />,
      },
    ],
  },
  {
    path: pathConstants.ROOT,
    element: <AdminLayout />,
    children: [
      {
        path: pathConstants.ADMIN_SETTINGS,
        element: <FirmOverview />,
      },
    ],
  },
]
