/* eslint-disable @typescript-eslint/no-unused-vars */
import { updatePasswordUserUpdatePasswordWithTokenMutation } from "./__generated__/updatePasswordUserUpdatePasswordWithTokenMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  Divider,
  Paper,
  PasswordInput,
  Stack,
  Text,
  Title,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { notifications } from "@shared/ui/notifications"
import { IconEye, IconEyeOff } from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { graphql, useMutation } from "react-relay"
import { useNavigate } from "react-router-dom"
import { z } from "zod"

const FormSchema = z
  .object({
    password: z.string().min(8),
    confirmPassword: z.string().min(8),
  })
  .superRefine(({ password, confirmPassword }, checkPassComplexity) => {
    const containsLetter = (ch: string) => /[A-Za-z]/.test(ch)
    const containsSpecialChar = (ch: string) =>
      /[ !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/.test(ch)
    let countOfLetters = 0,
      countOfNumbers = 0,
      countOfSpecialChar = 0
    for (let i = 0; i < password.length; i++) {
      const ch = password.charAt(i)
      if (!Number.isNaN(+ch)) countOfNumbers++
      else if (containsLetter(ch)) countOfLetters++
      else if (containsSpecialChar(ch)) countOfSpecialChar++
    }
    if (countOfLetters < 1 || countOfSpecialChar < 1 || countOfNumbers < 1) {
      checkPassComplexity.addIssue({
        code: "custom",
        path: ["password"],
        message:
          "Password must contain at least 8 chars, with at least 1 letter, 1 number and 1 symbol.",
      })
    }
    if (confirmPassword !== password) {
      checkPassComplexity.addIssue({
        code: "custom",
        path: ["confirmPassword"],
        message: "Confirm Password must be equal to New Password.",
      })
    }
  })

type formValues = z.infer<typeof FormSchema>

const UpdatePasswordMutation = graphql`
  mutation updatePasswordUserUpdatePasswordWithTokenMutation(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    userUpdatePasswordWithToken(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      authenticatable {
        email
      }
    }
  }
`
type Props = {
  token: string
}
export const UpdatePassword = ({ token }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<formValues>({
    resolver: zodResolver(FormSchema),
  })
  const navigate = useNavigate()
  const { reset } = useUserStore()
  const [success, setSuccess] = useState(false)
  const [updatePassword, updatePassLoading] =
    useMutation<updatePasswordUserUpdatePasswordWithTokenMutation>(
      UpdatePasswordMutation
    )
  const onSubmit: SubmitHandler<formValues> = ({
    confirmPassword,
    password,
  }) => {
    updatePassword({
      variables: {
        password,
        passwordConfirmation: confirmPassword,
        resetPasswordToken: token,
      },
      onCompleted: () => {
        setSuccess(true)
        reset()
      },
      onError: (error) => {
        notifications.show({
          title: "Error",
          message: error.message,
          variant: "error",
        })
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper p={rem(32)} shadow="xs" w={rem(504)}>
        {success ? (
          <Stack gap={rem(32)}>
            <Title order={4} ta="center">
              {"Password Reset Successful!"}
            </Title>
            <Divider />
            <Text ta="center" c="gray" size="sm">
              {
                "Your password has been successfully reset. You can now proceed to sign in using your updated credentials."
              }
            </Text>
            <Button
              variant="transparent"
              onClick={() => navigate(pathConstants.PUBLIC.SIGN_IN)}
            >
              Go to Sign In
            </Button>
          </Stack>
        ) : (
          <Stack gap={rem(32)}>
            <Title order={3} ta="center">
              Created New Password
            </Title>
            <Divider />
            <Stack gap={rem(16)}>
              <Text c="gray" size="sm">
                This password should be different from the previous password.
              </Text>
              <PasswordInput
                {...register("password")}
                label="New Password"
                error={errors.password?.message}
                description="At least 8 chars, with at least 1 letter, 1 number and 1 symbol."
                inputWrapperOrder={["label", "input", "error", "description"]}
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? (
                    <IconEye
                      color={themeVars.colors.gray[6]}
                      style={{ width: rem(18) }}
                    />
                  ) : (
                    <IconEyeOff
                      color={themeVars.colors.gray[6]}
                      style={{ width: rem(18) }}
                    />
                  )
                }
              />
              <PasswordInput
                {...register("confirmPassword")}
                label="Confirm New Password"
                error={errors.password?.message}
                inputWrapperOrder={["label", "input", "error", "description"]}
                visibilityToggleIcon={({ reveal }) =>
                  reveal ? (
                    <IconEye
                      color={themeVars.colors.gray[6]}
                      style={{ width: rem(18) }}
                    />
                  ) : (
                    <IconEyeOff
                      color={themeVars.colors.gray[6]}
                      style={{ width: rem(18) }}
                    />
                  )
                }
              />
            </Stack>
            <Button disabled={!isValid} type="submit">
              Reset Password
            </Button>
          </Stack>
        )}
      </Paper>
    </form>
  )
}
