import { ErrorBadge } from "../../../shared/ui/error-badge"
import {
  StatusIntegrationTypeEnum,
  WorkbookSpreadsheetStatusTypeEnum,
  spreadsheetsTableFragment$key,
} from "./__generated__/spreadsheetsTableFragment.graphql"
import emptyTableIcon from "@assets/add-table.svg"
import spreadsheetIcon from "@assets/spreadsheets-icon.svg"
import {
  Avatar,
  Button,
  Flex,
  Group,
  Image,
  Loader,
  Space,
  Stack,
  Text,
  Tooltip,
  rem,
} from "@mantine/core"
import { useSyncingRefetch } from "@shared/hooks/use-syncing-refetch"
import { themeVars } from "@shared/theme"
import { Table } from "@shared/ui/table"
import { getAvatarText, getDaysAgo } from "@shared/utils/helpers"
import { IconExternalLink } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import { pathConstants } from "frontend/routes/path-constants"
import { useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import { useNavigate } from "react-router-dom"

type Spreadsheet = {
  spreadsheet: string
  source: string
  lastSync: Date | null
  status: WorkbookSpreadsheetStatusTypeEnum
  sourceName: string | null
}

type Workbook = {
  externalLink: string
  id: string
  lastSync: Date | null
  workbook: string
  workbookId: string
  connection: string
  subRows?: Spreadsheet[]
  status: StatusIntegrationTypeEnum
  userName: string
  userAvatar: string
}

const SpreadsheetsTableFragment = graphql`
  fragment spreadsheetsTableFragment on Query
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int" }
    refetchQuestions: { type: "Boolean" }
  ) {
    getUserWorkbooks(
      first: $count
      after: $cursor
      refetchQuestions: $refetchQuestions
    ) @connection(key: "SpreadsheetsTableFragment_getUserWorkbooks") {
      edges {
        node {
          bookId
          createdAt
          id
          name
          syncPeriod
          externalLink
          updatedAt
          lastSync
          workbookSpreadsheets {
            edges {
              node {
                createdAt
                id
                sheetId
                lastSync
                name
                status
                syncPeriod
                spreadsheetable {
                  ... on MetabaseQuestion {
                    id
                    metabaseQuestionId
                    name
                    status
                    source
                  }
                  ... on SavedStatement {
                    id
                    name
                    updatedAt
                    source
                  }
                  ... on SavedTransaction {
                    id
                    name
                    updatedAt
                    source
                  }
                }
              }
            }
          }
          userConnection {
            user {
              fullName
              avatar
            }
            status
            name
            id
          }
        }
      }
    }
  }
`

const columns: ColumnDef<Workbook>[] = [
  {
    header: "Workbook",
    accessorKey: "workbook",
    cell: (info) => (
      <Flex align="center" gap={rem(8)}>
        <Avatar src={spreadsheetIcon} size="xs" />
        <Text size="sm">{info.getValue<string>()}</Text>
      </Flex>
    ),
  },
  {
    header: "Connection",
    accessorKey: "connection",
    meta: {
      keepCasing: true,
    },
  },
  {
    header: "Creator",
    accessorKey: "userName",
    cell: (info) => (
      <Group gap={rem(8)}>
        <Avatar src={info.row.original.userAvatar} radius="xl" size="xs">
          {getAvatarText(info.getValue<string>())}
        </Avatar>
        <Text size="sm" c="gray.7">
          {info.getValue<string>()}
        </Text>
      </Group>
    ),
  },
  {
    header: "Last Sync",
    accessorKey: "lastSync",
    cell: (info) => {
      const lastSyncValue = info.getValue<Date | null>()
      return (
        <span
          title={
            lastSyncValue
              ? format(lastSyncValue, "EEEE, MMMM d, y 'at' h:mm a")
              : "None"
          }
        >
          {lastSyncValue ? getDaysAgo(lastSyncValue) : "--"}
        </span>
      )
    },
  },
  {
    header: "",
    accessorKey: "status",
    enableSorting: false,
    enableGlobalFilter: false,
    cell: (info) => {
      const childrenStatus = info.row.original.subRows?.find((children) =>
        ["GOOGLE_ERROR", "BROKEN", "SPREADSHEETABLE_ERROR"].includes(
          children.status
        )
      )?.status
      const childrenSyncing = info.row.original.subRows?.some(
        (children) => children.status === "SYNCING"
      )
      const workbookStatus = info.getValue<StatusIntegrationTypeEnum>()
      const status = childrenStatus ?? workbookStatus

      return (
        <>
          {childrenSyncing ? (
            <Tooltip label="Syncing data" color="gray.7" withArrow>
              <Loader size={14} />
            </Tooltip>
          ) : (
            <>{getStatusBadge(status, workbookStatus != "ONLINE")}</>
          )}
          {status != "BROKEN" && (
            <Tooltip label="Open workbook" color="gray.7" withArrow>
              <a
                href={info.row.original.externalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconExternalLink size={16} color={themeVars.colors.blue[6]} />
              </a>
            </Tooltip>
          )}
        </>
      )
    },
    meta: {
      align: "right",
      disableRowClick: true,
    },
  },
]
const subColumns: ColumnDef<Spreadsheet>[] = [
  {
    header: "SpreadSheet",
    accessorKey: "spreadsheet",
  },
  {
    header: "Source Type",
    accessorKey: "source",
  },
  {
    header: "Source Name",
    accessorKey: "sourceName",
  },
  {
    header: "Last Sync",
    accessorKey: "lastSync",
    cell: (info) => {
      const lastSyncValue = info.getValue<Date | null>()
      return (
        <span
          title={
            lastSyncValue
              ? format(lastSyncValue, "EEEE, MMMM d, y 'at' h:mm a")
              : "None"
          }
        >
          {lastSyncValue ? getDaysAgo(lastSyncValue) : "--"}
        </span>
      )
    },
  },
  {
    header: "",
    accessorKey: "status",
    enableSorting: false,
    enableGlobalFilter: false,
    cell: (info) =>
      info.getValue<WorkbookSpreadsheetStatusTypeEnum>() === "SYNCING" ? (
        <Tooltip label="Syncing data" color="gray.7" withArrow>
          <Loader size={14} />
        </Tooltip>
      ) : (
        getStatusBadge(info.getValue<WorkbookSpreadsheetStatusTypeEnum>())
      ),
    meta: {
      align: "right",
    },
  },
]

export const getStatusBadge = (
  statusType: WorkbookSpreadsheetStatusTypeEnum | StatusIntegrationTypeEnum,
  workbookPriority = false
) => {
  switch (statusType) {
    case "GOOGLE_ERROR": {
      return <ErrorBadge label="Spreadsheet Issues" />
    }
    case "SPREADSHEETABLE_ERROR": {
      return <ErrorBadge label="Data Source Issues" />
    }
    case "BROKEN": {
      return workbookPriority ? (
        <ErrorBadge label="Connection Issues" />
      ) : (
        <ErrorBadge label="Sync Issues" />
      )
    }
    default: {
      return null
    }
  }
}

type Props = {
  data: spreadsheetsTableFragment$key
  emptyConnections?: boolean
  onSpreadsheetRowClick: (val: Workbook) => void
  setRefetchKey: React.Dispatch<React.SetStateAction<number>>
}

export const SpreadsheetsTable = ({
  data,
  emptyConnections,
  onSpreadsheetRowClick,
  setRefetchKey,
}: Props) => {
  const {
    getUserWorkbooks: { edges },
  } = useFragment(SpreadsheetsTableFragment, data)
  const someSyncing = edges
    .flatMap((wb) => wb.node.workbookSpreadsheets)
    .flatMap((sp) => sp.edges)
    .some((item) => item.node.status === "SYNCING")
  useSyncingRefetch(someSyncing, setRefetchKey, 10)
  const navigate = useNavigate()

  const spreadsheetsDisplayData: Workbook[] = useMemo(
    () =>
      edges.map(({ node }) => ({
        lastSync:
          typeof node.lastSync == "string" ? new Date(node.lastSync) : null,
        workbook: node.name ?? "--",
        workbookId: node.id,
        externalLink: node.externalLink || "",
        connection: node.userConnection.name ?? "--",
        status: node.userConnection.status,
        id: node.id,
        userName: node.userConnection.user.fullName ?? "--",
        userAvatar: node.userConnection.user.avatar ?? "",
        subRows: [...(node.workbookSpreadsheets.edges ?? [])].map(
          ({ node: spreadsheetNode }) => ({
            spreadsheet: spreadsheetNode.name ?? "--",
            sourceName: spreadsheetNode.spreadsheetable.name ?? "--",
            source: spreadsheetNode.spreadsheetable.source ?? "--",
            lastSync:
              typeof spreadsheetNode.lastSync == "string"
                ? new Date(spreadsheetNode.lastSync)
                : null,
            status: spreadsheetNode.status,
          })
        ),
      })),
    [edges]
  )

  return spreadsheetsDisplayData.length > 0 ? (
    <>
      <Space h={rem(32)} />
      <Table
        columns={columns}
        onRowClick={(row) => onSpreadsheetRowClick(row)}
        data={spreadsheetsDisplayData}
        subColumns={subColumns}
        autoColumnsWidth
      />
    </>
  ) : (
    <Flex justify="center" h="calc(100% - 1rem)">
      <Space h={rem(32)} />
      <Flex h={"100%"} justify={"center"} align={"center"}>
        <Stack align="center" gap={0} maw={rem(451)}>
          <Image src={emptyTableIcon} w={rem(173)} />
          <Text c="gray.5" ta="center">
            {emptyConnections
              ? "Your spreadsheet connection isn't established. Navigate to the Personal connections and link your Google account."
              : "Link your spreadsheets to associate data from your sources."}
          </Text>
          {emptyConnections && (
            <>
              <Space h={rem(24)} />
              <Button
                onClick={() => navigate(pathConstants.PROFILE_SETTINGS)}
                w={rem(77)}
              >
                Go
              </Button>
            </>
          )}
        </Stack>
      </Flex>
    </Flex>
  )
}
