import {
  StatusIntegrationTypeEnum,
  userConnectionsTableFragment$key,
} from "./__generated__/userConnectionsTableFragment.graphql"
import { DeleteUserConnectionModal } from "./delete-user-connection-modal"
import { oauthRedirectorQuery as OauthRedirectorQueryType } from "@components/connections/new-connection-button/oauth-redirector/__generated__/oauthRedirectorQuery.graphql"
import {
  OauthRedirector,
  OauthRedirectorQuery,
} from "@components/connections/new-connection-button/oauth-redirector/oauth-redirector"
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  HoverCard,
  Image,
  Loader,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { themeVars } from "@shared/theme"
import { ErrorBadge } from "@shared/ui/error-badge"
import { Table } from "@shared/ui/table"
import { getIntegrationSlugIcon } from "@shared/utils/helpers"
import { IconTrash } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { Suspense, useState } from "react"
import { graphql, useFragment, useQueryLoader } from "react-relay"

const UserConnectionsTableFragment = graphql`
  fragment userConnectionsTableFragment on Query
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" })
  @refetchable(queryName: "UserConnectionsTableFragment_RefetchQuery") {
    getUserIntegrations(first: $count, after: $cursor)
      @connection(key: "UserConnectionsTableFragment_getUserIntegrations") {
      edges {
        node {
          id
          name
          system {
            category
            name
            slug
          }
          status
        }
      }
    }
  }
`

type UserConnection = {
  id: string
  name: string
  dataSource: string
  slug: string
  status: string
}

type Props = {
  userConnections: userConnectionsTableFragment$key
}

export const UserConnectionsTable = ({ userConnections }: Props) => {
  const {
    getUserIntegrations: { edges },
  } = useFragment(UserConnectionsTableFragment, userConnections)
  const [
    confirmationOpened,
    { open: confirmationOpen, close: confirmationClose },
  ] = useDisclosure(false)
  const [selectedConnection, setSelectedConnection] = useState<string | null>(
    null
  )

  const [oauthQueryRef, oauthQuery] =
    useQueryLoader<OauthRedirectorQueryType>(OauthRedirectorQuery)

  const columns: ColumnDef<UserConnection>[] = [
    {
      header: "Account",
      accessorKey: "name",
      cell: (info) => (
        <Text fw="bold" size="sm">
          {info.getValue<string>()}
        </Text>
      ),
    },
    {
      header: "Data Source",
      accessorKey: "dataSource",
      cell: (info) => (
        <Flex gap={rem(8)}>
          <Image
            src={getIntegrationSlugIcon(info.row.original.slug)}
            fit="contain"
            h={rem(18)}
          />
          {info.getValue<string>()}
        </Flex>
      ),
      enableSorting: false,
    },
    {
      header: "",
      accessorKey: "status",
      cell: (info) => (
        <Group justify="flex-end" w="100%" h="100%" gap="0.5rem" wrap="nowrap">
          {(info.getValue<StatusIntegrationTypeEnum>() === "BROKEN" ||
            info.getValue<StatusIntegrationTypeEnum>() === "SYNC_FAILED") && (
            <HoverCard
              openDelay={500}
              shadow="xs"
              width="11rem"
              position="top-end"
            >
              <HoverCard.Target>
                <Box style={{ cursor: "default  " }}>
                  <ErrorBadge label="Connection Issues" />
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Stack gap="0.5rem">
                  <Text size="sm" c="gray.7">
                    This connection have issues that need to be resolved.
                  </Text>
                  <Button
                    variant="outline"
                    color="blue"
                    size="xs"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      oauthQuery(
                        { input: "GOOGLE" },
                        {
                          fetchPolicy: "network-only",
                        }
                      )
                    }}
                    loading={!!oauthQueryRef}
                    w="min-content"
                  >
                    Reconnect
                  </Button>
                </Stack>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
          {oauthQueryRef && (
            <Suspense>
              <OauthRedirector queryRef={oauthQueryRef} />
            </Suspense>
          )}
          {info.getValue<StatusIntegrationTypeEnum>() === "SYNCING" && (
            <Loader size={14} />
          )}
          <ActionIcon
            variant="light"
            color="gray.0"
            onClick={() => handleTrashButton(info.row.original.id)}
          >
            <IconTrash size={16} color={themeVars.colors.gray[5]} />
          </ActionIcon>
        </Group>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ]

  const tableData: UserConnection[] = edges.map((userConnection) => ({
    id: userConnection.node.id,
    name: userConnection.node.name,
    dataSource: userConnection.node.system.name,
    slug: userConnection.node.system.slug,
    status: userConnection.node.status,
  }))

  const handleTrashButton = (connectionID: string) => {
    setSelectedConnection(connectionID)
    confirmationOpen()
  }

  return (
    <>
      <Table columns={columns} data={tableData} />
      <DeleteUserConnectionModal
        connectionId={selectedConnection}
        onClose={confirmationClose}
        opened={confirmationOpened}
      />
    </>
  )
}
