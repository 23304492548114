import styles from "./role-select.module.scss"
import {
  Combobox,
  ComboboxProps,
  Flex,
  Input,
  InputBase,
  Text,
  useCombobox,
} from "@mantine/core"
import { themeVars } from "@shared/theme"
import { IconChevronDown } from "@tabler/icons-react"

const roleOptions = [
  {
    label: "Member",
    description: "View-only Access",
    value: "member",
  },
  {
    label: "Admin",
    description: "Edition Access",
    value: "admin",
  },
]
interface Props extends ComboboxProps {
  value: string | null
  onChange: (_val: string) => void
}
export const RoleSelect = ({ value, onChange, ...rest }: Props) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })

  const selectedOption = roleOptions.find(
    (option) => option.value == value
  )?.label

  const options = roleOptions.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      className={styles.roleOptionWrapper}
    >
      <Flex justify="space-between" align="center">
        <Text size="xs" fw="bold" className={styles.roleOptionText}>
          {item.label}
        </Text>
        <Text c="gray.5" size="xs" style={{ ".bg-red-200": { color: "red" } }}>
          {item.description}
        </Text>
      </Flex>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        onChange(val)
        combobox.closeDropdown()
      }}
      {...rest}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          type="button"
          withAsterisk
          pointer
          label="Role"
          rightSection={
            <IconChevronDown size={16} color={themeVars.colors.gray[6]} />
          }
          rightSectionPointerEvents="none"
          onClick={() => combobox.toggleDropdown()}
        >
          {selectedOption || (
            <Input.Placeholder>Select the role</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
