import { signUpMutation as SignUpMutationType } from "../__generated__/signUpMutation.graphql"
import { UserDataForm } from "../user-data-form"
import { UserFormType, UserSchema } from "../user-data-form/user-data-form"
import { userSignUpFragment$key } from "./__generated__/userSignUpFragment.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { Divider, Paper, Stack, Text, rem } from "@mantine/core"
import { notifications } from "@shared/ui/notifications"
import { FormProvider, useForm } from "react-hook-form"
import {
  GraphQLTaggedNode,
  graphql,
  useFragment,
  useMutation,
} from "react-relay"

const UserSignUpFragment = graphql`
  fragment userSignUpFragment on GetOnboardingInfo {
    user {
      isFirmOwner
      firm {
        name
      }
      avatar
      email
      fullName
    }
  }
`

type Props = {
  invitationToken: string
  onboardingUser: userSignUpFragment$key
  mutation: GraphQLTaggedNode
}

export const UserSignUp = ({
  onboardingUser,
  mutation,
  invitationToken,
}: Props) => {
  const { user } = useFragment(UserSignUpFragment, onboardingUser)
  const [signUpMutation, signUpMutationLoading] =
    useMutation<SignUpMutationType>(mutation)

  const initialValues: UserFormType = {
    email: user?.email ?? "",
    fullName: user?.fullName ?? "",
    password: "",
    avatar: user?.avatar ?? "",
  }

  const form = useForm<UserFormType>({
    defaultValues: initialValues,
    resolver: zodResolver(UserSchema),
  })

  const handleSignUp = (data: UserFormType) => {
    signUpMutation({
      variables: {
        input: {
          user: {
            email: data.email,
            fullName: data.fullName,
            password: data.password,
            avatar: data.avatar,
          },
          invitationToken: invitationToken,
        },
      },
      onError: (error) => {
        notifications.show({
          message: error.message,
          title: "An error ocurred",
          variant: "error",
        })
      },
    })
  }

  return (
    <Paper shadow="xs" radius="md" p="xl" w={rem(504)}>
      <Stack gap={0}>
        <Stack gap={rem(4)} align="center" py={rem(24)}>
          <Text ta="center" size={rem(24)} fw="bold">
            Join to
            <Text c="blue" component="span" fw="bold">
              {" "}
              {user?.firm.name}{" "}
            </Text>
            on Alpyne
          </Text>
          <Text size="sm" c="gray" ta="center">
            The gateway to financial transformation through data mastery.
          </Text>
        </Stack>
        <Divider c="gray.3" my={rem(8)} />
        <FormProvider {...form}>
          <UserDataForm
            onSubmit={handleSignUp}
            loading={signUpMutationLoading}
          />
        </FormProvider>
      </Stack>
    </Paper>
  )
}
