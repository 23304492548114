import {
  SpreadsheetSettingsInput,
  WorkbookSyncPeriodTypeEnum,
  spreadsheetsDrawerCreateSpreadsheetSettingsMutation,
} from "./__generated__/spreadsheetsDrawerCreateSpreadsheetSettingsMutation.graphql"
import { SpreadsheetableEnum } from "./__generated__/spreadsheetsDrawerCreateSpreadsheetSettingsMutation.graphql"
import { spreadsheetsDrawerCreateWorkbookMutation } from "./__generated__/spreadsheetsDrawerCreateWorkbookMutation.graphql"
import { spreadsheetsDrawerListSpreadsheetsQuery } from "./__generated__/spreadsheetsDrawerListSpreadsheetsQuery.graphql"
import containerStyles from "./spreadsheets-drawer.module.scss"
import { SpreadsheetsItemsFields } from "./spreadsheets-items-fields"
import { spreadsheetsItemsFieldsFragment$key } from "./spreadsheets-items-fields/__generated__/spreadsheetsItemsFieldsFragment.graphql"
import { WorkbookDataFields } from "./workbook-data-fields"
import { connectionSelectorFragment$key } from "./workbook-data-fields/connection-selector/__generated__/connectionSelectorFragment.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Box,
  Button,
  Flex,
  Notification,
  Skeleton,
  Stack,
  Text,
  Transition,
  rem,
} from "@mantine/core"
import { AppDrawer } from "@shared/ui/app-drawer"
import { notifications } from "@shared/ui/notifications"
import { Suspense, useCallback, useEffect, useState } from "react"
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form"
import {
  ConnectionHandler,
  graphql,
  useMutation,
  useQueryLoader,
} from "react-relay"
import { z } from "zod"

enum title {
  "Link Set Up" = 0,
  "Spreadsheet Set Up" = 1,
}

const SELECT_ERROR_MESSAGE = "Please select an option."
const TEXT_ERROR_MESSAGE = "Please fill out this required field."
const TEXT_DUPLICATE_ERROR_MESSAGE = "This Sheet Name already exists."

const CreateWorkbookMutation = graphql`
  mutation spreadsheetsDrawerCreateWorkbookMutation(
    $input: CreateWorkbookIntegrationInput!
    $connections: [ID!]!
  ) {
    createIntegrationWorkbook(input: $input) {
      errors
      workbook
        @appendNode(
          connections: $connections
          edgeTypeName: "WorkbookIntegration"
        ) {
        id
        name
      }
    }
  }
`
const SaveWorkbookSettings = graphql`
  mutation spreadsheetsDrawerCreateSpreadsheetSettingsMutation(
    $input: CreateSpreadsheetSettingsInput!
    $connections: [ID!]!
  ) {
    createSpreadsheetSettings(input: $input) {
      clientMutationId
      errors
      workbook
        @appendNode(connections: $connections, edgeTypeName: "Workbook") {
        bookId
        createdAt
        externalLink
        id
        name
        syncPeriod
        updatedAt
        userConnection {
          user {
            fullName
          }
          id
        }
        workbookSpreadsheets {
          edges {
            node {
              createdAt
              id
              lastSync
              name
              status
              syncPeriod
              spreadsheetable {
                ... on MetabaseQuestion {
                  id
                  metabaseQuestionId
                  name
                  status
                }
                ... on SavedStatement {
                  id
                  name
                  updatedAt
                }
                ... on SavedTransaction {
                  id
                  name
                  updatedAt
                }
              }
            }
          }
        }
      }
    }
  }
`
export const ListSpreadsheetsFromWorkbook = graphql`
  query spreadsheetsDrawerListSpreadsheetsQuery(
    $userConnectionId: ID!
    $workbookIntegrationId: ID!
  ) {
    listSpreadsheetsFromIntegration(
      userConnectionId: $userConnectionId
      workbookIntegrationId: $workbookIntegrationId
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
    getMetabaseQuestions {
      count
      edges {
        node {
          id
          collectionPath
          createdAt
          name
          status
        }
      }
    }
  }
`

const LinkworkbookSchema = z
  .object({
    connection: z
      .string({ invalid_type_error: SELECT_ERROR_MESSAGE })
      .min(1, { message: SELECT_ERROR_MESSAGE }),
    workbookName: z.string().min(1),
    newWorkbookName: z.string().optional(),
    workbook: z
      .string({ invalid_type_error: SELECT_ERROR_MESSAGE })
      .min(1, { message: SELECT_ERROR_MESSAGE }),
    syncPeriod: z.custom<WorkbookSyncPeriodTypeEnum>(),
  })
  .superRefine(({ workbook, newWorkbookName }, context) => {
    if (workbook === "new_workbook" && !newWorkbookName?.trim())
      context.addIssue({
        code: "custom",
        path: ["newWorkbookName"],
        message: TEXT_ERROR_MESSAGE,
      })
  })

const LinkSpreadsheetsSchema = z
  .object({
    spreadsheets: z
      .array(
        z.object({
          spreadsheetable: z
            .string({ invalid_type_error: SELECT_ERROR_MESSAGE })
            .min(1, { message: SELECT_ERROR_MESSAGE }),
          sheet: z
            .string({ invalid_type_error: SELECT_ERROR_MESSAGE })
            .min(1, { message: SELECT_ERROR_MESSAGE }),
          name: z.string().min(1),
          newName: z.string().optional(),
          spreadsheetableType: z.custom<SpreadsheetableEnum>(),
          erroneousSheet: z.string().optional(),
          id: z.string().min(1),
        })
      )
      .nonempty(),
  })
  .superRefine(({ spreadsheets }, context) => {
    spreadsheets.forEach((spreadsheetItem, index) => {
      const spreadsheetsNames = new Set(
        spreadsheets
          .filter(
            (spreadsheet) =>
              spreadsheet.id != spreadsheetItem.id &&
              !!spreadsheet.newName?.trim()
          )
          .flatMap((spreadsheet) => spreadsheet.newName?.trim())
      )
      if (
        spreadsheetItem.sheet === "new_spreadsheet" &&
        !spreadsheetItem.newName?.trim()
      ) {
        context.addIssue({
          code: "custom",
          path: [`spreadsheets.${index}.newName`],
          message: TEXT_ERROR_MESSAGE,
        })
      }
      if (spreadsheetsNames.has(spreadsheetItem.newName?.trim())) {
        context.addIssue({
          code: "custom",
          path: [`spreadsheets.${index}.newName`],
          message: TEXT_DUPLICATE_ERROR_MESSAGE,
        })
      }
    })
  })

export type SpreadsheetsDrawerType = z.infer<
  typeof LinkworkbookSchema & typeof LinkSpreadsheetsSchema
>
type Props = {
  opened: boolean
  connectionsData: connectionSelectorFragment$key
  reportsData: spreadsheetsItemsFieldsFragment$key
  onClose: () => void
}

export const SpreadsheetsDrawer = ({
  onClose,
  opened,
  connectionsData,
  reportsData,
}: Props) => {
  const [step, setStep] = useState<0 | 1>(0)
  const [newWorkbookCreated, setNewWorkbookCreated] = useState<boolean>(false)

  const [createNewWorkbook, createNewWorkbookLoading] =
    useMutation<spreadsheetsDrawerCreateWorkbookMutation>(
      CreateWorkbookMutation
    )
  const [saveSettings, saveSettingsLoading] =
    useMutation<spreadsheetsDrawerCreateSpreadsheetSettingsMutation>(
      SaveWorkbookSettings
    )
  const [spreadsheetsQueryRef, getSpreadsheets] =
    useQueryLoader<spreadsheetsDrawerListSpreadsheetsQuery>(
      ListSpreadsheetsFromWorkbook
    )

  const form = useForm<SpreadsheetsDrawerType>({
    defaultValues: {
      syncPeriod: "MANUALLY",
      workbook: "",
      connection: "",
      workbookName: "",
      newWorkbookName: "",
      spreadsheets: [
        {
          id: crypto.randomUUID(),
          spreadsheetable: "",
          spreadsheetableType: "MetabaseQuestion",
          sheet: "",
        },
      ],
    },
    mode: "all",
    resolver: zodResolver(
      step === 0
        ? LinkworkbookSchema
        : LinkworkbookSchema.and(LinkSpreadsheetsSchema)
    ),
  })

  const { append, remove } = useFieldArray({
    control: form.control,
    name: "spreadsheets",
  })

  const onAppendSpreadsheet = () => {
    append({
      id: crypto.randomUUID(),
      spreadsheetable: "",
      sheet: "",
      name: "",
      spreadsheetableType: "MetabaseQuestion",
    })
    form.getValues("spreadsheets").length === 1 &&
      form.clearErrors(`spreadsheets`)
  }

  const isNewWorkbook =
    form.getValues("workbook") === "new_workbook" || newWorkbookCreated

  const onSubmit: SubmitHandler<SpreadsheetsDrawerType> = (values) => {
    const spreadsheetsValues: Array<SpreadsheetSettingsInput> =
      values.spreadsheets.map((spreadsheet) => ({
        spreadsheetableId: spreadsheet.spreadsheetable,
        spreadsheetId: spreadsheet.sheet,
        spreadsheetName: spreadsheet.name,
        spreadsheetableType: spreadsheet.spreadsheetableType,
        newSpreadsheetName: spreadsheet.newName ?? null,
      }))
    const userWorkbooksId = ConnectionHandler.getConnectionID(
      "root",
      "SpreadsheetsTableFragment_getUserWorkbooks",
      {
        refetchQuestions: true,
      }
    )

    saveSettings({
      variables: {
        input: {
          spreadsheets: spreadsheetsValues,
          syncPeriod: values.syncPeriod,
          userConnectionId: values.connection,
          workbookIntegration: {
            id: values.workbook,
            name: values.newWorkbookName || values.workbookName,
          },
        },
        connections: [userWorkbooksId],
      },
      onCompleted: () => {
        notifications.show({
          title: "Connected Successfully",
          message: "The spreadsheet has been successfully connected.",
          variant: "success",
        })
        form.reset()
        setStep(0)
        setNewWorkbookCreated(false)
        onClose()
      },
      onError: (err) => {
        notifications.show({
          title: "Error",
          message:
            err.message ?? "An error occurred creating spreadsheets settings",
          variant: "success",
        })
        form.reset()
        setStep(0)
        onClose()
      },
    })
  }

  const onContinue = useCallback(() => {
    const newWorkbookName = form.getValues("newWorkbookName")
    const userConnectionId = form.getValues("connection")
    const workbooksIntegrationsConnection = ConnectionHandler.getConnectionID(
      "root",
      "WorkbookDataFields__listWorkbooksFromIntegration",
      { userConnectionId }
    )

    if (!isNewWorkbook) {
      setStep(1)
      return
    }
    if (newWorkbookName)
      createNewWorkbook({
        variables: {
          input: { name: newWorkbookName, userConnectionId },
          connections: [workbooksIntegrationsConnection],
        },
        onCompleted: (response) => {
          const newWorkbookId = response.createIntegrationWorkbook?.workbook?.id
          if (newWorkbookId) {
            getSpreadsheets({
              userConnectionId,
              workbookIntegrationId: newWorkbookId,
            })
            form.setValue("workbook", newWorkbookId)
            setNewWorkbookCreated(true)
            setStep(1)
          }
        },
      })
  }, [createNewWorkbook, form, getSpreadsheets, isNewWorkbook])

  const actionButtons = {
    "0": (
      <Button
        onClick={onContinue}
        w="100%"
        disabled={!form.formState.isValid}
        loading={createNewWorkbookLoading}
      >
        Continue
      </Button>
    ),
    "1": (
      <Flex gap={rem(8)}>
        <Button
          onClick={() => {
            setStep(0)
          }}
          display={isNewWorkbook ? "none" : undefined}
          w="100%"
          variant="outline"
        >
          Back
        </Button>
        <Button
          w="100%"
          type="submit"
          disabled={
            !form.formState.isValid ||
            Object.keys(form.formState.errors).length > 0
          }
          loading={saveSettingsLoading}
        >
          Done
        </Button>
      </Flex>
    ),
  }

  // Form trigger validation for changing step,
  // in order to set the bottom action button disabled or not
  useEffect(() => {
    if (form.formState.dirtyFields.spreadsheets) {
      form.trigger("spreadsheets")
    }
    if (
      form.formState.dirtyFields.connection ||
      form.formState.dirtyFields.workbook ||
      form.formState.dirtyFields.newWorkbookName ||
      form.formState.dirtyFields.workbookName ||
      form.formState.dirtyFields.syncPeriod
    ) {
      form.trigger([
        "connection",
        "syncPeriod",
        "workbook",
        "workbookName",
        "newWorkbookName",
      ])
    }
  }, [form, step])

  return (
    <AppDrawer
      opened={opened}
      size={500}
      title={title[step]}
      onClose={() => {
        setStep(0)
        setNewWorkbookCreated(false)
        onClose()
        form.reset()
      }}
      closeOnClickOutside={!form.formState.isDirty}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} style={{ height: "100%" }}>
          <Stack
            justify="space-between"
            h="calc(100% - 3.5rem)"
            styles={containerStyles}
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <Stack gap={rem(16)}>
              {step === 1 && (
                <Notification
                  withCloseButton={false}
                  color="yellow"
                  withBorder
                  style={{ boxShadow: "var(--mantine-shadow-xs)" }}
                >
                  <Text c="gray" size="sm">
                    Establishing a connection with this spreadsheet will result
                    in the overwrite of existing data.
                  </Text>
                </Notification>
              )}
              <Transition
                mounted={step == 0}
                transition="slide-right"
                duration={200}
                exitDuration={100}
                timingFunction="ease"
                keepMounted
              >
                {(styles) => (
                  <div style={styles}>
                    <WorkbookDataFields
                      isNewWorkbook={isNewWorkbook}
                      connectionsData={connectionsData}
                      onWorkbookChange={(workbook) => {
                        workbook !== "new_workbook" &&
                          getSpreadsheets(
                            {
                              userConnectionId: form.getValues("connection"),
                              workbookIntegrationId: workbook,
                            },
                            { fetchPolicy: "network-only" }
                          )
                      }}
                    />
                  </div>
                )}
              </Transition>
              <Transition
                mounted={step == 1}
                transition="slide-left"
                duration={200}
                exitDuration={100}
                timingFunction="ease"
              >
                {(styles) => (
                  <div style={styles}>
                    <Suspense
                      fallback={
                        <>
                          <Skeleton height={rem(16)} />
                          <Skeleton height={rem(154)} mt={rem(16)} />
                        </>
                      }
                    >
                      {spreadsheetsQueryRef && (
                        <SpreadsheetsItemsFields
                          reportsData={reportsData}
                          isNewWorkbook={isNewWorkbook}
                          onAppendSpreadsheet={onAppendSpreadsheet}
                          onRemoveSpreadsheet={(index) => remove(index)}
                          queryRef={spreadsheetsQueryRef}
                        />
                      )}
                    </Suspense>
                  </div>
                )}
              </Transition>
            </Stack>
            <Box
              style={{
                position: "absolute",
                bottom: rem(16),
                paddingTop: rem(16),
                width: "calc(100% - 2rem)",
              }}
              bg="white"
            >
              {actionButtons[step]}
            </Box>
          </Stack>
        </form>
      </FormProvider>
    </AppDrawer>
  )
}
