/**
 * @generated SignedSource<<1193b66a24d76ef7e182ebe47f05eb00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateIntegrationInput = {
  clientMutationId?: string | null;
  integrationId: string;
  name: string;
};
export type connectionsDrawerUpdateMutation$variables = {
  input: UpdateIntegrationInput;
};
export type connectionsDrawerUpdateMutation$data = {
  readonly updateIntegration: {
    readonly clientMutationId: string | null;
    readonly integration: {
      readonly id: string;
      readonly lastSync: string | null;
      readonly name: string;
    } | null;
  } | null;
};
export type connectionsDrawerUpdateMutation = {
  response: connectionsDrawerUpdateMutation$data;
  variables: connectionsDrawerUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateIntegrationPayload",
    "kind": "LinkedField",
    "name": "updateIntegration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "SystemIntegration",
        "kind": "LinkedField",
        "name": "integration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastSync",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "connectionsDrawerUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "connectionsDrawerUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9fdf2f7a1e5a1e9541c4243599f4fab3",
    "id": null,
    "metadata": {},
    "name": "connectionsDrawerUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation connectionsDrawerUpdateMutation(\n  $input: UpdateIntegrationInput!\n) {\n  updateIntegration(input: $input) {\n    clientMutationId\n    integration {\n      id\n      name\n      lastSync\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "65a8d3d55daa11383670aa499070deec";

export default node;
