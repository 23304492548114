import styles from "./expandable-table.module.scss"
import {
  ActionIcon,
  Group,
  Table as MantineTable,
  Text,
  Tooltip,
} from "@mantine/core"
import {
  IconArrowDown,
  IconArrowUp,
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconArrowsSort,
} from "@tabler/icons-react"
import {
  ColumnDef,
  ExpandedState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useEffect, useState } from "react"

type Props<T> = {
  data: T[]
  columns: ColumnDef<T>[]
  searchQuery?: string
  onRowClick?: (row: T) => void
  miw?: number
  expandedIds?: Record<string, boolean>
}

export function ExpandableTable<T>({
  data,
  columns,
  searchQuery = "",
  onRowClick,
  miw,
  expandedIds,
}: Props<T>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState(searchQuery)
  const [expanded, setExpanded] = useState<ExpandedState>(expandedIds ?? true)

  useEffect(() => {
    setGlobalFilter(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setExpanded(expandedIds ?? true)
  }, [expandedIds])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      expanded,
    },
    globalFilterFn: "auto",
    getSubRows: (row: T) => {
      return (row as T & { accounts: T[] }).accounts
    },
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  })

  const allExpanded = table.getIsAllRowsExpanded()

  return (
    <MantineTable.ScrollContainer
      minWidth="100%"
      type="native"
      style={{
        border: "solid 1px var(--mantine-color-gray-2)",
        borderRadius: "0.5rem",
        boxShadow: "var(--mantine-shadow-xs)",
        scrollbarWidth: "none",
      }}
    >
      <MantineTable
        classNames={{
          table: styles.ExpandableTable,
          th: styles.Th,
          td: styles.Td,
          tr: styles.Tr,
        }}
        withRowBorders={false}
      >
        <MantineTable.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <MantineTable.Tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <MantineTable.Th
                  miw={miw}
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className={[
                    header.column.getCanSort() ? styles["Th--sortable"] : "",
                    header.column.columnDef.meta?.keepCasing
                      ? ""
                      : styles["Th--capitalize"],
                    header.column.columnDef.meta?.className,
                  ].join(" ")}
                  align={header.column.columnDef.meta?.align}
                >
                  {header.isPlaceholder ? null : (
                    <Group
                      gap="0.5rem"
                      justify={
                        header.column.columnDef.meta &&
                        header.column.columnDef.meta.align === "right"
                          ? "flex-end"
                          : "flex-start"
                      }
                    >
                      {header.column.columnDef.header !== "" && (
                        <Text
                          size="sm"
                          fw="bold"
                          ta={header.column.columnDef.meta?.align}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Text>
                      )}

                      {index === 0 && (
                        <Tooltip
                          label={allExpanded ? "Collapse all" : "Expand all"}
                          color="gray.7"
                          withArrow
                          onClick={() => table.toggleAllRowsExpanded()}
                        >
                          <ActionIcon variant="white" color="gray.6">
                            {allExpanded ? (
                              <IconArrowsMinimize size={16} />
                            ) : (
                              <IconArrowsMaximize size={16} />
                            )}
                          </ActionIcon>
                        </Tooltip>
                      )}

                      {header.column.getCanSort() &&
                        ({
                          asc: <IconArrowUp size={12} />,
                          desc: <IconArrowDown size={12} />,
                        }[header.column.getIsSorted() as string] ?? (
                          <IconArrowsSort size={12} />
                        ))}
                    </Group>
                  )}
                </MantineTable.Th>
              ))}
            </MantineTable.Tr>
          ))}
        </MantineTable.Thead>
        <MantineTable.Tbody className={styles["Tbody"]}>
          {table.getRowModel().rows.map((row) => (
            <MantineTable.Tr key={row.id}>
              {row.getVisibleCells().map((cell, index) => {
                return (
                  <MantineTable.Td
                    align={
                      cell.column.columnDef.meta?.cellAlign ??
                      cell.column.columnDef.meta?.align ??
                      "left"
                    }
                    key={cell.id}
                    onClick={() =>
                      !cell.column.columnDef.meta?.disableRowClick &&
                      onRowClick?.(row.original)
                    }
                    className={[
                      onRowClick && !cell.column.columnDef.meta?.disableRowClick
                        ? styles["Td--clickable"]
                        : "",
                      cell.column.columnDef.meta?.className,
                      cell.column.columnDef.meta?.getCellContext != undefined &&
                        cell.column.columnDef.meta?.getCellContext(
                          cell.getContext()
                        ),
                      index > 0 && styles["Td--hoverable"],
                    ].join(" ")}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </MantineTable.Td>
                )
              })}
            </MantineTable.Tr>
          ))}
        </MantineTable.Tbody>
      </MantineTable>
    </MantineTable.ScrollContainer>
  )
}
