/**
 * @generated SignedSource<<2e03f231cc633b9ba41d8c1684975f80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type workbookDataFieldsListWorkbooksQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  userConnectionId: string;
};
export type workbookDataFieldsListWorkbooksQuery$data = {
  readonly listWorkbooksFromIntegration: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isLinked: boolean;
        readonly name: string;
      };
    }>;
  };
};
export type workbookDataFieldsListWorkbooksQuery = {
  response: workbookDataFieldsListWorkbooksQuery$data;
  variables: workbookDataFieldsListWorkbooksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userConnectionId"
},
v3 = {
  "kind": "Variable",
  "name": "userConnectionId",
  "variableName": "userConnectionId"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "WorkbookIntegrationEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkbookIntegration",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLinked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "workbookDataFieldsListWorkbooksQuery",
    "selections": [
      {
        "alias": "listWorkbooksFromIntegration",
        "args": [
          (v3/*: any*/)
        ],
        "concreteType": "WorkbookIntegrationConnection",
        "kind": "LinkedField",
        "name": "__WorkbookDataFields__listWorkbooksFromIntegration_connection",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "workbookDataFieldsListWorkbooksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "WorkbookIntegrationConnection",
        "kind": "LinkedField",
        "name": "listWorkbooksFromIntegration",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "userConnectionId"
        ],
        "handle": "connection",
        "key": "WorkbookDataFields__listWorkbooksFromIntegration",
        "kind": "LinkedHandle",
        "name": "listWorkbooksFromIntegration"
      }
    ]
  },
  "params": {
    "cacheID": "c815242bbf47a2d8cde2273297e81e10",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "listWorkbooksFromIntegration"
          ]
        }
      ]
    },
    "name": "workbookDataFieldsListWorkbooksQuery",
    "operationKind": "query",
    "text": "query workbookDataFieldsListWorkbooksQuery(\n  $cursor: String\n  $count: Int\n  $userConnectionId: ID!\n) {\n  listWorkbooksFromIntegration(first: $count, after: $cursor, userConnectionId: $userConnectionId) {\n    count\n    edges {\n      node {\n        id\n        name\n        isLinked\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4696a9de0cd80f36077b23514158aaf3";

export default node;
