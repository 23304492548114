import { removeUserModalRemoveUserFromFirmMutation as RemoveUserModalRemoveUserFromFirmMutationType } from "./__generated__/removeUserModalRemoveUserFromFirmMutation.graphql"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { graphql, useMutation } from "react-relay"

const RemoveUserModalRemoveUserFromFirmMutation = graphql`
  mutation removeUserModalRemoveUserFromFirmMutation(
    $input: RemoveUserFromFirmInput!
  ) {
    removeUserFromFirm(input: $input) {
      success
      firm {
        ...firmUsersTableFragment
      }
    }
  }
`

type Props = {
  userId: string
  opened: boolean
  onClose: () => void
}

export const RemoveUserModal = ({ userId, opened, onClose }: Props) => {
  const [unassignUserMutation, unassignUserLoading] =
    useMutation<RemoveUserModalRemoveUserFromFirmMutationType>(
      RemoveUserModalRemoveUserFromFirmMutation
    )

  const handleDeleteClick = () => {
    unassignUserMutation({
      variables: {
        input: {
          userId,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "User removed successfully!",
          message: "Access permissions successfully revoked.",
        })
        onClose()
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
      },
    })
  }

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to remove this user?"
        opened={opened}
        onClose={onClose}
        cancelButtonProps={{
          onClick: onClose,
        }}
        confirmButtonProps={{
          onClick: handleDeleteClick,
          children: "Remove User",
          color: "red",
          loading: unassignUserLoading,
        }}
      >
        Removing this user will not result in the loss of generated data or any
        connections; only access permissions will be revoked.
      </ConfirmationModal>
    </>
  )
}
