/**
 * @generated SignedSource<<e8ec6d4e84882edeecd1a3f35859c70b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type signUpGetOnboardingInfoQuery$variables = {
  token: string;
};
export type signUpGetOnboardingInfoQuery$data = {
  readonly getOnboardingInfo: {
    readonly errors: ReadonlyArray<string> | null;
    readonly user: {
      readonly isFirmOwner: boolean;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ownerSignUpFragment" | "userSignUpFragment">;
  } | null;
};
export type signUpGetOnboardingInfoQuery = {
  response: signUpGetOnboardingInfoQuery$data;
  variables: signUpGetOnboardingInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "invitationToken",
    "variableName": "token"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFirmOwner",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signUpGetOnboardingInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetOnboardingInfo",
        "kind": "LinkedField",
        "name": "getOnboardingInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "userSignUpFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ownerSignUpFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signUpGetOnboardingInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetOnboardingInfo",
        "kind": "LinkedField",
        "name": "getOnboardingInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Firm",
                "kind": "LinkedField",
                "name": "firm",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97b479759c39cec6d6da33d4e242bdf6",
    "id": null,
    "metadata": {},
    "name": "signUpGetOnboardingInfoQuery",
    "operationKind": "query",
    "text": "query signUpGetOnboardingInfoQuery(\n  $token: String!\n) {\n  getOnboardingInfo(invitationToken: $token) {\n    errors\n    user {\n      isFirmOwner\n      id\n    }\n    ...userSignUpFragment\n    ...ownerSignUpFragment\n  }\n}\n\nfragment ownerSignUpFragment on GetOnboardingInfo {\n  user {\n    isFirmOwner\n    firm {\n      logo\n      name\n      id\n    }\n    avatar\n    email\n    fullName\n    id\n  }\n}\n\nfragment userSignUpFragment on GetOnboardingInfo {\n  user {\n    isFirmOwner\n    firm {\n      name\n      id\n    }\n    avatar\n    email\n    fullName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fa7eb08d5fb13e61ea82782f66113ff";

export default node;
