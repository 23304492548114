/**
 * @generated SignedSource<<91da4e6714f904e91390012fba1f6ac9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type profileSettingsGetCurrentUserDataQuery$variables = {};
export type profileSettingsGetCurrentUserDataQuery$data = {
  readonly getCurrentUserData: {
    readonly errors: ReadonlyArray<string> | null;
    readonly user: {
      readonly avatar: string | null;
      readonly createdAt: string;
      readonly email: string;
      readonly firstName: string | null;
      readonly id: string;
      readonly isFirmAdmin: boolean;
      readonly lastName: string | null;
      readonly role: string | null;
    };
  };
};
export type profileSettingsGetCurrentUserDataQuery = {
  response: profileSettingsGetCurrentUserDataQuery$data;
  variables: profileSettingsGetCurrentUserDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CurrentUser",
    "kind": "LinkedField",
    "name": "getCurrentUserData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFirmAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatar",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "profileSettingsGetCurrentUserDataQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "profileSettingsGetCurrentUserDataQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ca53123c77578e57f6adafc38281e7e9",
    "id": null,
    "metadata": {},
    "name": "profileSettingsGetCurrentUserDataQuery",
    "operationKind": "query",
    "text": "query profileSettingsGetCurrentUserDataQuery {\n  getCurrentUserData {\n    errors\n    user {\n      id\n      firstName\n      lastName\n      isFirmAdmin\n      role\n      email\n      createdAt\n      avatar\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "65d9fae8a386c0e00ab57cd342912c18";

export default node;
