/**
 * @generated SignedSource<<0af5350f488ecbb276f0e22cd61e65af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StatusIntegrationTypeEnum = "BROKEN" | "INACTIVE" | "ONLINE" | "SYNCING" | "SYNC_FAILED";
export type connectionsDrawerQuery$variables = {
  input: string;
};
export type connectionsDrawerQuery$data = {
  readonly getIntegrationDetails: {
    readonly creator: {
      readonly fullName: string | null;
    };
    readonly domain: string | null;
    readonly id: string;
    readonly lastSync: string | null;
    readonly name: string;
    readonly status: StatusIntegrationTypeEnum;
    readonly system: {
      readonly category: string;
      readonly name: string;
      readonly slug: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"deleteButtonFragment" | "syncButtonFragment">;
  } | null;
};
export type connectionsDrawerQuery = {
  response: connectionsDrawerQuery$data;
  variables: connectionsDrawerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "integrationId",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastSync",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "connectionsDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SystemIntegration",
        "kind": "LinkedField",
        "name": "getIntegrationDetails",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "System",
            "kind": "LinkedField",
            "name": "system",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "syncButtonFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "deleteButtonFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "connectionsDrawerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SystemIntegration",
        "kind": "LinkedField",
        "name": "getIntegrationDetails",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "System",
            "kind": "LinkedField",
            "name": "system",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cf31b2c3baeaed12de300f2d7da7b4e2",
    "id": null,
    "metadata": {},
    "name": "connectionsDrawerQuery",
    "operationKind": "query",
    "text": "query connectionsDrawerQuery(\n  $input: ID!\n) {\n  getIntegrationDetails(integrationId: $input) {\n    id\n    name\n    status\n    lastSync\n    system {\n      slug\n      name\n      category\n      id\n    }\n    domain\n    creator {\n      fullName\n      id\n    }\n    ...syncButtonFragment\n    ...deleteButtonFragment\n  }\n}\n\nfragment deleteButtonFragment on SystemIntegration {\n  id\n  status\n  name\n}\n\nfragment syncButtonFragment on SystemIntegration {\n  id\n  status\n  system {\n    name\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "85957a5ebd62c85e7a080668ea64b76b";

export default node;
