import { Badge, BadgeProps, Group } from "@mantine/core"
import { IconAlertTriangle } from "@tabler/icons-react"

type Props = BadgeProps & {
  label: string
}

export const ErrorBadge = ({ label }: Props) => {
  return (
    <Badge variant="light" color="red" fw={400} tt="capitalize" fz={"xs"}>
      <Group gap="0.5rem" wrap="nowrap">
        {label}
        <IconAlertTriangle size={12} />
      </Group>
    </Badge>
  )
}
