import { oauthRedirectorQuery as OauthRedirectorQueryType } from "./__generated__/oauthRedirectorQuery.graphql"
import { useEffect } from "react"
import { PreloadedQuery, graphql, usePreloadedQuery } from "react-relay"

export const OauthRedirectorQuery = graphql`
  query oauthRedirectorQuery($input: IntegrationTypeEnum!) {
    getIntegrationsOauthUrl(connectionType: $input) {
      errors
      url
    }
  }
`

type Props = {
  queryRef: PreloadedQuery<OauthRedirectorQueryType>
}

export const OauthRedirector = ({ queryRef }: Props) => {
  const { getIntegrationsOauthUrl } = usePreloadedQuery(
    OauthRedirectorQuery,
    queryRef
  )

  useEffect(() => {
    if (getIntegrationsOauthUrl?.url) {
      window.location.href = getIntegrationsOauthUrl.url
    }
  }, [getIntegrationsOauthUrl?.url])

  return <></>
}
