import { firmsTableFragment$key } from "./__generated__/firmsTableFragment.graphql"
import { DeleteFirmModal } from "./delete-firm-modal"
import { ImpersonateButton } from "./impersonate-button"
import { ActionIcon, Group, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { Table } from "@shared/ui/table"
import { getFormattedDate } from "@shared/utils/helpers"
import { IconTrash } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

const FirmsTableFragment = graphql`
  fragment firmsTableFragment on Query
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    getFirms(first: $count, after: $cursor)
      @connection(key: "FirmsTableFragment_getFirms") {
      edges {
        node {
          id
          name
          createdAt
          creator {
            id
            fullName
            email
            invitationAcceptedAt
          }
          id
          users {
            count
          }
        }
      }
    }
  }
`

type Firm = {
  id: string
  created_at: Date
  owner: string
  ownerId: string
  isRegistered: boolean
  email: string
  users: number
  name: string
}

type Props = {
  firms: firmsTableFragment$key
}

export const FirmsTable = ({ firms }: Props) => {
  const {
    getFirms: { edges },
  } = useFragment(FirmsTableFragment, firms)

  const { userId } = useUserStore()

  const [
    deleteFirmModalOpened,
    { open: deleteFirmModalOpen, close: deleteFirmModalClose },
  ] = useDisclosure(false)

  const [firmToRemove, setFirmToRemove] = useState({ id: "", name: "" })

  const handleRemoveFirmAction = (firmId: string, firmName: string) => {
    setFirmToRemove({ id: firmId, name: firmName })
    deleteFirmModalOpen()
  }

  const columns: ColumnDef<Firm>[] = [
    {
      header: "Creation Day",
      accessorKey: "created_at",
      cell: (info) => getFormattedDate(info.getValue<Date>()),
    },
    {
      header: "Owner name",
      accessorKey: "owner",
    },
    {
      header: "E-mail",
      accessorKey: "email",
      meta: {
        keepCasing: true,
      },
    },
    {
      header: "Users",
      accessorKey: "users",
      meta: {
        cellAlign: "right",
      },
    },
    {
      header: "Firm Name",
      accessorKey: "name",
      cell: (info) => {
        const firmName = info.getValue<string>()

        return <Text size="sm">{firmName}</Text>
      },
    },
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: (info) => {
        const firmId = info.row.original.id
        const firmName = info.row.original.name

        return (
          <Group w="100%" justify="flex-end">
            {userId !== info.row.original.ownerId &&
              info.row.original.isRegistered && (
                <ImpersonateButton userId={info.row.original.ownerId} />
              )}
            <ActionIcon
              variant="transparent"
              aria-label="Settings"
              onClick={() => handleRemoveFirmAction(firmId, firmName)}
            >
              <IconTrash
                size={16}
                stroke={2}
                color={themeVars.colors.gray[6]}
              />
            </ActionIcon>
          </Group>
        )
      },
    },
  ]

  const tableData: Firm[] = edges.map((firm) => ({
    id: firm.node.id,
    created_at: new Date(firm.node.createdAt),
    email: firm.node.creator?.email ?? "--",
    name: firm.node.name ?? "--",
    owner: firm.node.creator?.fullName ?? "--",
    ownerId: firm.node.creator?.id ?? "",
    isRegistered: !!firm.node.creator?.invitationAcceptedAt,
    users: firm.node.users.count,
  }))

  return (
    <>
      <Table columns={columns} data={tableData} />
      <DeleteFirmModal
        firmData={firmToRemove}
        opened={deleteFirmModalOpened}
        onClose={deleteFirmModalClose}
      />
    </>
  )
}
