/**
 * @generated SignedSource<<20f901dc521310ab8802300e7d22af10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type shareButtonGetSharedDashboardQuery$variables = {
  metabaseDashboardId: number;
};
export type shareButtonGetSharedDashboardQuery$data = {
  readonly getSharedDashboard: {
    readonly sharedDashboard: {
      readonly id: string;
      readonly metabaseDashboardId: number;
      readonly name: string;
      readonly url: string;
      readonly uuid: string;
    } | null;
  };
};
export type shareButtonGetSharedDashboardQuery = {
  response: shareButtonGetSharedDashboardQuery$data;
  variables: shareButtonGetSharedDashboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "metabaseDashboardId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "metabaseDashboardId",
        "variableName": "metabaseDashboardId"
      }
    ],
    "concreteType": "GetSharedDashboardData",
    "kind": "LinkedField",
    "name": "getSharedDashboard",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SharedDashboard",
        "kind": "LinkedField",
        "name": "sharedDashboard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metabaseDashboardId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uuid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "shareButtonGetSharedDashboardQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "shareButtonGetSharedDashboardQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0a58efd96e6531085c90380a15c04f13",
    "id": null,
    "metadata": {},
    "name": "shareButtonGetSharedDashboardQuery",
    "operationKind": "query",
    "text": "query shareButtonGetSharedDashboardQuery(\n  $metabaseDashboardId: Int!\n) {\n  getSharedDashboard(metabaseDashboardId: $metabaseDashboardId) {\n    sharedDashboard {\n      id\n      metabaseDashboardId\n      name\n      uuid\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe51f6884915b035d37c90eaa6acebff";

export default node;
