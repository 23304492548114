import { membersTableFirmInfoFragment$key } from "./__generated__/membersTableFirmInfoFragment.graphql"
import { membersTableFragment$key } from "./__generated__/membersTableFragment.graphql"
import { RemoveMemberModal } from "./remove-member-modal"
import { ActionIcon, Avatar, Group, Text, rem } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { DashedCircle } from "@shared/ui/dashed-circle"
import { Table } from "@shared/ui/table"
import { getAvatarText } from "@shared/utils/helpers"
import { IconTrash } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { useState } from "react"
import { graphql, useFragment } from "react-relay"

const MembersTableFragment = graphql`
  fragment membersTableFragment on Client
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    members(first: $count, after: $cursor)
      @connection(key: "MembersTableFragment_members") {
      edges {
        node {
          id
          firstName
          lastName
          email
          avatar
          isFirmAdmin
          role
          invitationAcceptedAt
        }
      }
    }
  }
`

const MembersTableFirmInfoFragment = graphql`
  fragment membersTableFirmInfoFragment on Firm
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    users(first: $count, after: $cursor)
      @connection(key: "MembersTableFirmInfoFragment_users") {
      edges {
        node {
          id
          firstName
          lastName
          email
          avatar
          isFirmAdmin
          role
          invitationAcceptedAt
        }
      }
    }
  }
`

type Members = {
  id: string
  avatar: string
  email: string
  role: string
  name: string
  jobTitle: string
  isAdmin: boolean
  isRegistered: boolean
}

type Props = {
  members: membersTableFragment$key
  firm: membersTableFirmInfoFragment$key
}

export const MembersTable = ({ members, firm }: Props) => {
  const { currentClient, isAdmin } = useUserStore()

  const [
    confirmationOpened,
    { open: confirmationOpen, close: confirmationClose },
  ] = useDisclosure(false)

  const [userToRemove, setUserToRemove] = useState("")

  const {
    members: { edges: currentMembers },
  } = useFragment(MembersTableFragment, members)

  const {
    users: { edges: firmUsers },
  } = useFragment(MembersTableFirmInfoFragment, firm)

  const formattedFirmUsers = firmUsers
    .map(({ node }) => node)
    .filter(({ isFirmAdmin }) => isFirmAdmin)
  const formattedCurrentMembers = currentMembers
    .map(({ node }) => node)
    .filter(({ isFirmAdmin }) => !isFirmAdmin)

  const clientMembers = [...formattedFirmUsers, ...formattedCurrentMembers]

  const tableData: Members[] = clientMembers.map((member) => ({
    id: member.id,
    avatar: member.avatar ?? "",
    email: member.email,
    role: member.isFirmAdmin ? "Admin" : "Member",
    name: `${member.firstName ?? "Pending"} ${member.lastName ?? ""}`,
    jobTitle: member.role ?? "--",
    isAdmin: member.isFirmAdmin,
    isRegistered: !!member.invitationAcceptedAt,
  }))

  const handleRemoveMemberAction = (userId: string) => {
    setUserToRemove(userId)
    confirmationOpen()
  }

  const columns: ColumnDef<Members>[] = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info) => {
        const memberName = info.getValue<string>()
        const avatar = info.row.original.avatar
        const isRegistered = info.row.original.isRegistered

        if (isRegistered) {
          return (
            <Group gap={rem(8)}>
              <Avatar src={avatar} radius="xl" size="xs">
                {getAvatarText(memberName)}
              </Avatar>
              <Text size="sm" c="gray.7">
                {memberName}
              </Text>
            </Group>
          )
        }

        return (
          <Group gap={rem(8)}>
            <DashedCircle />
            <Text size="sm" c="gray.5">
              --
            </Text>
          </Group>
        )
      },
    },
    {
      header: "E-mail",
      accessorKey: "email",
      meta: {
        keepCasing: true,
      },
      cell: (info) => {
        const memberEmail = info.getValue<string>()
        const isRegistered = info.row.original.isRegistered

        return (
          <Text size="sm" c={isRegistered ? "gray.7" : "gray.5"}>
            {memberEmail}
          </Text>
        )
      },
    },
    {
      header: "Role",
      accessorKey: "role",
      cell: (info) => {
        const memberRole = info.getValue<string>()
        const isRegistered = info.row.original.isRegistered

        return (
          <Text size="sm" c={isRegistered ? "gray.7" : "gray.5"}>
            {memberRole}
          </Text>
        )
      },
    },
    {
      header: "Job Title",
      accessorKey: "jobRole",
      cell: (info) => {
        const isRegistered = info.row.original.isRegistered
        const memberJobRole = isRegistered ? info.getValue<string>() : "--"

        return (
          <Text size="sm" c={isRegistered ? "gray.7" : "gray.5"}>
            {memberJobRole}
          </Text>
        )
      },
    },
    {
      header: "",
      accessorKey: "isAdmin",
      enableSorting: false,
      cell: (info) => {
        const userId = info.row.original.id
        const memberIsAdmin = info.getValue<boolean>()

        if (isAdmin && !memberIsAdmin) {
          return (
            <ActionIcon
              variant="white"
              c="gray.6"
              onClick={() => handleRemoveMemberAction(userId)}
            >
              <IconTrash size="16px" />
            </ActionIcon>
          )
        }

        return <></>
      },
    },
  ]

  return (
    <>
      <Table columns={columns} data={tableData} />
      <RemoveMemberModal
        userId={userToRemove}
        clientSlug={currentClient.slug ?? ""}
        opened={confirmationOpened}
        onClose={confirmationClose}
      />
    </>
  )
}
