/**
 * @generated SignedSource<<3c5594942058ec3ff1b621f43a39a8f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CashFlowCategoryEnum = "FINANCING" | "INVESTING" | "OPERATING";
export type balanceAccountsTableQuery$variables = {
  accountTypes?: ReadonlyArray<string> | null;
  cashFlowCategories?: ReadonlyArray<CashFlowCategoryEnum> | null;
  connectionIds: ReadonlyArray<string>;
  count?: number | null;
  cursor?: string | null;
  includeInCashFlow?: boolean | null;
  limit?: number | null;
  pageNumber?: number | null;
  search?: string | null;
};
export type balanceAccountsTableQuery$data = {
  readonly getBalanceAccounts: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountType: string | null;
        readonly cashFlowCategory: CashFlowCategoryEnum | null;
        readonly id: string;
        readonly includeInCashFlow: boolean | null;
        readonly name: string | null;
      };
    }>;
    readonly totalCount: number | null;
  };
};
export type balanceAccountsTableQuery = {
  response: balanceAccountsTableQuery$data;
  variables: balanceAccountsTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountTypes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cashFlowCategories"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connectionIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeInCashFlow"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "limit"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageNumber"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v9 = {
  "kind": "Variable",
  "name": "accountTypes",
  "variableName": "accountTypes"
},
v10 = {
  "kind": "Variable",
  "name": "cashFlowCategories",
  "variableName": "cashFlowCategories"
},
v11 = {
  "kind": "Variable",
  "name": "connectionIds",
  "variableName": "connectionIds"
},
v12 = {
  "kind": "Variable",
  "name": "includeInCashFlow",
  "variableName": "includeInCashFlow"
},
v13 = {
  "kind": "Variable",
  "name": "limit",
  "variableName": "limit"
},
v14 = {
  "kind": "Variable",
  "name": "pageNumber",
  "variableName": "pageNumber"
},
v15 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LedgerAccountEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LedgerAccount",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cashFlowCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "includeInCashFlow",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v17 = [
  (v9/*: any*/),
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v10/*: any*/),
  (v11/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "balanceAccountsTableQuery",
    "selections": [
      {
        "alias": "getBalanceAccounts",
        "args": [
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "concreteType": "LedgerAccountsConnection",
        "kind": "LinkedField",
        "name": "__BalanceAccountsTableQuery_getBalanceAccounts_connection",
        "plural": false,
        "selections": (v16/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "balanceAccountsTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "LedgerAccountsConnection",
        "kind": "LinkedField",
        "name": "getBalanceAccounts",
        "plural": false,
        "selections": (v16/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "filters": [
          "connectionIds",
          "accountTypes",
          "cashFlowCategories",
          "includeInCashFlow",
          "search",
          "pageNumber",
          "limit"
        ],
        "handle": "connection",
        "key": "BalanceAccountsTableQuery_getBalanceAccounts",
        "kind": "LinkedHandle",
        "name": "getBalanceAccounts"
      }
    ]
  },
  "params": {
    "cacheID": "c708bcc0604de9cb5fa5e10a0081ffde",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "getBalanceAccounts"
          ]
        }
      ]
    },
    "name": "balanceAccountsTableQuery",
    "operationKind": "query",
    "text": "query balanceAccountsTableQuery(\n  $cursor: String\n  $count: Int\n  $connectionIds: [ID!]!\n  $accountTypes: [String!]\n  $cashFlowCategories: [CashFlowCategoryEnum!]\n  $includeInCashFlow: Boolean\n  $search: String\n  $pageNumber: Int\n  $limit: Int\n) {\n  getBalanceAccounts(connectionIds: $connectionIds, accountTypes: $accountTypes, cashFlowCategories: $cashFlowCategories, includeInCashFlow: $includeInCashFlow, first: $count, after: $cursor, search: $search, pageNumber: $pageNumber, limit: $limit) {\n    count\n    totalCount\n    edges {\n      node {\n        id\n        name\n        accountType\n        cashFlowCategory\n        includeInCashFlow\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d14a06eaa7b030dcddb89247f3dc8a64";

export default node;
