import { SpreadsheetsDrawerType } from "../spreadsheets-drawer"
import { workbookDataFieldsListWorkbooksQuery } from "./__generated__/workbookDataFieldsListWorkbooksQuery.graphql"
import { ConnectionSelector } from "./connection-selector"
import { connectionSelectorFragment$key } from "./connection-selector/__generated__/connectionSelectorFragment.graphql"
import { WorkbookSelector } from "./workbook-selector"
import { Loader, Stack, TextInput, Transition, rem } from "@mantine/core"
import authEnvironment from "@shared/relay/environment/auth-environment"
import { notifications } from "@shared/ui/notifications"
import { Select } from "@shared/ui/select"
import { Suspense, useCallback } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Controller, useFormContext } from "react-hook-form"
import { commitLocalUpdate, graphql, useQueryLoader } from "react-relay"

type Props = {
  isNewWorkbook?: boolean
  connectionsData: connectionSelectorFragment$key
  onWorkbookChange: (val: string) => void
}

export const ListWorkbookFromIntegration = graphql`
  query workbookDataFieldsListWorkbooksQuery(
    $cursor: String
    $count: Int
    $userConnectionId: ID!
  ) {
    listWorkbooksFromIntegration(
      first: $count
      after: $cursor
      userConnectionId: $userConnectionId
    ) @connection(key: "WorkbookDataFields__listWorkbooksFromIntegration") {
      count
      edges {
        node {
          id
          name
          isLinked
        }
      }
    }
  }
`
export const WorkbookDataFields = ({
  isNewWorkbook = false,
  connectionsData,
  onWorkbookChange,
}: Props) => {
  const {
    control,
    watch,
    resetField,
    formState,
    register,
    trigger,
    getValues,
    setValue,
  } = useFormContext<SpreadsheetsDrawerType>()
  const [queryReference, loadQuery] =
    useQueryLoader<workbookDataFieldsListWorkbooksQuery>(
      ListWorkbookFromIntegration
    )

  const onConnectionsErrorsHandler = useCallback(() => {
    commitLocalUpdate(authEnvironment, (store) => {
      store.get(getValues("connection"))?.setValue("BROKEN", "status")
    })
    notifications.show({
      message: "Something went wrong, check your personal connections settings",
      variant: "error",
    })
    setValue("connection", "")
  }, [getValues, setValue])

  return (
    <Stack
      gap={rem(16)}
      style={{ position: "absolute", width: "100%", top: 0 }}
    >
      <ConnectionSelector
        data={connectionsData}
        onChange={(val) =>
          loadQuery({ userConnectionId: val }, { fetchPolicy: "network-only" })
        }
      />
      <Controller
        control={control}
        name="workbook"
        render={({ field: { onChange, onBlur, value } }) => (
          <ErrorBoundary
            fallbackRender={() => <Select label="Workbook" disabled />}
            onError={onConnectionsErrorsHandler}
          >
            <Suspense
              fallback={
                <Select
                  disabled
                  label="Workbook"
                  value={value ?? ""}
                  leftSection={<Loader size={"1rem"} />}
                ></Select>
              }
            >
              {queryReference ? (
                <WorkbookSelector
                  onChange={(val) => {
                    onChange(val)
                    trigger("workbook")
                    resetField("spreadsheets")
                    resetField("newWorkbookName")
                    watch("spreadsheets")
                    onWorkbookChange(val)
                  }}
                  onBlur={onBlur}
                  inputProps={{
                    error: formState.errors.workbook?.message,
                    label: "Workbook",
                  }}
                  value={value ?? ""}
                  queryReference={queryReference}
                />
              ) : (
                <Select label="Workbook" value={value ?? ""} disabled></Select>
              )}
            </Suspense>
          </ErrorBoundary>
        )}
      />
      <Transition
        mounted={isNewWorkbook}
        transition="slide-down"
        duration={200}
        exitDuration={100}
        timingFunction="ease"
      >
        {(stylesNewWorkbook) => (
          <div style={stylesNewWorkbook}>
            <TextInput
              {...register("newWorkbookName")}
              label="New Workbook Name"
              error={formState.errors.newWorkbookName?.message}
            />
          </div>
        )}
      </Transition>
      <Controller
        control={control}
        name="syncPeriod"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            ref={ref}
            defaultValue={"Manually"}
            allowDeselect={false}
            label="Sync Period"
            error={formState.errors.syncPeriod?.message}
            onChange={onChange}
            value={value ?? ""}
            onBlur={onBlur}
            data={[
              { label: "Manually", value: "MANUALLY" },
              { label: "Daily", value: "DAILY" },
            ]}
          />
        )}
      />
    </Stack>
  )
}
