import classes from "./filter-multiselect.module.scss"
import {
  Badge,
  Checkbox,
  Group,
  Input,
  MantineProvider,
  Popover,
  Stack,
  Text,
  createTheme,
  rem,
} from "@mantine/core"
import { IconChevronDown } from "@tabler/icons-react"

const theme = createTheme({
  cursorType: "pointer",
})
type Props = {
  values: string[]
  options: {
    label: string
    key: string
  }[]
  noCheckedLabel?: React.ReactNode
  onChange: (values: string[]) => void
}

export const FilterMultiselect = ({
  noCheckedLabel = "",
  options,
  values,
  onChange,
}: Props) => {
  const allChecked = values.length === options.length
  const indeterminate = values.length > 0 && !allChecked

  const items = options.map((value) => (
    <Checkbox
      key={value.key}
      checked={values.includes(value.key)}
      label={value.label}
      classNames={classes}
      onChange={() =>
        onChange(
          values.includes(value.key)
            ? values.filter((val) => val != value.key)
            : [...values, value.key]
        )
      }
      ml={"2rem"}
    />
  ))

  return (
    <Popover position="bottom-start" shadow="md">
      <Popover.Target>
        <Input
          type="button"
          component="button"
          pointer
          rightSection={<IconChevronDown size={16} />}
        >
          <Group gap={rem(8)}>
            <Text truncate="end" size="sm">
              {noCheckedLabel}
            </Text>
            <Badge
              size="xs"
              w={rem(42)}
              bg={values.length > 0 ? "blue.0" : "transparent"}
              c={"indigo.6"}
            >
              <Text size="xxs" fw={500}>
                {allChecked ? "All" : values.length > 0 ? values.length : ""}
              </Text>
            </Badge>
          </Group>
        </Input>
      </Popover.Target>
      <Popover.Dropdown p={".75rem"} py={"10px"} miw={rem(236)} maw={rem(480)}>
        <MantineProvider theme={theme}>
          <Stack gap={0}>
            <Checkbox
              checked={allChecked}
              label="Select All"
              indeterminate={indeterminate}
              classNames={classes}
              onChange={() =>
                onChange(allChecked ? [] : options.map(({ key }) => key))
              }
            />
            {items}
          </Stack>
        </MantineProvider>
      </Popover.Dropdown>
    </Popover>
  )
}
