import { notifications } from "@mantine/notifications"

export enum AmountRateEnum {
  BETWEEN = "between",
  EQUAL_TO = "equal_to",
  GREATER_THAN = "greater_than",
  GREATER_THAN_OR_EQUAL_TO = "greater_than_or_equal_to",
  LESS_THAN = "less_than",
  LESS_THAN_OR_EQUAL_TO = "less_than_or_equal_to",
}

export type GenerateTableReportProps = {
  systemConnectionIds: string[]
  clientId: string | null
  filename: string
  accountIds?: string[]
  vendorIds?: string[]
  customerIds?: string[]
  classIds?: string[]
  startDate?: string
  endDate?: string
  maxRange?: number
  minRange?: number
  search?: string
  amountRate?: AmountRateEnum
  limit?: number
  pageNumber?: number
}

export const generateXlsxReport = async (
  bodyRequest: GenerateTableReportProps,
  alpyneToken: string
) => {
  await fetch("/download_reports", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + alpyneToken,
    },
    body: JSON.stringify({
      exportType: "transactions",
      exportParams: {
        ...bodyRequest,
        amountRate: bodyRequest.amountRate?.toLowerCase(),
      },
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.blob()
      } else {
        throw new TypeError("Error creating report.")
      }
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = bodyRequest.filename + ".xlsx"
      document.body.append(a)
      a.click()
      return true
    })
    .catch(() => {
      notifications.show({
        title: "There was an error creating the report.",
        message: "Please try again",
        variant: "error",
      })
    })
}
