import { firmUsersTableFragment$key } from "./__generated__/firmUsersTableFragment.graphql"
import { RemoveUserModal } from "./remove-user-modal/remove-user-modal"
import { ActionIcon, Avatar, Box, Flex, Group, Text, rem } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { DashedCircle } from "@shared/ui/dashed-circle"
import { Table } from "@shared/ui/table"
import { getAvatarText, getClientMantineColor } from "@shared/utils/helpers"
import { IconTrash } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { useMemo, useState } from "react"
import { graphql, useFragment } from "react-relay"

type ClientInfoType = {
  color: string
  name: string
  logo: string | null
}
type FirmDataTable = {
  name: string
  email: string
  avatar: string | null
  isRegistered: boolean
  clients: ClientInfoType[]
  role: string
  isOwner: boolean
  id: string
}

const FirmSettingsFragment = graphql`
  fragment firmUsersTableFragment on Firm
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    users(first: $count, after: $cursor)
      @connection(key: "firmUsersTableFragment_users") {
      edges {
        node {
          avatar
          createdAt
          email
          fullName
          invitationAcceptedAt
          id
          isFirmAdmin
          isFirmOwner
          clients {
            nodes {
              name
              color
              logo
            }
          }
        }
      }
    }
  }
`
type Props = {
  firmSettings: firmUsersTableFragment$key
}
export const FirmUsersTable = ({ firmSettings }: Props) => {
  const {
    users: { edges },
  } = useFragment(FirmSettingsFragment, firmSettings)
  const { isAdmin } = useUserStore()

  const [
    confirmationOpened,
    { open: confirmationOpen, close: confirmationClose },
  ] = useDisclosure(false)

  const [userToRemove, setUserToRemove] = useState("")

  const handleRemoveMemberAction = (userId: string) => {
    setUserToRemove(userId)
    confirmationOpen()
  }

  const tableData: FirmDataTable[] = useMemo(
    () =>
      edges?.map((user) => ({
        id: user?.node.id ?? "",
        name: user?.node.fullName ?? "",
        email: user?.node.email ?? "",
        isOwner: user?.node.isFirmOwner,
        clients:
          [...(user?.node.clients.nodes ?? [])].map((client) => ({
            name: client.name[0] ?? "",
            color: getClientMantineColor(client.color),
            logo: client.logo ?? null,
          })) ?? [],
        role: user?.node.isFirmAdmin ? "Admin" : "Member",
        avatar: user?.node.avatar ?? null,
        isRegistered: !!user?.node.invitationAcceptedAt,
      })) ?? [],
    [edges]
  )

  const tableColumns: ColumnDef<FirmDataTable>[] = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info) => {
        const userAvatar = info.row.original.avatar
        const isRegistered = info.row.original.isRegistered

        if (isRegistered) {
          return (
            <Flex gap={rem(8)} align="center">
              <Avatar size={rem(16)} color="blue" src={userAvatar}>
                {getAvatarText(info.getValue<string>())}
              </Avatar>
              <Text size="sm" c="gray.7">
                {info.getValue<string>()}
              </Text>
            </Flex>
          )
        }
        return (
          <Group gap={rem(8)}>
            <DashedCircle />
            <Text size="sm" c="gray.5">
              Pending
            </Text>
          </Group>
        )
      },
    },
    {
      header: "E-mail",
      accessorKey: "email",
      cell: (info) => {
        const isRegistered = info.row.original.isRegistered
        return (
          <Text size="sm" c={isRegistered ? "gray.7" : "gray.5"}>
            {info.getValue<string>()}
          </Text>
        )
      },
      meta: {
        keepCasing: true,
      },
    },
    {
      header: "Clients",
      accessorKey: "clients",
      cell: (info) => {
        const userClients = info.getValue<ClientInfoType[]>()
        return (
          <Avatar.Group w={"100%"} spacing={rem(6)}>
            {userClients.length > 0 ? (
              userClients.map((client: ClientInfoType, index) => {
                return (
                  <Avatar
                    variant="filled"
                    size={rem(17)}
                    key={`${client.name} ${index}`}
                    color={client.color}
                    src={client.logo ?? null}
                  >
                    {client.name[0]}
                  </Avatar>
                )
              })
            ) : (
              <Text c="gray.5">--</Text>
            )}
          </Avatar.Group>
        )
      },
    },
    {
      header: "Role",
      accessorKey: "role",
      cell: (info) => {
        const isRegistered = info.row.original.isRegistered
        return (
          <Text size="sm" c={isRegistered ? "gray.7" : "gray.5"}>
            {info.getValue<string>()}
          </Text>
        )
      },
    },
    {
      header: "",
      accessorKey: "id",
      enableSorting: false,
      cell: (info) => {
        const userId = info.getValue<string>()

        return info.row.original.role === "Member" && isAdmin ? (
          <ActionIcon
            variant="transparent"
            aria-label="Settings"
            onClick={() => handleRemoveMemberAction(userId)}
          >
            <IconTrash size={16} stroke={2} color={themeVars.colors.gray[6]} />
          </ActionIcon>
        ) : (
          <></>
        )
      },
    },
  ]
  return (
    <Box>
      <Table columns={tableColumns} data={tableData} />
      <RemoveUserModal
        userId={userToRemove}
        opened={confirmationOpened}
        onClose={confirmationClose}
      />
    </Box>
  )
}
