import { deleteButtonFragment$key } from "./__generated__/deleteButtonFragment.graphql"
import { deleteButtonMutation } from "./__generated__/deleteButtonMutation.graphql"
import { Button } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { IconTrash } from "@tabler/icons-react"
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay"

const DeleteButtonMutation = graphql`
  mutation deleteButtonMutation(
    $input: DeleteIntegrationInput!
    $connections: [ID!]!
  ) {
    deleteIntegration(input: $input) {
      deletedIntegrationId @deleteEdge(connections: $connections)
      success
      errors
    }
  }
`

const DeleteButtonFragment = graphql`
  fragment deleteButtonFragment on SystemIntegration {
    id
    status
    name
  }
`

type Props = {
  integrationRef: deleteButtonFragment$key
  onComplete: () => void
}

export const DeleteButton = ({ integrationRef, onComplete }: Props) => {
  const { id, status, name } = useFragment(DeleteButtonFragment, integrationRef)
  const [deleteIntegration, deleteIntegrationLoading] =
    useMutation<deleteButtonMutation>(DeleteButtonMutation)
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false)

  const handleDelete = () => {
    const integrationsConnection = ConnectionHandler.getConnectionID(
      "root",
      "ConnectionsTableFragment_getIntegrations"
    )

    deleteIntegration({
      variables: {
        input: {
          integrationId: id,
        },
        connections: [integrationsConnection],
      },
      onCompleted: () => {
        notifications.show({
          title: "Connection Successfully Deleted",
          message: "It will take a while to delete all the data.",
          variant: "success",
        })
        closeModal()
        onComplete()
      },
      onError: () => {
        notifications.show({
          title: "An error ocurred",
          message: "Please try again.",
          variant: "error",
        })
      },
    })
  }

  return (
    <>
      <Button
        variant="transparent"
        color="red"
        leftSection={<IconTrash size={16} />}
        disabled={status === "SYNCING"}
        onClick={openModal}
      >
        Delete Connection
      </Button>
      <ConfirmationModal
        title={`Are you sure you want to delete ${name ?? "this"} connection?`}
        opened={modalOpened}
        onClose={closeModal}
        cancelButtonProps={{
          onClick: closeModal,
        }}
        confirmButtonProps={{
          onClick: handleDelete,
          color: "red",
          children: "Delete Connection",
          loading: deleteIntegrationLoading,
        }}
      >
        This action is irreversible and will permanently delete all associated
        data.
      </ConfirmationModal>
    </>
  )
}
