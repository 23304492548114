import styles from "./progress-bar.module.scss"

export const ProgressBar = () => {
  return (
    <div className={styles.Container}>
      <div className={styles.Progress}>
        <div className={styles.ProgressValue}></div>
      </div>
    </div>
  )
}
