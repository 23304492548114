/**
 * @generated SignedSource<<d80cb31c375b34ba0f37e78df8767dea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SavedReportsTypeEnum = "BALANCE" | "CASH" | "INCOME" | "TRANSACTION";
export type SavedStatementsTableRefetchQuery$variables = {
  clientId: string;
  count?: number | null;
  cursor?: string | null;
  pinned?: boolean | null;
  reportType?: ReadonlyArray<SavedReportsTypeEnum> | null;
  search?: string | null;
  userIds?: ReadonlyArray<string> | null;
};
export type SavedStatementsTableRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"savedStatementsTableFragment">;
};
export type SavedStatementsTableRefetchQuery = {
  response: SavedStatementsTableRefetchQuery$data;
  variables: SavedStatementsTableRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pinned"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reportType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v2 = {
  "kind": "Variable",
  "name": "pinned",
  "variableName": "pinned"
},
v3 = {
  "kind": "Variable",
  "name": "reportType",
  "variableName": "reportType"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SavedStatementsTableRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "savedStatementsTableFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SavedStatementsTableRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "SavedReportConnection",
        "kind": "LinkedField",
        "name": "getSavedReports",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SavedReportEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "filterType",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "type": "SavedStatement",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/)
                    ],
                    "type": "SavedTransaction",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "SavedStatementsTableFragment_getSavedReports",
        "kind": "LinkedHandle",
        "name": "getSavedReports"
      }
    ]
  },
  "params": {
    "cacheID": "79752b66c2a22635b7fb3d71d6d3aa32",
    "id": null,
    "metadata": {},
    "name": "SavedStatementsTableRefetchQuery",
    "operationKind": "query",
    "text": "query SavedStatementsTableRefetchQuery(\n  $clientId: ID!\n  $count: Int\n  $cursor: String\n  $pinned: Boolean\n  $reportType: [SavedReportsTypeEnum!]\n  $search: String\n  $userIds: [ID!]\n) {\n  ...savedStatementsTableFragment_2VYSpQ\n}\n\nfragment savedStatementsTableFragment_2VYSpQ on Query {\n  getSavedReports(clientId: $clientId, reportType: $reportType, pinned: $pinned, search: $search, userIds: $userIds, first: $count, after: $cursor) {\n    edges {\n      node {\n        __typename\n        ... on SavedStatement {\n          id\n          name\n          filterType\n          pinned\n          type\n          source\n          updatedAt\n          user {\n            id\n            fullName\n            avatar\n          }\n        }\n        ... on SavedTransaction {\n          id\n          name\n          pinned\n          type\n          source\n          updatedAt\n          user {\n            id\n            fullName\n            avatar\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "31a20d427c06c32e97f55244949011f6";

export default node;
