import { transactionsTableFragment$key } from "./__generated__/transactionsTableFragment.graphql"
import { EmptyAccountsTable } from "@components/statements/cash-flow-configuration/balance-accounts-table/empty-accounts-table"
import { Card, Flex, InputLabel, Pagination, Stack, Text } from "@mantine/core"
import { Select } from "@shared/ui/select"
import { Table } from "@shared/ui/table"
import { formatMoney } from "@shared/utils/helpers"
import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import { useMemo } from "react"
import React from "react"
import { graphql, useFragment } from "react-relay"
import { Link } from "react-router-dom"

type Transaction = {
  account: string | null
  accountNumber: number | string | null
  type: string | null
  date: string
  class: string | null
  vendor: string | null
  customer: string | null
  description: string | null
  amount: string | null
  externalLink: string | null
}
const TransactionsFragment = graphql`
  fragment transactionsTableFragment on Query
  @argumentDefinitions(
    accountIds: { type: "[ID!]" }
    classIds: { type: "[ID!]" }
    customerIds: { type: "[ID!]" }
    systemConnectionIds: { type: "[ID!]!" }
    vendorIds: { type: "[ID!]" }
    amountRate: { type: "AmountRateEnum" }
    startDate: { type: "ISO8601DateTime" }
    endDate: { type: "ISO8601DateTime" }
    limit: { type: "Int" }
    pageNumber: { type: "Int" }
    search: { type: "String" }
    maxRange: { type: "Float" }
    minRange: { type: "Float" }
  ) {
    getTransactions(
      systemConnectionIds: $systemConnectionIds
      accountIds: $accountIds
      classIds: $classIds
      customerIds: $customerIds
      vendorIds: $vendorIds
      amountRate: $amountRate
      startDate: $startDate
      endDate: $endDate
      limit: $limit
      pageNumber: $pageNumber
      search: $search
      maxRange: $maxRange
      minRange: $minRange
    ) {
      count
      totalCount
      classTracking
      edges {
        node {
          accountName
          accountNumber
          amount
          className
          customerName
          date
          description
          id
          type
          vendorName
          externalLink
        }
      }
    }
  }
`

type Props = {
  data: transactionsTableFragment$key
  paginationProps: {
    page?: string | null
    limit?: string | null
    defaultPage: string
    defaultLimit: string
  }
  onChange: (limit?: string | null, page?: string | null) => void
}

export const TransactionsTable = ({
  data,
  paginationProps,
  onChange,
}: Props) => {
  const {
    getTransactions: { edges, totalCount, classTracking },
  } = useFragment(TransactionsFragment, data)

  const columns: ColumnDef<Transaction>[] = [
    {
      header: "Account",
      accessorKey: "account",
      cell: (info) => {
        const cellContent = info.getValue<string>()
        const accountNumber = info.row.original.accountNumber
        return (
          <Text truncate="end" size="sm" title={cellContent}>
            {[accountNumber, cellContent].join(" ")}
          </Text>
        )
      },
    },
    {
      header: "Type",
      accessorKey: "type",
      cell: (info) => {
        const cellContent = info.getValue<string>()
        return (
          <Text truncate="end" size="sm" title={cellContent}>
            {cellContent}
          </Text>
        )
      },
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: (info) => {
        const cellContent = info.getValue<string>()
        return (
          <Text truncate="end" size="sm" title={cellContent}>
            {cellContent}
          </Text>
        )
      },
    },
    {
      header: "Vendor",
      accessorKey: "vendor",
      cell: (info) => {
        const cellContent = info.getValue<string>()
        return (
          <Text truncate="end" size="sm" title={cellContent}>
            {cellContent}
          </Text>
        )
      },
    },

    {
      header: "Customer",
      accessorKey: "customer",
      cell: (info) => {
        const cellContent = info.getValue<string>()
        return (
          <Text truncate="end" size="sm" title={cellContent}>
            {cellContent}
          </Text>
        )
      },
    },

    {
      header: "Description",
      accessorKey: "description",
      cell: (info) => {
        const cellContent = info.getValue<string>()
        return (
          <Text truncate="end" size="sm" title={cellContent}>
            {cellContent}
          </Text>
        )
      },
    },

    {
      header: "Amount",
      accessorKey: "amount",
      cell: (info) => {
        const cellContent = info.getValue<string>()
        const externalLink = info.row.original.externalLink
        const LinkWrapper = externalLink ? Link : React.Fragment

        return (
          <LinkWrapper
            to={externalLink || ""}
            target="__blank"
            style={{ width: "100%" }}
          >
            <Text
              c={"blue"}
              w={"100%"}
              truncate="end"
              ta={"end"}
              size="sm"
              title={cellContent}
            >
              {cellContent}
            </Text>
          </LinkWrapper>
        )
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...(classTracking
      ? [
          {
            header: "Class",
            accessorKey: "class",
            enableHiding: !!classTracking,
            cell: (info: { getValue: () => string }) => {
              const cellContent = info.getValue()
              return (
                <Text truncate="end" size="sm">
                  {cellContent}
                </Text>
              )
            },
          },
        ]
      : []),
  ]

  const tableData: Transaction[] = useMemo(
    () =>
      edges.map(({ node }) => ({
        account: node.accountName,
        accountNumber: node.accountNumber ?? "",
        type: node.type ?? "-",
        date: node.date ? format(new Date(node.date), "MM/dd/yyyy") : "",
        class: node.className ?? "-",
        vendor: node.vendorName ?? "-",
        customer: node.customerName ?? "-",
        description: node.description ?? "-",
        amount: node.amount
          ? formatMoney({
              value: node.amount,
              divideOneThousand: false,
              negativeParenthesis: true,
              showDecimals: true,
              hideCurrency: true,
            })
          : null,
        externalLink: node.externalLink,
      })),
    [edges]
  )
  const pageLimit = paginationProps.limit || paginationProps.defaultLimit
  const pageNumber = paginationProps.page || paginationProps.defaultPage
  return (
    <>
      <Stack gap={0.5} style={{ flexGrow: 1 }}>
        {edges.length > 0 ? (
          <>
            <Table
              columns={columns}
              fixedLayout
              data={tableData}
              compact
              withPagination
            />
            <Card
              shadow="xs"
              py={".75rem"}
              mt={".0325rem"}
              px={"1.5rem"}
              style={{ borderRadius: "0 0 0.5rem 0.5rem" }}
            >
              <Flex justify={"space-between"}>
                <Flex align={"center"} gap={"xs"}>
                  <InputLabel>Rows per page</InputLabel>
                  <Select
                    data={["50", "100", "150", "200"]}
                    onChange={(val) => {
                      onChange(val, paginationProps.page)
                    }}
                    value={pageLimit ? pageLimit.toString() : null}
                    w={"4.6875rem"}
                  />
                </Flex>
                <Pagination
                  total={Math.ceil((totalCount ?? 0) / Number(pageLimit))}
                  value={Number(pageNumber)}
                  onChange={(val) => {
                    onChange(paginationProps.limit, val.toString())
                  }}
                />
              </Flex>
            </Card>
          </>
        ) : (
          <Flex style={{ flexGrow: 1 }}>
            <EmptyAccountsTable fullWidth />
          </Flex>
        )}
      </Stack>
    </>
  )
}
