import {
  AccountOptionType,
  AccountsPopover,
} from "../accounts-popover/accounts-popover"
import { accountSelectorFragment$key } from "./__generated__/accountSelectorFragment.graphql"
import { ActionIcon, Badge, Button, Flex, Stack, Text } from "@mantine/core"
import { IconPlus, IconX } from "@tabler/icons-react"
import { isEqual } from "lodash"
import { useCallback, useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"

type AccountBadgeProps = {
  name: string
  onRemove: () => void
}
const AccountBadge = ({ name, onRemove }: AccountBadgeProps) => {
  return (
    <Badge
      color="indigo.6"
      radius={"sm"}
      rightSection={
        <ActionIcon
          onClick={onRemove}
          variant="transparent"
          c={"white"}
          size={"1rem"}
        >
          <IconX size={"1rem"} />
        </ActionIcon>
      }
    >
      {name}
    </Badge>
  )
}

const AccountsFragment = graphql`
  fragment accountSelectorFragment on Query
  @argumentDefinitions(connectionIds: { type: "[ID!]!" }) {
    getAccounts(systemConnectionIds: $connectionIds) {
      data
    }
  }
`
type Props = {
  data: accountSelectorFragment$key
  preSelectedAccounts: string[]
  onAccountsChange: (val: string[]) => void
  setAccountName?: (val: string) => void
}
type AccountsType = {
  name: string
  account_name: string
  account_number: string
  account_type: string
  accounts: AccountsType[]
  ledger_account_id: string
}
type CategoryDataType = {
  name: string
  accounts: AccountsType[]
}

export const AccountSelector = ({
  data,
  preSelectedAccounts = [],
  onAccountsChange,
  setAccountName,
}: Props) => {
  const { getAccounts } = useFragment(AccountsFragment, data)
  const accountsData = getAccounts.data as { categories: CategoryDataType[] }

  const fillRows = useCallback(
    (accountsToTransform: AccountsType[], res: AccountOptionType[]) => {
      accountsToTransform.forEach((account) => {
        if (account.accounts.length > 0) {
          const childAccount: AccountOptionType = {
            checked: preSelectedAccounts.includes(account.ledger_account_id)
              ? true
              : false,
            key: account.ledger_account_id,
            label: [account.account_number, account.account_name].join(" "),
            options: [],
            rightLabel: account.account_type,
          }
          fillRows(account.accounts, childAccount.options)
          res.push(childAccount)
        } else {
          res.push({
            checked: preSelectedAccounts.includes(account.ledger_account_id)
              ? true
              : false,
            key: account.ledger_account_id,
            label: [account.account_number, account.account_name].join(" "),
            rightLabel: account.account_type,
            options: [],
          })
        }
      })
    },
    [preSelectedAccounts]
  )

  const getFormattedAccounts = useCallback(
    (categories: CategoryDataType[]) => {
      const result: AccountOptionType[] = []
      categories.forEach((category) => {
        const accounts = category.accounts
        const res: AccountOptionType[] = []
        fillRows(accounts, res)
        result.push({
          checked: false,
          key: `CATEGORY_KEY_${crypto.randomUUID()}`,
          label: category.name,
          options: res,
        })
      })
      return result
    },
    [fillRows]
  )

  const [accounts, setAccounts] = useState<AccountOptionType[]>(
    getFormattedAccounts(accountsData.categories)
  )

  const flatten = useCallback((items: AccountOptionType[]) => {
    const flat: AccountOptionType[] = []
    items.forEach((item) => {
      if (item.options.length > 0) {
        flat.push(item, ...flatten(item.options))
      } else {
        flat.push(item)
      }
    })

    return flat
  }, [])

  const updateItem = (
    options: AccountOptionType[],
    key: string,
    checked: boolean
  ) => {
    if (options.length === 0) {
      return
    }
    options.forEach((option) => {
      if (option.key === key) {
        option.checked = checked
      }
      updateItem(option.options, key, checked)
    })
    return options
  }

  const onRemoveAccounts = (key: string) => {
    const accountsCopy = Object.assign([], [...accounts])
    updateItem(accountsCopy, key, false)
    setAccounts(accountsCopy)
    // Pass to URL params
    onAccountsChange(
      flatten(accountsCopy)
        .filter((acc) => acc.checked)
        .map((item) => item.key)
    )
    setAccountName &&
      setAccountName(
        flatten(accountsCopy).find((acc) => acc.checked)?.label ?? ""
      )
  }

  const onPopoverChange = useCallback((items: AccountOptionType[]) => {
    setAccounts(items)
  }, [])

  const onPopoverClose = useCallback(() => {
    const checkedAccountsKeys = flatten(accounts)
      .filter((acc) => acc.checked)
      .map((item) => item.key)

    if (!isEqual(checkedAccountsKeys, preSelectedAccounts)) {
      onAccountsChange(checkedAccountsKeys)
      setAccountName &&
        setAccountName(
          flatten(accounts).find((acc) => acc.checked)?.label ?? ""
        )
    }
  }, [accounts, flatten, onAccountsChange, preSelectedAccounts, setAccountName])

  useEffect(() => {
    setAccountName &&
      setAccountName(flatten(accounts).find((acc) => acc.checked)?.label ?? "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack>
      <Flex
        mih={"1.25rem"}
        align={"center"}
        gap={".5rem"}
        maw={"100%"}
        style={{ flexWrap: "wrap" }}
      >
        <Text fz=".875rem" fw={500} c={"gray.7"}>
          Accounts:
        </Text>
        {flatten(accounts)
          .filter((acc) => acc.checked)
          .map((account) => (
            <AccountBadge
              name={account.label}
              key={account.key}
              onRemove={() => onRemoveAccounts(account.key)}
            />
          ))}
      </Flex>
      <Flex gap={"0.5rem"} align={"center"}>
        <AccountsPopover
          onClose={onPopoverClose}
          position="bottom-start"
          target={
            <Button
              variant="light"
              fz={".75rem"}
              size="compact-xs"
              color="indigo.6"
            >
              <IconPlus size={".75rem"} style={{ marginRight: ".25rem" }} />
              Add
            </Button>
          }
          options={accounts}
          width={400}
          onChange={onPopoverChange}
          groupLabel={"ACCOUNTS LIST"}
        />
      </Flex>
    </Stack>
  )
}
