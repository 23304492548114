import {
  DisplayEnum,
  ReportTypeEnum,
} from "../report-table/__generated__/reportTableGetFinancialStatementQuery.graphql"
import { RepresentationEnum } from "../statements"
import { notifications } from "@mantine/notifications"
import {
  capitalizeString,
  downloadPDF,
  formatMoney,
} from "@shared/utils/helpers"
import { ColumnInput, RowInput } from "jspdf-autotable"

export type ExportStatementHeaderType = { header: string; accessorKey: string }
export type ExportStatementRowType = {
  amounts: { [x: string]: ammountType | null }
  account_number: string
  accounts_totals: { [x: string]: ammountType | null }
  accounts: ExportStatementRowType[]
  name: string
  type: string
  total: ammountType
  accounts_total: number
}
type ammountType = {
  amount: number
  annualized: number
  percentage: number
  twelve_months: number
}
export type GenerateTableReportProps = {
  columns: ExportStatementHeaderType[]
  data: ExportStatementRowType[]
  title: string
  filename: string
  exceptZeroAmmount?: boolean
  negativeInParentheses?: boolean
  divideOneThousand?: boolean
  negativeInRed?: boolean
  hideCurrency?: boolean
  showDecimals?: boolean
  footer?: string | null | undefined
  systemConnectionIds: string[]
  startDate: string
  endDate: string
  clientId: string | null
  display: DisplayEnum
  reportType: ReportTypeEnum
  numberFormat: string[]
  showZeroBalance: boolean
  representation: RepresentationEnum
}

export const generateXlsxReport = async (
  bodyRequest: GenerateTableReportProps,
  alpyneToken: string
) => {
  await fetch("/download_reports", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + alpyneToken,
    },
    body: JSON.stringify({
      exportType: "statements",
      exportParams: {
        clientId: bodyRequest.clientId,
        startDate: bodyRequest.startDate,
        endDate: bodyRequest.endDate,
        systemConnectionIds: bodyRequest.systemConnectionIds,
        display: bodyRequest.display,
        reportType: bodyRequest.reportType,
        numberFormat: bodyRequest.numberFormat,
        showZeroBalance: bodyRequest.showZeroBalance,
        representation: bodyRequest.representation,
      },
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.blob()
      } else {
        throw new TypeError("Error creating report.")
      }
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = bodyRequest.filename + ".xlsx"
      document.body.append(a)
      a.click()
      return true
    })
    .catch(() => {
      notifications.show({
        title: "There was an error creating the report.",
        message: "Please try again",
        variant: "error",
      })
    })
}

export const generateTablePDF = ({
  columns,
  data,
  title,
  filename,
  footer,
  exceptZeroAmmount = true,
  showDecimals = false,
  negativeInParentheses = true,
  negativeInRed = false,
  divideOneThousand = false,
  hideCurrency = true,
  representation,
}: GenerateTableReportProps) => {
  const showPercentage = representation === RepresentationEnum.PERCENTAGE
  const pdfColumns: ColumnInput[] = columns.map((col) => ({
    header: capitalizeString(col.header),
    dataKey: col.accessorKey,
  }))
  const childCategories = data
    .filter((val) => val.type === "category_child")
    .map((val) => val.name)

  const rows: RowInput[] = []

  const fillRows = (row: ExportStatementRowType, levelIterator: number) => {
    const rowTotals: Record<string, string> = {}
    const accountTotals: Record<string, string> = {}
    const showAccountTotals =
      row.type === "account_totals" || childCategories.includes(row.name)
    const isCategoryParent = row.type === "category_parent"
    row.amounts
      ? Object.keys(row.amounts).forEach((key) => {
          const rowAmmounts = row.amounts[key]
          const accountAmmount = rowAmmounts ? rowAmmounts[representation] : 0
          rowTotals[key] =
            accountAmmount == 0
              ? exceptZeroAmmount && !showAccountTotals
                ? ""
                : formatMoney({
                    value: accountAmmount,
                    showDecimals,
                    negativeParenthesis: negativeInParentheses,
                    divideOneThousand,
                    hideCurrency,
                  })
              : formatMoney({
                  value: accountAmmount,
                  showDecimals,
                  negativeParenthesis: negativeInParentheses,
                  divideOneThousand,
                  hideCurrency,
                })
        })
      : null
    row.accounts_totals
      ? Object.keys(row.accounts_totals).forEach((key) => {
          const rowAccountTotal = row.accounts_totals[key]
          const accountTotal = rowAccountTotal
            ? rowAccountTotal[representation]
            : 0
          accountTotals[key] =
            accountTotal == 0
              ? exceptZeroAmmount && !showAccountTotals
                ? ""
                : formatMoney({
                    value: accountTotal,
                    showDecimals,
                    negativeParenthesis: negativeInParentheses,
                    divideOneThousand,
                    hideCurrency: showPercentage,
                    showPercentage,
                  })
              : formatMoney({
                  value: accountTotal,
                  showDecimals,
                  negativeParenthesis: negativeInParentheses,
                  divideOneThousand,
                  hideCurrency: showPercentage,
                  showPercentage,
                })
        })
      : null

    if (row.accounts?.length > 0) {
      const rowTotal = row.total[representation]
      rows.push({
        name: Array.from({ length: levelIterator }).join("  ") + row.name,
        total: isCategoryParent
          ? ""
          : formatMoney({
              value: rowTotal,
              showDecimals,
              negativeParenthesis: negativeInParentheses,
              divideOneThousand,
              hideCurrency: showPercentage,
              showPercentage,
            }),
        ...(showAccountTotals ? accountTotals : rowTotals),
      })
      row.accounts.forEach((account) => fillRows(account, levelIterator + 1))
    } else {
      const rowTotal = row.total[representation]
      rows.push({
        name: Array.from({ length: levelIterator }).join("  ") + row.name,
        total: isCategoryParent
          ? ""
          : formatMoney({
              value: rowTotal,
              showDecimals,
              negativeParenthesis: negativeInParentheses,
              divideOneThousand,
              hideCurrency: showPercentage,
              showPercentage,
            }),
        ...(showAccountTotals ? accountTotals : rowTotals),
      })
    }
  }

  data.forEach((row: ExportStatementRowType) => {
    fillRows(row, 0)
  })

  downloadPDF({
    rows,
    columns: pdfColumns,
    title,
    filename,
    footer,
    childCategories,
    negativeInRed,
  })
}
