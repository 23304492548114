import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Popover,
  Stack,
  Text,
} from "@mantine/core"
import { DatePicker } from "@shared/ui/date-picker"
import { DateRangePicker } from "@shared/ui/date-range-picker/date-range-picker"
import { convertDateToISOString, getFormattedDate } from "@shared/utils/helpers"
import { IconChevronDown } from "@tabler/icons-react"
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  intlFormat,
  isAfter,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subMonths,
  subQuarters,
  subYears,
} from "date-fns"
import { useState } from "react"

const REPORT_OPTIONS = {
  thisMonth: "This Month",
  yearToLastMonth: "This Year - to last month",
  lastMonth: "Last Month",
  lastQuarter: "Last Quarter",
  lastYear: "Last Year",
  lastTwelveToLastMonth: "Last Twelve Months - to last month",
  lastThreeMonthsToLastMonth: "Last Three Months - to last month ",
}
type DateRanges = {
  startDate: string
  endDate: string
}
type DateRangesInDates = {
  startDate: Date | null
  endDate: Date | null
}
type Props = {
  onChange: (dates: DateRanges) => void
  type?: "month" | "date"
  defaultDates?: DateRangesInDates | null
}
export const ReportPeriodSelector = ({
  onChange,
  type = "month",
  defaultDates,
}: Props) => {
  const [selectedReportOption, setSelectedReportOption] = useState<
    keyof typeof REPORT_OPTIONS | null
  >(defaultDates ? null : "yearToLastMonth")
  const today = new Date()
  const initialDate = defaultDates?.startDate
  const finalDate = defaultDates?.endDate

  const [dateRangeValue, setDateRangeValue] = useState<
    [Date | null, Date | null]
  >(
    initialDate && finalDate
      ? [initialDate, finalDate]
      : [startOfYear(today), endOfMonth(subMonths(today, 1))]
  )
  const onReportOptionClick = (option: keyof typeof REPORT_OPTIONS) => {
    setSelectedReportOption(option)
    switch (option) {
      case "yearToLastMonth": {
        const firstDate = startOfYear(today)
        const secondDate = endOfMonth(subMonths(today, 1))
        setDateRangeValue([firstDate, secondDate])
        break
      }
      case "lastMonth": {
        const lastMonthDate = subMonths(today, 1)
        setDateRangeValue([
          startOfMonth(lastMonthDate),
          endOfMonth(lastMonthDate),
        ])
        break
      }

      case "lastQuarter": {
        const currentQuarter = startOfQuarter(today)
        const lastQuarterDate = subQuarters(currentQuarter, 1)
        setDateRangeValue([
          startOfMonth(lastQuarterDate),
          endOfQuarter(lastQuarterDate),
        ])
        break
      }
      case "lastTwelveToLastMonth": {
        const firstDate = startOfMonth(subMonths(today, 12))
        const secondDate = endOfMonth(subMonths(today, 1))
        setDateRangeValue([firstDate, secondDate])
        break
      }
      case "lastThreeMonthsToLastMonth": {
        const firstDate = startOfMonth(subMonths(today, 3))
        const secondDate = endOfMonth(subMonths(today, 1))
        setDateRangeValue([firstDate, secondDate])
        break
      }

      case "lastYear": {
        const lastYearDate = subYears(today, 1)
        setDateRangeValue([startOfYear(lastYearDate), endOfYear(lastYearDate)])
        break
      }
      case "thisMonth": {
        const startDate = startOfMonth(today)
        const endDate = endOfMonth(today)
        setDateRangeValue([startDate, endDate])
        break
      }
      default: {
        break
      }
    }
  }
  const orderedDates =
    dateRangeValue[1] &&
    dateRangeValue[0] &&
    isAfter(dateRangeValue[0], dateRangeValue[1])
      ? [dateRangeValue[1], dateRangeValue[0]]
      : [dateRangeValue[0], dateRangeValue[1]]

  const secondDateTransformed =
    !selectedReportOption && orderedDates[1]
      ? endOfMonth(orderedDates[1])
      : orderedDates[1]

  return (
    <Popover
      width={"auto"}
      position="bottom-start"
      shadow="md"
      onClose={() => {
        if (orderedDates[0] && !orderedDates[1]) {
          setDateRangeValue((prev) => [
            prev[0],
            prev[0] ? endOfMonth(prev[0]) : null,
          ])
          onChange({
            startDate: convertDateToISOString(orderedDates[0]),
            endDate: convertDateToISOString(endOfMonth(orderedDates[0])),
          })
          return
        }

        orderedDates[0] &&
          orderedDates[1] &&
          onChange({
            startDate: convertDateToISOString(orderedDates[0]),
            endDate: secondDateTransformed
              ? convertDateToISOString(secondDateTransformed)
              : convertDateToISOString(endOfMonth(orderedDates[0])),
          })
      }}
    >
      <Popover.Target>
        <Input
          type="button"
          component="button"
          pointer
          rightSection={<IconChevronDown size={16} />}
        >
          <Text truncate="end" size="sm">
            {selectedReportOption
              ? REPORT_OPTIONS[selectedReportOption]
              : `${
                  orderedDates[0] ? getFormattedDate(orderedDates[0]) : ""
                } - ${
                  secondDateTransformed
                    ? intlFormat(
                        secondDateTransformed,
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        },
                        {
                          locale: "en",
                        }
                      )
                    : ""
                }`}
          </Text>
        </Input>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <Flex gap={0}>
          <Stack gap={"0.0625rem"} w="auto" p="0.5rem">
            {(
              Object.keys(REPORT_OPTIONS) as (keyof typeof REPORT_OPTIONS)[]
            ).map((key, index) => (
              <Button
                key={`${key}_${index}`}
                variant={
                  key === selectedReportOption ? "filled" : "transparent"
                }
                h={"2rem"}
                color={key === selectedReportOption ? "blue.1" : ""}
                c={key === selectedReportOption ? "blue" : "gray.7"}
                justify="start"
                px="0.5rem"
                onClick={() => {
                  onReportOptionClick(key)
                }}
              >
                <Text size="sm" fw={500}>
                  {REPORT_OPTIONS[key]}
                </Text>
              </Button>
            ))}
          </Stack>
          <Divider variant="solid" orientation="vertical" />
          {type === "date" && (
            <Box p={"1rem"}>
              <DatePicker
                hideOutsideDates={false}
                allowSingleDateInRange={true}
                numberOfColumns={2}
                value={dateRangeValue}
                columnsToScroll={1}
                type="range"
                onChange={(value) => {
                  const dateRange = value as [Date, Date]
                  setDateRangeValue(dateRange)
                  setSelectedReportOption(null)
                }}
              />
            </Box>
          )}
          {type == "month" && (
            <DateRangePicker
              firstDate={dateRangeValue[0]}
              secondDate={dateRangeValue[1]}
              setFirstDate={(val) => {
                setSelectedReportOption(null)
                val && setDateRangeValue([val, null])
              }}
              setSecondDate={(val) => {
                setSelectedReportOption(null)
                val && setDateRangeValue((prev) => [prev[0], val])
              }}
            />
          )}
        </Flex>
      </Popover.Dropdown>
    </Popover>
  )
}
