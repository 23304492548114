import { SavedReportsTypeEnum } from "../__generated__/savedStatementsQuery.graphql"
import { SavedStatementsTableRefetchQuery } from "./__generated__/SavedStatementsTableRefetchQuery.graphql"
import {
  SavedReportTypeEnum,
  savedStatementsTableFragment$key,
} from "./__generated__/savedStatementsTableFragment.graphql"
import { savedStatementsTableGetUsersQuery as SavedStatementsTableGetUsersQueryType } from "./__generated__/savedStatementsTableGetUsersQuery.graphql"
import { savedStatementsTablePinStatementMutation as SavedStatementsTablePinStatementMutationType } from "./__generated__/savedStatementsTablePinStatementMutation.graphql"
import { DeleteSavedReportModal } from "./delete-saved-statement-modal"
import { EmptySavedStatements } from "./empty-saved-statements"
import { EmptyAccountsTable } from "@components/statements/cash-flow-configuration/balance-accounts-table/empty-accounts-table"
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Space,
  Stack,
  Switch,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { themeVars } from "@shared/theme"
import { FilterMultiselect } from "@shared/ui/filter-multiselect"
import { FilterMultiselector } from "@shared/ui/filter-multiselector"
import { Table } from "@shared/ui/table"
import { getAvatarText, getDaysAgo } from "@shared/utils/helpers"
import { IconPin, IconSearch, IconTrash } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { pathConstants } from "frontend/routes/path-constants"
import { debounce, isEqual } from "lodash"
import { useEffect, useMemo, useState, useTransition } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  useRefetchableFragment,
} from "react-relay"
import { useNavigate, useSearchParams } from "react-router-dom"

const SavedStatementsTableFragment = graphql`
  fragment savedStatementsTableFragment on Query
  @refetchable(queryName: "SavedStatementsTableRefetchQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int" }
    clientId: { type: "ID!" }
    reportType: { type: "[SavedReportsTypeEnum!]" }
    pinned: { type: "Boolean" }
    search: { type: "String" }
    userIds: { type: "[ID!]" }
  ) {
    getSavedReports(
      clientId: $clientId
      reportType: $reportType
      pinned: $pinned
      search: $search
      userIds: $userIds
      first: $count
      after: $cursor
    )
      @connection(
        key: "SavedStatementsTableFragment_getSavedReports"
        filters: []
      ) {
      edges {
        node {
          ... on SavedStatement {
            id
            name
            filterType
            pinned
            type
            source
            updatedAt
            user {
              id
              fullName
              avatar
            }
          }
          ... on SavedTransaction {
            id
            name
            pinned
            type
            source
            updatedAt
            user {
              id
              fullName
              avatar
            }
          }
        }
      }
    }
  }
`

const SavedStatementsTableGetUsersQuery = graphql`
  query savedStatementsTableGetUsersQuery {
    getFirmUsers {
      edges {
        node {
          id
          fullName
        }
      }
    }
  }
`

const SavedStatementsTablePinStatementMutation = graphql`
  mutation savedStatementsTablePinStatementMutation(
    $input: PinSavedReportMutationInput!
  ) {
    pinSavedReport(input: $input) {
      savedReport {
        ... on SavedStatement {
          id
          name
          filterType
          pinned
          type
          source
          updatedAt
          user {
            id
            fullName
            avatar
          }
        }
        ... on SavedTransaction {
          id
          name
          pinned
          type
          source
          updatedAt
          user {
            id
            fullName
            avatar
          }
        }
      }
    }
  }
`

type User = {
  id: string
  fullName: string
  avatar: string
}

type SavedReport = {
  id: string
  name: string
  filterType: SavedReportsTypeEnum
  type: SavedReportTypeEnum
  source: string
  pinned?: boolean
  updatedAt: Date
  user: User
}

type FormType = {
  reportType: SavedReportsTypeEnum[]
  search: string | null
  userIds: string[] | null
  pinned: boolean | null
}
const REFETCH_DEBOUNCE_TIMEOUT = 500

type Props = {
  savedStatements: savedStatementsTableFragment$key
}

const defaultFormValues = {
  reportType: [],
  search: "",
  userIds: [],
  pinned: false,
}

export const SavedStatementsTable = ({ savedStatements }: Props) => {
  const navigate = useNavigate()
  const [
    {
      getSavedReports: { edges },
    },
    refetch,
  ] = useRefetchableFragment<
    SavedStatementsTableRefetchQuery,
    savedStatementsTableFragment$key
  >(SavedStatementsTableFragment, savedStatements)
  const [searchParams, setSearchParams] = useSearchParams()

  const paramReportType: SavedReportsTypeEnum[] = searchParams.getAll(
    "reportType"
  ) as SavedReportsTypeEnum[]
  const paramUserIds = searchParams.getAll("userIds")
  const paramPinned = searchParams.get("pinned") == "true" ? true : false
  const paramSearch = searchParams.get("search")

  const defaultTouchedFields = {
    search: !!paramSearch,
    reportType: paramReportType.length > 0,
    userIds: paramUserIds.length > 0,
    pinned: paramPinned,
  }

  const { getFirmUsers } =
    useLazyLoadQuery<SavedStatementsTableGetUsersQueryType>(
      SavedStatementsTableGetUsersQuery,
      {}
    )

  const [pinSavedReport] =
    useMutation<SavedStatementsTablePinStatementMutationType>(
      SavedStatementsTablePinStatementMutation
    )

  const handlePinStatement = (id: string, pinned: boolean) => {
    const onlyPinned = getValues("pinned")

    pinSavedReport({
      variables: {
        input: {
          id: id,
          pinned: pinned,
        },
      },
      onCompleted: ({ pinSavedReport: response }) => {
        response?.savedReport?.pinned === false &&
          onlyPinned &&
          startTransition(() => {
            refetch(
              { ...getValues(), pinned: onlyPinned ? true : null },
              {
                fetchPolicy: "network-only",
              }
            )
          })
      },
    })
  }

  const [, startTransition] = useTransition()
  const [touchedFields, setTouchedFields] =
    useState<Record<string, boolean>>(defaultTouchedFields)
  const [loading, setLoading] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [selectedSavedStatement, setSelectedSavedStatement] = useState<{
    id: string
    name: string
  } | null>(null)

  const [confirmationOpened, { open, close }] = useDisclosure(false)

  const data: SavedReport[] = edges.map(({ node }) => ({
    id: node.id ?? "",
    name: node.name ?? "",
    filterType: (node.filterType as SavedReportsTypeEnum) ?? "",
    updatedAt: new Date(node.updatedAt ?? ""),
    type: node.type as SavedReportTypeEnum,
    source: node.source ?? "",
    pinned: node.pinned ?? false,
    user: {
      id: node.user?.id ?? "",
      fullName: node.user?.fullName ?? "",
      avatar: node.user?.avatar ?? "",
    },
  }))

  const usersFilterMultiselect = useMemo(
    () =>
      getFirmUsers.edges.map(({ node }) => ({
        key: node.id ?? "",
        label: node.fullName ?? "",
      })),
    [getFirmUsers.edges]
  )

  const { handleSubmit, watch, control, reset, getValues } = useForm<FormType>({
    defaultValues: {
      reportType: paramReportType.length > 0 ? paramReportType : [],
      search: paramSearch ?? "",
      userIds: paramUserIds.length > 0 ? paramUserIds : [],
      pinned: paramPinned,
    },
  })

  const cleanFilters = (params: FormType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanedFilters: Record<string, any> = {}
    Object.keys(params).forEach((key) => {
      const formKey = key as keyof FormType
      if (
        defaultFormValues &&
        defaultFormValues[formKey] !== params[formKey] &&
        params[formKey] !== null
      ) {
        cleanedFilters[formKey] = params[formKey]
      }
    })
    return cleanedFilters
  }

  const onSubmit = debounce((params: FormType) => {
    const formTouchedFields: Record<keyof FormType, boolean> = touchedFields

    Object.keys(params).forEach((key) => {
      const objKey = key as keyof FormType
      formTouchedFields[objKey] = isEqual(
        params[objKey],
        defaultFormValues[objKey]
      )
        ? false
        : true
    })

    const cleanedFilters = cleanFilters(params)
    setSearchParams((prev) => ({ ...prev, ...cleanedFilters }), {
      replace: true,
    })
    setTouchedFields(formTouchedFields)
    startTransition(() => {
      refetch(
        { ...params, pinned: params.pinned ? true : null },
        {
          fetchPolicy: "network-only",
          onComplete: () => {
            onSubmit.cancel()
            setLoading(false)
            setFilterLoading(false)
          },
        }
      )
    })
  }, REFETCH_DEBOUNCE_TIMEOUT)

  const columns: ColumnDef<SavedReport>[] = [
    {
      header: "Source",
      accessorKey: "source",
      cell: (info) => info.getValue<string>(),
    },
    { header: "Statements", accessorKey: "name" },
    {
      header: "Creator",
      accessorKey: "user",
      cell: (info) => (
        <Group gap={rem(8)}>
          <Avatar src={info.getValue<User>().avatar} radius="xl" size="xs">
            {getAvatarText(info.getValue<User>().fullName)}
          </Avatar>
          <Text size="sm" c="gray.7">
            {info.getValue<User>().fullName}
          </Text>
        </Group>
      ),
    },
    {
      header: "Last Update",
      accessorKey: "updatedAt",
      cell: (info) => getDaysAgo(info.getValue<Date>()),
    },
    {
      header: "",
      accessorKey: "id",
      enableSorting: false,
      meta: {
        align: "right",
        disableRowClick: true,
      },
      cell: (info) => (
        <Group>
          <ActionIcon
            variant="transparent"
            onClick={() =>
              handlePinStatement(
                info.row.original.id,
                !info.row.original.pinned
              )
            }
          >
            <IconPin
              size={16}
              color={
                info.row.original.pinned
                  ? themeVars.colors.blue[6]
                  : themeVars.colors.gray[5]
              }
            />
          </ActionIcon>
          <ActionIcon
            variant="transparent"
            onClick={() =>
              handleTrashButton(info.row.original.id, info.row.original.name)
            }
          >
            <IconTrash size={16} color={themeVars.colors.gray[6]} />
          </ActionIcon>
        </Group>
      ),
    },
  ]

  const handleTrashButton = (id: string, name: string) => {
    setSelectedSavedStatement({ id, name })
    open()
  }

  useEffect(() => {
    const subscription = watch(() => {
      setFilterLoading(true)
      handleSubmit(onSubmit)()
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch])

  const hasTouchedFields = Object.keys(touchedFields).some(
    (key) => touchedFields[key]
  )
  const emptyData =
    (isEqual(defaultFormValues, getValues()) && data.length === 0) ||
    (!hasTouchedFields && data.length === 0)

  if (emptyData && !loading && !filterLoading) {
    return <EmptySavedStatements />
  }
  const sourceOptions = [
    { key: "TRANSACTION", label: "Transaction", options: [] },
    {
      key: "STATEMENT",
      label: "Statement",
      options: [
        { key: "INCOME", label: "Income Statement" },
        { key: "BALANCE", label: "Balance Sheet" },
        { key: "CASH", label: "Cash Flow" },
      ],
    },
  ]

  return (
    <>
      <Box
        display="grid"
        style={{ gridTemplateRows: "min-content 1fr", gap: "2rem" }}
      >
        <Stack gap={0}>
          <Space h="0.75rem" />
          <Group justify="space-between">
            <Group>
              <Text size="sm">Filter by:</Text>
              <Controller
                name="reportType"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FilterMultiselector
                    options={sourceOptions}
                    values={value ?? []}
                    noCheckedLabel="Source"
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="userIds"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FilterMultiselect
                    options={usersFilterMultiselect}
                    values={value ?? []}
                    noCheckedLabel="Creator"
                    onChange={onChange}
                  />
                )}
              />
              <Divider
                orientation="vertical"
                style={{ borderColor: themeVars.colors.gray[3] }}
              />
              <Controller
                name="pinned"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    label={
                      <Text size="sm" fw={400} c={themeVars.colors.gray[7]}>
                        Only Pinned
                      </Text>
                    }
                    checked={value ?? false}
                    onChange={(event) => {
                      onChange(event)
                    }}
                    labelPosition="left"
                  />
                )}
              />

              {hasTouchedFields && (
                <Button
                  variant="subtle"
                  size="sm"
                  px={0}
                  bg={"transparent"}
                  onClick={() => {
                    setFilterLoading(true)
                    reset(defaultFormValues)
                  }}
                >
                  Clear Filters
                </Button>
              )}
            </Group>
            <Controller
              name="search"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  placeholder="Search"
                  value={value ?? ""}
                  onChange={(e) => {
                    !loading && setFilterLoading(true)
                    onChange(e)
                  }}
                  leftSection={<IconSearch size={16} />}
                  rightSection={loading ? <Loader size={16} /> : null}
                  w={"18.5rem"}
                  size="sm"
                />
              )}
            />
          </Group>
        </Stack>
        {data.length > 0 ? (
          <Box>
            <Table
              columns={columns}
              data={data}
              compact
              onRowClick={(row) => {
                const redirectPath = {
                  STATEMENT: pathConstants.SAVED_STATEMENTS,
                  TRANSACTION: pathConstants.SAVED_TRANSACTIONS,
                }

                navigate(
                  `${(redirectPath as { [key: string]: string })[row.type]}/${
                    row.id
                  }`
                )
              }}
              highlightOnHover
            />
          </Box>
        ) : (
          <EmptyAccountsTable />
        )}
      </Box>
      {selectedSavedStatement && (
        <DeleteSavedReportModal
          savedReport={selectedSavedStatement}
          onClose={close}
          opened={confirmationOpened}
        />
      )}
    </>
  )
}
