import { Card, Image, Stack, Text, rem } from "@mantine/core"
import EmptyBalanceAccounts from "@shared/assets/empty-balance-accounts.svg"

export const EmptyAccountsTable = ({ fullWidth }: { fullWidth?: boolean }) => {
  return (
    <Card shadow="xs" radius={rem(8)} {...(fullWidth ? { w: "100%" } : {})}>
      <Stack
        gap={0}
        w="100%"
        h="100%"
        justify="center"
        align="center"
        style={{ alignSelf: "center" }}
      >
        <Image
          src={EmptyBalanceAccounts}
          style={{ mixBlendMode: "multiply" }}
          w={rem(170)}
        />
        <Text c="gray" ta="center" size="sm">
          No results found matching your search.
        </Text>
      </Stack>
    </Card>
  )
}
