import { SpreadsheetsDrawerType } from "../spreadsheets-drawer/spreadsheets-drawer"
import { SavedReportTypeEnum } from "../spreadsheets-drawer/spreadsheets-items-fields/__generated__/spreadsheetsItemsFieldsFragment.graphql"
import classes from "./workbook-spreadsheet.module.scss"
import {
  ActionIcon,
  Box,
  Collapse,
  Flex,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  Tooltip,
  Transition,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { themeVars } from "@shared/theme"
import { SelectCombobox } from "@shared/ui/select-combobox/select-combobox"
import { capitalizeFirstLetter } from "@shared/utils/helpers"
import {
  IconCaretDownFilled,
  IconCaretRightFilled,
  IconDatabase,
  IconHelp,
  IconPlus,
  IconRefresh,
  IconTable,
  IconTrash,
} from "@tabler/icons-react"
import { useCallback, useEffect, useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"

type Props = {
  index: number
  onDelete: () => void
  isNewWorkbook?: boolean
  collapsed?: boolean
  erroneousSheet?: string | undefined
  availableSpreadsheets: { id: string; name: string }[]
  availableStatements: {
    id: string
    name: string
    type?: SavedReportTypeEnum | undefined
    error?: string | undefined
  }[]
  availableQuestions: {
    id: string
    name: string
    error?: string | undefined
    type?: SavedReportTypeEnum
  }[]
}
export const WorkbookSpreadsheet = ({
  index,
  onDelete,
  availableSpreadsheets,
  availableStatements,
  availableQuestions,
  isNewWorkbook,
  erroneousSheet,
  collapsed,
}: Props) => {
  const {
    getValues,
    control,
    watch,
    resetField,
    formState,
    register,
    setError,
    setValue,
    trigger,
  } = useFormContext<SpreadsheetsDrawerType>()

  const [opened, { toggle, open, close }] = useDisclosure(true)
  const spredsheetItemsLength = getValues("spreadsheets").length
  const spreadsheetableType = getValues(
    `spreadsheets.${index}.spreadsheetableType`
  )
  const spreadsheetErrors = (formState.errors.spreadsheets ?? [])[index]

  const selectedSpreadsheets = new Set(
    getValues("spreadsheets").flatMap(
      (spreadSheetItem) => spreadSheetItem.sheet
    )
  )

  const initialSpreadsheetable = useMemo(
    () => ({
      type: spreadsheetableType,
      value: getValues(`spreadsheets.${index}.spreadsheetable`),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [spreadsheetableType, index]
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const spreadsheetOptionDisabled = (optionValue: string, value: string) => {
    return selectedSpreadsheets.has(optionValue) && optionValue != value
  }

  const currentSheet = getValues().spreadsheets[index].sheet
  const currentSpreadsheetable = getValues().spreadsheets[index].spreadsheetable

  const isNewSpreadsheet = currentSheet === "new_spreadsheet"
  const spreadsheetsOptions = useMemo(
    () =>
      [
        {
          label: "New Spreadsheet",
          value: "new_spreadsheet",
          labelRenderer: (
            <Flex
              gap="0.5rem"
              align="center"
              style={{ color: "var(--mantine-color-blue-6)" }}
            >
              <IconPlus size={"0.75rem"} />
              <Text fw="bold" size="xs">
                New Spreadsheet
              </Text>
            </Flex>
          ),
          disabled: false,
        },
        ...availableSpreadsheets.map(({ id, name }) => ({
          label: name,
          value: id,
          ...(erroneousSheet === name
            ? {
                rightSection: (
                  <Flex gap="0.25rem" align="center">
                    <Text size="xs">Disabled</Text>
                    <Tooltip
                      label={
                        <Box
                          w="8.375rem"
                          h="auto"
                          style={{ whiteSpace: "normal" }}
                        >
                          <Text size="xs">This spreadsheet has errors</Text>
                        </Box>
                      }
                      color="gray"
                      withArrow
                      position="top-end"
                    >
                      <IconHelp size={"0.75rem"} />
                    </Tooltip>
                  </Flex>
                ),
                disabled: true,
              }
            : { disabled: spreadsheetOptionDisabled(id, currentSheet) }),
        })),
      ].sort((_a, b) => (b.disabled ? -1 : 1)),
    [
      availableSpreadsheets,
      currentSheet,
      spreadsheetOptionDisabled,
      erroneousSheet,
    ]
  )

  const spreadsheetableOptions = useMemo(() => {
    const savedReports =
      spreadsheetableType === "MetabaseQuestion"
        ? availableQuestions
        : spreadsheetableType === "SavedFilter"
        ? availableStatements
        : []

    return [...savedReports]
      .map(({ id, name, error, ...rest }) => ({
        label: name,
        value: id,
        ...(error || spreadsheetableType === "SavedFilter"
          ? {
              rightSection: (
                <>
                  {error ? (
                    <Flex gap="0.25rem" align="center">
                      <Text size="xs">Disabled</Text>
                      <Tooltip
                        label={
                          <Box
                            w="8.375rem"
                            h="auto"
                            style={{ whiteSpace: "normal" }}
                          >
                            <Text size="xs">This option has errors</Text>
                          </Box>
                        }
                        color="gray"
                        withArrow
                        position="top-end"
                      >
                        <IconHelp size={"0.75rem"} />
                      </Tooltip>
                    </Flex>
                  ) : (
                    <Flex gap="0.25rem" align="center">
                      <Text c="gray" size="xs">
                        {capitalizeFirstLetter(rest.type as string)}
                      </Text>
                    </Flex>
                  )}
                </>
              ),
              disabled: Boolean(error),
            }
          : {}),
      }))
      .sort((_a, b) => (b.disabled ? -1 : 1))
  }, [availableQuestions, availableStatements, spreadsheetableType])

  const selectedQuestionError = availableQuestions.find(
    (question) => question.id === currentSpreadsheetable
  )?.error
  const selectedSheetError =
    availableSpreadsheets.find((sheet) => sheet.name === erroneousSheet)?.id ===
    currentSheet
      ? "Review Spreadsheet File"
      : ""

  const spreadsheetDisplayText = spreadsheetsOptions.find(
    (spreadsheet) => spreadsheet.value === currentSheet
  )?.label

  const spreadsheetableDisplayText = spreadsheetableOptions.find(
    (question) => question.value === currentSpreadsheetable
  )?.label

  const isSpreadsheetDuplicate = useCallback(
    (val: string) => {
      const disabledSpreadsheets = new Set(
        spreadsheetsOptions.flatMap((spreadsheet) =>
          spreadsheet.label.trim().toLowerCase()
        )
      )
      return disabledSpreadsheets.has(val)
    },
    [spreadsheetsOptions]
  )

  useEffect(() => {
    if (
      spreadsheetErrors?.spreadsheetable ||
      spreadsheetErrors?.sheet ||
      spreadsheetErrors?.name ||
      spreadsheetErrors?.newName
    ) {
      open()
    }
  }, [index, open, spreadsheetErrors])

  useEffect(() => {
    const isNotLastSpreadsheet = index !== spredsheetItemsLength - 1
    if (spredsheetItemsLength > 1) {
      isNotLastSpreadsheet && close()
      !isNotLastSpreadsheet && collapsed && open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spredsheetItemsLength])

  return (
    <Paper
      bg={opened ? "gray.0" : "transparent"}
      shadow={opened ? "none" : "xs"}
      style={{
        transition: "all ease 0.3s",
        borderColor: opened
          ? themeVars.colors.gray[3]
          : themeVars.colors.gray[0],
      }}
      p={rem(16)}
      withBorder
      radius={"sm"}
    >
      <Stack gap={rem(16)}>
        <Flex
          justify="space-between"
          align="center"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        >
          <Flex gap={rem(8)} align={"center"}>
            {opened ? (
              <IconCaretDownFilled
                size={14}
                style={{ color: themeVars.colors.gray[6] }}
              />
            ) : (
              <IconCaretRightFilled
                size={14}
                style={{ color: themeVars.colors.gray[6] }}
              />
            )}
            <Text size="sm" fw={500} c="gray.7">
              {spreadsheetableDisplayText ||
                (spreadsheetableType === "MetabaseQuestion"
                  ? "Question"
                  : "Report")}
            </Text>
            <IconRefresh color={themeVars.colors.gray[6]} size={14} />
            <Text size="sm" fw={500} c="gray.7">
              {getValues().spreadsheets[index].newName ||
                spreadsheetDisplayText ||
                "Spreadsheet"}
            </Text>
          </Flex>
          <ActionIcon
            variant="transparent"
            c="gray"
            size="sm"
            onClick={(e) => {
              e.preventDefault()
              onDelete()
            }}
          >
            <IconTrash size={14} />
          </ActionIcon>
        </Flex>

        <Collapse in={opened}>
          <Stack gap={rem(16)}>
            <Controller
              control={control}
              name={`spreadsheets.${index}.spreadsheetableType`}
              render={({ field: { onChange, value } }) => (
                <SegmentedControl
                  value={value}
                  styles={{ indicator: { transition: "none" } }}
                  onChange={(val) => {
                    onChange(val)
                    if (val === initialSpreadsheetable.type) {
                      setValue(
                        `spreadsheets.${index}.spreadsheetable`,
                        initialSpreadsheetable.value
                      )
                    } else {
                      resetField(`spreadsheets.${index}.spreadsheetable`)
                    }
                  }}
                  classNames={{ label: classes.label }}
                  data={[
                    {
                      label: (
                        <Flex justify={"center"} gap={".5rem"} align={"center"}>
                          <IconDatabase size={".875rem"} />
                          <Text size="sm">Question</Text>
                        </Flex>
                      ),
                      value: "MetabaseQuestion",
                    },
                    {
                      label: (
                        <Flex justify={"center"} gap={".5rem"} align={"center"}>
                          <IconTable size={".875rem"} />
                          <Text size="sm">Reports</Text>
                        </Flex>
                      ),
                      value: "SavedFilter",
                    },
                  ]}
                />
              )}
            />
            <Controller
              control={control}
              name={`spreadsheets.${index}.spreadsheetable`}
              render={({ field: { onChange, onBlur, value } }) => (
                <SelectCombobox
                  autocomplete
                  inputProps={{
                    error:
                      selectedQuestionError ||
                      spreadsheetErrors?.spreadsheetable?.message,
                    label:
                      spreadsheetableType === "MetabaseQuestion"
                        ? "Question"
                        : spreadsheetableType === "SavedFilter"
                        ? "Report"
                        : "Source",
                  }}
                  onBlur={onBlur}
                  onChange={(val) => {
                    onChange(val)
                    watch("spreadsheets")
                  }}
                  value={value}
                  options={spreadsheetableOptions}
                />
              )}
            />
            <Controller
              control={control}
              name={`spreadsheets.${index}.sheet`}
              render={({ field: { onChange, onBlur, value } }) => (
                <SelectCombobox
                  autocomplete
                  onBlur={onBlur}
                  onChange={(val) => {
                    onChange(val)
                    resetField(`spreadsheets.${index}.newName`)
                    setValue(
                      `spreadsheets.${index}.name`,
                      spreadsheetsOptions.find(
                        (spreadsheet) => spreadsheet.value === val
                      )?.label ?? ""
                    )
                    watch("spreadsheets")
                    trigger(`spreadsheets.${index}.name`)
                  }}
                  inputProps={{
                    error:
                      selectedSheetError || spreadsheetErrors?.sheet?.message,
                    label: "Spreadsheet",
                  }}
                  value={value}
                  options={spreadsheetsOptions}
                />
              )}
            />
            <Transition
              mounted={isNewSpreadsheet || (!!isNewWorkbook && index === 0)}
              transition="slide-down"
              duration={200}
              exitDuration={100}
              timingFunction="ease"
            >
              {(styles) => (
                <div style={styles}>
                  <TextInput
                    {...register(`spreadsheets.${index}.newName`)}
                    error={spreadsheetErrors?.newName?.message}
                    onBlur={(e) => {
                      const stringToCompare = e.target.value
                        .trim()
                        .toLowerCase()
                      isSpreadsheetDuplicate(stringToCompare) &&
                        setError(`spreadsheets.${index}.newName`, {
                          message: "This Sheet Name already exists.",
                        })
                    }}
                    label={
                      isNewSpreadsheet
                        ? "New Spreadsheet Name"
                        : "Rename Spreadsheet"
                    }
                  />
                </div>
              )}
            </Transition>
          </Stack>
        </Collapse>
      </Stack>
    </Paper>
  )
}
