import {
  SavedReportsTypeEnum,
  savedStatementsQuery as SavedStatementsQueryType,
} from "./__generated__/savedStatementsQuery.graphql"
import { SavedStatementsTable } from "./saved-statements-table"
import { Box, Text, rem } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useSearchParams } from "react-router-dom"

const SavedStatementsQuery = graphql`
  query savedStatementsQuery(
    $clientId: ID!
    $reportType: [SavedReportsTypeEnum!]
    $pinned: Boolean
    $search: String
    $userIds: [ID!]
    $cursor: String
    $count: Int
  ) {
    ...savedStatementsTableFragment
      @arguments(
        count: $count
        cursor: $cursor
        clientId: $clientId
        reportType: $reportType
        pinned: $pinned
        search: $search
        userIds: $userIds
      )
  }
`

export const SavedStatements = () => {
  const { currentClient, lastUpdate } = useUserStore()
  const [searchParams] = useSearchParams()

  const paramReportType: SavedReportsTypeEnum[] = searchParams.getAll(
    "reportType"
  ) as SavedReportsTypeEnum[]
  const paramUserIds = searchParams.getAll("userIds")
  const paramPinned = searchParams.get("pinned") == "true" ? true : false
  const paramSearch = searchParams.get("search")

  const savedStatements = useLazyLoadQuery<SavedStatementsQueryType>(
    SavedStatementsQuery,
    {
      clientId: currentClient.id ?? "",
      reportType: paramReportType.length > 0 ? paramReportType : [],
      search: paramSearch ?? "",
      userIds: paramUserIds.length > 0 ? paramUserIds : [],
      pinned: paramPinned,
    },
    {
      fetchKey: [currentClient.id, lastUpdate].join(""),
      fetchPolicy: "store-and-network",
    }
  )

  return (
    <Box
      p={rem(32)}
      mih="100dvh"
      display="grid"
      style={{ gridTemplateRows: "min-content 1fr", gap: "2rem" }}
    >
      <Text size="xxl" fw={700} c={"gray"} component="h1">
        Saved Reports
      </Text>
      <SavedStatementsTable savedStatements={savedStatements} />
    </Box>
  )
}
