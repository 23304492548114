import styles from "./multiselect.module.scss"
import { Combobox, Group, Input, InputBase, useCombobox } from "@mantine/core"
import { IconCheck, IconChevronDown } from "@tabler/icons-react"
import { compact } from "lodash"

const MAX_DISPLAYED_VALUES = 1

type Props = {
  data: {
    label: string
    value: string
  }[]
  defaultValue: string[]
  onChange: (values: string[]) => void
  placeholder?: string
}

export function MultiSelect({
  data,
  defaultValue,
  placeholder = "Pick one or more",
  onChange,
}: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  })

  const defaultCandidates = compact(
    defaultValue.map(
      (value) => data.find((item) => item.value === value)?.value
    )
  )

  const handleValueSelect = (val: string) => {
    const newValues = defaultCandidates.includes(val)
      ? defaultCandidates.filter((v) => v !== val)
      : [...defaultCandidates, val]

    onChange(newValues)
  }

  const values = defaultCandidates
    .slice(
      0,
      MAX_DISPLAYED_VALUES === defaultCandidates.length
        ? MAX_DISPLAYED_VALUES
        : MAX_DISPLAYED_VALUES - 1
    )
    .map((item) => data.find((option) => option.value === item)?.label)
    .join(", ")

  const options = data.map((item) => (
    <Combobox.Option
      value={item.value}
      key={item.value}
      active={defaultCandidates.includes(item.value)}
      classNames={{
        option: styles.Option,
      }}
    >
      <Group gap="sm" justify="space-between">
        <span>{item.label}</span>
        {defaultCandidates.includes(item.value) ? (
          <IconCheck size={16} />
        ) : null}
      </Group>
    </Combobox.Option>
  ))

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        handleValueSelect(val)
      }}
    >
      <Combobox.Target>
        <InputBase
          pointer
          onClick={() => combobox.toggleDropdown()}
          component="button"
          type="button"
          rightSection={<IconChevronDown size={16} />}
          rightSectionPointerEvents="none"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {defaultCandidates.length > 0 ? (
            <>
              {values}
              {defaultCandidates.length > MAX_DISPLAYED_VALUES &&
                `${defaultCandidates.length} Selected`}
            </>
          ) : (
            <Input.Placeholder mt="0.125rem">{placeholder}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
