import { balanceAccountsTableAccountTypesFragment$key } from "./__generated__/balanceAccountsTableAccountTypesFragment.graphql"
import {
  balanceAccountsTableQuery as BalanceAccountsTableQueryType,
  CashFlowCategoryEnum,
} from "./__generated__/balanceAccountsTableQuery.graphql"
import { balanceAccountsTableSettingMutation as BalanceAccountsTableSettingMutationType } from "./__generated__/balanceAccountsTableSettingMutation.graphql"
import styles from "./balance-accounts-table.module.scss"
import { EmptyAccountsTable } from "./empty-accounts-table"
import {
  Box,
  Button,
  Card,
  Flex,
  Group,
  InputLabel,
  Loader,
  Pagination,
  SegmentedControl,
  Stack,
  Switch,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { FilterMultiselect } from "@shared/ui/filter-multiselect"
import { ProgressBar } from "@shared/ui/progress-bar/progress-bar"
import { Select } from "@shared/ui/select"
import { Table } from "@shared/ui/table"
import { IconSearch } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from "react-relay"
import { useSearchParams } from "react-router-dom"

const BalanceAccountsTableQuery = graphql`
  query balanceAccountsTableQuery(
    $cursor: String
    $count: Int
    $connectionIds: [ID!]!
    $accountTypes: [String!]
    $cashFlowCategories: [CashFlowCategoryEnum!]
    $includeInCashFlow: Boolean
    $search: String
    $pageNumber: Int
    $limit: Int
  ) {
    getBalanceAccounts(
      connectionIds: $connectionIds
      accountTypes: $accountTypes
      cashFlowCategories: $cashFlowCategories
      includeInCashFlow: $includeInCashFlow
      first: $count
      after: $cursor
      search: $search
      pageNumber: $pageNumber
      limit: $limit
    ) @connection(key: "BalanceAccountsTableQuery_getBalanceAccounts") {
      count
      totalCount
      edges {
        node {
          id
          name
          accountType
          cashFlowCategory
          includeInCashFlow
        }
      }
    }
  }
`

const BalanceAccountsTableSettingMutation = graphql`
  mutation balanceAccountsTableSettingMutation(
    $input: BalanceCashFlowSettingMutationInput!
  ) {
    balanceCashFlowSetting(input: $input) {
      ledgerAccounts {
        id
        name
        accountType
        cashFlowCategory
        includeInCashFlow
      }
    }
  }
`

const BalanceAccountsTableAccountTypesFragment = graphql`
  fragment balanceAccountsTableAccountTypesFragment on Query
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" }) {
    getLedgerAccountTypes(first: $count, after: $cursor, statement: BALANCE)
      @connection(
        key: "BalanceAccountsTableAccountTypesFragment_getLedgerAccountTypes"
      ) {
      edges {
        node {
          name
        }
      }
    }
  }
`

type BalanceAccount = {
  id: string
  name: string
  accountType: string
  cashFlowCategory: CashFlowCategoryEnum
  includeInCashFlow: boolean
}

type LedgerAccountType = {
  key: string
  label: string
}

type UpdateBalanceAccounts = {
  id: string
  cashFlowCategory: CashFlowCategoryEnum
  includeInCashFlow: boolean
}[]

type FormType = {
  accountTypes: string[] | null
  cashFlowCategories: CashFlowCategoryEnum[] | null
  includeInCashFlow: string[] | null
  search: string | null
  limit: number
  pageNumber: number
}
const REFETCH_DEBOUNCE_TIMEOUT = 500

type Props = {
  accountTypes: balanceAccountsTableAccountTypesFragment$key
  defaultLimit: number
  connectionId: string
  isVisible?: boolean
}

const DEFAULT_TABLE_LIMIT = 50

export const BalanceAccountsTable = ({
  accountTypes,
  defaultLimit,
  connectionId,
  isVisible = true,
}: Props) => {
  const formInitialValues: FormType = {
    accountTypes: [],
    cashFlowCategories: [],
    includeInCashFlow: [],
    search: "",
    limit: defaultLimit,
    pageNumber: 1,
  }

  const { currentClient } = useUserStore()
  const [searchParams, setSearchParams] = useSearchParams()

  const paramAccountTypes = searchParams.getAll("accountTypes")
  const paramCashFlowCategories = searchParams.getAll(
    "cashFlowCategories"
  ) as CashFlowCategoryEnum[]
  const paramIncludeInCashFlow = searchParams.getAll("includeInCashFlow")
  const paramLimit = searchParams.get("limit")
  const paramPageNumber = searchParams.get("pageNumber")
  const paramSearch = searchParams.get("search")

  const includeInCashFlow =
    paramIncludeInCashFlow == null ||
    paramIncludeInCashFlow.length === 0 ||
    paramIncludeInCashFlow.length === 2
      ? null
      : paramIncludeInCashFlow[0] == "true"
      ? true
      : false

  const balanceAccountsInputs = {
    clientId: currentClient.id || "",
    connectionIds: [connectionId || ""],
    limit: Number(paramLimit ?? DEFAULT_TABLE_LIMIT),
    accountTypes: paramAccountTypes,
    cashFlowCategories: paramCashFlowCategories,
    includeInCashFlow: includeInCashFlow,
    pageNumber: paramPageNumber ? Number(paramPageNumber) : 1,
    ...(paramSearch ? { search: paramSearch || null } : {}),
  }

  const {
    getBalanceAccounts: { edges, totalCount },
  } = useLazyLoadQuery<BalanceAccountsTableQueryType>(
    BalanceAccountsTableQuery,
    balanceAccountsInputs,
    { fetchPolicy: "network-only" }
  )

  const [searchLoading, setSearchLoading] = useState(false)
  const [refetchLoading, setRefetchLoading] = useState(false)

  const {
    getLedgerAccountTypes: { edges: accountTypesEdges },
  } = useFragment(BalanceAccountsTableAccountTypesFragment, accountTypes)

  const [updateBalanceAccount] =
    useMutation<BalanceAccountsTableSettingMutationType>(
      BalanceAccountsTableSettingMutation
    )

  const handleUpdate = (updateBalanceAccounts: UpdateBalanceAccounts) => {
    updateBalanceAccount({
      variables: { input: { ledgerAccounts: updateBalanceAccounts } },
    })
  }

  const cashType = [
    { key: "OPERATING", label: "Operating", value: "OPERATING" },
    { key: "FINANCING", label: "Financing", value: "FINANCING" },
    { key: "INVESTING", label: "Investing", value: "INVESTING" },
  ]

  const {
    handleSubmit,
    watch,
    control,
    formState: { isDirty, dirtyFields },
    reset,
    getValues,
    setValue,
    resetField,
  } = useForm<FormType>({
    defaultValues: {
      accountTypes: paramAccountTypes || [],
      cashFlowCategories: paramCashFlowCategories || [],
      includeInCashFlow: paramIncludeInCashFlow || [],
      search: paramSearch || "",
      limit: paramLimit ? Number(paramLimit) : defaultLimit,
      pageNumber: paramPageNumber ? Number(paramPageNumber) : 1,
    },
  })
  const cleanFilters = (params: FormType) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cleanedFilters: Record<string, any> = {}
    Object.keys(params).forEach((key) => {
      const formKey = key as keyof FormType
      if (
        formInitialValues[formKey] !== params[formKey] &&
        params[formKey] !== null
      ) {
        cleanedFilters[formKey] = params[formKey]
      }
    })
    return cleanedFilters
  }
  const onSubmit = debounce((params: FormType) => {
    const cleanedFilters = cleanFilters({
      ...params,
      pageNumber: params.search ? 1 : params.pageNumber,
    })
    setSearchParams((prev) => ({
      ...prev,
      ...cleanedFilters,
    }))
    setSearchLoading(false)
    setRefetchLoading(false)
  }, REFETCH_DEBOUNCE_TIMEOUT)

  const columns: ColumnDef<BalanceAccount>[] = [
    { header: "Account Name", accessorKey: "name" },
    { header: "Account Type", accessorKey: "accountType" },
    {
      header: "Cash Type",
      accessorKey: "cashFlowCategory",
      enableSorting: false,
      cell: (info) => {
        const account = info.row.original

        return (
          <SegmentedControl
            transitionDuration={0}
            key={`control-${account.id}`}
            data={cashType}
            value={account.cashFlowCategory}
            size="xs"
            className={styles.BalanceAccountsTable__segmentedControl}
            onChange={(value) =>
              handleUpdate([
                {
                  id: account.id,
                  cashFlowCategory: value as CashFlowCategoryEnum,
                  includeInCashFlow: account.includeInCashFlow,
                },
              ])
            }
          />
        )
      },
    },
    {
      header: "Include in Cash Flow",
      accessorKey: "includeInCashFlow",
      enableSorting: false,
      meta: {
        align: "right",
      },
      cell: (info) => {
        const account = info.row.original

        return (
          <Switch
            key={`switch-${account.id}`}
            checked={account.includeInCashFlow}
            onChange={(event) => {
              handleUpdate([
                {
                  id: account.id,
                  cashFlowCategory: account.cashFlowCategory,
                  includeInCashFlow: event.currentTarget.checked,
                },
              ])
            }}
          />
        )
      },
    },
  ]
  const data: BalanceAccount[] = edges.map(({ node }) => ({
    id: node.id,
    name: node.name ?? "",
    accountType: node.accountType ?? "",
    cashFlowCategory: node.cashFlowCategory ?? "OPERATING",
    includeInCashFlow: node.includeInCashFlow ?? true,
  }))

  const ledgerAccountTypes: LedgerAccountType[] = accountTypesEdges.map(
    ({ node }) => ({
      key: node.name ?? "",
      label: node.name ?? "",
    })
  )

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch])

  const filtersDirty = Object.keys(formInitialValues).some(
    (key) =>
      getValues()[key as keyof FormType]?.toString().trim() !==
      formInitialValues[key as keyof FormType]?.toString().trim()
  )

  return (
    <>
      {refetchLoading && (
        <Box
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            left: 0,
            zIndex: 150,
          }}
        >
          <ProgressBar />
        </Box>
      )}
      <Stack gap={rem(32)} display={isVisible ? "inherit" : "none"}>
        <Group justify="space-between">
          <Group>
            <Text size="sm">Filter by:</Text>
            <Controller
              name="accountTypes"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FilterMultiselect
                  options={ledgerAccountTypes}
                  values={value ?? []}
                  noCheckedLabel="Account Type"
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="cashFlowCategories"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FilterMultiselect
                  options={cashType}
                  values={value ?? []}
                  noCheckedLabel="Cash Type"
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="includeInCashFlow"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FilterMultiselect
                  options={[
                    { label: "Include", key: "true" },
                    { label: "Not Include", key: "false" },
                  ]}
                  values={value ?? []}
                  noCheckedLabel="Include in Cash Flow"
                  onChange={onChange}
                />
              )}
            />
            {(isDirty || filtersDirty) &&
              !dirtyFields.pageNumber &&
              !dirtyFields.limit && (
                <Button
                  variant="subtle"
                  size="sm"
                  px={0}
                  bg={"transparent"}
                  onClick={() => {
                    reset(formInitialValues)
                  }}
                >
                  Clear Filters
                </Button>
              )}
          </Group>
          <Controller
            name="search"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <TextInput
                ref={ref}
                placeholder="Search"
                value={value ?? ""}
                onChange={(e) => {
                  e.preventDefault()
                  resetField("pageNumber")
                  !searchLoading && setSearchLoading(true)
                  onChange(e)
                }}
                leftSection={<IconSearch size={16} />}
                rightSection={searchLoading ? <Loader size={16} /> : null}
                w={"18.5rem"}
                size="sm"
              />
            )}
          />
        </Group>
        <Stack gap={0}>
          {data.length > 0 ? (
            <>
              <Table columns={columns} data={data} compact withPagination />
              <Card
                shadow="xs"
                py={".75rem"}
                mt={".0325rem"}
                px={"1.5rem"}
                style={{ borderRadius: "0 0 0.5rem 0.5rem" }}
              >
                <Flex justify={"space-between"}>
                  <Flex align={"center"} gap={"xs"}>
                    <InputLabel>Rows per page</InputLabel>
                    <Controller
                      name="limit"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          data={["50", "100", "150", "200"]}
                          onChange={(val) => {
                            onChange(Number(val))
                            setRefetchLoading(true)
                            setValue("pageNumber", 1)
                          }}
                          value={value?.toString() ?? null}
                          w={"4.6875rem"}
                        />
                      )}
                    />
                  </Flex>
                  <Controller
                    name="pageNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Pagination
                        total={Math.ceil(
                          (totalCount ?? 0) / getValues("limit")
                        )}
                        value={value}
                        onChange={(val) => {
                          onChange(val)
                          setRefetchLoading(true)
                        }}
                      />
                    )}
                  />
                </Flex>
              </Card>
            </>
          ) : (
            <EmptyAccountsTable />
          )}
        </Stack>
      </Stack>
    </>
  )
}
