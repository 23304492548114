import { SpreadsheetsDrawerType } from "../spreadsheets-drawer"
import { workbookDataFieldsListWorkbooksQuery } from "./__generated__/workbookDataFieldsListWorkbooksQuery.graphql"
import { ListWorkbookFromIntegration } from "./workbook-data-fields"
import { Box, Flex, Text, Tooltip } from "@mantine/core"
import {
  SelectCombobox,
  SelectComboboxOptionType,
  SelectComboboxProps,
} from "@shared/ui/select-combobox/select-combobox"
import { IconHelp, IconPlus } from "@tabler/icons-react"
import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { PreloadedQuery, usePreloadedQuery } from "react-relay"

interface Props extends Omit<SelectComboboxProps, "options"> {
  queryReference: PreloadedQuery<workbookDataFieldsListWorkbooksQuery>
  options?: SelectComboboxOptionType[]
}
export const WorkbookSelector = ({ queryReference, ...rest }: Props) => {
  const {
    listWorkbooksFromIntegration: { edges },
  } = usePreloadedQuery(ListWorkbookFromIntegration, queryReference)
  const { setValue, trigger } = useFormContext<SpreadsheetsDrawerType>()

  const availableWorkbooks = useMemo(
    () => [
      {
        label: "New Workbook",
        value: "new_workbook",
        labelRenderer: (
          <Flex
            gap="0.5rem"
            align="center"
            style={{ color: "var(--mantine-color-blue-6)" }}
          >
            <IconPlus size={"0.75rem"} />
            <Text fw="bold" size="xs">
              New Workbook
            </Text>
          </Flex>
        ),
      },
      ...edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
        ...(node.isLinked
          ? {
              rightSection: (
                <Flex gap="0.25rem" align="center">
                  <Text size="xs">Disabled</Text>
                  <Tooltip
                    label={
                      <Box
                        w="8.375rem"
                        h="auto"
                        style={{ whiteSpace: "normal" }}
                      >
                        <Text size="xs">
                          This workbook is already connected
                        </Text>
                      </Box>
                    }
                    color="gray"
                    withArrow
                    position="top-end"
                  >
                    <IconHelp size={"0.75rem"} />
                  </Tooltip>
                </Flex>
              ),
              disabled: true,
            }
          : {}),
      })),
    ],
    [edges]
  )

  return (
    <SelectCombobox
      {...rest}
      options={availableWorkbooks}
      autocomplete
      onChange={(val) => {
        setValue(
          "workbookName",
          availableWorkbooks.find((workbook) => workbook.value === val)
            ?.label ?? ""
        )
        trigger("workbookName")
        rest.onChange(val)
      }}
    />
  )
}
