import { deleteLinkModalDeleteMutation as DeleteLinkModalDeleteMutationType } from "./__generated__/deleteLinkModalDeleteMutation.graphql"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { graphql, useMutation } from "react-relay"

const DeleteLinkModalDeleteMutation = graphql`
  mutation deleteLinkModalDeleteMutation(
    $input: DeleteSharedDashboardMutationInput!
  ) {
    deleteSharedDashboard(input: $input) {
      errors
      sharedDashboard {
        id
      }
    }
  }
`

type Props = {
  dashboardId: number
  sharedDashboardId: string
  opened: boolean
  onClose: () => void
}

export const DeleteLinkModal = ({
  dashboardId,
  sharedDashboardId,
  opened,
  onClose,
}: Props) => {
  const [deleteLinkMutation, deleteLinkLoading] =
    useMutation<DeleteLinkModalDeleteMutationType>(
      DeleteLinkModalDeleteMutation
    )

  const handleDeleteClick = () => {
    deleteLinkMutation({
      variables: {
        input: {
          sharedDashboardId,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "Link Deleted Successfully!",
          message:
            "No one with the previous link can access it. Generate a new link if needed to share.",
        })
        onClose()
      },
      onError: () => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: "Please try again",
        })
      },
      updater: (store) => {
        store
          .getRoot()
          .getLinkedRecord(
            `getSharedDashboard(metabaseDashboardId:${dashboardId})`
          )
          ?.setValue(null, "sharedDashboard")
      },
    })
  }

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to delete the link?"
        opened={opened}
        onClose={onClose}
        cancelButtonProps={{
          onClick: onClose,
        }}
        confirmButtonProps={{
          onClick: handleDeleteClick,
          children: "Delete Link",
          color: "red",
          loading: deleteLinkLoading,
        }}
      >
        Delete the link is a permanent action, rendering the board inaccessible
        to anyone with the link. For a new link, simply generate a new one.
      </ConfirmationModal>
    </>
  )
}
