import { AuthRoutes } from "./auth-routes"
import { PublicRoutes } from "./public-routes"
import { AuthRelayProvider, PublicRelayProvider } from "@shared/relay/providers"
import { NotFoundError } from "@shared/ui/errors/not-found-error"
import { useRoutes } from "react-router-dom"

export const Router = () => {
  return useRoutes([
    {
      element: <PublicRelayProvider />,
      children: [...PublicRoutes],
    },
    {
      element: <AuthRelayProvider />,
      children: [...AuthRoutes],
    },
    {
      path: "*",
      element: <NotFoundError />,
    },
  ])
}
