import { Select } from "../select"
import {
  clientDrawerCreateMutation as ClientDrawerCreateMutationType,
  ClientTypeEnum,
} from "./__generated__/clientDrawerCreateMutation.graphql"
import { clientDrawerUpdateMutation as ClientDrawerUpdateMutationType } from "./__generated__/clientDrawerUpdateMutation.graphql"
import { CloseMonthPicker } from "./month-picker"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button, Stack, TextInput } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { AppDrawer } from "@shared/ui/app-drawer"
import { CloseMonthSelector } from "@shared/ui/close-month-selector"
import { notifications } from "@shared/ui/notifications"
import { format, lastDayOfMonth, sub } from "date-fns"
import { useEffect } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { isValidPhoneNumber } from "react-phone-number-input"
import PhoneInput from "react-phone-number-input/react-hook-form-input"
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import { z } from "zod"

const DEFAULT_FISCAL_CLOSE_MONTH = "1"

const ClientDrawerCreateMutation = graphql`
  mutation clientDrawerCreateMutation(
    $input: CreateClientMutationInput!
    $connections: [ID!]!
  ) {
    createClient(input: $input) {
      client @appendNode(connections: $connections, edgeTypeName: "Client") {
        createdAt
        id
        metabaseCollectionId
        metabaseDefaultDashboardId
        metabaseGroupId
        metabasePublicGroupId
        metabasePublicUserId
        name
        slug
        status
        type
        updatedAt
      }
      errors
      metabaseJwtToken
    }
  }
`

const ClientDrawerUpdateMutation = graphql`
  mutation clientDrawerUpdateMutation(
    $input: UpdateCurrentClientMutationInput!
  ) {
    updateCurrentClient(input: $input) {
      client {
        id
        creator {
          firstName
          lastName
        }
        createdAt
        logo
        fiscal
        name
        type
        contactEmail
        contactName
        contactPhone
        closeMonth
        status
      }
      errors
    }
  }
`

const formValidationSchema = z.object({
  name: z.string().trim().min(1, "Required"),
  fiscal: z.string(),
  type: z.string().min(1, "Required"),
  closeMonth: z.date(),
  contactName: z
    .string()
    .trim()
    .transform((name) => (name === "" ? null : name)),
  contactEmail: z
    .string()
    .trim()
    .email("Enter a valid email")
    .or(z.literal("").transform(() => null)),
  contactPhone: z
    .string()
    .trim()
    .refine(isValidPhoneNumber, { message: "Invalid phone number" })
    .or(z.literal("").transform(() => null))
    .nullable(),
})

type formValues = z.infer<typeof formValidationSchema>

type Props = {
  opened: boolean
  onClose: () => void
  propsFormValues?: formValues
}

const defaultValues: formValues = {
  fiscal: DEFAULT_FISCAL_CLOSE_MONTH,
  name: "",
  contactPhone: "",
  contactName: "",
  contactEmail: "",
  type: "",
  closeMonth: sub(new Date(), { months: 1 }),
}

type CompanyTypes = { label: string; value: ClientTypeEnum }[]

const COMPANY_TYPES: CompanyTypes = [
  { label: "SaaS", value: "SAAS" },
  { label: "E-Commerce", value: "ECOMMERCE" },
  { label: "Services", value: "SERVICES" },
  { label: "Retail", value: "RETAIL" },
  { label: "Other", value: "OTHER" },
]

export const ClientDrawer = ({ opened, onClose, propsFormValues }: Props) => {
  const clientFormValues = propsFormValues ?? defaultValues

  const { userId, setCurrentClientInfo, setMetabaseToken } = useUserStore()
  const [createClient, createClientLoading] =
    useMutation<ClientDrawerCreateMutationType>(ClientDrawerCreateMutation)

  const [updateClient, updateClientLoading] =
    useMutation<ClientDrawerUpdateMutationType>(ClientDrawerUpdateMutation)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<formValues>({
    mode: "onTouched",
    defaultValues: clientFormValues,
    resolver: zodResolver(formValidationSchema),
  })

  const createNewClient = (data: formValues) => {
    const clientsConnection = ConnectionHandler.getConnectionID(
      "root",
      "ClientsTableFragment_getClients"
    )

    const currentUserConnection = ConnectionHandler.getConnectionID(
      userId ?? "root",
      "ClientSelectorClientsFragment_clients"
    )

    createClient({
      variables: {
        input: {
          name: data.name,
          fiscal: Number.parseInt(data.fiscal),
          type: data.type as ClientTypeEnum, // This cast is necessary since we could have default empty value
          contactEmail: data.contactEmail,
          contactName: data.contactName,
          contactPhone: data.contactPhone,
          closeMonth: format(lastDayOfMonth(data.closeMonth), "yyyy-MM-dd"),
        },
        connections: [currentUserConnection, clientsConnection],
      },
      onCompleted: (info) => {
        const { createClient: createdClientData } = info

        if (createdClientData?.client) {
          const { client: clientData, metabaseJwtToken } = createdClientData

          setCurrentClientInfo(
            clientData.id,
            clientData.name,
            clientData.slug,
            clientData.metabaseDefaultDashboardId
          )

          if (metabaseJwtToken) {
            setMetabaseToken(metabaseJwtToken)
          }
        }

        onClose()
        reset()

        notifications.show({
          title: "New client has been successfully created!",
          message: "Head over to configure their space.",
          variant: "success",
        })
      },
      onError: (error) => {
        notifications.show({
          title: error.message,
          message: "Please try again",
          variant: "error",
        })
      },
      updater: (store) => {
        const currentUserData = store
          .getRoot()
          .getLinkedRecord("getCurrentUserData")
          ?.getLinkedRecord("user")
          ?.getLinkedRecord<{ count: number }>("clients")

        const clientData = store
          .getRoot()
          .getLinkedRecord<{ count: number }>("getClients")

        if (clientData && currentUserData) {
          const countValue = clientData.getValue("count")

          clientData.setValue(countValue + 1, "count")
          currentUserData.setValue(countValue + 1, "count")
        }
      },
    })
  }

  const updateClientInfo = (data: formValues) => {
    updateClient({
      variables: {
        input: {
          name: data.name,
          fiscal: Number.parseInt(data.fiscal),
          type: data.type as ClientTypeEnum, // This cast is necessary since we could have default empty value
          contactEmail: data.contactEmail,
          contactName: data.contactName,
          contactPhone: data.contactPhone,
          closeMonth: format(lastDayOfMonth(data.closeMonth), "yyyy-MM-dd"),
        },
      },
      onCompleted: () => {
        onClose()

        notifications.show({
          title: "Changes Saved Successfully",
          message:
            "Your changes have been saved successfully. You're all set to continue!",
          variant: "success",
        })
      },
      onError: () => {
        notifications.show({
          title: "There was an error updating client",
          message: "Please try again",
          variant: "error",
        })
      },
    })
  }

  const onSubmit: SubmitHandler<formValues> = (data) => {
    propsFormValues ? updateClientInfo(data) : createNewClient(data)
  }

  useEffect(() => {
    reset(clientFormValues)
  }, [reset, propsFormValues, clientFormValues])

  return (
    <AppDrawer
      opened={opened}
      title={propsFormValues ? "Edit Client Information" : "New Client"}
      onClose={() => {
        onClose()
        reset(clientFormValues)
      }}
      closeOnClickOutside={!isDirty}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Stack justify="space-between" h="100%">
          <Stack gap="1.5rem">
            <TextInput
              {...register("name")}
              label="Client's Name"
              error={errors.name?.message}
              withAsterisk
            />
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  ref={ref}
                  label="Company Type"
                  placeholder="Select Company Type"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value ?? ""}
                  data={COMPANY_TYPES}
                  error={errors.type?.message}
                  withAsterisk
                />
              )}
            />
            <Controller
              control={control}
              name="closeMonth"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CloseMonthPicker
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  onBlur={onBlur}
                />
              )}
            />
            <Controller
              control={control}
              name="fiscal"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CloseMonthSelector
                  {...register("fiscal")}
                  label="Fiscal Start Month"
                  placeholder="Select Your Fiscal Start Month"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  ref={ref}
                  error={errors.fiscal?.message}
                />
              )}
            />

            <TextInput
              {...register("contactName")}
              label="Contact Name"
              error={errors.contactName?.message}
            />
            <TextInput
              {...register("contactEmail")}
              label="Contact E-mail"
              type="email"
              inputMode="email"
              error={errors.contactEmail?.message}
            />
            <PhoneInput
              inputComponent={TextInput}
              label="Contact Phone"
              name="contactPhone"
              control={control}
              error={errors.contactPhone?.message}
            />
          </Stack>
          <Button
            type="submit"
            disabled={!isValid || !isDirty}
            loading={
              propsFormValues ? updateClientLoading : createClientLoading
            }
            fullWidth
          >
            Save
          </Button>
        </Stack>
      </form>
    </AppDrawer>
  )
}
