/**
 * @generated SignedSource<<834988ff7e6c476923cf5310b284098e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImpersonateUserMutationInput = {
  clientMutationId?: string | null;
  userId: string;
};
export type impersonateButtonImpersonateUserMutation$variables = {
  input: ImpersonateUserMutationInput;
};
export type impersonateButtonImpersonateUserMutation$data = {
  readonly impersonateUser: {
    readonly alpyneJwtToken: string | null;
    readonly errors: ReadonlyArray<string>;
    readonly superAdmin: {
      readonly fullName: string | null;
      readonly id: string;
    } | null;
    readonly superAdminToken: string | null;
    readonly user: {
      readonly fullName: string | null;
      readonly id: string;
    } | null;
  } | null;
};
export type impersonateButtonImpersonateUserMutation = {
  response: impersonateButtonImpersonateUserMutation$data;
  variables: impersonateButtonImpersonateUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ImpersonateUserMutationPayload",
    "kind": "LinkedField",
    "name": "impersonateUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "alpyneJwtToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "superAdminToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "superAdmin",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "impersonateButtonImpersonateUserMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "impersonateButtonImpersonateUserMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c74711372417da37ad89fcb5fb793f6e",
    "id": null,
    "metadata": {},
    "name": "impersonateButtonImpersonateUserMutation",
    "operationKind": "mutation",
    "text": "mutation impersonateButtonImpersonateUserMutation(\n  $input: ImpersonateUserMutationInput!\n) {\n  impersonateUser(input: $input) {\n    errors\n    alpyneJwtToken\n    superAdminToken\n    superAdmin {\n      id\n      fullName\n    }\n    user {\n      id\n      fullName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "838864bd353d92ef99926c26ede311da";

export default node;
