import { isEmpty } from "lodash"
import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

export type State = {
  alpyneToken: string | null
  metabaseToken: string | null
  navbarCollapsed: boolean
  currentClient: {
    name: string | null
    dashboardId: number | null
    id: string | null
    slug: string | null
  }
  userId: string | null
  isAdmin: boolean | null
  isSuperAdmin: boolean | null
  impersonatorUser: {
    name: string | null
    id: string | null
    jwtToken: string | null
  }
  lastUpdate: number
  cashFlowPath: string | null
  transactionPath: string | null
  features: ReadonlyArray<string>
  permissions: { [key: string]: boolean }
}

type UserInfo = Partial<Pick<State, "userId" | "isAdmin" | "isSuperAdmin">>

type Actions = {
  setNavbarCollapsed: (collapsed: boolean) => void
  setAlpyneToken: (token: string) => void
  setImpersonatorUser: (
    id: string | null,
    name: string | null,
    jwtToken: string | null
  ) => void
  setMetabaseToken: (token: string) => void
  setCurrentClientInfo: (
    id: string,
    name: string,
    slug: string,
    dashboardId: number
  ) => void
  setUserInfo: (userInfo: UserInfo) => void
  setCashFlowPath: (pathname: string | null) => void
  setTransactionPath: (pathname: string | null) => void
  reset: () => void
  setFeatureFlags: (
    features: ReadonlyArray<string>,
    permissions: { [key: string]: boolean }
  ) => void
  checkFeature: (feature: string) => boolean
}

const initialState: State = {
  alpyneToken: null,
  metabaseToken: null,
  navbarCollapsed: false,
  currentClient: {
    name: null,
    dashboardId: null,
    id: null,
    slug: null,
  },
  userId: null,
  isAdmin: null,
  isSuperAdmin: null,
  impersonatorUser: {
    name: null,
    id: null,
    jwtToken: null,
  },
  lastUpdate: Date.now(),
  cashFlowPath: null,
  transactionPath: null,
  features: [],
  permissions: {},
}

export const useUserStore = create(
  persist(
    immer<State & Actions>((set, getState) => ({
      ...initialState,
      setNavbarCollapsed: (collapsed) =>
        set(() => ({ navbarCollapsed: collapsed })),
      setAlpyneToken: (token) => set(() => ({ alpyneToken: token })),
      setImpersonatorUser: (id, name, jwtToken) =>
        set(() => ({ impersonatorUser: { id, name, jwtToken } })),
      setMetabaseToken: (token) => set(() => ({ metabaseToken: token })),
      setCurrentClientInfo: (id, name, slug, dashboardId) => {
        set(() => ({
          currentClient: {
            name: name,
            dashboardId: dashboardId,
            id: id,
            slug: slug,
          },
          lastUpdate: Date.now(),
        }))
      },
      setUserInfo: (input) => set(() => ({ ...input })),
      setCashFlowPath: (pathname) => set(() => ({ cashFlowPath: pathname })),
      setTransactionPath: (pathname) =>
        set(() => ({ transactionPath: pathname })),
      reset: () => {
        set(initialState)
      },
      setFeatureFlags: (features, permissions) =>
        set(() => ({ features: features, permissions: permissions })),
      checkFeature: (feature) => {
        const { permissions, features } = getState()

        if (isEmpty(permissions)) {
          return false
        }

        if (Object.prototype.hasOwnProperty.call(permissions, feature)) {
          return permissions[feature]
        }

        throw new Error(
          `Permission for key "${feature}" does not exist.\nPosible options are: ${features.join(
            ", "
          )}`
        )
      },
    })),

    {
      name: "session",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        alpyneToken: state.alpyneToken,
        impersonatorUser: state.impersonatorUser,
        navbarCollapsed: state.navbarCollapsed,
      }),
    }
  )
)
