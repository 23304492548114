import {
  StatusIntegrationTypeEnum,
  connectionsTableFragment$key,
} from "./__generated__/connectionsTableFragment.graphql"
import {
  Avatar,
  Badge,
  Flex,
  Group,
  Image,
  Loader,
  Text,
  rem,
} from "@mantine/core"
import { Table } from "@shared/ui/table"
import {
  getAvatarText,
  getDaysAgo,
  getIntegrationSlugIcon,
} from "@shared/utils/helpers"
import { IconAlertTriangle } from "@tabler/icons-react"
import { ColumnDef } from "@tanstack/react-table"
import { graphql, useFragment } from "react-relay"
import { useSearchParams } from "react-router-dom"

const ConnectionsTableFragment = graphql`
  fragment connectionsTableFragment on Query
  @argumentDefinitions(cursor: { type: "String" }, count: { type: "Int" })
  @refetchable(queryName: "ConnectionsTableFragment_RefetchQuery") {
    getIntegrations(first: $count, after: $cursor)
      @connection(key: "ConnectionsTableFragment_getIntegrations") {
      edges {
        node {
          name
          id
          lastSync
          system {
            category
            name
            slug
          }
          creator {
            fullName
            avatar
          }
          status
        }
      }
    }
  }
`

type Connection = {
  name: string
  dataSource: string
  slug: string
  id: string
  owner: string
  ownerAvatar: string | null
  lastUpdate: string
  status: string
}

type Props = {
  connections: connectionsTableFragment$key
  searchQuery: string
  onClick: (id: string) => void
}

export const ConnectionsTable = ({
  connections,
  searchQuery,
  onClick,
}: Props) => {
  const {
    getIntegrations: { edges },
  } = useFragment(ConnectionsTableFragment, connections)
  const [, setSearchParams] = useSearchParams()

  const columns: ColumnDef<Connection>[] = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info) => (
        <Text fw="bold" size="sm">
          {info.getValue<string>()}
        </Text>
      ),
    },
    {
      header: "Data Source",
      accessorKey: "dataSource",
      cell: (info) => (
        <Flex gap={rem(8)}>
          <Image
            src={getIntegrationSlugIcon(info.row.original.slug)}
            fit="contain"
            h={rem(18)}
          />
          {info.getValue<string>()}
        </Flex>
      ),
    },
    {
      header: "Owner",
      accessorKey: "owner",
      cell: (info) => (
        <Flex gap={rem(8)} align="center">
          <Avatar src={info.row.original.ownerAvatar} size="xs" color="blue">
            {getAvatarText(info.getValue<string>())}
          </Avatar>
          {info.getValue<string>()}
        </Flex>
      ),
    },
    {
      header: "Last Updated",
      accessorKey: "lastUpdate",
      enableGlobalFilter: false,
    },
    {
      header: "",
      accessorKey: "status",
      cell: (info) => (
        <Group justify="flex-end">
          {(info.getValue<StatusIntegrationTypeEnum>() === "BROKEN" ||
            info.getValue<StatusIntegrationTypeEnum>() === "SYNC_FAILED") && (
            <Badge variant="light" color="red">
              <IconAlertTriangle size={10} />
            </Badge>
          )}
          {info.getValue<StatusIntegrationTypeEnum>() === "SYNCING" && (
            <Loader size={14} />
          )}
        </Group>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ]

  const tableData: Connection[] = edges.map((connection) => ({
    id: connection.node.id,
    name: connection.node.name,
    dataSource: connection.node.system.name,
    slug: connection.node.system.slug,
    owner: connection.node.creator.fullName ?? "",
    ownerAvatar: connection.node.creator.avatar,
    lastUpdate:
      connection.node.status === "SYNCING"
        ? "--"
        : connection.node.lastSync
        ? getDaysAgo(new Date(connection.node.lastSync))
        : "--",
    status: connection.node.status,
  }))

  return (
    <Table
      columns={columns}
      data={tableData}
      searchQuery={searchQuery}
      onRowClick={(row) => {
        if (row.id) {
          onClick(row.id)
          setSearchParams(`connectionId=${row.id}`)
        }
      }}
    />
  )
}
