import { AccountPlaceholder } from "../account-placeholder"
import { transactionsContentConnectionsQuery } from "../transactions-content/__generated__/transactionsContentConnectionsQuery.graphql"
import { ConnectionsQuery } from "../transactions-content/transactions-content"
import { EntitiesComponent } from "@components/statements/entities-component/entities-component"
import {
  Box,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  rem,
} from "@mantine/core"
import { useUserStore } from "@shared/store"
import { Select } from "@shared/ui/select"
import { IconChevronLeft, IconSearch } from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"
import NoDataIcon from "frontend/shared/assets/no-data.svg"
import { useMemo, useTransition } from "react"
import { useLazyLoadQuery } from "react-relay"
import { useNavigate, useSearchParams } from "react-router-dom"

type Props = {
  reset: () => void
}
export const TransactionsEmpty = ({ reset }: Props) => {
  const { currentClient, lastUpdate } = useUserStore()
  const [params, setSearchParams] = useSearchParams()
  const connectionIds = params.getAll("connectionIds")
  const navigate = useNavigate()
  const [, startTransition] = useTransition()

  const connectionsData = useLazyLoadQuery<transactionsContentConnectionsQuery>(
    ConnectionsQuery,
    {
      systemType: "ledger",
    },
    {
      fetchKey: [currentClient.id, lastUpdate].join(""),
      fetchPolicy: "network-only",
    }
  )
  const connections = useMemo(
    () =>
      connectionsData.getIntegrations.edges.map((integration) => {
        return {
          key: integration.node.id,
          label: integration.node.name,
          isDeleted: false,
        }
      }),
    [connectionsData.getIntegrations.edges]
  )
  return (
    <Box
      size="xl"
      p={rem(32)}
      mih={"100dvh"}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Group justify="space-between">
        <Group gap={8}>
          <IconChevronLeft
            color="gray"
            onClick={() => {
              navigate(pathConstants.STATEMENTS)
              reset()
            }}
            style={{
              cursor: "pointer",
            }}
          />
          <Space w={0} />
          <Text size="xxl" fw={700} c={"gray"} component="h1">
            Transactions
          </Text>
          <Divider mx={8} orientation="vertical" />
          <EntitiesComponent
            options={connections}
            values={connectionIds}
            allCheckedLabel="All Entities"
            onChange={(value) => {
              startTransition(() => {
                setSearchParams((prev) => ({ ...prev, connectionIds: value }))
                reset()
              })
            }}
          />
        </Group>
      </Group>
      <Flex
        direction={"column"}
        style={{ flexGrow: 1 }}
        pt={"3.5rem"}
        gap={"2rem"}
        mih={"100% !important"}
      >
        <Text c={"gray.7"} fw={"bold"} size="md">
          Customize your view
        </Text>
        <AccountPlaceholder />
        <Card shadow="xs">
          <Flex gap={"md"}>
            <Select label="Date Range" disabled w={"100%"} />
            <Select label="Vendor" disabled w={"100%"} />
            <Select label="Customer" disabled w={"100%"} />
            <Select label="Class" disabled w={"100%"} />
            <Select label="Amount Range" disabled w={"100%"} />
          </Flex>
        </Card>
        <Flex gap={"md"} align={"center"}>
          <Text fw={"bold"} c={"gray.7"} size="md">
            Details
          </Text>
          <Divider orientation="vertical" />
          <TextInput
            w={"18.5rem"}
            placeholder="Search"
            disabled
            leftSection={
              <IconSearch
                size={"1rem"}
                style={{ color: "var(--mantine-color-gray-6)" }}
              />
            }
          />
        </Flex>
        <Card
          shadow="xs"
          radius={rem(8)}
          w="100%"
          h={"100%"}
          style={{ flexGrow: 1 }}
        >
          <Stack my="auto" justify="center" align="center">
            <Image
              src={NoDataIcon}
              style={{ mixBlendMode: "multiply" }}
              w={rem(170)}
            />
            <Text mt={"md"} c="gray" ta="center" size="sm">
              No results found matching your search.
            </Text>
          </Stack>
        </Card>
      </Flex>
    </Box>
  )
}
