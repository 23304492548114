import { useOauthCallbackHubspotMutation } from "./__generated__/useOauthCallbackHubspotMutation.graphql"
import { useOauthCallbackQuickbooksMutation as UseOauthCallbackQuickbooksMutationType } from "./__generated__/useOauthCallbackQuickbooksMutation.graphql"
import { useOauthCallbackSalesforceMutation } from "./__generated__/useOauthCallbackSalesforceMutation.graphql"
import { useOauthCallbackShopifyMutation } from "./__generated__/useOauthCallbackShopifyMutation.graphql"
import { notifications } from "@shared/ui/notifications"
import { useEffect, useRef } from "react"
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import { useSearchParams } from "react-router-dom"

const UseOauthCallbackQuickbooksMutation = graphql`
  mutation useOauthCallbackQuickbooksMutation(
    $input: QuickbooksOauthCallbackInput!
    $connections: [ID!]!
  ) {
    quickbooksOauthCallback(input: $input) {
      connection
        @appendNode(
          connections: $connections
          edgeTypeName: "SystemIntegration"
        ) {
        name
        id
        lastSync
        system {
          category
          name
          slug
        }
        creator {
          fullName
        }
        status
      }
      errors
    }
  }
`

const UseOauthCallbackSalesforceMutation = graphql`
  mutation useOauthCallbackSalesforceMutation(
    $input: SalesforceOauthCallbackInput!
    $connections: [ID!]!
  ) {
    salesforceOauthCallback(input: $input) {
      connection
        @appendNode(
          connections: $connections
          edgeTypeName: "SystemIntegration"
        ) {
        name
        id
        lastSync
        system {
          category
          name
          slug
        }
        creator {
          fullName
        }
        status
      }
      errors
    }
  }
`

const UseOauthCallbackShopifyMutation = graphql`
  mutation useOauthCallbackShopifyMutation(
    $input: ShopifyOauthCallbackInput!
    $connections: [ID!]!
  ) {
    shopifyOauthCallback(input: $input) {
      connection
        @appendNode(
          connections: $connections
          edgeTypeName: "SystemIntegration"
        ) {
        name
        id
        lastSync
        system {
          category
          name
          slug
        }
        creator {
          fullName
        }
        status
      }
      errors
    }
  }
`

const UseOauthCallbackHubspotMutation = graphql`
  mutation useOauthCallbackHubspotMutation(
    $input: HubspotOauthCallbackInput!
    $connections: [ID!]!
  ) {
    hubspotOauthCallback(input: $input) {
      connection
        @appendNode(
          connections: $connections
          edgeTypeName: "SystemIntegration"
        ) {
        name
        id
        lastSync
        system {
          category
          name
          slug
        }
        creator {
          fullName
        }
        status
      }
      errors
    }
  }
`

export const useOauthCallback = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const redirectSource = searchParams.get("source")
  const authResponseCode = searchParams.get("code")
  const realmId = searchParams.get("realmId")
  const shop = searchParams.get("shop")
  const mutationLoading = useRef(false)

  const [quickbooksCallback, quickbooksCallbackLoading] =
    useMutation<UseOauthCallbackQuickbooksMutationType>(
      UseOauthCallbackQuickbooksMutation
    )
  const [salesforceCallback, salesforceCallbackLoading] =
    useMutation<useOauthCallbackSalesforceMutation>(
      UseOauthCallbackSalesforceMutation
    )

  const [shopifyCallback, shopifyCallbackLoading] =
    useMutation<useOauthCallbackShopifyMutation>(
      UseOauthCallbackShopifyMutation
    )

  const [hubspotCallback, hubspotCallbackLoading] =
    useMutation<useOauthCallbackHubspotMutation>(
      UseOauthCallbackHubspotMutation
    )

  const successHandler = (newConnectionId: string | undefined) => {
    notifications.show({
      title: "Successful Connection",
      message: "The connection has been successfully added from the system.",
      variant: "success",
    })

    newConnectionId &&
      setSearchParams({
        connectionId: newConnectionId,
      })
  }

  const errorHandler = () => {
    notifications.show({
      title: "Ops, connection failed!",
      message: "Try again later. ",
      variant: "error",
    })
  }

  useEffect(() => {
    if (!authResponseCode || !redirectSource || mutationLoading.current) {
      return
    }

    const generalconnection = ConnectionHandler.getConnectionID(
      "root",
      "Connections_getIntegrations"
    )
    const integrationsConnection = ConnectionHandler.getConnectionID(
      "root",
      "ConnectionsTableFragment_getIntegrations"
    )

    const connections = [generalconnection, integrationsConnection]

    switch (redirectSource) {
      case "quickbooks": {
        realmId &&
          quickbooksCallback({
            variables: {
              input: {
                code: authResponseCode,
                realm: realmId,
              },
              connections: connections,
            },
            onCompleted: (response) => {
              const newConnectionId =
                response.quickbooksOauthCallback?.connection?.id
              const errors = response.quickbooksOauthCallback?.errors

              if (errors && errors[0]) {
                notifications.show({
                  title: "Connection Error",
                  message: response.quickbooksOauthCallback?.errors[0],
                  variant: "error",
                })
                setSearchParams()
              } else {
                successHandler(newConnectionId)
              }
            },
            onError: errorHandler,
          })
        break
      }
      case "salesforce": {
        salesforceCallback({
          variables: {
            input: {
              code: authResponseCode,
            },
            connections: connections,
          },
          onCompleted: (response) => {
            const newConnectionId =
              response.salesforceOauthCallback?.connection?.id
            const errors = response.salesforceOauthCallback?.errors

            if (errors && errors[0]) {
              notifications.show({
                title: "Connection Error",
                message: response.salesforceOauthCallback?.errors[0],
                variant: "error",
              })
              setSearchParams()
            } else {
              successHandler(newConnectionId)
            }
          },
          onError: errorHandler,
        })
        break
      }
      case "shopify": {
        shop &&
          shopifyCallback({
            variables: {
              input: {
                shop: shop,
                code: authResponseCode,
              },
              connections: connections,
            },
            onCompleted: (response) => {
              const newConnectionId =
                response.shopifyOauthCallback?.connection?.id
              const errors = response.shopifyOauthCallback?.errors

              if (errors && errors[0]) {
                notifications.show({
                  title: "Connection Error",
                  message: response.shopifyOauthCallback?.errors[0],
                  variant: "error",
                })
                setSearchParams()
              } else {
                successHandler(newConnectionId)
              }
            },
            onError: errorHandler,
          })
        break
      }
      case "hubspot": {
        authResponseCode &&
          hubspotCallback({
            variables: {
              input: {
                code: authResponseCode,
              },
              connections: connections,
            },
            onCompleted: (response) => {
              const newConnectionId =
                response.hubspotOauthCallback?.connection?.id
              const errors = response.hubspotOauthCallback?.errors

              if (errors && errors[0]) {
                notifications.show({
                  title: "Connection Error",
                  message: response.hubspotOauthCallback?.errors[0],
                  variant: "error",
                })
                setSearchParams()
              } else {
                successHandler(newConnectionId)
              }
            },
            onError: errorHandler,
          })
        break
      }
    }

    return () => {
      mutationLoading.current = true
    }
  }, [])

  return (
    quickbooksCallbackLoading ||
    salesforceCallbackLoading ||
    shopifyCallbackLoading ||
    hubspotCallbackLoading
  )
}
