import { notifications } from "../notifications"
import ImagePlaceholder from "@assets/image-placeholder.svg"
import {
  Box,
  Button,
  Card,
  FileButton,
  Flex,
  Image,
  LoadingOverlay,
  Paper,
  Space,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { getBase64 } from "@shared/utils/helpers"

interface ImageBoxProps {
  image: string
  shouldUpdate: boolean
  title: string
  uploadText?: string
  placeholder?: string | undefined
  placeholderColor?: string | undefined
  loading: boolean
  imageUpdaterCallback: (image: string) => void
}

export const ImageBox = ({
  image,
  shouldUpdate,
  title,
  loading,
  imageUpdaterCallback,
  placeholder,
  placeholderColor,
  uploadText = "Upload image",
}: ImageBoxProps) => {
  const temporalImage = async (temporalFile: File | null) => {
    if (!temporalFile) return

    if (temporalFile.size > 4_194_304) {
      notifications.show({
        title: "Image must be less than 4 megabytes",
        message: "Please try again",
        variant: "error",
      })

      return
    }

    const formattedFile = await getBase64(temporalFile)

    imageUpdaterCallback(formattedFile)
  }

  return (
    <Box>
      <Flex align="center" justify="space-between">
        <Text fw="bold" c="gray.7">
          {title}
        </Text>
        {shouldUpdate && (
          <FileButton onChange={temporalImage} accept="image/png,image/jpeg">
            {(props) => (
              <Button
                {...props}
                variant="outline"
                px={rem(16)}
                fw="normal"
                miw={rem(112)}
              >
                {uploadText}
              </Button>
            )}
          </FileButton>
        )}
      </Flex>
      <Space h={rem(32)} />
      <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
        <Stack gap={rem(8)}>
          <Text size="sm" c="gray.7" py={rem(8)}>
            It&apos;s recommended to use a picture that&apos;s at least 98 x 98
            pixels and 4MB or less.
          </Text>
          <Box pos="relative">
            <LoadingOverlay
              visible={loading}
              overlayProps={{ blur: 2 }}
              h={rem(128)}
              w={rem(128)}
            />

            {placeholder && placeholderColor && !image ? (
              <Paper
                h={rem(84)}
                w={rem(84)}
                bg={placeholderColor}
                display="flex"
                style={{ alignItems: "center", justifyContent: "center" }}
                radius={rem(4)}
              >
                <Text size={rem(40)} fw={700} tt="uppercase" c="white">
                  {placeholder}
                </Text>
              </Paper>
            ) : (
              <Image
                h={rem(128)}
                w={rem(128)}
                radius="sm"
                src={image}
                fallbackSrc={ImagePlaceholder}
              />
            )}
          </Box>
        </Stack>
      </Card>
    </Box>
  )
}
