/**
 * @generated SignedSource<<30f91a6caa7f1f2565853897452ca183>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateFirmInput = {
  clientMutationId?: string | null;
  firmId: string;
  logo?: string | null;
  name?: string | null;
};
export type editFirmUpdateFirmMutation$variables = {
  input: UpdateFirmInput;
};
export type editFirmUpdateFirmMutation$data = {
  readonly updateFirm: {
    readonly clientMutationId: string | null;
    readonly firm: {
      readonly createdAt: string;
      readonly id: string;
      readonly logo: string | null;
      readonly name: string | null;
    } | null;
  } | null;
};
export type editFirmUpdateFirmMutation = {
  response: editFirmUpdateFirmMutation$data;
  variables: editFirmUpdateFirmMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateFirmPayload",
    "kind": "LinkedField",
    "name": "updateFirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Firm",
        "kind": "LinkedField",
        "name": "firm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logo",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editFirmUpdateFirmMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editFirmUpdateFirmMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b5b4cf219fbe87474a96f54d2f1274fb",
    "id": null,
    "metadata": {},
    "name": "editFirmUpdateFirmMutation",
    "operationKind": "mutation",
    "text": "mutation editFirmUpdateFirmMutation(\n  $input: UpdateFirmInput!\n) {\n  updateFirm(input: $input) {\n    clientMutationId\n    firm {\n      createdAt\n      id\n      name\n      logo\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ccb386c3ba468179dfb27b12d7107bd2";

export default node;
