import { Button, Image, Stack, Text, rem } from "@mantine/core"
import AddFiles from "@shared/assets/add-files.svg"
import { pathConstants } from "frontend/routes/path-constants"
import { useNavigate } from "react-router-dom"

export const EmptySavedStatements = () => {
  const navigate = useNavigate()

  return (
    <Stack gap={32} w="100%" justify="center" align="center">
      <Image
        src={AddFiles}
        style={{ mixBlendMode: "multiply" }}
        h={rem(170)}
        w={rem(228)}
      />
      <Text c="gray" ta="center" size="md">
        It seems you haven&apos;t saved any statements yet. Generate <br /> them
        in the &apos;Statements&apos; section and save them to find them <br />{" "}
        here later.
      </Text>
      <Button
        size="sm"
        w={rem(77)}
        onClick={() => {
          navigate(pathConstants.STATEMENTS, { replace: true })
        }}
      >
        Go
      </Button>
    </Stack>
  )
}
