import { EmptyState } from "../empty-state"
import { cashFlowConfigurationGetIntegrationQuery as CashFlowConfigurationGetIntegrationQueryType } from "./__generated__/cashFlowConfigurationGetIntegrationQuery.graphql"
import { cashFlowConfigurationQuery as CashFlowConfigurationQueryType } from "./__generated__/cashFlowConfigurationQuery.graphql"
import { BalanceAccountsTable } from "./balance-accounts-table"
import styles from "./cash-flow-configuration.module.scss"
import { AddAccount } from "./income/add-account"
import { IncomeAccountsTable } from "./income/income-accounts-table"
import { Box, Divider, Group, Stack, Text, Tooltip, rem } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { themeVars } from "@shared/theme"
import { CustomSelect, CustomSelectDatatype } from "@shared/ui/select-custom"
import { getIntegrationSlugIcon } from "@shared/utils/helpers"
import {
  IconCaretDownFilled,
  IconCaretRightFilled,
  IconChevronLeft,
  IconHelp,
} from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"
import { useMemo, useState } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { useNavigate, useSearchParams } from "react-router-dom"

const CashFlowConfigurationQuery = graphql`
  query cashFlowConfigurationQuery(
    $clientId: ID!
    $connectionIds: [ID!]!
    $cursor: String
    $count: Int
  ) {
    ...incomeAccountsTableFragment
      @arguments(
        count: $count
        cursor: $cursor
        clientId: $clientId
        connectionIds: $connectionIds
      )
    ...balanceAccountsTableAccountTypesFragment
      @arguments(count: $count, cursor: $cursor)
  }
`

const CashFlowConfigurationGetIntegrationQuery = graphql`
  query cashFlowConfigurationGetIntegrationQuery($systemType: String) {
    getIntegrations(systemType: $systemType) {
      edges {
        node {
          id
          name
          system {
            slug
          }
        }
      }
    }
  }
`
const DEFAULT_TABLE_LIMIT = 50

export const CashFlowConfiguration = () => {
  const { cashFlowPath } = useUserStore()
  const [, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const [showIncome, setShowIncome] = useState<boolean>(true)
  const [showBalance, setShowBalance] = useState<boolean>(true)
  const { currentClient, lastUpdate } = useUserStore()

  const { getIntegrations } =
    useLazyLoadQuery<CashFlowConfigurationGetIntegrationQueryType>(
      CashFlowConfigurationGetIntegrationQuery,
      { systemType: "ledger" },
      {
        fetchKey: [currentClient.id, lastUpdate].join(""),
        fetchPolicy: "network-only",
      }
    )

  const hasConnections = getIntegrations.edges.length > 0 ?? false
  const defaultConnectionId = getIntegrations.edges[0]?.node.id
  const [connectionIdSelected, setConnectionIdSelected] = useState<
    string | null
  >(defaultConnectionId)

  const cashFlowConfigurationInputs = {
    clientId: currentClient.id || "",
    connectionIds: [connectionIdSelected || ""],
  }

  const cashFlowConfiguration =
    useLazyLoadQuery<CashFlowConfigurationQueryType>(
      CashFlowConfigurationQuery,
      cashFlowConfigurationInputs
    )

  const connections: CustomSelectDatatype[] = useMemo(
    () =>
      getIntegrations.edges.map((edge) => ({
        label: edge.node.name,
        value: edge.node.id,
        imageSrc: getIntegrationSlugIcon(edge.node.system.slug) || "",
      })),
    [getIntegrations.edges]
  )

  const defaultCashFlowPath = `${pathConstants.STATEMENTS}?reportType=CASH&numberFormat=EXCEPT_ZERO&numberFormat=WITHOUT_CENTS&numberFormat=SHOW_RED`

  const pageTitle = (
    <Group gap={rem(8)}>
      <IconChevronLeft
        color="gray"
        onClick={() => {
          navigate(cashFlowPath ?? defaultCashFlowPath)
        }}
      />
      <Text size="xxl" fw={700} c={"gray"} component="h1">
        Cash Flow Configuration
      </Text>
    </Group>
  )

  if (!hasConnections) {
    return <EmptyState pageTitle={pageTitle} />
  }

  return (
    <Box className={styles.CashFlowConfiguration}>
      <Group gap={rem(16)}>
        <Group gap={rem(8)}>
          <IconChevronLeft
            color="gray"
            onClick={() => {
              navigate(cashFlowPath ?? defaultCashFlowPath)
            }}
            style={{
              cursor: "pointer",
            }}
          />
          <Text fw={700} c={"gray"} size="xl">
            Cash Flow Configuration
          </Text>
        </Group>
        <Divider
          orientation="vertical"
          style={{ borderColor: themeVars.colors.gray[3] }}
        />
        <CustomSelect
          defaultValue={connectionIdSelected}
          data={connections}
          onChange={(val) => {
            setConnectionIdSelected(val)
            setSearchParams({})
          }}
          disabled={connections.length === 0}
          menuLabel="CONNECTIONS"
          width="13.75rem"
          height="2rem"
        />
      </Group>
      <Stack gap="2rem">
        <Group justify="space-between">
          <Group gap="0.25rem" align="flex-start">
            <Group
              gap="0.25rem"
              onClick={() => setShowIncome((prev) => !prev)}
              className={styles.CashFlowConfiguration__CollapsibleHeader}
            >
              {showIncome ? <IconCaretDownFilled /> : <IconCaretRightFilled />}
              <Text fw="bold" c="gray.7">
                Income Accounts
              </Text>
              <Tooltip
                multiline
                w="21.875rem"
                label={
                  <Text size="0.875rem">
                    The accounts included in the income accounts list are added
                    to the &ldquo;Non-cash Expense Accounts&quot; line in the
                    cash flow report.
                  </Text>
                }
                color="gray"
                arrowPosition="center"
                withArrow
                position="right"
              >
                <IconHelp size={16} />
              </Tooltip>
            </Group>
          </Group>
          <AddAccount connectionIds={[connectionIdSelected || ""]} />
        </Group>

        {showIncome && (
          <IncomeAccountsTable
            cashFlowConfigurationInputs={{
              clientId: cashFlowConfigurationInputs.clientId,
              connectionIds: cashFlowConfigurationInputs.connectionIds,
            }}
            incomeAccounts={cashFlowConfiguration}
          />
        )}

        <Group gap="0.25rem">
          <Group
            gap="0.25rem"
            onClick={() => setShowBalance((prev) => !prev)}
            className={styles.CashFlowConfiguration__CollapsibleHeader}
          >
            {showBalance ? <IconCaretDownFilled /> : <IconCaretRightFilled />}
            <Text fw="bold" c="gray.7">
              Balance Accounts
            </Text>
          </Group>
        </Group>
        {connectionIdSelected && (
          <BalanceAccountsTable
            key={connectionIdSelected}
            accountTypes={cashFlowConfiguration}
            defaultLimit={DEFAULT_TABLE_LIMIT}
            connectionId={connectionIdSelected}
            isVisible={showBalance}
          />
        )}
      </Stack>
    </Box>
  )
}
