/**
 * @generated SignedSource<<4da656ffcf6c1a9304f2d43d5ef03add>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type clientSettingsUpdateLogoMutation$variables = {
  logo?: string | null;
};
export type clientSettingsUpdateLogoMutation$data = {
  readonly updateCurrentClient: {
    readonly client: {
      readonly logo: string | null;
    };
    readonly errors: ReadonlyArray<string> | null;
  };
};
export type clientSettingsUpdateLogoMutation = {
  response: clientSettingsUpdateLogoMutation$data;
  variables: clientSettingsUpdateLogoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "logo"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "logo",
        "variableName": "logo"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientSettingsUpdateLogoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetClient",
        "kind": "LinkedField",
        "name": "updateCurrentClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientSettingsUpdateLogoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GetClient",
        "kind": "LinkedField",
        "name": "updateCurrentClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Client",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0ea46c9eacdccec2977b799d2c578d1",
    "id": null,
    "metadata": {},
    "name": "clientSettingsUpdateLogoMutation",
    "operationKind": "mutation",
    "text": "mutation clientSettingsUpdateLogoMutation(\n  $logo: String\n) {\n  updateCurrentClient(input: {logo: $logo}) {\n    client {\n      logo\n      id\n    }\n    errors\n  }\n}\n"
  }
};
})();

(node as any).hash = "1cca3e2592d6f483bad12049fc16be5d";

export default node;
