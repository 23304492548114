import classes from "./filter-multiselector.module.scss"
import {
  Badge,
  Checkbox,
  Group,
  Input,
  MantineProvider,
  Popover,
  Stack,
  Text,
  createTheme,
  rem,
} from "@mantine/core"
import { IconChevronDown } from "@tabler/icons-react"

const theme = createTheme({
  cursorType: "pointer",
})

type Options = {
  label: string
  key: string
}

type Props = {
  values: string[]

  options: {
    label: string
    key: string
    options: Options[]
  }[]
  noCheckedLabel?: React.ReactNode
  onChange: (values: string[]) => void
}

export const FilterMultiselector = ({
  noCheckedLabel = "",
  options,
  values,
  onChange,
}: Props) => {
  const allChildrenChecked: boolean[] = []
  const indeterminate: boolean[] = []

  options.forEach((option, index: number) => {
    const childrenCheckedSize = option.options.filter((op) =>
      values.includes(op.key)
    ).length
    allChildrenChecked[index] =
      childrenCheckedSize === 0
        ? values.includes(option.key)
        : childrenCheckedSize === option.options.length

    indeterminate[index] = childrenCheckedSize > 0 && !allChildrenChecked[index]
  })

  return (
    <Popover position="bottom-start" shadow="md">
      <Popover.Target>
        <Input
          type="button"
          component="button"
          pointer
          rightSection={<IconChevronDown size={16} />}
        >
          <Group gap={rem(8)}>
            <Text truncate="end" size="sm">
              {noCheckedLabel}
            </Text>
            <Badge
              size="xs"
              w={rem(42)}
              bg={values.length > 0 ? "blue.0" : "transparent"}
              c={"indigo.6"}
            >
              <Text size="xxs" fw={500}>
                {allChildrenChecked.every(Boolean)
                  ? "All"
                  : values.length > 0
                  ? values.length
                  : ""}
              </Text>
            </Badge>
          </Group>
        </Input>
      </Popover.Target>
      <Popover.Dropdown p={".75rem"} py={"10px"} miw={rem(236)} maw={rem(480)}>
        <MantineProvider theme={theme}>
          <Stack gap={0}>
            {options.map((option, index) => (
              <>
                <Checkbox
                  key={option.key}
                  checked={allChildrenChecked[index]}
                  label={option.label}
                  indeterminate={indeterminate[index]}
                  classNames={classes}
                  onChange={() => {
                    const isAlreadySelected =
                      option.options.length === 0
                        ? values.includes(option.key)
                        : allChildrenChecked[index]
                    if (isAlreadySelected) {
                      const optionKeys = option.options.map((op) => op.key)
                      const updatedValues = values.filter(
                        (item) =>
                          ![...optionKeys, option.key]
                            .map((val) => val)
                            .includes(item)
                      )

                      onChange(updatedValues)
                    } else {
                      const selectedValues = [
                        ...values,
                        ...option.options.map(({ key }) => key),
                      ]
                      if (option.options.length === 0) {
                        selectedValues.push(option.key)
                      }
                      const uniqueValues = [...new Set(selectedValues)]
                      onChange(uniqueValues)
                    }
                  }}
                />
                {option.options.map((value: Options) => (
                  <Checkbox
                    key={value.key}
                    checked={values.includes(value.key)}
                    label={value.label}
                    classNames={classes}
                    onChange={() => {
                      onChange(
                        values.includes(value.key)
                          ? values.filter((val) => val != value.key)
                          : [...values, value.key]
                      )
                    }}
                    ml={"2rem"}
                  />
                ))}
              </>
            ))}
          </Stack>
        </MantineProvider>
      </Popover.Dropdown>
    </Popover>
  )
}
