import { adminLayoutCurrentUserDataQuery as AdminLayoutCurrentUserDataQueryType } from "./__generated__/adminLayoutCurrentUserDataQuery.graphql"
import styles from "./admin-layout.module.scss"
import { AppShell, Box, Group, Text } from "@mantine/core"
import Logo from "@shared/assets/logo.svg"
import { themeVars } from "@shared/theme"
import { IconChevronLeft, IconHome } from "@tabler/icons-react"
import { pathConstants } from "frontend/routes/path-constants"
import { useEffect } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import { Link, Outlet, useNavigate } from "react-router-dom"

const AdminLayoutCurrentUserDataQuery = graphql`
  query adminLayoutCurrentUserDataQuery {
    getCurrentUserData {
      user {
        isSuperAdmin
      }
    }
  }
`

export const AdminLayout = () => {
  const {
    getCurrentUserData: {
      user: { isSuperAdmin },
    },
  } = useLazyLoadQuery<AdminLayoutCurrentUserDataQueryType>(
    AdminLayoutCurrentUserDataQuery,
    {}
  )
  const navigate = useNavigate()

  useEffect(() => {
    !isSuperAdmin && navigate(pathConstants.PORTFOLIO, { replace: true })
  }, [isSuperAdmin, navigate])

  return (
    <AppShell header={{ height: "5rem" }} padding="2rem">
      <AppShell.Header>
        <Box className={styles.AdminLayout__Topbar}>
          <Link to={pathConstants.PORTFOLIO}>
            <Group gap="0.75rem">
              <Group gap={0}>
                <IconChevronLeft color={themeVars.colors.blue[6]} size={16} />
                <IconHome color={themeVars.colors.blue[6]} size={16} />
              </Group>
              <img src={Logo} style={{ height: "2rem" }} />
            </Group>
          </Link>
          <Group c="gray" gap={"0.5rem"} mr="0.5rem">
            <Text size="sm" fw={700}>
              Admin Settings
            </Text>
          </Group>
        </Box>
      </AppShell.Header>
      <AppShell.Main>{isSuperAdmin && <Outlet />}</AppShell.Main>
    </AppShell>
  )
}
