import { AvatarUpload } from "../avatar-upload/avatar-upload"
import { UserFormType } from "../user-data-form/user-data-form"
import { Button, Stack, TextInput, rem } from "@mantine/core"
import { Controller, useFormContext } from "react-hook-form"
import { z } from "zod"

export const FirmSchema = z.object({
  logo: z.string().nullable(),
  firmName: z
    .string()
    .min(1, { message: "Please fill out this required field." }),
})

export type FirmFormType = z.infer<typeof FirmSchema>
export type OwnerSignupType = UserFormType & FirmFormType

type Props = {
  onSubmit: (data: OwnerSignupType) => void
  loading?: boolean
}

export const FirmDataForm = ({ onSubmit, loading = false }: Props) => {
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useFormContext<OwnerSignupType>()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack justify="space-between" h={rem(539)} mt={rem(16)}>
        <Stack gap={rem(16)}>
          <Controller
            control={control}
            name={"logo"}
            render={({ field: { value, onChange } }) => {
              return (
                <AvatarUpload
                  optional
                  image={value ?? undefined}
                  label="Firm Logo"
                  onChange={onChange}
                />
              )
            }}
          />
          <TextInput
            {...register("firmName")}
            label="Firm Name"
            withAsterisk
            error={errors.firmName?.message}
            autoFocus
          />
        </Stack>
        <Button type="submit" disabled={!isValid} loading={loading}>
          Sign Up
        </Button>
      </Stack>
    </form>
  )
}
