import { homeGetHomeInfoQuery as HomeGetHomeInfoQueryType } from "./__generated__/homeGetHomeInfoQuery.graphql"
import { DashboardsTable } from "./dashboards-table"
import { Box, Card, Group, Space, Stack, Text, rem } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { graphql, useLazyLoadQuery } from "react-relay"

const HomeGetHomeInfoQuery = graphql`
  query homeGetHomeInfoQuery($clientSlug: String!) {
    getHomeInfo(clientSlug: $clientSlug) {
      errors
      data {
        connectionsCount
        brokenConnections
        dataSourcesCount
        dashboards {
          count
        }
        ...dashboardsTableFragment
      }
    }
  }
`

export const Home = () => {
  const { currentClient } = useUserStore()

  const { getHomeInfo } = useLazyLoadQuery<HomeGetHomeInfoQueryType>(
    HomeGetHomeInfoQuery,
    { clientSlug: currentClient.slug ?? "" },
    { fetchPolicy: "store-and-network" }
  )

  const homeData = getHomeInfo.data

  return (
    <Box p="xl">
      <Text size="xxl" fw={700} c={"gray"} component="h1">
        Home
      </Text>
      <Space h={rem(56)} />
      <Stack gap={rem(56)}>
        <Stack gap={rem(32)}>
          <Text fw="bold" c="gray.7">
            Statistics
          </Text>
          <Group grow align="normal">
            <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
              <Stack gap={rem(12)} py={rem(8)}>
                <Text size="xl" fw="bold" c="gray.7">
                  {homeData.connectionsCount}
                </Text>
                <Text size="sm" c="gray">
                  {homeData.connectionsCount == 0
                    ? "Connections"
                    : `Connections from ${homeData.dataSourcesCount} data sources`}
                </Text>
              </Stack>
            </Card>
            <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
              <Stack gap={rem(12)} py={rem(8)}>
                <Text size="xl" fw="bold" c="gray.7">
                  {homeData.dashboards.count}
                </Text>
                <Text size="sm" c="gray">
                  Dashboards
                </Text>
              </Stack>
            </Card>
            <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
              <Stack gap={rem(12)} py={rem(8)}>
                <Text size="xl" fw="bold" c="gray.7">
                  {homeData.brokenConnections}
                </Text>
                <Text size="sm" c="gray">
                  Broken Connections
                </Text>
              </Stack>
            </Card>
          </Group>
        </Stack>
        <Stack gap={rem(32)}>
          <Text fw="bold" c="gray.7">
            Dashboard
          </Text>
          <DashboardsTable dashboards={homeData} />
        </Stack>
      </Stack>
    </Box>
  )
}
