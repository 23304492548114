/**
 * @generated SignedSource<<e654225e61847f7c0f725f726684040d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CashFlowCategoryEnum = "FINANCING" | "INVESTING" | "OPERATING";
export type BalanceCashFlowSettingMutationInput = {
  clientMutationId?: string | null;
  ledgerAccounts: ReadonlyArray<BalanceCashFlowSettingInput>;
};
export type BalanceCashFlowSettingInput = {
  cashFlowCategory: CashFlowCategoryEnum;
  id: string;
  includeInCashFlow: boolean;
};
export type balanceAccountsTableSettingMutation$variables = {
  input: BalanceCashFlowSettingMutationInput;
};
export type balanceAccountsTableSettingMutation$data = {
  readonly balanceCashFlowSetting: {
    readonly ledgerAccounts: ReadonlyArray<{
      readonly accountType: string | null;
      readonly cashFlowCategory: CashFlowCategoryEnum | null;
      readonly id: string;
      readonly includeInCashFlow: boolean | null;
      readonly name: string | null;
    }> | null;
  } | null;
};
export type balanceAccountsTableSettingMutation = {
  response: balanceAccountsTableSettingMutation$data;
  variables: balanceAccountsTableSettingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BalanceCashFlowSettingMutationPayload",
    "kind": "LinkedField",
    "name": "balanceCashFlowSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "LedgerAccount",
        "kind": "LinkedField",
        "name": "ledgerAccounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cashFlowCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "includeInCashFlow",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "balanceAccountsTableSettingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "balanceAccountsTableSettingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "af50b163e6b996d158a6476aeb7203ce",
    "id": null,
    "metadata": {},
    "name": "balanceAccountsTableSettingMutation",
    "operationKind": "mutation",
    "text": "mutation balanceAccountsTableSettingMutation(\n  $input: BalanceCashFlowSettingMutationInput!\n) {\n  balanceCashFlowSetting(input: $input) {\n    ledgerAccounts {\n      id\n      name\n      accountType\n      cashFlowCategory\n      includeInCashFlow\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5cd4349d1bd51a8ed078f5bf607db8e";

export default node;
