import { SaveTransactionModal } from "../save-transaction-modal"
import { AmountRateEnum } from "../save-transaction-modal/__generated__/saveTransactionModalUpsertSavedTransactionMutation.graphql"
import { Button } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

export type SaveTransactionButtonProps = {
  id?: string
  name: string
  systemConnectionIds: string[]
  accountIds?: string[]
  vendorIds?: string[]
  customerIds?: string[]
  classIds?: string[]
  startDate: string
  endDate: string
  maxRange?: number
  minRange?: number
  search?: string
  amountRate?: AmountRateEnum
  limit?: number
  pageNumber?: number
  disabled?: boolean
}

export const SaveTransactionButton = (params: SaveTransactionButtonProps) => {
  const [
    confirmationOpened,
    { open: confirmationOpen, close: confirmationClose },
  ] = useDisclosure(false)

  return (
    <>
      <Button
        variant={params.id ? "filled" : "outline"}
        color="blue"
        disabled={params.disabled || false}
        onClick={() => confirmationOpen()}
      >
        {params.id ? "Save Updates" : "Save Transaction"}
      </Button>
      <SaveTransactionModal
        saveTransactionInput={{
          id: params.id || null,
          name: params.name,
          systemConnectionIds: params.systemConnectionIds || [],
          accountIds: params.accountIds ?? null,
          startDate: params.startDate,
          endDate: params.endDate,
          vendorIds: params.vendorIds ?? null,
          customerIds: params.customerIds ?? null,
          classIds: params.classIds ?? null,
          maxRange: params.maxRange ?? null,
          minRange: params.minRange ?? null,
          search: params.search ?? null,
          amountRate: params.amountRate ?? null,
          limit: params.limit ?? null,
          pageNumber: params.pageNumber ?? null,
        }}
        title={params.id ? "Save Statement Updates" : "Saved Report"}
        onClose={confirmationClose}
        opened={confirmationOpened}
      />
    </>
  )
}
