import { editFirmUpdateFirmMutation } from "./__generated__/editFirmUpdateFirmMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { Alert, Button, Stack, TextInput, rem } from "@mantine/core"
import { notifications } from "@shared/ui/notifications"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { graphql, useMutation } from "react-relay"
import { z } from "zod"

const FirmSchema = z.object({
  firmName: z.string().min(1),
})

type formValues = z.infer<typeof FirmSchema>

type Props = {
  firmId: string | undefined
  name: string | undefined
  close: () => void
}

export const UpdateFirm = graphql`
  mutation editFirmUpdateFirmMutation($input: UpdateFirmInput!) {
    updateFirm(input: $input) {
      clientMutationId
      firm {
        createdAt
        id
        name
        logo
      }
    }
  }
`

export const EditFirm = ({ firmId, name, close }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid, isDirty },
  } = useForm<formValues>({
    resolver: zodResolver(FirmSchema),
  })
  const [errors, setErrors] = useState(false)

  const [updateFirm, updateFirmLoading] =
    useMutation<editFirmUpdateFirmMutation>(UpdateFirm)

  const onSubmit: SubmitHandler<formValues> = ({ firmName }) => {
    if (firmId) {
      updateFirm({
        variables: {
          input: { firmId: firmId, name: firmName },
        },
        onError: () => setErrors(true),
        onCompleted: () => {
          notifications.show({
            title: "Changes Saved Successfully",
            message:
              "Your changes have been saved successfully. You're all set to continue!",
            variant: "success",
          })
          close()
        },
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack justify="space-between" h="calc(100vh - 76px)">
        <Stack gap={rem(24)}>
          {errors && (
            <Alert
              color="red"
              title="Error"
              withCloseButton
              onClose={() => setErrors(false)}
            >
              Something went wrong.
            </Alert>
          )}
          <TextInput
            {...register("firmName")}
            withAsterisk
            label="Firm Name"
            defaultValue={name}
            error={formErrors.firmName?.message}
          />
        </Stack>
        <Button
          type="submit"
          loading={updateFirmLoading}
          disabled={!isValid || !isDirty}
        >
          Save
        </Button>
      </Stack>
    </form>
  )
}
