import { ForgotPassword } from "./forgot-password"
import { UpdatePassword } from "./update-password"
import { useSearchParams } from "react-router-dom"

export const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")

  if (token) {
    return <UpdatePassword token={token} />
  }
  return <ForgotPassword />
}
