import {
  Collapse,
  Flex,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { themeVars } from "@shared/theme"
import { Select } from "@shared/ui/select"
import {
  IconCaretDownFilled,
  IconDatabase,
  IconRefresh,
  IconTable,
} from "@tabler/icons-react"

type Props = {
  spreadsheetableText: string
  spreadsheetableError: string
  spreadsheetText: string
  spreadsheetError: string
  spreadsheetableType: string
  defaultOpen?: boolean
}
export const SpreadsheetPlaceholder = ({
  spreadsheetableText,
  spreadsheetableError,
  spreadsheetText,
  spreadsheetError,
  spreadsheetableType,
  defaultOpen = false,
}: Props) => {
  const [opened, { toggle }] = useDisclosure(defaultOpen)

  return (
    <Paper
      bg={opened ? "gray.0" : "transparent"}
      shadow={opened ? "none" : "xs"}
      style={{
        transition: "all ease 0.3s",
        borderColor: opened
          ? themeVars.colors.gray[3]
          : themeVars.colors.gray[0],
      }}
      p={rem(16)}
      withBorder
      radius={"sm"}
    >
      <Stack gap={rem(16)}>
        <Flex
          justify="space-between"
          align="center"
          onClick={toggle}
          style={{ cursor: "pointer" }}
        >
          <Flex gap={rem(8)} align={"center"}>
            <IconCaretDownFilled
              size={14}
              style={{ color: themeVars.colors.gray[6] }}
            />
            <Text size="sm" fw={500} c="gray.7">
              {spreadsheetableText || "Question"}
            </Text>
            <IconRefresh color={themeVars.colors.gray[6]} size={14} />
            <Text size="sm" fw={500} c="gray.7">
              {spreadsheetText || "Spreadsheet"}
            </Text>
          </Flex>
        </Flex>
        <Collapse in={opened}>
          <Stack gap={rem(16)}>
            <SegmentedControl
              value={spreadsheetableType}
              styles={{ indicator: { transition: "none" } }}
              data={[
                {
                  label: (
                    <Flex justify={"center"} gap={".5rem"} align={"center"}>
                      <IconDatabase size={".875rem"} />
                      <Text size="sm">Question</Text>
                    </Flex>
                  ),
                  value: "MetabaseQuestion",
                },
                {
                  label: (
                    <Flex justify={"center"} gap={".5rem"} align={"center"}>
                      <IconTable size={".875rem"} />
                      <Text size="sm">Reports</Text>
                    </Flex>
                  ),
                  value: "SavedFilter",
                },
              ]}
              disabled
            />
            <Select
              label={
                spreadsheetableType === "MetabaseQuestion"
                  ? "Question"
                  : spreadsheetableType === "SavedFilter"
                  ? "Report"
                  : "Source"
              }
              disabled={true}
              error={spreadsheetableError}
              value={spreadsheetableText}
              data={[spreadsheetableText]}
            />
            <Select
              label="Spreadsheet"
              disabled={true}
              error={spreadsheetError}
              value={spreadsheetText}
              data={[spreadsheetText]}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Paper>
  )
}
