/**
 * @generated SignedSource<<6affaadfe06ea1a6288621e31bcffe69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type classSelectorGetClassesFragment$data = {
  readonly getClasses: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null;
      };
    }>;
  };
  readonly " $fragmentType": "classSelectorGetClassesFragment";
};
export type classSelectorGetClassesFragment$key = {
  readonly " $data"?: classSelectorGetClassesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"classSelectorGetClassesFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "connectionIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "classSelectorGetClassesFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "connectionIds",
          "variableName": "connectionIds"
        }
      ],
      "concreteType": "ClassConnection",
      "kind": "LinkedField",
      "name": "getClasses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClassEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Class",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "a9e97efe0128899b11d0c35d0f8200e5";

export default node;
