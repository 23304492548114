import { useUserConnectionOauthCallbackGoogleMutation } from "./__generated__/useUserConnectionOauthCallbackGoogleMutation.graphql"
import { notifications } from "@shared/ui/notifications"
import { useEffect, useRef } from "react"
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import { useSearchParams } from "react-router-dom"

const UseUserConnectionOauthCallbackGoogleMutation = graphql`
  mutation useUserConnectionOauthCallbackGoogleMutation(
    $input: GoogleOauthCallbackInput!
    $userConnections: [ID!]!
  ) {
    googleOauthCallback(input: $input) {
      userConnection
        @appendNode(
          connections: $userConnections
          edgeTypeName: "UserIntegration"
        ) {
        name
        id
        system {
          category
          name
          slug
        }
        status
      }
      errors
    }
  }
`

export const useUserConnectionOauthCallback = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const authResponseCode = searchParams.get("code")
  const redirectSource = searchParams.get("source")
  const mutationLoading = useRef(false)

  const [googleCallback, googleCallbackLoading] =
    useMutation<useUserConnectionOauthCallbackGoogleMutation>(
      UseUserConnectionOauthCallbackGoogleMutation
    )

  const successHandler = () => {
    notifications.show({
      title: "Personal connection Successfully Linked!",
      message:
        "The personal connection has been successfully added from the system.",
      variant: "success",
    })

    setSearchParams()
  }

  const errorHandler = () => {
    notifications.show({
      title: "Ops, personal connection failed!",
      message: "Try again later. ",
      variant: "error",
    })
  }

  useEffect(() => {
    if (!authResponseCode || !redirectSource || mutationLoading.current) {
      return
    }

    const generalUserconnection = ConnectionHandler.getConnectionID(
      "root",
      "UserConnections_getUserIntegrations"
    )
    const integrationsUserConnection = ConnectionHandler.getConnectionID(
      "root",
      "UserConnectionsTableFragment_getUserIntegrations"
    )

    const userConnections = [generalUserconnection, integrationsUserConnection]

    switch (redirectSource) {
      case "google": {
        googleCallback({
          variables: {
            input: {
              code: authResponseCode,
            },
            userConnections: userConnections,
          },
          onCompleted: (response) => {
            const errors = response.googleOauthCallback?.errors

            if (errors && errors[0]) {
              notifications.show({
                title: "Personal connection Error",
                message: response.googleOauthCallback?.errors[0],
                variant: "error",
              })
              setSearchParams()
            } else {
              successHandler()
            }
          },
          onError: errorHandler,
        })
        break
      }
      case "microsoft": {
        break
      }
    }

    return () => {
      mutationLoading.current = true
    }
  }, [])

  return googleCallbackLoading
}
