import styles from "./server-error.module.scss"
import { Box, Button, Code, Text } from "@mantine/core"
import ServerErrorImage from "@shared/assets/errors/server-error.svg"
import { FallbackProps } from "react-error-boundary"
import { useLocation } from "react-router-dom"

type Props = {
  showReload?: boolean
  error?: FallbackProps
}

export const ServerError = ({ showReload = true, error }: Props) => {
  const location = useLocation()
  const queryErrors = error?.error.message

  return (
    <Box className={styles.ServerError}>
      <img src={ServerErrorImage} />
      <Text c="gray">Oops! Something went wrong.</Text>
      {queryErrors && (
        <Code block>
          <b>Error: </b>
          {queryErrors}
        </Code>
      )}
      {showReload && (
        <Button onClick={() => (window.location.href = location.pathname)}>
          Reload
        </Button>
      )}
    </Box>
  )
}
