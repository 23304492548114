import { firmSettingsGetUserFirmQuery } from "./__generated__/firmSettingsGetUserFirmQuery.graphql"
import { EditFirm } from "./edit-firm"
import { editFirmUpdateFirmMutation } from "./edit-firm/__generated__/editFirmUpdateFirmMutation.graphql"
import { UpdateFirm } from "./edit-firm/edit-firm"
import { FirmUsersTable } from "./firm-users-table"
import { InviteUserDrawer } from "./invite-user-drawer"
import {
  Box,
  Button,
  Card,
  Group,
  Space,
  Stack,
  Text,
  rem,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useUserStore } from "@shared/store"
import { AppDrawer } from "@shared/ui/app-drawer"
import { ImageBox } from "@shared/ui/image-box"
import { notifications } from "@shared/ui/notifications"
import { getAvatarText, getFormattedDate } from "@shared/utils/helpers"
import { graphql, useLazyLoadQuery, useMutation } from "react-relay"

const GetFirm = graphql`
  query firmSettingsGetUserFirmQuery {
    getUserFirm {
      createdAt
      id
      name
      logo
      slug
      creator {
        fullName
      }
      ...firmUsersTableFragment
      ...inviteUserDrawerFragment
    }
  }
`

export const FirmSettings = () => {
  const { getUserFirm: firmData } =
    useLazyLoadQuery<firmSettingsGetUserFirmQuery>(GetFirm, {})
  const [openedEditDrawer, { open: openEditDrawer, close: closeEditDrawer }] =
    useDisclosure(false)
  const [
    openedInviteDrawer,
    { open: openInviteDrawer, close: closeInviteDrawer },
  ] = useDisclosure(false)
  const { isAdmin } = useUserStore()

  const [updateFirm, updateFirmLoading] =
    useMutation<editFirmUpdateFirmMutation>(UpdateFirm)

  const updateLogoCallback = (file: string) => {
    if (!firmData?.id) return
    updateFirm({
      variables: {
        input: {
          logo: file,
          firmId: firmData?.id,
        },
      },
      onError: (error) => {
        notifications.show({
          title: error.message,
          message: "Please try again",
          variant: "error",
        })
      },
    })
  }

  return (
    <Box p={rem(32)}>
      <AppDrawer
        title="Edit Firm Information"
        opened={openedEditDrawer}
        onClose={closeEditDrawer}
      >
        <EditFirm
          firmId={firmData?.id}
          name={firmData?.name ?? ""}
          close={closeEditDrawer}
        />
      </AppDrawer>

      {firmData && (
        <InviteUserDrawer
          firmId={firmData.id}
          firmSettings={firmData}
          opened={openedInviteDrawer}
          close={closeInviteDrawer}
        />
      )}

      <Text size="xxl" fw="bold" c="gray">
        Firm Settings
      </Text>
      <Space h={rem(56)} />
      <Group justify="space-between">
        <Stack gap="0.5rem">
          <Text fw="bold" c="gray.7">
            Firm Information
          </Text>
          {firmData?.createdAt ? (
            <Text size="sm" c="gray">
              Created by <b>{`${firmData.creator.fullName ?? ""}`}</b> on{" "}
              <b>{getFormattedDate(new Date(firmData.createdAt))}</b>
            </Text>
          ) : (
            <></>
          )}
        </Stack>
        {isAdmin && (
          <Button
            variant="outline"
            px={rem(16)}
            fw="normal"
            w={rem(112)}
            onClick={openEditDrawer}
          >
            Edit
          </Button>
        )}
      </Group>
      <Space h={rem(32)} />
      <Card shadow="xs" radius="md" px={rem(24)} py={rem(16)}>
        <Stack gap={rem(12)} py={rem(8)}>
          <Text size="sm" fw="bold" c="dark.3">
            Firm Name
          </Text>
          <Text size="sm" c="gray.7">
            {firmData?.name}
          </Text>
        </Stack>
      </Card>
      <Space h={rem(32)} />
      <ImageBox
        title="Firm Logo"
        uploadText="Upload"
        placeholder={getAvatarText(firmData?.name ?? "")}
        placeholderColor="lightBlue"
        image={firmData?.logo ?? ""}
        loading={updateFirmLoading}
        imageUpdaterCallback={updateLogoCallback}
        shouldUpdate={isAdmin ?? false}
      />
      <Space h={rem(32)} />
      <Group justify="space-between">
        <Text fw="bold" c="gray.7">
          Users
        </Text>
        {isAdmin && (
          <Button
            variant="outline"
            px={rem(16)}
            fw="normal"
            w={rem(112)}
            onClick={openInviteDrawer}
          >
            Invite User
          </Button>
        )}
      </Group>
      <Space h={rem(32)} />
      {firmData && <FirmUsersTable firmSettings={firmData} />}
    </Box>
  )
}
