import { workbookSyncButtonMutation as WorkbookSyncButtonMutationType } from "./__generated__/workbookSyncButtonMutation.graphql"
import { Button, ButtonProps, Flex, Loader, Text } from "@mantine/core"
import { notifications } from "@shared/ui/notifications"
import { IconRefresh } from "@tabler/icons-react"
import { graphql, useMutation } from "react-relay"

const WorbookSyncButtonMutation = graphql`
  mutation workbookSyncButtonMutation($input: SyncWorkbookMutationInput!) {
    syncWorkbook(input: $input) {
      errors
      workbook {
        id
        name
        syncPeriod
        lastSync
        workbookSpreadsheets {
          edges {
            node {
              createdAt
              id
              lastSync
              name
              status
              syncPeriod
              spreadsheetable {
                ... on MetabaseQuestion {
                  id
                  metabaseQuestionId
                  name
                  status
                }
                ... on SavedStatement {
                  id
                  name
                  updatedAt
                }
                ... on SavedTransaction {
                  id
                  name
                  updatedAt
                }
              }
            }
          }
        }
        userConnection {
          user {
            fullName
          }
          status
          id
        }
      }
    }
  }
`

type Props = ButtonProps & {
  workbookId: string
  onClick: () => void
}
export const WorkbookSyncButton = ({ workbookId, onClick, ...rest }: Props) => {
  const [syncWorkbook, syncWorkboorLoading] =
    useMutation<WorkbookSyncButtonMutationType>(WorbookSyncButtonMutation)

  const handleSync = () => {
    syncWorkbook({
      variables: {
        input: {
          workbookId: workbookId,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "Workbook sync started",
          message: "The data will start syncing now",
        })
      },
      onError: (error) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: error.message,
        })
      },
    })
  }

  return rest.loading ? (
    <Flex gap={"0.5rem"} align="center">
      <Loader size={"0.875rem"} />
      <Text size="sm">Syncing</Text>
    </Flex>
  ) : (
    <Button
      leftSection={<IconRefresh size={12} />}
      size="compact-sm"
      fz="0.75rem"
      lh={"0.875rem"}
      fw="normal"
      pl="0.75rem"
      loading={syncWorkboorLoading}
      onClick={() => {
        onClick()
        handleSync()
      }}
      {...rest}
    >
      Sync Data
    </Button>
  )
}
