import { forgotPasswordGenerateResetPasswordTokenMutation } from "./__generated__/forgotPasswordGenerateResetPasswordTokenMutation.graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  Button,
  Divider,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core"
import { notifications } from "@shared/ui/notifications"
import { pathConstants } from "frontend/routes/path-constants"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { graphql, useMutation } from "react-relay"
import { useNavigate } from "react-router-dom"
import { z } from "zod"

const FormSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Please fill out this required field." })
    .email("Email address is not valid."),
})

type formValues = z.infer<typeof FormSchema>

const ForgotPasswordMutation = graphql`
  mutation forgotPasswordGenerateResetPasswordTokenMutation(
    $input: GenerateResetPasswordTokenInput!
  ) {
    generateResetPasswordToken(input: $input) {
      success
    }
  }
`

export const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<formValues>({
    resolver: zodResolver(FormSchema),
  })
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const [generateResetPasswordToken, generateResetPasswordTokenLoading] =
    useMutation<forgotPasswordGenerateResetPasswordTokenMutation>(
      ForgotPasswordMutation
    )

  const onSubmit: SubmitHandler<formValues> = ({ email }) => {
    generateResetPasswordToken({
      variables: { input: { email } },
      onCompleted: () => {
        setSuccess(true)
      },
      onError: (error) => {
        notifications.show({
          title: "Error",
          message: error.message,
          variant: "error",
        })
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper shadow="xs" w={rem(504)} p={rem(32)}>
        {success ? (
          <Stack gap={rem(32)}>
            <Title order={4} ta="center">
              {"E-mail Sent!"}
            </Title>
            <Divider />
            <Text ta="center" c="gray" size="sm">
              {
                "If an account with this email exists, we will send an E-mail to "
              }
              <Text fw={500} component="span">
                {getValues("email")}
              </Text>
              {" with instructions on how to reset your password."}
            </Text>
            <Button
              variant="transparent"
              onClick={() =>
                navigate(pathConstants.PUBLIC.SIGN_IN, { replace: true })
              }
            >
              Back to Login
            </Button>
          </Stack>
        ) : (
          <Stack gap={rem(32)}>
            <Stack gap={0}>
              <Title order={3} ta="center">
                Forgot your password?
              </Title>
              <Text ta="center" c="gray" size="sm">
                No problem, this happens all the time!
              </Text>
            </Stack>
            <Divider />
            <Stack gap={rem(16)}>
              <Text c="gray" size="sm">
                {
                  "Enter your account's email, and we'll send a password reset link."
                }
              </Text>
              <TextInput
                label="E-mail"
                {...register("email")}
                type="email"
                inputMode="email"
                error={errors.email?.message}
                autoFocus
              />
            </Stack>
            <Button
              disabled={!isValid}
              type="submit"
              loading={generateResetPasswordTokenLoading}
            >
              Send Email
            </Button>
            <Button
              variant="transparent"
              onClick={() =>
                navigate(pathConstants.PUBLIC.SIGN_IN, { replace: true })
              }
            >
              Back to Login
            </Button>
          </Stack>
        )}
      </Paper>
    </form>
  )
}
