import { signUpGetOnboardingInfoQuery } from "./__generated__/signUpGetOnboardingInfoQuery.graphql"
import { OwnerSignUp } from "./owner-sign-up"
import { UserSignUp } from "./user-sign-up"
import { Container, Loader } from "@mantine/core"
import { useUserStore } from "@shared/store"
import { graphql, useLazyLoadQuery } from "react-relay"
import { Navigate, useSearchParams } from "react-router-dom"

const GetOnboardingInfo = graphql`
  query signUpGetOnboardingInfoQuery($token: String!) {
    getOnboardingInfo(invitationToken: $token) {
      errors
      user {
        isFirmOwner
      }
      ...userSignUpFragment
      ...ownerSignUpFragment
    }
  }
`

const SignUpMutation = graphql`
  mutation signUpMutation($input: SaveOnboardingInfoInput!) {
    saveOnboardingInfo(input: $input) {
      errors
      user {
        email
      }
    }
  }
`

export const SignUp = () => {
  const [searchParams] = useSearchParams()
  const invitationToken = searchParams.get("invitation-token")
  const { alpyneToken } = useUserStore()

  const { getOnboardingInfo } = useLazyLoadQuery<signUpGetOnboardingInfoQuery>(
    GetOnboardingInfo,
    { token: invitationToken ?? "" }
  )

  if (alpyneToken) {
    return <Navigate to="/portfolio" replace />
  }

  if (!getOnboardingInfo) {
    return (
      <Container fluid h={"100%"}>
        <Loader />
      </Container>
    )
  }

  return getOnboardingInfo?.user?.isFirmOwner ? (
    <OwnerSignUp
      invitationToken={invitationToken ?? ""}
      onboardingUser={getOnboardingInfo}
      mutation={SignUpMutation}
    />
  ) : (
    <UserSignUp
      invitationToken={invitationToken ?? ""}
      onboardingUser={getOnboardingInfo}
      mutation={SignUpMutation}
    />
  )
}
