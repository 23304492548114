/**
 * @generated SignedSource<<9ea414d4db191380a6bbd14d86d4a7d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CashFlowCategoryEnum = "FINANCING" | "INVESTING" | "OPERATING";
export type IncomeCashFlowSettingMutationInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type addAccountIncomeCashFlowSettingMutation$variables = {
  connections: ReadonlyArray<string>;
  input: IncomeCashFlowSettingMutationInput;
};
export type addAccountIncomeCashFlowSettingMutation$data = {
  readonly incomeCashFlowSetting: {
    readonly accounts: ReadonlyArray<{
      readonly accountSubtype: string | null;
      readonly accountType: string | null;
      readonly active: boolean | null;
      readonly cashFlowCategory: CashFlowCategoryEnum | null;
      readonly createdAt: string;
      readonly description: string | null;
      readonly displayName: string;
      readonly id: string;
      readonly includeInCashFlow: boolean | null;
      readonly name: string | null;
      readonly number: string | null;
      readonly parent: number | null;
      readonly subaccount: string | null;
      readonly updatedAt: string;
    }> | null;
  } | null;
};
export type addAccountIncomeCashFlowSettingMutation = {
  response: addAccountIncomeCashFlowSettingMutation$data;
  variables: addAccountIncomeCashFlowSettingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "LedgerAccount",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountSubtype",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accountType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cashFlowCategory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "includeInCashFlow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subaccount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "addAccountIncomeCashFlowSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "IncomeCashFlowSettingMutationPayload",
        "kind": "LinkedField",
        "name": "incomeCashFlowSetting",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "addAccountIncomeCashFlowSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "IncomeCashFlowSettingMutationPayload",
        "kind": "LinkedField",
        "name": "incomeCashFlowSetting",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "accounts",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "LedgerAccount"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d47424baab0ebc7f01fe6f562459f1d7",
    "id": null,
    "metadata": {},
    "name": "addAccountIncomeCashFlowSettingMutation",
    "operationKind": "mutation",
    "text": "mutation addAccountIncomeCashFlowSettingMutation(\n  $input: IncomeCashFlowSettingMutationInput!\n) {\n  incomeCashFlowSetting(input: $input) {\n    accounts {\n      accountSubtype\n      accountType\n      active\n      cashFlowCategory\n      createdAt\n      description\n      displayName\n      id\n      includeInCashFlow\n      name\n      number\n      parent\n      subaccount\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddfd894ffeec86187506d8e90444b982";

export default node;
