import { pathConstants } from "./path-constants"
import { ResetPassword } from "@components/reset-password"
import { SharedDashboard } from "@components/shared-dashboard"
import { SignIn } from "@components/sign-in"
import { SignUp } from "@components/sign-up"
import { PublicLayout } from "@shared/layouts/public-layout/public-layout"
import type { RouteObject } from "react-router-dom"

export const PublicRoutes: RouteObject[] = [
  {
    path: pathConstants.ROOT,
    element: <PublicLayout />,
    children: [
      {
        path: pathConstants.PUBLIC.SIGN_IN,
        element: <SignIn />,
      },
      {
        path: pathConstants.PUBLIC.RESET_PASSWORD,
        element: <ResetPassword />,
      },
      {
        path: pathConstants.PUBLIC.SIGN_UP,
        element: <SignUp />,
      },
    ],
  },
  {
    path: pathConstants.PUBLIC.DASHBOARD,
    element: <SharedDashboard />,
    index: true,
  },
]
