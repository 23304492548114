import { deleteClientModalDeleteClientMutation as DeleteClientModalDeleteClientMutationType } from "./__generated__/deleteClientModalDeleteClientMutation.graphql"
import { updateCurrentMetabaseTokenCache } from "@shared/relay/utils/cache"
import { ConfirmationModal } from "@shared/ui/confirmation-modal"
import { notifications } from "@shared/ui/notifications"
import { pathConstants } from "frontend/routes/path-constants"
import { ConnectionHandler, graphql, useMutation } from "react-relay"
import { useNavigate } from "react-router-dom"

const DeleteClientModalDeleteClientMutation = graphql`
  mutation deleteClientModalDeleteClientMutation(
    $input: DeleteClientMutationInput!
  ) {
    deleteClient(input: $input) {
      user {
        id
        ...navbarUserFragment
        currentClient {
          id
          name
          metabaseDefaultDashboardId
        }
      }
      client {
        id
        status
      }
      metabaseJwtToken
    }
  }
`

type ClientType = {
  id: string
  name: string
  metabaseDefaultDashboardId?: number
}

type Props = {
  clientData: ClientType
  opened: boolean
  onClose: () => void
}

export const DeleteClientModal = ({ clientData, opened, onClose }: Props) => {
  const navigate = useNavigate()
  const [deleteClientMutation, deleteClientLoading] =
    useMutation<DeleteClientModalDeleteClientMutationType>(
      DeleteClientModalDeleteClientMutation
    )

  const hasName = clientData.name == "--" ? false : true

  const handleDeleteClick = () => {
    deleteClientMutation({
      variables: {
        input: {
          clientId: clientData.id,
        },
      },
      onCompleted: () => {
        notifications.show({
          variant: "success",
          title: "Client Successfully Deleted",
          message: "It will take a while to delete all the data.",
        })
        onClose()
        navigate(pathConstants.PORTFOLIO)
      },
      updater: (store, data) => {
        const newToken = data.deleteClient?.metabaseJwtToken
        updateCurrentMetabaseTokenCache(store, data, newToken as string)

        const clients = ConnectionHandler.getConnection(
          store.getRoot(),
          "ClientsTableFragment_getClients"
        )

        if (clients) {
          ConnectionHandler.deleteNode(clients, clientData.id)
        }

        const currentUser = store
          .getRoot()
          .getLinkedRecord("getCurrentUserData")
          ?.getLinkedRecord("user")

        if (currentUser) {
          const selectorClients = ConnectionHandler.getConnection(
            currentUser,
            "ClientSelectorClientsFragment_clients"
          )
          if (selectorClients) {
            ConnectionHandler.deleteNode(selectorClients, clientData.id)
          }
        }

        const currentClient = store
          .getRoot()
          .getLinkedRecord("getCurrentUserData")
          ?.getLinkedRecord("user")
          ?.getLinkedRecord("currentClient")

        if (data.deleteClient?.user?.currentClient) {
          const newCurrentClient: Partial<ClientType> =
            data.deleteClient?.user?.currentClient

          currentClient?.setValue(newCurrentClient.id, "id")
          currentClient?.setValue(
            newCurrentClient.metabaseDefaultDashboardId,
            "metabaseDefaultDashboardId"
          )
          currentClient?.setValue(newCurrentClient.name, "name")
        }

        const currentUserData = store
          .getRoot()
          .getLinkedRecord("getCurrentUserData")
          ?.getLinkedRecord("user")
          ?.getLinkedRecord<{ count: number }>("clients")

        const clientStored = store
          .getRoot()
          .getLinkedRecord<{ count: number }>("getClients")

        if (clientData && clientStored) {
          const countValue = clientStored.getValue("count") ?? 1
          clientStored.setValue(countValue - 1, "count")
        }

        if (clientData && currentUserData) {
          const countValue = currentUserData.getValue("count") ?? 1
          currentUserData.setValue(countValue - 1, "count")
        }
      },
      onError: (e) => {
        notifications.show({
          variant: "error",
          title: "An error ocurred",
          message: e.message,
        })
      },
    })
  }

  return (
    <ConfirmationModal
      title={`Are you sure you want to delete ${
        hasName ? `"${clientData.name}"` : "this client"
      }?`}
      opened={opened}
      onClose={() => {
        onClose()
      }}
      cancelButtonProps={{
        onClick: () => {
          onClose()
        },
      }}
      confirmButtonProps={{
        onClick: handleDeleteClick,
        children: "Delete Client",
        color: "red",
        loading: deleteClientLoading,
      }}
      fieldNameType="client"
      fieldNameValidation={hasName}
      fieldNameValue={clientData.name}
      fieldNameValidationLabel="Client Name"
      fieldNameValidationPlaceholder="Enter the exact client name to proceed"
    >
      Deleting a client is a permanent action, resulting in the loss of all
      data, connections, and boards. Additionally, existing member accounts will
      be removed.
    </ConfirmationModal>
  )
}
