import { useUserStore } from "@shared/store"
import axios from "axios"
import {
  Environment,
  Network,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime"

const publicAxios = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
})

publicAxios.interceptors.response.use((response) => {
  const authToken = response.headers?.authorization

  if (typeof authToken === "string") {
    useUserStore.getState().setAlpyneToken(authToken)
  }

  return response
})

async function fetchRelay(params: RequestParameters, variables: Variables) {
  const { data } = await publicAxios.post(
    import.meta.env.VITE_API_URL,
    JSON.stringify({
      query: params.text,
      variables,
    })
  )

  if (Array.isArray(data.errors)) {
    const error = data.errors[0].message as string
    throw new TypeError(error)
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data
}

export default new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource(), {
    gcReleaseBufferSize: 10,
  }),
})
