import { InputBase, Popover } from "@mantine/core"
import { MonthPicker } from "@mantine/dates"
import { themeVars } from "@shared/theme"
import { IconChevronDown } from "@tabler/icons-react"
import { format } from "date-fns"
import { Ref, forwardRef } from "react"
import { ControllerRenderProps } from "react-hook-form"

export const CloseMonthPicker = forwardRef(function CloseMonthPicker(
  { onBlur, onChange, value }: Omit<ControllerRenderProps, "ref" | "name">,
  ref: Ref<HTMLDivElement>
) {
  return (
    <Popover shadow="xs" position="bottom-start" width="target">
      <Popover.Target>
        <InputBase
          component="button"
          type="button"
          withAsterisk
          pointer
          label="Close Month"
          rightSection={
            <IconChevronDown size={16} color={themeVars.colors.gray[6]} />
          }
          rightSectionPointerEvents="none"
        >
          {format(value, "MMM, yyyy")}
        </InputBase>
      </Popover.Target>
      <Popover.Dropdown p="1rem">
        <MonthPicker
          value={value}
          onChange={onChange}
          ref={ref}
          onBlur={onBlur}
          styles={{
            levelsGroup: {
              justifyContent: "center",
            },
          }}
        />
      </Popover.Dropdown>
    </Popover>
  )
})
